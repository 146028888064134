import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { BroadcastService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';

@Component({
  selector: 'app-ls-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class LSSearchInputComponent implements OnInit {
  @Input() placeholder:string = 'Search';
  @Output() onSearch:EventEmitter<string> = new EventEmitter();
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
  @Input() DisableSearch:boolean = false;
  constructor(
    private broadCastService: BroadcastService,
    private translateService:TranslateService
    ) {
      this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); 
     }

  ngOnInit(): void {

    this.broadCastService
      .on(MsgConstantConfig.RESET_DATA_WAREHOUSE_ACCORDION_SEARCH)
      .subscribe((field: any) => {
        console.log('broadcast : ',this.searchInput.nativeElement.value)
        this.searchInput.nativeElement.value = '';
      });

    //search box functionality
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
     map((event: any) => event.target.value),
     debounceTime(1500),
     distinctUntilChanged()
   ).subscribe((text: string) => {
     this.onSearch.emit(text);
   });

  }

}
