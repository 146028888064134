import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { HttpInterceptorClass } from './interceptors/http.interceptor';
import { HeaderModule } from './modules/header/header.module';
import { BaseService } from './services/base.service';
import { BaseComponent } from './components/base.component';
import { PipesModule } from './pipes/pipes.module';
import { BroadcastService } from './services/broadcaster.service';
import { HttpService } from './services/http.service';
import { LocalStorageService } from './services/local-storage.service';
import { AppRoutingModule } from './routing/app-routing.module';
import { GlobalErrorHandler } from './services/error.handler.service';
import { LeftNavigationModule } from './modules/left-navigation/left-navigation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth-service';
import { EncryptDecryptService } from './services/encrypt-decrypt.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MessageService } from './services/message.service';
import { NgBootstrapDarkmodeModule} from 'ng-bootstrap-darkmode';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from 'ng-bootstrap-darkmode';
import { UserService } from './services/user.service';
import { AppInitService } from './services/app-init-service';
import { ClientDetailService } from './services/client-detail-service';
import { NgIdleModule } from '@ng-idle/core';
import { PermissionService } from './services/permission.service';
import { DirectivesModule } from './directives/directives.module';
import { ApplicantMobileChangeService } from './services/applicant-mobile-change.service';
import { QBTokenChangeService } from './services/qb_token_change-service';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<boolean> => {
    return appInitService.init();
  }
}

@NgModule({
    imports: [
      HeaderModule,
      LeftNavigationModule,
      PipesModule,
      AppRoutingModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      BrowserAnimationsModule,
      ToastrModule.forRoot({
        enableHtml:true,
        preventDuplicates: true,
        closeButton: true,
      }),
      NgBootstrapDarkmodeModule,
      NgbModule,
      NgIdleModule.forRoot(),
      DirectivesModule
    ],
    declarations: [
      BaseComponent,
    ],
    providers: [
      BaseService,
      BroadcastService,
      GlobalErrorHandler,
      HttpService,
      LoaderService,
      AuthService,
      LocalStorageService,
      EncryptDecryptService,
      MessageService,
      UserService,
      ClientDetailService,
      PermissionService,
      ApplicantMobileChangeService,
      QBTokenChangeService,
      { provide: APP_INITIALIZER,useFactory: initializeApp, deps: [AppInitService], multi: true},
      { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorClass, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor,multi: true}

    ],

    exports:[
      HeaderModule,
      LeftNavigationModule,
      PipesModule,
      AppRoutingModule,
      BaseComponent,
      FormsModule,
      ReactiveFormsModule,
      NgBootstrapDarkmodeModule,
      NgbModule,
      DirectivesModule
    ]
})
export class CoreModule {
}

export * from './services/loader.service';
export * from './services/base.service';
export * from './services/broadcaster.service';
export * from './services/error.handler.service';
export * from './services/http.service';
export * from './services/local-storage.service';
export * from './services/message.service';
export * from './services/encrypt-decrypt.service';
export * from './services/user.service';
export * from './services/permission.service';
export {ThemeService}
