<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<ng-container>
  <div class="modal-header align-items-center" >
    <h6 class="modal-title modal-title-success">
     {{modalTitle|translate}}
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-0" >

    <div class="lms-module-card">
      <div class="card-body">
        <div class="card-form-content card-form-success">
          <div class="bg-note-info p-2 mb-3">
            {{modalSubTitle|translate}}
          </div>
          <div class="table-responsive">
            <table class="table table-sm table-no-bg table-align-middle mb-0">
              <thead>
                <tr>
                  <td class="border-0">{{'Field Name'|translate}}</td>
                  <td class="border-0">{{'Before'|translate}}</td>
                  <td class="border-0"></td>
                  <td class="border-0">{{'After'|translate}}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{'Fee Accrual'|translate}}</td>
                  <td>{{(this.loanDetail?.late_fee_accrual_on_yn=='YES'? 'Yes' : 'No')|translate }}</td>
                  <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                  </td>
                  <td class="">
                    {{(this.loanDetail?.late_fee_accrual_on_yn=='YES'? 'No' : 'Yes')|translate }}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <div>

    </div>
    <div>
      <button  type="button" class="btn btn-success-light btn-150" (click)="submit()">
        {{'Submit'|translate}}
      </button>

    </div>
  </div>
</ng-container>
</div>
</div>
