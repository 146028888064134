import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { interval, Observable, Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/core/components/base.component';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { PathConfig } from '../../config/path.config';
import {
  UserService,
  BaseService,
  ThemeService,
  HttpService,
  MessageService,
  LocalStorageService,
  PermissionService,
  LoaderService,
} from './../../../core';

import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { UserInfo } from '../../models/user/user-info.model';
import { ClientDetailService } from '../../services/client-detail-service';
import { environment } from 'src/environments/environment';
import { ClientUtils } from '../../utils/clientUtil';
import { BroadcastService } from '../../services/broadcaster.service';
import { MsgConstantConfig } from '../../config/message.constant.config';
import { CallerComponent } from 'src/app/modules/caller/caller.component';
import { Utility } from '../../utils/utility';
import moment from 'moment';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectedUserServiceService } from 'src/app/modules/servicing/queues/queues-datatable/selected-user-service.service';
import { ApplicantMobileChangeService } from '../../services/applicant-mobile-change.service';
import { MODULES } from '../../config/enum';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { includes } from 'lodash';
import { AnyARecord } from 'dns';
import { AuthService } from '../../services/auth-service';
import { TranslateService } from '@ngx-translate/core';
import { MultilingualService } from './multilingual.service';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

/**
 * Class which provide header functionalities
 */
export class HeaderComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('MessageModal', { static: true }) messageModalTemplate!: TemplateRef<any>;
  @ViewChild('customSearch', { static: true }) customSearchElement!: ElementRef<HTMLInputElement>;
  sectionTitle: string = '';
  eventSubscription: Array<Subscription> = [];
  userName: string = '';
  userDesignation: string = '';
  userProfilePic: string = '';
  userTheme: string = '';
  input: string = '';
  result: string = '';
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;

  isTemplateSelectionScreenActive:boolean=false;
  themeOptions: Array<any> = [
    { label: 'Light Mode', value: 'light' },
    { label: 'Dark Mode', value: 'dark' },
  ];
  isChangePassword: boolean = false;
  isSearchOpen: boolean = false;
  searchData:Array<any> =  [];
  logo: string = '';
  profilePicture: string = '';
  profile_picture_temp_url: string = '';
  loginAsClient: boolean = false;
  showMessage: boolean = true;
  showMessageChat: boolean = false;
  showMessageTemplate: boolean = false;
  showMessageOptin: boolean = false;
  showMessageOptinSend: boolean = false;
  showMessageOptinVerify: boolean = false;
  showMessageHeader:string='MESSAGES';
  isPinNotValid:boolean=true;
  pin:string='';
  isAccountSelected:boolean=false;
  TIME_FORMAT = ConstantConfig.TIME_FORMAT_HALF;
  DATE_TIME_FORMAT = ConstantConfig.DATE_FORMAT;
  apiTextMesasages : Array<any> = [];
  apiLoanDetailResponse:any;
  another_apiLoanDetailResponse:any;
  last_apiLoanDetailResponse:any;
  selectedLoanId:any;
  selected_applicant_id:any;
  selected_type:string='App';
  last_visited_page:any="showMessage";
  apiTemplates:any=[];
  loanID :any ='';
  selected_template_id:any=null;
  form!: UntypedFormGroup;
  searchForm!:UntypedFormGroup;
  pin_form!: UntypedFormGroup;

  textMessages:any=[];
  current_user_type:string='all';
  isTemplateMessageSend:boolean=false;
  isMessageBoxFilled:boolean=true;
  isFreeMessage:boolean=false;
  freeMessage:string='';
  selectedHeaderName='None';
  total_unread:number=0;
  selectedAccountStatus:string='';
  countUnreadMessage:number = 0;
  // countDeactivated:number = 0;
  showCountMessage:String='';
  // showCountDeactivated:String='';
  unreadmessagescount:number=0;
  SMSPermissionStatus:any;
  thisAccountStatus:boolean=false;
  allAccountsStatus:boolean=false;
  CallPermissionStatus:boolean=true;
  loadAllMessages:boolean=true;
  messageSearchKey:string='';
  imageUrl:any='';
  prof_pic:string='';
  current_user_id:any='';

  totalUnreadMessagesCount:number = 0;
  selectedLoanUnreadMessageCount:number =0;

  loadingMessages:boolean=false;
  insideLoanWorkScreen:boolean=false;

  selecteduser:any;
  selectedMainStatus:any;
  selected_sub_status:any;
  selectedpriority:any;
  pri:any;

  queueType:any;
  sortBy:any;
  orderBy:any;
  pageLimit:any;
  currentPage:any;
  receivedValue: any;
  private subscription: Subscription;
  searchPermission:any = false;
  is_In_queue:boolean=true;
  current_applicant_id:any=0;
  saved_applicant_id:any=null;
  last_un_read:any=0;
  ALLSMSPermission:string = 'NO';
  isURL: boolean = false;
  is_scroll_chat : boolean = false;
  is_reload_chat : boolean = false;
  any_unread_message : boolean = false;
  is_sending_event   : boolean = false;
  private unreadapiCallInProgress = false;
  enableForLoanDetailAPI : boolean = false;
  unread_applicants_counts : number = 0;
  can_call_loan_detail : boolean =false;
  is_loan_id_Selected : boolean = false;
  isLoading = false;
  current_page_no = 1;
  isloanDetailInProcess : boolean =false;
  thisAccountIsLoading  : boolean =false;
  selected_user : any = null;
  last_total_unread_messages:number = 0 ;
  total_unread_messages : number = 0;
  current_mode : boolean = true;
  is_loanTextMessagesInProcess : boolean =false;
  current_saved_applicant : any = null;
  select_another_loan : boolean = false;
  unreadApplicantIds : Array<any> = [];
  unreadCompanyIds : Array<any> = [];

  total_messages : number = 0;
  load_messages_per_page : number =5;
  total_pages :number = 0;
  current_screen_status : string = 'OTHER';
  search_flag :boolean = false;
  pre_search_key : string = '';
  is_mode_change : boolean = false;
  isGlobalSearchInRunning : boolean = false;
  sms_service : string = '';
  Login_as_lendisoft: any;
  showClientBanner: boolean = false;
  maintenanceStartWarning: boolean = false;
  isMaintenancModeOn: boolean = false;
  clientName: string;
  isReLoginModalOpen: boolean = false;
  searchTextMessage: any  = '';
  isScrolled: boolean = false;
  totalPage: number = 1;
  chatMessageList: Array<any> = [];
  chatMessageListLoading: boolean = false;
  currentChatPage: number = 1;
  chatMessageLimit:number = 20;
  vendorConfig:any;
  auto_subscribe:any = 1;
  scrollUpDistance = 1.5;
  dnc:boolean = false;
  selectedLanguage: any;
  selectedLanguageIcon: any;
  dropdownOpen: boolean = false;
  canAccessLoanInformation:boolean = false;

  constructor(
    protected _baseService: BaseService,
    private httpService: HttpService,
    private localStorageService: LocalStorageService,
    private permissionService: PermissionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private route: ActivatedRoute,
    private msgService: MessageService,
    private themeService: ThemeService,
    private userService: UserService,
    private modalService: NgbModal,
    private clientDetailService: ClientDetailService,
    private broadCastService: BroadcastService,
    private selectedUserIdServiceService : SelectedUserServiceService,
    private dataSharingService: ApplicantMobileChangeService,
    private loaderService: LoaderService,
    private sanitizer: DomSanitizer,
    private auth:AuthService,
    private translateService:TranslateService,
    private el: ElementRef,
    private multilingualService:MultilingualService
  ) {
    
    super(_baseService);

    this.showClientBanner = ClientUtils.isLoginAsClient(this.clientDetailService, this.userService);
    this.clientName = this.clientDetailService.getClientInfo('name');
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    const routeEvents = this.router.events.subscribe((event) => {
      const storedLanguage = localStorage.getItem('Selected_Language') ?? 'en';
      if (event instanceof NavigationEnd) {
        this.sectionTitle = this.route.snapshot.firstChild?.data?.title || '';
        this.is_In_queue = (this.router.url.indexOf("servicing/queues/")!=-1) ? true : false;
        this.thisAccountStatus = this.is_In_queue ? false : true;
        if(this.router.url.indexOf("servicing/queues/")!=-1)
        {
          this.current_screen_status='QUEUES';
        }
        else if(this.router.url.indexOf("servicing/queues-detail/")!=-1)
        {
          this.current_screen_status='QUEUES_DETAIL';
        }
        else
        {
          this.current_screen_status='OTHER';
        }

        this.unReadText();
      }
      this.getLoanId();

      if (storedLanguage) {
        const language = this.languages.find(lang => lang.selectedLanguage === storedLanguage);
        if (language) {
          this.selectedLanguage = language.selectedLanguage;
          this.selectedLanguageIcon = language.icon;
        }
      }
    
    });
    this.eventSubscription.push(routeEvents);
    // this.logo = environment.IMG_URL + this.clientDetailService.LOGO_DARK;
    this.imageUrl = environment?.IMG_URL;
    this.SMSPermissionStatus = this.permissionService.specialPermissionDetail('send_sms');
    this.searchPermission = this.permissionService?.isAccess(MODULES.QUEUES_ASSIGNED);
    if(this.permissionService.specialPermissionDetail('can_view_all_sms')){
      this.ALLSMSPermission    = this.permissionService.specialPermissionDetail('can_view_all_sms');
    }
    const theme = this.userService.getUserInfo('theme_mode');
    console.log(theme)
    //this.logo = environment.IMG_URL + this.clientDetailService.LOGO_DARK;
    this.logo = environment.IMG_URL + this.clientDetailService.FAVICON;

/*
    if(theme?.toLowerCase()=='dark'){

      this.logo = environment.IMG_URL + this.clientDetailService.LOGO_DARK;
    }
    if(theme?.toLowerCase()=='light'){
      this.logo = environment.IMG_URL + this.clientDetailService.LOGO_LIGHT;
    }
    */
    // this.CallPermissionStatus  = this.permissionService.isSpecialPermission('can_make_calls');
   let call_per = this.permissionService.specialPermissionDetail('can_make_calls');

   this.CallPermissionStatus= (call_per=='YES' || call_per==null || call_per==undefined)?true:false;
   


    this.subscription = this.dataSharingService.dataObservable$.subscribe((data) => {
      this.receivedValue = data;
      
      //const modalContent = document.getElementById('MessageModal');
      this.saved_applicant_id = this.receivedValue[2];
      this.current_saved_applicant = this.receivedValue[3];
      
     if(![null,undefined,''].includes(this.current_saved_applicant?.mobile_phone))
     {
     this.openMessageModal(this.messageModalTemplate);
     if(this.receivedValue[0]=='APPLICANT' || this.receivedValue[0]=='CO-APPLICANT')
     this.showChatBoxApplicant(this.receivedValue[1],this.receivedValue[0],0);
     else if(this.receivedValue[0]=='CO-SIGNER-1' || this.receivedValue[0]=='CO-SIGNER-2')
     this.showChatBoxCoSigners(this.receivedValue[1],null,this.receivedValue[1]=='CO-SIGNER-1' ? 0 : 1,this.receivedValue[0]);
     else if(this.receivedValue[0]=='RECIPIENT')
     this.showChatBoxRec(this.receivedValue[1],this.receivedValue[0],0)
     else if(this.receivedValue[0]=='COMPANY')
     this.showChatBoxCompany(this.receivedValue[1],this.receivedValue[0],0)
     }
    });
    this.getSmsServiceVendor();

    //  this.canAccessLoanInformation = this.permissionService.isEdit('loan_information');
     this.canAccessLoanInformation = this.permissionService.isSpecialPermission('can_add_new_loans');

  }

  ngOnInit(): void {
    // if authenticated check for token expiry
    this.verifyExpiredToken();
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
        this.msgService.hideAllMessage();
        //verify auth token expired
        this.verifyExpiredToken();
      }
      this.compareWithPstTime();
      // this.checkMaintainceMode();
   })
   this.compareWithPstTime();
    this.searchForm = new UntypedFormGroup({
      searchText: new UntypedFormControl('',[]),
    });
    this.form = new UntypedFormGroup({
        message: new UntypedFormControl('',[]),
        searchTextMessage: new UntypedFormControl('',[])
      });

    this.pin_form = new UntypedFormGroup({
      pin1: new UntypedFormControl('',[]),
      pin2: new UntypedFormControl('',[]),
      pin3: new UntypedFormControl('',[]),
      pin4: new UntypedFormControl('',[]),
      pin5: new UntypedFormControl('',[]),
      pin6: new UntypedFormControl('',[])
    });

     //this.pin_form.controls.pin1.value.focus();
    //check for user detail updated
    const broadcastEvt = this.broadCastService
      .on(MsgConstantConfig.REFRESH_LOGGEDIN_USER_DETAIL)
      .subscribe(() => {
        this.showDetail();
        this.getProf();
      });
    this.eventSubscription.push(broadcastEvt);
    this.showDetail();
    this.getProf();
    this.loadTextMessages();

    // this.loanID = Number(this.router.url.split('/')[5]);
    
    if(isNaN(this.loanID)){
      this.selectedAccountStatus='All';
    }
    else{
      this.selectedAccountStatus='This';
    }
    // let isEditMode = this.loanID ? true : false;
    // if(isEditMode){
    
    //   // this.selectLoan(null,"other",this.loanID);
    // }
    // else{
    
    // }
    this.form.controls.message.valueChanges.subscribe((val: any) => {
      
       if(val=='' || val==null || val==undefined){
        this.freeMessage='';
        this.isMessageBoxFilled=true;
        this.isFreeMessage=false;
       }
       else{
        // val=val?.trim();
        if(val?.length>500){
          val=val?.substr(0,500);
        }
        this.freeMessage=val;
        this.isFreeMessage=true;

        this.isMessageBoxFilled = val?.trim()?.length>0 ? false:true;
       }
    });
    //decreased api calling time as per the client request from 20 secs to 10 secs
    const messageApi = interval(7000).subscribe(()=>this.unReadText());
    this.eventSubscription.push(messageApi);
    // this.unReadText()
    this.getProf();


    this.broadCastService.broadcast(
      MsgConstantConfig.REFRESH_VIEW_USER_ID
    );

    this.selectedUserIdServiceService.currentUserResponse.subscribe((data : any) => {
      
      this.total_unread=0;
      this.current_user_id='';
      if(data){
        this.current_user_id=data??'';
      }
      // this.current_user_id=data;
    setTimeout(()=>  this.unReadText(),500);
    // this.unReadText();
     });

     this.selectedLanguage = localStorage.getItem('Selected_Language') || 'en';
     //broadcastservice added
     this.broadCastService
     .on(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID)
     .subscribe(() => {
      
       this.multilingualService.updateSelectedLanguageResponse(this.selectedLanguage);
     });


   setTimeout(() => {
     this.broadCastService.broadcast(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID)
   }, 1000);

   this.multilingualService.currentLanguageResponse.subscribe((data : any) => {
    if(data){
      this.selectedLanguage=data??'';
      this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    }
   });

  }

    // verify token expiry
    verifyExpiredToken(){
      if(this.auth.isAuthenticated() && this.auth.isTokenExpired()){
        this.broadCastService.broadcast(MsgConstantConfig.AUTH_TOKEN_EXPIRED);
      }
    }

  /**
   * logoutClient
   */
public logoutClient() {
  this.httpService.delete(PathConfig.CLIENT_AUTH).subscribe((client_response) => {
    this.removeClientInfo();
  }, (err) => {
    this.msgService.showError("Something went wrong");
  });
}
//reset user information
removeClientInfo():void{
  const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
  this.localStorageService.remove(clientSlug + "_" + ConstantConfig.AUTH_TOKEN_EXPIRY);
  this.localStorageService.remove(clientSlug + "_" + ConstantConfig.AUTH_TOKEN);
  this.localStorageService.remove(clientSlug + "_" + ConstantConfig.USER_INFO);
  this.localStorageService.remove(clientSlug + "_" + ConstantConfig.SELECTED_THEME);
  this.localStorageService.remove(clientSlug + "_"  + ConstantConfig.LOGIN_AS_CLIENT);
  close();
 }
 //compare with pst time
 compareWithPstTime(){

  const date = new Date();
  const pst = date?.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
  this.maintenanceStartWarning = ( pst>"19:29" && pst<"20:00")? true:false;
  // console.log(pst)
  this.isMaintenancModeOn = ( pst>="20:00" || pst<"01:00")? true:false;
  if(this.isMaintenancModeOn){
    this.maintenanceStartWarning=false;
  }
}

  getLoanId(){
    
    // if(this.router.url.indexOf("mapping-tool")==-1){}
    // else{}
    this.loanID = Number(this.router.url.split('/')[5]);
    const loan_id=this.loanID;
    // if(loan_id!=this.loanID)
    // {
    //   this.last_total_unread=0;
    // }
  }

  unReadText(){
    if(this.router.url.indexOf("mapping-tool")==-1&&this.router.url.indexOf("import-tool")==-1&&this.router.url.indexOf("clients")==-1 && !this.unreadapiCallInProgress && !this.isloanDetailInProcess){
      let url='';
      this.unreadapiCallInProgress=true;

      // if(loan_id==''|| isNaN(loan_id)){

      // if(this.current_user_id){
        //    url = `${PathConfig.UNREAD_COUNT}?user_id=${this.current_user_id}`;
        // }
        // else{
        //   if(this.router.url.indexOf("servicing/queues/")!=-1){
        //     url = `${PathConfig.UNREAD_COUNT}?user_id=${''}`;
        //   }
        //   else{
        //     url = `${PathConfig.UNREAD_COUNT}?skip_auth_user=false`;
        //   }
        // }

        //in queues page (loan list)
        if(this.router.url.indexOf("servicing/queues/")!=-1){
          if(this.current_user_id){
           url = `${PathConfig.UNREAD_COUNT}?user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}`;
          }
          else{
            url = `${PathConfig.UNREAD_COUNT}?skip_auth_user=false`;
            if(this.ALLSMSPermission=='YES'){
              url +="&user_id=ALL";
            }
          }
        }
        //inside a loan
        else if(this.router.url.indexOf("servicing/queues-detail/")!=-1){
          let temp_loanID = Number(this.router.url.split('/')[5]);
          url = `${PathConfig.UNREAD_COUNT}?skip_auth_user=false`;
          if(this.ALLSMSPermission=='NO'){
            url +=`&loan_id=${temp_loanID}`;
          }
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
        //not on loan list or inside a loan
        else{
          url = `${PathConfig.UNREAD_COUNT}?skip_auth_user=false`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }




      // }
      // else if(loan_id!=''&& !isNaN(loan_id)){
      //   url = `${PathConfig.UNREAD_COUNT}?loan_id=`+loan_id;
      // }
      this.httpService.get(url,true).subscribe((response) => {
       // this.loaderService.hideLoader();
        if(response){
         this.last_un_read=this.total_unread;

         this.total_unread=0;
         let loan_detail_flag=0;
          if(response?.total_unread!=null && response?.total_unread!='' ){
            // if(this.total_unread!=response?.total_unread){
              const applicant_ids = response?.applicant_ids?.length > 0 ? response?.applicant_ids : [];
              //to do
              const company_ids = response?.company_ids?.length > 0 ? response?.company_ids : [];
              this.unreadApplicantIds = response?.applicant_ids?.length > 0 ? response?.applicant_ids : [];
              this.unreadCompanyIds = response?.company_ids?.length > 0 ? response?.company_ids : [];
              this.current_applicant_id = [null,'',undefined].includes(this.apiLoanDetailResponse) ? this.current_applicant_id : this.selected_applicant_id;
              this.total_unread=response?.total_unread;
              this.last_total_unread_messages = this.total_unread_messages;
              this.total_unread_messages = response?.total_unread_messages;

              if(applicant_ids?.includes(this.current_applicant_id) && this.showMessageChat)
              {
                this.is_scroll_chat=true;
                this.any_unread_message = true;
                // this.getLoanDetails(this.loanID);
                this.getLoanDetails(this.loanID,'',true);
              }
              else if(company_ids?.includes(this.current_applicant_id?.toString()) && this.showMessageChat)
              {
                this.is_scroll_chat=true;
                this.any_unread_message = true;
                // this.getLoanDetails(this.loanID);
                this.getLoanDetails(this.loanID,'',true);
              }
              else  if(this.unread_applicants_counts < response?.applicant_ids?.length && this.thisAccountStatus && !this.thisAccountIsLoading)
              {
                this.can_call_loan_detail =true;
                this.unread_applicants_counts = response?.applicant_ids?.length;
                //this.any_unread_message = true;
                loan_detail_flag=1;
                this.getLoanDetails(this.loanID);
              }
              else if(!this.is_In_queue && !this.select_another_loan)
              {
                this.can_call_loan_detail =true;
                this.unread_applicants_counts = response?.applicant_ids?.length;
                this.thisAccountIsLoading=false;
                this.getLoanDetailsForBack(this.loanID);
              }
              if(this.last_total_unread_messages != response?.total_unread_messages || this.last_un_read != response?.total_unread)
              {
                this.apiTextMesasages=[];
                this.current_page_no=1;
                this.setscrollToTop();

                (!['',null,includes].includes(this.messageSearchKey) && this.messageSearchKey?.length >= 3) ?   this.loadTextMessages(this.messageSearchKey) : this.loadTextMessages() ;
              }
              // else if(this.is_In_queue)
              // {
              //   this.loadTextMessages();
              // }
              this.last_un_read=response?.total_unread;
            if((this.loanID && !isNaN(this.loanID) && !this.showMessageChat) &&  loan_detail_flag==0 && !this.select_another_loan){
              this.getLoanDetails(this.loanID);
            }
            else
            {
              loan_detail_flag=0;
            }
            this.unreadmessagescount=response?.total_unread;
            if(this.showMessageChat==true && this.unreadmessagescount && ( applicant_ids?.includes(this.selected_applicant_id) || company_ids.includes(this.selected_applicant_id.toString()) )){
             this.markAsRead(this.selected_applicant_id,this.unreadmessagescount)
            }
            // }
          }else if(!this.is_In_queue && !this.showMessageChat)
          {
            this.unreadApplicantIds=[];
            this.unreadCompanyIds=[];
            this.total_unread=0;
            this.getLoanDetails(this.loanID);
          }
          if(this.last_un_read != response.total_unread)
          {
            this.apiTextMesasages=[];
            this.current_page_no=1;
            this.setscrollToTop();
            //this.loadTextMessages();
            (!['',null,includes].includes(this.messageSearchKey) && this.messageSearchKey?.length >= 3) ?   this.loadTextMessages(this.messageSearchKey) : this.loadTextMessages() ;
          }
        }
        this.unreadapiCallInProgress=false;

      });
    }
    // setTimeout(()=>  this.unReadText(),20000);
  }

  ngOnDestroy(): void {
    //remove all events
    this.eventSubscription.forEach((event) => event.unsubscribe());
    this.subscription.unsubscribe();
  }


  formatPhoneNumber(phoneNumberString:any) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  conver_To_Camel_Case(str:string){
    let STR = str.toLowerCase()
    .trim()
    .split(' ')
    .map(word => word.replace(word[0], word[0].toString().toUpperCase()))
    .join(' ');
    return STR?.replace('-','  ');
  }

  getPin(p1:any,p2:any,p3:any,p4?:any,p5?:any,p6?:any,e?:any,p?:any,c?:any,n?:any){
    if(this.vendorConfig.pin_size =='4')
    this.pin=p1+p2+p3+p4;
    if(this.vendorConfig.pin_size =='5')
    this.pin=p1+p2+p3+p4+p5;
    if(this.vendorConfig.pin_size =='6')
    this.pin=p1+p2+p3+p4+p5+p6;
    if(this.pin.length==this.vendorConfig.pin_size)
      this.isPinNotValid=false;
    else
      this.isPinNotValid=true;

    var length=c?.value?.length;
    var maxlength=c?.getAttribute('maxlength');

    if(length==maxlength){
      if(n!=""){
        n?.focus();
      }
    }
    if(e?.key==='Backspace'){
      if(p!=""){
        p?.focus();
      }
    }
  }

  convertLocalTimeZone(d:any){
    if(d!=null && d!=''){
      var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
      return moment(testDateUtc).local().format("MM/DD/YY hh:mm:ss A");
    }
    else {
      return ;
    }
  }

  //show Templates Screen
  showTemplates(){
    this.isTemplateSelectionScreenActive=true;
  // this.showMessageHeader="Templates";
    this.showMessageOptinSend=false;
    this.showMessageOptinVerify=false;
    this.showMessageChat=false;
    this.showMessage=true;
    this.last_visited_page="Message_Chat";


    const url = `${PathConfig.SBT_TEXT_TEMPLATES}`;
    this.httpService.get(url,true).subscribe(
      (response) => {
        this.apiTemplates = response?.templates;
      },
      (err) => {
        // this.msgService.showError(err.error.message);
      }
    );
  }


  loadMessages(type:boolean){

    this.current_mode=type;
    this.countUnreadMessage=0;
    this.apiTextMesasages=[];
    this.search_flag = false;
    this.showCountMessage = '';
    this.current_page_no = 1;
    this.allAccountsStatus = true ;
    this.total_messages = 0;
    this.total_pages = 0;
    this.is_mode_change=true;
    this.can_call_loan_detail = true;

    if(type==true){
      this.loadAllMessages=true;
      this.apiTextMesasages=[];
      (!['',null,includes].includes(this.messageSearchKey) && this.messageSearchKey?.length >= 3) ?   this.loadTextMessages(this.messageSearchKey) : this.loadTextMessages() ;
    }
    else if(type==false){
      this.current_page_no = 0;
      this.apiTextMesasages=[];
      this.loadAllMessages=false;
      (!['',null,includes].includes(this.messageSearchKey) && this.messageSearchKey?.length >= 3) ?   this.loadTextMessages(this.messageSearchKey) : this.loadTextMessages() ;
    }
    else {
      this.apiTextMesasages=[];
      this.loadAllMessages=true;
      (!['',null,includes].includes(this.messageSearchKey) && this.messageSearchKey?.length >= 3) ?   this.loadTextMessages(this.messageSearchKey) : this.loadTextMessages() ;
    }

    setTimeout(() => {
      this.setscrollToTop();
    }, 100);

  }

  //load Text Messages (1st layer of the sms widget where we show the account names with last message)
  //message argument is passed for  searching a particular message
  loadTextMessages(message?:string){

    this.total_pages = Math.ceil(this.total_messages / this.load_messages_per_page);

    if(!this.is_loanTextMessagesInProcess && (this.current_page_no==1 || this.current_page_no<=this.total_pages))
    {
      this.is_loanTextMessagesInProcess=true;
    this.countUnreadMessage=0;
    let url='';
    if(message){
      // this.apiTextMesasages = !this.search_flag ? [] : this.apiTextMesasages ;
      // this.search_flag = true;
      // if(this.current_user_id){
      //   url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${message}&user_id=${this.current_user_id}`;
      // }
      // else{
      //   if(this.router.url.indexOf("servicing/queues/")!=-1){
      //     url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${message}&user_id=${''}`;
      //   }
      //   else{
      //     url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${message}&skip_auth_user=false`;
      //   }
      // }
      if(this.router.url.indexOf("servicing/queues/")!=-1){
        if(this.current_user_id){
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}&page=${this.current_page_no}`;
        }
        else{
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&skip_auth_user=false&page=${this.current_page_no}`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
      }
      else if(this.router.url.indexOf("servicing/queues-detail/")!=-1){
        let temp_loanID = Number(this.router.url.split('/')[5]);
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&skip_auth_user=false&loan_id=${temp_loanID}&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }
      else{
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&skip_auth_user=false&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }

    }
    else{
      // if(this.current_user_id){
      //   url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&user_id=${this.current_user_id}`;
      // }
      // else{
      //   if(this.router.url.indexOf("servicing/queues/")!=-1){
      //     url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&user_id=${''}`;
      //   }
      //   else{
      //     url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false`;
      //   }
      // }

      if(this.router.url.indexOf("servicing/queues/")!=-1){
        if(this.current_user_id){
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}&page=${this.current_page_no}`;
        }
        else{
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false&page=${this.current_page_no}`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
      }
      else if(this.router.url.indexOf("servicing/queues-detail/")!=-1){

        let temp_loanID = Number(this.router.url.split('/')[5]);
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false&loan_id=${temp_loanID}&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }
      else{
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }
    }
    this.loaderService.hideLoader();
    this.httpService.get(url,true).subscribe(
      (response) => {
        this.loaderService.hideLoader();
        //this.apiTextMesasages = response?.length==0?this.textMessages:response;
        // this.apiTextMesasages = response?.text_messages?.length === 0 ? this.textMessages : [...this.apiTextMesasages, ...response?.text_messages];
        this.apiTextMesasages =  [...this.apiTextMesasages, ...response?.text_messages];
        this.total_messages   =  response?.read_unread_total_messages;

        if(response?.text_messages?.length==0)
        {
          this.allAccountsStatus=false;
        }
        else
        {
          this.allAccountsStatus=true;
        }

        this.countUnreadMessage=0;
        this.showCountMessage = '';
        // this.showCountDeactivated = '';
        this.totalUnreadMessagesCount=0;
        if (this.apiTextMesasages?.length > 0) {
          this.countUnreadMessage = this.apiTextMesasages?.filter(this.checkUnread)?.length;
          this.totalUnreadMessagesCount = this.countUnreadMessage??0;
          // this.countDeactivated = this.apiTextMesasages?.filter(this.checkDeactivated)?.length;
        }
        if (this.total_unread > 0 && this.totalUnreadMessagesCount!=0) {
          if (this.total_unread == 1) {
            this.showCountMessage = this.total_unread + " Unread Message";
          } else {
            this.showCountMessage = this.total_unread + " Unread Messages";
          }
        }
        else{
          this.showCountMessage = this.totalUnreadMessagesCount === 0 ? '' : this.totalUnreadMessagesCount + " Unread Message";
        }
        // if (this.countDeactivated > 0) {
        //   if (this.countDeactivated == 1) {
        //     this.showCountDeactivated = this.countDeactivated + " Deactivated Number";
        //   } else {
        //     this.showCountDeactivated = this.countDeactivated + " Deactivated Numbers";
        //   }
        // }

        if(this.loanID&&!isNaN(this.loanID)){
          this.getLoanDetails(this.loanID,message);
        }
        this.isLoading=false;
        this.is_loanTextMessagesInProcess=false;
      },
      (err) => {
        this.msgService.showError(err.error.message);
        this.loadingMessages=false;
        this.is_loanTextMessagesInProcess=false;
        this.isLoading=false;
      }
    );
    }
    else
    {
      this.isLoading=false;
    }
  }

  //select loan
  selectLoan(item:any,optin_status:any,loan_id:any,user_type?:any,is_loan_id_change:boolean=false){

    if(!this.enableForLoanDetailAPI)
    {
    this.selected_applicant_id=item?.loan_applicant_id;
    this.current_applicant_id=item?.loan_applicant_id;
    this.selected_user=item;
    this.current_user_type=item?.user_type;
    if(item?.user_type == 'COMPANY'){
      this.selected_applicant_id=item?.company_id;
      this.current_applicant_id=item?.company_id;
      this.showMessageHeader = item.company + ' ' + '(' +item.loan_loan_id + ')';
    }

    if(optin_status!='DEACTIVATED'){
      this.current_page_no=0;
      // this.apiTextMesasages=[];
      this.isAccountSelected=true;
      this.selectedLoanId=loan_id;
      this.is_loan_id_Selected=is_loan_id_change ? false : true;
      if((this.loanID&&!isNaN(this.loanID)) && this.loanID != loan_id)
      {
        this.select_another_loan=true;
        this.isloanDetailInProcess=false;
      }
      this.loanID= loan_id;
      if(this.loanID&&!isNaN(this.loanID)){
        this.enableForLoanDetailAPI=true;
        if(!this.is_In_queue)
        {
        this.thisAccountIsLoading=true;
        }

        this.another_apiLoanDetailResponse=null;
      //this.apiLoanDetailResponse=null;
        this.getLoanDetails(this.loanID,'',false,true,true,optin_status,user_type);

      }
    }
  }
  }

  checkUnread(message:any) {
    return message.read == 0;
  }

  // checkDeactivated(message:any) {
  //   return message.opt_in_status == "DEACTIVATED";
  // }

  //get loan messages
  getLoanDetails(loan_id:any,message?:string,unreadMessage:boolean=false,scroll:boolean=false,showChatBox:boolean=false,optin_status:string='',user_type:string=''){
   
    let url='';
    // if(this.any_unread_message || !this.showMessageChat || this.is_sending_event)
    if( (this.any_unread_message  ||   this.can_call_loan_detail || this.is_sending_event ||  this.enableForLoanDetailAPI) &&   !this.isloanDetailInProcess ){
    
      loan_id=(this.is_loan_id_Selected || this.is_In_queue) ? loan_id : this.getCurrentLoanId();
      if(isNaN(loan_id))
      {
        loan_id=this.loanID;
      }

      this.isloanDetailInProcess=true;
      if(!this.any_unread_message && !this.is_sending_event && !this.showMessageChat)
      {
        this.apiLoanDetailResponse=null;
      }
      this.showCountMessage='';
      if(!['',null,undefined].includes(message)){
        if(this.current_user_id){
        url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&search=${message}&user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}`
        }
        else{
        url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&search=${message}&skip_auth_user=false`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
      }
      else{
        if(this.current_user_id){
          url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}`
        }
        else{
          url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&skip_auth_user=false`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
      }
      this.loaderService.hideLoader();
      this.httpService.get(url,true).subscribe(
        (response) => {
          this.loaderService.hideLoader();
          this.loadingMessages=true;
          this.last_apiLoanDetailResponse=this.apiLoanDetailResponse;

          this.apiLoanDetailResponse=response;
          if(this.select_another_loan)
          {
            this.another_apiLoanDetailResponse=response;
          }

          if(this.apiLoanDetailResponse?.applicant?.mobile_phone==null &&
            this.apiLoanDetailResponse?.co_applicant?.mobile_phone==null &&
            this.apiLoanDetailResponse?.co_signers?.length==0 &&
            this.router.url.indexOf("servicing/queues-detail/")==-1){

            this.thisAccountStatus=false;
          }
          else{
            this.thisAccountStatus=true;
          }

          // if (this.apiLoanDetailResponse?.applicant?.text_messages?.length > 0) {
          //   this.countUnreadMessage = this.apiLoanDetailResponse?.applicant?.text_messages?.filter(this.checkUnread)?.length;
          // }
          // if (this.apiLoanDetailResponse?.co_applicant?.text_messages?.length > 0) {
          //   this.countUnreadMessage += this.apiLoanDetailResponse?.co_applicant?.text_messages?.filter(this.checkUnread)?.length;
          // }
          if (this.apiLoanDetailResponse?.applicant?.unread_count > 0) {
            this.selectedLoanUnreadMessageCount = this.apiLoanDetailResponse?.applicant?.unread_count??0;
          }
          if (this.apiLoanDetailResponse?.company?.unread_count > 0) {
            this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.company?.unread_count??0;
          }
          if (this.apiLoanDetailResponse?.co_applicant?.unread_count > 0) {
            this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.co_applicant?.unread_count??0;
          }
          if (this.apiLoanDetailResponse?.recipient?.unread_count > 0) {
            this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.recipient?.unread_count??0;
          }
          if (this.apiLoanDetailResponse &&
            this.apiLoanDetailResponse?.co_signers &&
            this.apiLoanDetailResponse?.co_signers[0] &&
            this.apiLoanDetailResponse?.co_signers[0]?.unread_count > 0) {
            this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.co_signers[0]?.unread_count??0;
          }
          if (  this.apiLoanDetailResponse &&
            this.apiLoanDetailResponse?.co_signers &&
            this.apiLoanDetailResponse?.co_signers[1] &&
            this.apiLoanDetailResponse?.co_signers[1]?.unread_count > 0) {
            this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.co_signers[1]?.unread_count??0;
          }
          // this.countDeactivated = 0;
          this.showCountMessage = '';
          // this.showCountDeactivated = '';
          // this.countDeactivated = this.apiLoanDetailResponse?.applicant?.opt_in_status == "DEACTIVATED" ? this.countDeactivated+1 : this.countDeactivated;
          // this.countDeactivated = this.apiLoanDetailResponse?.co_applicant?.opt_in_status == "DEACTIVATED" ? this.countDeactivated+1 : this.countDeactivated;
          if (this.total_unread > 0) {
            if (this.total_unread == 1) {
              this.showCountMessage = this.total_unread + " Unread Message";
            } else {
              this.showCountMessage = this.total_unread + " Unread Messages";
            }
          }
          else
          {
            this.showCountMessage = '';
          }
          // if (this.countDeactivated > 0) {
          //   if (this.countDeactivated == 1) {
          //     this.showCountDeactivated = this.countDeactivated + " Deactivated Number";
          //   } else {
          //     this.showCountDeactivated = this.countDeactivated + " Deactivated Numbers";
          //   }
          // }
          // if(this.isModalClosed==false){
          //   setTimeout(()=>  this.getLoanDetails(loan_id),5000);
          // }
          this.loadingMessages=false;

          if(scroll)
          {

            this.is_scroll_chat=scroll;
          }

          if(this.any_unread_message && !this.select_another_loan)
          {
            this.markAsRead(this.current_applicant_id,this.total_unread);

            //
          }

          if(unreadMessage){
            this.isScrolled = true;
            this.currentChatPage=1;
            this.getChatMessages(this.currentChatPage, this.current_user_type)
            .subscribe((response) => {
              if(response)
              this.chatMessageList = response?.reverse()?? [];
              this.isScrolled = false;
              setTimeout(()=>{
                const contentRef = document.getElementById('chat');
                if(contentRef){
                let Height = document?.getElementById('chat')?.scrollHeight;
                if(Height){
                  contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
                  this.is_scroll_chat=false;
                }
                }
              },100);
            }, (err) => {
              this.isScrolled = false;
            });
          }

          if(this.is_scroll_chat ){this.scrollToRecent()};
          this.any_unread_message=false;
          this.is_sending_event= false;
          this.enableForLoanDetailAPI=false;
          this.can_call_loan_detail=false;
          this.isloanDetailInProcess = false;
          this.is_mode_change=false;
          if(showChatBox){
            if(this.router.url.indexOf("servicing/queues-detail/")==-1){
              this.insideLoanWorkScreen=false;
              this.loadingMessages=true;
              // this.getLoanDetails(loan_id);
              if(user_type=='APPLICANT')
                setTimeout(()=> this.showChatBoxApplicant(optin_status,user_type,0),1000);
              if(user_type=='CO-APPLICANT')
                setTimeout(()=> this.showChatBoxApplicant(optin_status,user_type,0),1000);
              if(user_type=='CO-SIGNER-1'||user_type=='CO-SIGNER-2')
                setTimeout(()=> this.showChatBoxCoSigners(optin_status,'co_signer',0,user_type ),1000);
              if(user_type=='RECIPIENT')
                setTimeout(()=> this.showChatBoxRec(optin_status,user_type,0),1000);
              if(user_type=='COMPANY')
                setTimeout(()=> this.showChatBoxCompany(optin_status,user_type,0),1000);
            }
            else{
    
              this.insideLoanWorkScreen=true;
    
              if(user_type=='APPLICANT')
                setTimeout(()=> this.showChatBoxApplicant(optin_status,user_type,0),1000);
              if(user_type=='CO-APPLICANT')
                setTimeout(()=> this.showChatBoxApplicant(optin_status,user_type,0),1000);
              if(user_type=='CO-SIGNER-1'||user_type=='CO-SIGNER-2')
                setTimeout(()=> this.showChatBoxCoSigners(optin_status,'co_signer',0,user_type ),1000);
              if(user_type=='RECIPIENT')
                setTimeout(()=> this.showChatBoxRec(optin_status,user_type,0),1000);
              if(user_type=='COMPANY')
                setTimeout(()=> this.showChatBoxCompany(optin_status,user_type,0),1000);
            }
          }

        },
        (err) => {
          this.loadingMessages=false;
          this.any_unread_message=false;
          this.is_sending_event= false;
          this.enableForLoanDetailAPI=false;
          this.can_call_loan_detail=false;
          this.isloanDetailInProcess = false;
          this.is_mode_change=false;

          this.msgService.showError(err.error.message);
        }
      );
    }
    else{
      setTimeout(() => {
      // this.is_mode_change=false;
      }, 1000);
    }
  }

  getLoanDetailsForBack(loan_id:any,message?:string,scroll:boolean=false){
    let url='';
    // if(this.any_unread_message || !this.showMessageChat || this.is_sending_event)

    if( (this.any_unread_message  ||   this.can_call_loan_detail || this.is_sending_event ||  this.enableForLoanDetailAPI) &&   !this.isloanDetailInProcess && !this.showMessageChat )
    {
      loan_id=this.is_loan_id_Selected ? loan_id : this.getCurrentLoanId();
      this.isloanDetailInProcess=true;
      if(isNaN(loan_id))
      {
        loan_id=this.loanID;
      }
      // if(!this.any_unread_message && !this.is_sending_event)
      // {
      //   this.apiLoanDetailResponse=null;
      // }

      //this.showCountMessage='';

    if(!['',null,undefined].includes(message)){
      if(this.current_user_id){
      url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&search=${message}&user_id=${this.current_user_id}`;
      }
      else{
      url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&search=${message}&skip_auth_user=false`;
      }
    }
    else{
      if(this.current_user_id){
        url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&user_id=${this.current_user_id}`;
      }
      else{
        url = `${PathConfig.LOAN_DETAILS}?loan_id=${loan_id}&read=${this.loadAllMessages}&skip_auth_user=false`;
      }
    }
    this.loaderService.hideLoader();
    this.httpService.get(url,true).subscribe(
      (response) => {
        this.loaderService.hideLoader();
        this.loadingMessages=true;
        this.last_apiLoanDetailResponse=this.apiLoanDetailResponse;
      this.apiLoanDetailResponse=response;
      if(this.apiLoanDetailResponse?.applicant?.mobile_phone==null &&
        this.apiLoanDetailResponse?.co_applicant?.mobile_phone==null &&
        this.apiLoanDetailResponse?.co_signers?.length==0 &&
        this.router.url.indexOf("servicing/queues-detail/")==-1){

        this.thisAccountStatus=false;
      }
      else{
        this.thisAccountStatus=true;
      }

        // if (this.apiLoanDetailResponse?.applicant?.text_messages?.length > 0) {
        //   this.countUnreadMessage = this.apiLoanDetailResponse?.applicant?.text_messages?.filter(this.checkUnread)?.length;
        // }
        // if (this.apiLoanDetailResponse?.co_applicant?.text_messages?.length > 0) {
        //   this.countUnreadMessage += this.apiLoanDetailResponse?.co_applicant?.text_messages?.filter(this.checkUnread)?.length;
        // }
        if (this.apiLoanDetailResponse?.applicant?.unread_count > 0) {
          this.selectedLoanUnreadMessageCount = this.apiLoanDetailResponse?.applicant?.unread_count??0;
        }
        if (this.apiLoanDetailResponse?.company?.unread_count > 0) {
          this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.company?.unread_count??0;
        }
        if (this.apiLoanDetailResponse?.co_applicant?.unread_count > 0) {
          this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.co_applicant?.unread_count??0;
        }
        if (this.apiLoanDetailResponse?.recipient?.unread_count > 0) {
          this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.recipient?.unread_count??0;
        }
        if (this.apiLoanDetailResponse &&
          this.apiLoanDetailResponse?.co_signers &&
          this.apiLoanDetailResponse?.co_signers[0] &&
          this.apiLoanDetailResponse?.co_signers[0]?.unread_count > 0) {
          this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.co_signers[0]?.unread_count??0;
        }
        if (  this.apiLoanDetailResponse &&
          this.apiLoanDetailResponse?.co_signers &&
          this.apiLoanDetailResponse?.co_signers[1] &&
          this.apiLoanDetailResponse?.co_signers[1]?.unread_count > 0) {
          this.selectedLoanUnreadMessageCount += this.apiLoanDetailResponse?.co_signers[1]?.unread_count??0;
        }
        // this.countDeactivated = 0;
        this.showCountMessage = '';
        // this.showCountDeactivated = '';
        // this.countDeactivated = this.apiLoanDetailResponse?.applicant?.opt_in_status == "DEACTIVATED" ? this.countDeactivated+1 : this.countDeactivated;
        // this.countDeactivated = this.apiLoanDetailResponse?.co_applicant?.opt_in_status == "DEACTIVATED" ? this.countDeactivated+1 : this.countDeactivated;


        if (this.total_unread > 0) {
          if (this.total_unread == 1) {
            this.showCountMessage = this.total_unread + " Unread Message";
          } else {
            this.showCountMessage = this.total_unread + " Unread Messages";
          }
        }
        else
        {
          this.showCountMessage = '';
        }

        // if (this.countDeactivated > 0) {
        //   if (this.countDeactivated == 1) {
        //     this.showCountDeactivated = this.countDeactivated + " Deactivated Number";
        //   } else {
        //     this.showCountDeactivated = this.countDeactivated + " Deactivated Numbers";
        //   }
        // }
        // if(this.isModalClosed==false){
        //   setTimeout(()=>  this.getLoanDetails(loan_id),5000);
        // }
        this.loadingMessages=false;

        if(scroll)
        {

          this.is_scroll_chat=scroll;
        }
        if(this.any_unread_message && !this.select_another_loan)
        {
          this.markAsRead(this.current_applicant_id,this.total_unread);
        }
        if(this.is_scroll_chat ){this.scrollToRecent()};
        this.any_unread_message=false;
        this.is_sending_event= false;
        this.enableForLoanDetailAPI=false;
        this.can_call_loan_detail=false;
        this.isloanDetailInProcess = false;
        this.is_mode_change=false;
      },
      (err) => {
        this.is_mode_change=false;
        this.loadingMessages=false;
        this.any_unread_message=false;
        this.is_sending_event= false;
        this.enableForLoanDetailAPI=false;
        this.can_call_loan_detail=false;
        this.isloanDetailInProcess = false;
        this.msgService.showError(err.error.message);
      }
    );
    }
  }

  goBack(lastPage:string){
    this.current_page_no=1;
    this.showMessage= false;
    this.showMessageChat = false;
    this.showMessageTemplate = false;
    this.showMessageOptin = false;
    this.showMessageOptinSend = false;
    this.showMessageOptinVerify = false;
    this.isTemplateSelectionScreenActive=false;
    this.unread_applicants_counts=0;
    this.is_loan_id_Selected=false;
    // this.search_flag = false ;
    // this.messageSearchKey = '';
    if(this.searchTextMessage) {
      this.form.patchValue({searchTextMessage:this.searchTextMessage});
      if(this.searchTextMessage.length >=3 && this.searchTextMessage.trim()!='')
      this.loadTextMessagesForSearchBar(this.searchTextMessage);
    }


    /************************** */
    this.isTemplateMessageSend=false;
    this.form.patchValue({message:''});
    this.form.updateValueAndValidity();
    this.isMessageBoxFilled=true;
    this.selected_template_id=null;
    /**************************** */
    if(lastPage=='showMessage'){
      this.select_another_loan=false;
      this.selected_applicant_id=null;
      if(this.searchTextMessage.length <3 ){
        this.apiTextMesasages=[];
      }
      this.allAccountsStatus=true;
      this.showMessage= false;
      this.showMessageChat = false;
      this.showMessageTemplate = false;
      this.showMessageOptin = false;
      this.showMessageOptinSend = false;
      this.showMessageOptinVerify = false;
      this.isTemplateSelectionScreenActive=false;
      // this.selectLoan(this.loanID)
      this.isAccountSelected=  this.is_In_queue ? false : true ;
      this.selectedLoanId=this.getCurrentLoanId();
      this.loanID=this.getCurrentLoanId();
      this.isScrolled = false;
      this.totalPage = 1;
      this.chatMessageList = [];
      this.chatMessageListLoading = false;
      this.currentChatPage = 1;
      if(this.router.url.indexOf("servicing/queues-detail/")!=-1){
        this.selectLoan(null,"other",this.loanID,null,true);
      }
      if(this.searchTextMessage.length <3 ){
        this.loadTextMessages();
      }
      this.last_visited_page='showMessage';
      this.showMessage= true;
      // if(this.router.url.indexOf("servicing/queues-detail/")==-1){
      //   this.showMessageChat = false;
      // }
    }
    if(lastPage=='Message_Chat'){
      this.showMessageChat = true;
      this.scrollToRecent();
      this.last_visited_page="Templates";
    }
    if(lastPage=='showSendPin'){
    this.selected_applicant_id=null;
      this.showMessageOptinSend = true;
    }

  }


  //go back from account
  // goBackFromToAccount(lastPage:string){
  //   this.showMessage= false;
  //   this.showMessageChat = false;
  //   this.showMessageTemplate = false;
  //   this.showMessageOptin = false;
  //   this.showMessageOptinSend = false;
  //   this.showMessageOptinVerify = false;
  //   this.isTemplateSelectionScreenActive=false;
  //   /************************** */
  //   this.isTemplateMessageSend=false;
  //   this.selected_applicant_id=null;
  //   this.form.patchValue({message:''});
  //   this.form.updateValueAndValidity();
  //   this.isMessageBoxFilled=true;
  //   /**************************** */
  //   if(lastPage=='showMessage'){
  //     this.showMessage= false;
  //     this.showMessageChat = false;
  //     this.showMessageTemplate = false;
  //     this.showMessageOptin = false;
  //     this.showMessageOptinSend = false;
  //     this.showMessageOptinVerify = false;
  //     this.isTemplateSelectionScreenActive=false;

  //     this.selectLoan(null,'Other',this.loanID)

  //     this.last_visited_page='showMessage';
  //     this.showMessage= true;
  //   }
  //   if(lastPage=='Message_Chat'){
  //     this.showMessageChat = true;
  //   }
  //   if(lastPage=='showSendPin'){
  //     this.showMessageOptinSend = true;
  //   }
  // }

  //go to account
  goToAccount(){
    if(this.apiLoanDetailResponse && this.apiLoanDetailResponse?.id){
      this.thisAccountStatus=true;
      this.goToRoute(this.apiLoanDetailResponse?.id)
      this.loanID = this.apiLoanDetailResponse?.id;
    }
  }

  selectTemplates(id:any,TemplateID:any){
    this.selected_template_id=id;
  //this.sendTemplateMessage(template_id);
  }

  sendTemplate(){

    this.form.patchValue({message:this.apiTemplates[this.selected_template_id].Text});
    let applicantId='';
    let templateId='';
      applicantId=this.selected_applicant_id;

      templateId=this.apiTemplates[this.selected_template_id]?.id;
      applicantId = this.selected_applicant_id ?? this.current_applicant_id;

    let url = `${PathConfig.SBT_PARSE_TEMPLATE}?applicant_id=${applicantId}&template_id=${templateId}&loan_id=${this.loanID}`;


    if(this.selected_type == 'COMPANY'){
      url = `${PathConfig.SBT_PARSE_TEMPLATE}?applicant_id=${null}&template_id=${templateId}&company_id=${applicantId}&loan_id=${this.loanID}`;
    }
    this.httpService.get(url,true).subscribe(
      (response) => {
        if(response){
          this.form.patchValue({message:response?.message});
        }

      },
      (err) => {
        // this.msgService.showError(err.error.message);
      }
    );

    this.form.updateValueAndValidity();
    this.isTemplateMessageSend=true;
    this.showMessage= false;
    this.showMessageTemplate = false;
    this.showMessageOptin = false;
    this.showMessageOptinSend = false;
    this.showMessageOptinVerify = false;
    this.isTemplateSelectionScreenActive=false;
    this.showMessageChat = true;
    if(this.selected_template_id!=null)
    this.isMessageBoxFilled=false;
    this.isFreeMessage=false;
    this.scrollToRecent();
  }






  //sendTemplates Message
  sendTemplateMessage(){
    // if(!this.isFreeMessage){
    //   this.httpService.post(PathConfig.SEND_TEMPLATES_MESSAGES,{
    //     reference_id :(this.selected_type=='Ref'?this.selected_applicant_id:null),
    //     template_id :(this.apiTemplates[this.selected_template_id]?.id??null),
    //     applicant_id : this.selected_type=='App'?this.selected_applicant_id:null}).subscribe(
    //     (response: any) => {
    //     this.msgService.showSuccess(response?.message);

    //     // this.selected_applicant_id=null;
        // this.form.patchValue({message:''});
    //     this.form.updateValueAndValidity();
    //     this.isMessageBoxFilled=true;
    //     if(this.loanID&&!isNaN(this.loanID)){
    //       this.getLoanDetails(this.loanID);
    //     }
    //     this.isTemplateMessageSend=false;
    //     //this.modalService.dismissAll();
    //     this.isFreeMessage=true;

    //     },
    //   (err) => {
    //     const msgList = Utility.getErrorMessage(err.error);
    //     msgList.forEach((msg) => this.msgService.showError(msg));
    //   // this.modalService.dismissAll();

    //   });
    // }
    // else
     if(this.isFreeMessage){
      this.isMessageBoxFilled=true;

      this.selected_applicant_id = this.selected_applicant_id ?? this.current_applicant_id;
      let params = {
        reference_id:(this.selected_type=='Ref'?this.selected_applicant_id:null),
        message:this.freeMessage,
        applicant_id : this.selected_type=='App'?this.selected_applicant_id:null,
        template_id :this.apiTemplates[this.selected_template_id]?.id??null,
        loan_id:this.loanID??this.getCurrentLoanId()??null,
        company_id:this.selected_type=='COMPANY'?this.apiLoanDetailResponse?.company?.id:null
      };
      // if(this.selected_type=='COMPANY')
      // delete params.applicant_id;

      this.httpService.post(PathConfig.SEND_FREE_MESSAGES,params,true).subscribe(
        (response: any) => {
          setTimeout(()=> this.msgService.showSuccess(response?.message),1000);
          // this.selected_applicant_id=null;
          this.form.patchValue({message:''});
          this.form.updateValueAndValidity();
          this.isMessageBoxFilled=true;
          if(this.loanID&&!isNaN(this.loanID)){
            this.is_scroll_chat=true;
            this.is_sending_event=true;

            this.getLoanDetails(this.loanID,'',true,false);

          }
          this.freeMessage='';
          this.selected_template_id=null;
          this.isFreeMessage=true;
          this.isTemplateMessageSend=false;
          //this.modalService.dismissAll();

        },
        (err) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
        // this.modalService.dismissAll();
          this.isMessageBoxFilled=false;

        }
      );

    }
  }



  //back from verify
  verifyPinBack(){
    this.showMessageOptinSend=true;
    this.showMessageOptinVerify=false;

  }

  //send optin
  sendOptin(app_id:any){
    this.showMessageOptinSend=false;
    this.showMessageOptinVerify=true;
    if([null,undefined,''].includes(app_id))
    {
       app_id=this.saved_applicant_id;
    }
    let params = {
      applicant_id:this.selected_type=='App'?app_id:null,
      reference_id:this.selected_type=='Ref'?app_id:null,
      loan_id:this.apiLoanDetailResponse?.id ?? this.loanID ?? null,
      company_id:this.selected_type=='COMPANY'?app_id:null
    };
    this.httpService.post(PathConfig.REQUEST_VBT,params).subscribe(
      (response: any) => {

        response?.status == 1 ? this.msgService.showSuccess(response?.message) : (this.msgService.showError(response?.message),this.refresh(), this.modalService.dismissAll());

        if(response?.opt_in_status=="SUBSCRIBED" && this.apiLoanDetailResponse==undefined){
          this.showMessageOptin=true;
          this.showMessageChat=false;
          this.modalService.dismissAll();
        }

        else if(response?.opt_in_status=="SUBSCRIBED"){
          this.showMessageOptin=false;
          this.showMessageChat=true;
          response.opt_in_status='SUBSCRIBED';
          this.selectLoan(null,response?.opt_in_status,this.loanID,this.current_user_type);
        }

        else{
          this.showMessageOptin=true;
          this.showMessageChat=false;
          // this.modalService.dismissAll();
        }

        //this.modalService.dismissAll();

      },
      (err) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
       this.modalService.dismissAll();

      }
    );
  }




  //verify optin
  verifyOptinCode(app_id:any){
    if([null,undefined,''].includes(app_id))
    {
       app_id=this.saved_applicant_id;
    }
    let params = {
      applicant_id:this.selected_type=='App'?app_id:null,
      reference_id:this.selected_type=='Ref'?app_id:null,
      pin:this.pin,
      loan_id:this.apiLoanDetailResponse?.id ?? this.loanID ?? null,
      company_id:this.selected_type=='COMPANY'?app_id:null
    };
    this.httpService.post(PathConfig.CONFIRM_VBT,params).subscribe(
      (response: any) => {

        if(response?.status)
        {
        this.msgService.showSuccess(response?.message);
        this.showMessage= false;
        this.showMessageChat = false;
        this.showMessageTemplate = false;
        this.showMessageOptin = false;
        this.showMessageOptinSend = false;
        this.showMessageOptinVerify = false;
        this.isTemplateSelectionScreenActive=false;
        this.selectLoan(null,"other",this.loanID);
        this.last_visited_page='showMessage';
        this.showMessage= true;
        }
        else
        {
        this.msgService.showError(response?.message);
        }


        //this.modalService.dismissAll();

      },
      (err) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
      // this.modalService.dismissAll();

      }
    );

  }

  //refreshes the message popup
  refresh(){
    this.loadAllMessages=true;
    this.selectedLoanUnreadMessageCount=0;
    this.totalUnreadMessagesCount=0;
    this.countUnreadMessage=0;
    this.selectedLoanId=0;
    this.loanID = Number(this.router.url.split('/')[5]);

    let isEditMode = this.loanID ? true : false;
    if(isEditMode && isNaN(this.loanID)){
      this.selectLoan(null,"other",this.loanID);
    }
    this.isAccountSelected=false;
    this.showMessage= true;
    this.showMessageChat = false;
    this.showMessageTemplate = false;
    this.showMessageOptin = false;
    this.showMessageOptinSend = false;
    this.showMessageOptinVerify = false;
    this.isTemplateSelectionScreenActive=false;
    this.selected_template_id=null;

    this.current_user_type='all';
    this.isTemplateMessageSend=false;
    this.isMessageBoxFilled=true;
    this.isFreeMessage=false;
    this.freeMessage='';
    this.form.patchValue({message:''});
    this.searchTextMessage = '';
    this.form.patchValue({searchTextMessage:''});
    this.form.updateValueAndValidity();

    this.isScrolled = false;
    this.totalPage = 1;
    this.chatMessageList = [];
    this.chatMessageListLoading = false;
    this.currentChatPage = 1;
    this.dnc=false;
  }

  //verify optin
  verifyOptin(){
     this.showMessageOptinSend=false;
     this.showMessageOptinVerify=false;
     this.showMessage=true;
     this.showMessageHeader="2 PENDING MESSAGES";
   }

  //show Chat Box
  showChatBoxApplicant(optin_status:any,user:any,id:any){
    this.dnc = false;
    this.loadingMessages=true;
    this.unreadmessagescount=0;
    this.selected_applicant_id='';
    this.current_user_type=user;
    if(optin_status!='DEACTIVATED'){
      this.last_visited_page="showMessage";
      this.selected_type='App';
      if((optin_status==null || optin_status=='UNSUBSCRIBED') && user=='APPLICANT'){
        this.dnc = this.apiLoanDetailResponse?.applicant?.dnc;

        this.unreadmessagescount = this.apiLoanDetailResponse?.applicant?.unread_count;
        this.showMessageOptinSend=true;
        this.showMessageOptin=true;
        this.showMessageChat=false;
        this.showMessage=false;
        this.selected_applicant_id =  this.getCurrentUserId(this.apiLoanDetailResponse?.applicant?.id) ;
        const applicant_object = this.apiLoanDetailResponse?.applicant ?? this.current_saved_applicant;
        this.showMessageHeader = this.getCurrentUserName(applicant_object);
      }
      else if((optin_status==null || optin_status=='UNSUBSCRIBED') && user=='CO-APPLICANT'){
        this.dnc = this.apiLoanDetailResponse?.co_applicant?.dnc;

        this.unreadmessagescount = this.apiLoanDetailResponse?.co_applicant?.unread_count;
        this.showMessageOptinSend=true;
        this.showMessageOptin=true;
        this.showMessageChat=false;
        this.showMessage=false;
        this.selected_applicant_id = this.getCurrentUserId(this.apiLoanDetailResponse?.co_applicant?.id);
        const applicant_object = this.apiLoanDetailResponse?.co_applicant ?? this.current_saved_applicant;
        this.showMessageHeader=this.getCurrentUserName(applicant_object);
      }
      else{
        if(user=='APPLICANT'){
        this.dnc = this.apiLoanDetailResponse?.applicant?.dnc;

          this.isScrolled = true;
          this.getChatMessages(this.currentChatPage, this.current_user_type)
          .subscribe((response) => {
            this.chatMessageList.push(...response.reverse());
            this.isScrolled = false;
            setTimeout(()=>{
              const contentRef = document.getElementById('chat');
              if(contentRef){
               let Height = document?.getElementById('chat')?.scrollHeight;
               if(Height){
                contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
                this.is_scroll_chat=false;
               }
              }
            },100);
          }, (err) => {
            this.isScrolled = false;
          });

          this.selected_applicant_id=this.apiLoanDetailResponse?.applicant?.id;
          this.unreadmessagescount = this.apiLoanDetailResponse?.applicant?.unread_count;
        }
        else{
          this.isScrolled = true;
          this.dnc = this.apiLoanDetailResponse?.co_applicant?.dnc;
          this.getChatMessages(this.currentChatPage, this.current_user_type)
          .subscribe((response) => {
            this.chatMessageList.push(...response.reverse());
            this.isScrolled = false;
            setTimeout(()=>{
              const contentRef = document.getElementById('chat');
              if(contentRef){
               let Height = document?.getElementById('chat')?.scrollHeight;
               if(Height){
                contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
                this.is_scroll_chat=false;
               }
              }
            },100);
          }, (err) => {
            this.isScrolled = false;
          });
          this.selected_applicant_id=this.apiLoanDetailResponse?.co_applicant?.id;
          this.unreadmessagescount = this.apiLoanDetailResponse?.co_applicant?.unread_count;

        }
        if(this.apiLoanDetailResponse)
        {
        const obj= this.getSelectedApplicant();
        this.showMessageHeader= ![null,undefined,''].includes(obj) ? (obj?.applicant) : (user=='APPLICANT')?this.getFullName(this.apiLoanDetailResponse?.applicant) : this.getFullName(this.apiLoanDetailResponse?.co_applicant);
        }
        else
        {
         const obj= this.getSelectedApplicant();
         this.showMessageHeader=obj?.applicant;
        }
        this.showMessageChat=true;

        this.scrollToRecent();
        this.showMessage=false;
        this.selected_applicant_id = this.selected_applicant_id ?? this.current_applicant_id;
        if(this.selected_applicant_id!=''){
          this.markAsRead(this.selected_applicant_id,this.unreadmessagescount)
        }
      }
    }

    this.loadingMessages=false;
  }

  //show Chat Box
  showChatBoxCompany(optin_status:any,user:any,id:any){
    this.dnc = false;

    this.loadingMessages=true;
    this.unreadmessagescount=0;
    this.selected_applicant_id='';
    this.current_user_type=user;
    if(optin_status!='DEACTIVATED'){
      this.last_visited_page="showMessage";
      this.selected_type='COMPANY';
      if((optin_status==null || optin_status=='UNSUBSCRIBED') && user=='COMPANY'){
        this.dnc = this.apiLoanDetailResponse?.company?.dnc;

        this.unreadmessagescount = this.apiLoanDetailResponse?.company?.unread_count;
        this.showMessageOptinSend=true;
        this.showMessageOptin=true;
        this.showMessageChat=false;
        this.showMessage=false;
        this.selected_applicant_id =  this.apiLoanDetailResponse?.company?.id || this.saved_applicant_id;
        const applicant_object = this.apiLoanDetailResponse?.company ?? this.current_saved_applicant;
        // this.showMessageHeader = applicant_object?.company_name;
      }
      else{
        if(user=='COMPANY'){
        this.dnc = this.apiLoanDetailResponse?.company?.dnc;
          
          this.isScrolled = true;
          this.getChatMessages(this.currentChatPage, this.current_user_type)
          .subscribe((response) => {
            this.chatMessageList.push(...response.reverse());
            this.isScrolled = false;
            setTimeout(()=>{
              const contentRef = document.getElementById('chat');
              if(contentRef){
               let Height = document?.getElementById('chat')?.scrollHeight;
               if(Height){
                contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
                this.is_scroll_chat=false;
               }
              }
            },100);
          }, (err) => {
            this.isScrolled = false;
          });

          this.selected_applicant_id = this.apiLoanDetailResponse?.company?.id
          this.unreadmessagescount = this.apiLoanDetailResponse?.company?.unread_count;
        }
        if(this.apiLoanDetailResponse)
        {
        this.selected_applicant_id =  this.getCurrentUserId(this.apiLoanDetailResponse?.company?.id) || this.saved_applicant_id;

        // const obj= this.getSelectedApplicant();
        if(this.apiLoanDetailResponse?.company?.company_name)
          this.selected_applicant_id =  this.getCurrentUserId(this.apiLoanDetailResponse?.company?.id) || this.saved_applicant_id;
          this.showMessageHeader = this.apiLoanDetailResponse?.company?.company_name;

        }
        else
        {
        //  const obj= this.getSelectedApplicant();
        //  this.showMessageHeader=obj?.applicant;
        if(this.apiLoanDetailResponse?.company?.company_name)
          this.showMessageHeader = this.apiLoanDetailResponse?.company?.company_name;
        }
        this.showMessageChat=true;

        this.scrollToRecent();
        this.showMessage=false;
        this.selected_applicant_id = this.selected_applicant_id ?? this.current_applicant_id;
        if(this.selected_applicant_id!=''){
          this.markAsRead(this.selected_applicant_id,this.unreadmessagescount)
        }
      }
    }

    this.loadingMessages=false;
  }

  showChatBoxCoSigners(optin_status:any,user:any,index:any,applicantType:any){
    this.dnc = false;

    this.loadingMessages=true;
    this.unreadmessagescount=0;
    this.selected_applicant_id='';
    this.current_user_type=applicantType;
    if(applicantType=='CO-SIGNER-1'){
      index=0;
    }
    if(applicantType=='CO-SIGNER-2'){
      index=1;
    }
    if(optin_status!='DEACTIVATED'){
      this.selected_type='App';
      if((optin_status==null || optin_status=='UNSUBSCRIBED')){
        this.dnc = this.apiLoanDetailResponse?.co_signers[index]?.dnc;

        this.unreadmessagescount = this.apiLoanDetailResponse?.co_signers[index]?.unread_count;
        this.unreadmessagescount += this.apiLoanDetailResponse?.co_signers[index]?.unread_count;
        this.showMessageOptinSend=true;
        this.showMessageOptin=true;
        this.showMessageChat=false;
        this.showMessage=false;
        this.last_visited_page="showMessage";
        this.selected_applicant_id=this.getCurrentUserId(this.apiLoanDetailResponse?.co_signers[index]?.id);
        const applicant_object = this.apiLoanDetailResponse?.co_signers[index] ?? this.current_saved_applicant;
        this.showMessageHeader = this.getCurrentUserName(applicant_object);
      }

      else{
        this.dnc = this.apiLoanDetailResponse?.co_signers[index]?.dnc;
        this.showMessageChat=true;
        this.showMessage=false;
        this.last_visited_page="showMessage";


        this.isScrolled = true;
        this.getChatMessages(this.currentChatPage, this.current_user_type)
        .subscribe((response) => {
          this.chatMessageList.push(...response.reverse());
          this.isScrolled = false;
          setTimeout(()=>{
            const contentRef = document.getElementById('chat');
            if(contentRef){
              let Height = document?.getElementById('chat')?.scrollHeight;
              if(Height){
              contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
              this.is_scroll_chat=false;
              }
            }
          },100);
        }, (err) => {
          this.isScrolled = false;
        });

        this.selected_applicant_id=this.getCurrentUserId(this.apiLoanDetailResponse?.co_signers[index]?.id);
        this.unreadmessagescount = this.apiLoanDetailResponse?.co_signers[index]?.unread_count;
        this.unreadmessagescount += this.apiLoanDetailResponse?.co_signers[index]?.unread_count;
        const applicant_object = this.apiLoanDetailResponse?.co_signers[index] ?? this.current_saved_applicant;
        this.showMessageHeader = this.getCurrentUserName(applicant_object);
        this.scrollToRecent();

        if(this.selected_applicant_id!='' && this.total_unread!=0 ){
          this.markAsRead(this.selected_applicant_id,this.unreadmessagescount)
        }
      }
    }

    this.loadingMessages=false;
  }

  setCoSigners(){
    if(this.current_user_type=='CO-SIGNER-1'||this.current_user_type=='CO-SIGNER-2'){

      return this.select_another_loan ? this.another_apiLoanDetailResponse?.co_signers?.find((item:any)=>
      item?.applicant_type == this.current_user_type) : this.apiLoanDetailResponse?.co_signers?.find((item:any)=>
      item?.applicant_type == this.current_user_type);

    }
    return;
  }

  showChatBoxRec(optin_status:any,user:any,id:any){
    this.dnc = false;

    this.loadingMessages=true;
    this.unreadmessagescount=0;
    this.selected_applicant_id='';
    this.current_user_type=user;
    if(optin_status!='DEACTIVATED'){
      this.selected_type='App';
      if((optin_status==null || optin_status=='UNSUBSCRIBED')){
        this.dnc = this.apiLoanDetailResponse?.recipient?.dnc;

        this.unreadmessagescount = this.apiLoanDetailResponse?.recipient?.unread_count;
        this.showMessageOptinSend=true;
        this.showMessageOptin=true;
        this.showMessageChat=false;
        this.showMessage=false;
        this.last_visited_page="showMessage";
        this.selected_applicant_id=this.getCurrentUserId(this.apiLoanDetailResponse?.recipient?.id);
        const applicant_object = this.apiLoanDetailResponse?.recipient ?? this.current_saved_applicant;
        this.showMessageHeader=this.getCurrentUserName(applicant_object);

      }
      else{
        this.dnc = this.apiLoanDetailResponse?.recipient?.dnc;

        this.showMessageChat=true;
        this.showMessage=false;
        this.last_visited_page="showMessage";

        this.isScrolled = true;
        this.getChatMessages(this.currentChatPage, this.current_user_type)
        .subscribe((response) => {
          this.chatMessageList.push(...response.reverse());
          this.isScrolled = false;
          setTimeout(()=>{
            const contentRef = document.getElementById('chat');
            if(contentRef){
              let Height = document?.getElementById('chat')?.scrollHeight;
              if(Height){
              contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
              this.is_scroll_chat=false;
              }
            }
          },100);
        }, (err) => {
          this.isScrolled = false;
        });

        this.unreadmessagescount = this.apiLoanDetailResponse?.recipient?.unread_count;
        this.selected_applicant_id = this.getCurrentUserId(this.apiLoanDetailResponse?.recipient?.id);
        this.showMessageHeader = this.getCurrentUserName(this.apiLoanDetailResponse?.recipient);
        this.scrollToRecent();
        if(this.selected_applicant_id!='' ){
          this.markAsRead(this.selected_applicant_id,this.unreadmessagescount)
        }
      }
    }

    this.loadingMessages=false;
  }

  // showChatBoxRef(optin_status:any,user:any,index:any){
  //   if(optin_status!='DEACTIVATED'){
  //     this.selected_type='Ref';
  //     if((optin_status==null || optin_status=='UNSUBSCRIBED')){
  //       this.showMessageOptinSend=true;
  //       this.showMessageOptin=true;
  //       this.showMessageChat=false;
  //       this.showMessage=false;
  //       this.last_visited_page="showMessage";
  //       this.selected_applicant_id=this.apiLoanDetailResponse?.references[index]?.id;
  //       this.showMessageHeader=this.apiLoanDetailResponse?.references[index]?.full_name;
  //     }

  //     else{
  //     this.showMessageChat=true;
  //     this.showMessage=false;
  //     this.last_visited_page="showMessage";
  //     this.showMessageHeader=this.apiLoanDetailResponse?.references[index]?.full_name;
  //     }
  //   }
  // this.scrollToRecent();
  // }

  //marksMessageAsRead
  markAsRead(loan_applicant_id:any,unreadmessagescount:any){
    if(loan_applicant_id!='' && loan_applicant_id &&  unreadmessagescount!=0 && this.last_un_read!==0){
      let url = this.current_user_type != 'COMPANY'?`${PathConfig.SBT_TEXT_READ}?applicant_id=`+loan_applicant_id:`${PathConfig.SBT_TEXT_READ}?loan_id=${this.loanID}&company_id=`+loan_applicant_id;
      // PathConfig.SBT_TEXT_READ+'/'+item.id+'/read' /text_messages/read?applicant_id=
      this.httpService.put(url,{}).subscribe(
        (response: any) => {
          this.loaderService.hideLoader();


          //this.msgService.showSuccess(response?.message);
          this.loadTextMessages();
         // this.unReadText();
          this.unreadmessagescount=0;
          // this.total_unread=this.total_unread-1;
        },
        (err) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
        }
      );
    }
    else
    {
      this.is_scroll_chat=true;
      this.scrollToRecent();
    }
  }



  //show user detail
  showDetail() {
    const fname = this.userService.getUserInfo('firstname');
    const lname = this.userService.getUserInfo('lastname');
    const designation = this.userService.getUserInfo('job_description') || '';
    const theme = this.userService.getUserInfo('theme_mode');

    const profilePicture = this.userService.getUserInfo('profile_picture_temp_url');
    this.userName = `${fname} ${lname}`;
    this.userDesignation = `${designation?.label}`;
    this.userTheme = theme ? theme.toLowerCase() : 'light';

    this.profilePicture = profilePicture
      ? environment.IMG_URL + profilePicture
      : '../assets/images/icons/default-user.png';
    if (this.userTheme) {
      this.themeService.theme = this.userTheme;
    }
      // this.logo = environment.IMG_URL + this.clientDetailService.LOGO_DARK;
      this.loginAsClient =
      this.clientDetailService.IS_DEFAULT == 'NO' &&
      this.userService.getLoginType(this.clientDetailService.CLIENT_ID);
      this.logo = environment.IMG_URL + this.clientDetailService.FAVICON
    /*
      if(theme?.toLowerCase()=='dark'){
        this.logo = environment.IMG_URL + this.clientDetailService.LOGO_DARK;
      }
      if(theme?.toLowerCase()=='light'){
        this.logo = environment.IMG_URL + this.clientDetailService.LOGO_LIGHT;
      }
    */
  }

  //logout
  logout(): void {
    this.httpService.delete(PathConfig.AUTH_LOGIN_LOGOUT).subscribe(
      (response) => {
        this.msgService.showSuccess(response.success);
        this.removeUserInfo();
      },
      (err) => {
        // this.msgService.showError(err.error.message);
        this.removeUserInfo();
      }
    );
  }

  //on theme change
  onThemeChange($e: any): void {
    const firstname = this.userService.getUserInfo('firstname');
    const lastname = this.userService.getUserInfo('lastname');
    const theme_mode = $e.target.value;
    const params = { firstname, lastname, theme_mode };
    // on theme change
    this.httpService.put(PathConfig.USER_ME, params).subscribe(
      (response) => {

        this.msgService.showSuccess(response.message);
        this.userService.setUserInfo(new UserInfo(response.user));
        this.themeService.theme = $e.target.value;
        if(this.themeService.theme=='dark'){
          this.logo = environment.IMG_URL + this.clientDetailService.LOGO_DARK;
        }
        if(this.themeService.theme=='light'){
          this.logo = environment.IMG_URL + this.clientDetailService.LOGO_LIGHT;
        }
      },
      (err) => {
        this.msgService.showError(err.error.message);
      }
    );
  }

  //on password changed event
  onPasswordChanged(status: boolean): void {
    this.modalService.dismissAll('changePasswordModal');
    if (status) {
      this.removeUserInfo();
    }
  }
  // Open Calculator Modal
  openCalculatorModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      windowClass:'draggable-container',
      backdrop:false
    });
  }
  pressNum(num: string) {

    //Do Not Allow . more than once
    if (num==".") {
      if (this.input !="" ) {

        const lastNum=this.getLastOperand()
        if (lastNum.lastIndexOf(".") >= 0) return;
      }
    }

    //Do Not Allow 0 at beginning.
    //Javascript will throw Octal literals are not allowed in strict mode.
    if (num=="0") {
      if (this.input=="" ) {
        return;
      }
      const PrevKey = this.input[this.input.length - 1];
      if (PrevKey === '/' || PrevKey === '*' || PrevKey === '-' || PrevKey === '+')  {
          return;
      }
    }
    if(num.includes('undefined')){
      this.input = '';
    }

    this.input = this.input + num
    this.calcAnswer();
  }

    // reverses the sign
  reversesign(){
    if((this.input+'').length >0){
      let formula = this.result;
      this.result=this.input= eval(formula+'*-1');
    }
  }

  //take out percentage by dividing by 100
  percentage(){
    if((this.input+'').length >0){
      let formula = this.result;
      this.result=this.input=eval(formula+'/100');
    }
  }

  getLastOperand() {
    let pos:number;
    pos=this.input.toString().lastIndexOf("+")
    if (this.input.toString().lastIndexOf("-") > pos) pos=this.input.lastIndexOf("-")
    if (this.input.toString().lastIndexOf("*") > pos) pos=this.input.lastIndexOf("*")
    if (this.input.toString().lastIndexOf("/") > pos) pos=this.input.lastIndexOf("/")
    return this.input.substr(pos+1)
  }


  pressOperator(op: string) {

    //IF to make sure no Operand gets inserted before the 1st number is inserted.
    if((this.input+'').length >0)
    {

    //Do not allow operators more than once
    const lastKey = this.input[this.input.length - 1];
    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+')  {
      return;
    }

    this.input = this.input + op
    this.calcAnswer();
  }
  }


  clear() {
    this.input = '';
  }

  delete(){
    if (this.input !='' ) {
      this.input+='';
      this.input=this.input.substr(0, this.input.length-1);
    }
  }

  allClear() {
    this.result = '';
    this.input = '';
  }

  calcAnswer() {
    let formula = this.input+'';

    let lastKey = formula[formula.length - 1];

    if (lastKey === '.')  {
      formula=formula.substr(0,formula.length - 1);
    }

    lastKey = formula[formula.length - 1];

    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+' || lastKey === '.')  {
      formula=formula.substr(0,formula.length - 1);
    }

    this.result = eval(formula);
  }

  getAnswer() {
    if(this.input!==''){
      if(this.input=='.'){
        this.allClear();
      }
        else{
        this.calcAnswer();
        this.input = this.result;
        if (this.input=="0" && this.input.includes('undefined') ) this.input="";
      }
    }
  }

  // Open search Modal
  openSearchModal($e:any) {
    const search_key = $e.target.value;
    const params = { search_key: search_key};
    if(search_key.length>=3 && !this.isGlobalSearchInRunning) {
      this.isGlobalSearchInRunning=true;
      this.httpService.post(PathConfig.GLOBAL_SEARCH, params).subscribe(
        (response) => {
          if(response.data.length>0){
            this.isSearchOpen = true
            this.searchData = response.data
          } else {
            this.isSearchOpen = false
            this.searchData = []
          }
          this.isGlobalSearchInRunning=false;
        },
        (err) => {
          this.msgService.showError(err.error.message);
        }
      );
    } else {
      this.isSearchOpen = false
      this.searchData = []
    }
  }

  // Open Message search Modal
  openmessageSearchModal($e:any) {

    this.messageSearchKey ='';
    if($e.key!='ArrowUp' && $e.key!='ArrowDown' && $e.key!='ArrowRight' && $e.key!='ArrowLeft')
    {

    const search_key = $e.target.value;
    if ($e.key === 'Backspace') {
      this.search_flag = false;
    }
    if(search_key.length>=3 && search_key.trim()!=''){
      this.is_loanTextMessagesInProcess=true;
      this.current_page_no = !this.search_flag ? 0 : this.current_page_no;
      this.messageSearchKey = search_key || '';
      this.searchTextMessage = this.form.controls.searchTextMessage.value;
      this.pre_search_key   = search_key || '';
      this.loadTextMessagesForSearchBar(this.messageSearchKey);
      //this.loadTextMessages(this.messageSearchKey);
    }
    else if(search_key.length<=0 && this.pre_search_key.trim()!=''){
      this.is_loanTextMessagesInProcess=true;
      this.apiTextMesasages = [];
      // this.messageSearchKey = '';
      this.pre_search_key   = search_key;
      this.searchTextMessage = this.form.controls.searchTextMessage.value;
      this.search_flag = false;
      this.current_page_no = 0;
      //this.loadTextMessages();
      this.loadTextMessagesForSearchBar();
    }
    else if((search_key.length>=1 && search_key.length<=2) && this.pre_search_key.trim()!='' && ($e.key === 'Backspace' || $e.key === 'Delete'))
    {
      this.is_loanTextMessagesInProcess=true;
      this.apiTextMesasages = [];
      // this.messageSearchKey ='';
      this.searchTextMessage = this.form.controls.searchTextMessage.value;

      this.pre_search_key   = search_key;
      this.search_flag = false;
      this.current_page_no = 0;
      this.loadTextMessagesForSearchBar();
    }
  }


  }


  // Open Message Modal
  openMessageModal(content: any) {
    this.messageSearchKey = '';
    this.search_flag = false;
    this.apiTextMesasages=[];
    this.current_page_no=1;
    this.allAccountsStatus=true;
    this.countUnreadMessage=0;
    this.showCountMessage = '';
    this.loanID = Number(this.router.url.split('/')[5]);
    let isEditMode = this.loanID ? true : false;
    // setTimeout(()=> this.loadTextMessages(),1000);
    this.loadTextMessages();
    if(isEditMode){
      this.selectLoan(null,"other",this.loanID);
    }
    else{
    }

    const modalInstance = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      windowClass:'draggable-container',
      backdrop:false
      // animation:true,
      // focus: false
    });

    modalInstance.result.then( () => {
      this.refresh();
      // this.isModalClosed=true;
    },  () => {
      this.refresh();
      // this.isModalClosed=true;
    });
  }

  // Open Phone Modal
  openPhoneModal(content:any) {
    this.modalService.open(CallerComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      windowClass:'draggable-container popup-outside-editable',
      backdrop:false
    });
  }

  //reset user information
  removeUserInfo(): void {
    const clientSlug = ClientUtils.getClientSlug(this.route, this.router);
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.AUTH_TOKEN_EXPIRY
    );
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.AUTH_TOKEN
    );
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.USER_INFO
    );
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.SELECTED_THEME
    );
    this.broadCastService.broadcast(MsgConstantConfig.USER_LOGGED_OUT);

    const clientID = this.clientDetailService.CLIENT_ID;
    this.router.navigate(['/', clientID]);
  }

  openChangePasswordModal(content: any) {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
    });
  }

  goToDashboard() {
    let clientId = this.userService.getClientId();
    // window.location.href = `/${clientId}`;
    // this.router.navigate(["", clientId]);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    const currentUrl:any = clientId ;
    this.router.navigateByUrl(currentUrl).then(() => {
      this.router.navigated = false;
      this.router.navigate(["", clientId]);
      });
  }

  /**
   * goToRoute
   */
   public goToRoute(route:any) {
    this.Login_as_lendisoft = ClientUtils.isLoginAsClient(this.clientDetailService, this.userService);
    if (this.Login_as_lendisoft == true) {
      this.selecteduser = "ALL";
    }
    else {
      this.selecteduser = this.userService.getUserInfo("id");
    }
    this.activatedRoute.queryParams
    .subscribe(params => {
      this.queueType = [null, '', undefined].includes(params['qtype']) ? "" : params['qtype'];
        this.sortBy = [null, '', undefined].includes(params['sortby']) ? "" : params['sortby'];
        this.orderBy = [null, '', undefined].includes(params['orderby']) ? "" : params['orderby'];
        this.pageLimit = [null, '', undefined].includes(params['limit']) ? 0 : params['limit'];
        this.currentPage = [null, '', undefined].includes(params['page']) ? 0 : params['page'];
      // this.queueType = params['qtype']==null?"":params['qtype'];
      // this.sortBy=params['sortby']==null?"":params['sortby'];
      // this.orderBy=params['orderby']==null?"":params['orderby'];
      // this.pageLimit=params['limit']==null?"":params['limit'];
      // this.currentPage=params['page']==null?"":params['page'];
    });

    this.activatedRoute.queryParams
    .subscribe(params => {
      let priority_status=params['account_work_activity_priority_status'];
      this.selectedMainStatus=[null,'',undefined].includes(params['loan_status'])?null:params['loan_status'];
      this.selected_sub_status=[null,'',undefined].includes(params['loan_sub_status_label'])?null:params['loan_sub_status_label'];
      this.selecteduser=[null,undefined].includes(params['user_id'])?this.selecteduser?.toString():params['user_id']?.toString();
      this.selectedpriority=[null,'',undefined].includes(params['account_work_activity_priority_status'])?null:priority_status?.split(',');
      this.pri=this.selectedpriority?.length>0?this.selectedpriority?.toString():'';

    })
    // this.searchForm.patchValue({
    //   'searchText':''
    // });
    this.searchForm.reset();
    this.isSearchOpen = false
    let clientId = this.userService.getClientId();
    let url = `/home/servicing/queues-detail/${route}`;
    // this.router.navigate([clientId + url]);
    // let clientId = this.userService.getClientId();
    // let url = `/home/servicing/queues-detail/${id}`;
    this.router.navigate([clientId + url],{  queryParams: { qtype: this.queueType , sortby: this.sortBy ,orderby: this.orderBy,limit: this.pageLimit,page :this.currentPage ,account_work_activity_priority_status:this.pri, user_id:this.selecteduser,loan_status:this.selectedMainStatus??'',loan_sub_status_label:this.selected_sub_status??''}, queryParamsHandling: "merge" });

    // setTimeout(()=> this.modalService.dismissAll(),1000);
  }

  scrollToRecent() {

    setTimeout(()=>{
      const contentRef = document.getElementById('chat');
      if(contentRef){
       let Height = document?.getElementById('chat')?.scrollHeight;
       if(Height){
        contentRef?.scrollTo( { left: 0, top: Height+500, behavior: "auto" });
        this.is_scroll_chat=false;
       }
      }
    },100);
  }

  getProf()
  {


    const p_pic   = this.userService.getUserInfo('profile_picture_temp_url');
    this.prof_pic = p_pic ? environment.IMG_URL + p_pic : '../assets/images/icons/default-user.png';


    // this.httpService.get(PathConfig.USER_ME).subscribe(
    //   (response) => {

    //     const p_pic=response?.user?.profile_picture_temp_url;
    //     this.prof_pic= p_pic
    //   ? environment.IMG_URL + p_pic
    //   : '../assets/images/icons/default-user.png';
    //     // //console.log("Resp:",this.prof_pic);


    //   },
    //   (err) => {
    //     this.msgService.showError(err.error.message);
    //   }
    // );
  }

  convertToUSFormat(mobileNumber: string): string {
    // Remove any non-numeric characters from the input number
    const cleanedNumber = mobileNumber.replace(/\D/g, '');

    // Check if the number is already in US format
    if (cleanedNumber.length === 10) {
      const areaCode = cleanedNumber.substring(0, 3);
      const prefix = cleanedNumber.substring(3, 6);
      const lineNumber = cleanedNumber.substring(6);
      return `(${areaCode}) ${prefix}-${lineNumber}`;
    }

    // If the number does not match the US format, return it as is
    return mobileNumber;
  }

  getSelectedApplicant()
  {
    return this.apiTextMesasages?.find((item:any) => item.user_type === this.current_user_type && item.loan_applicant_id === this.current_applicant_id);
  }

  getCurrentUserId(data:any)
  {
          if([null,undefined,''].includes(data))
             return this.current_applicant_id;
           else
           return data;
  }
  getCurrentUserName(data:any)
  {
    const obj= this.getSelectedApplicant();
    return ![null,undefined,''].includes(obj) ? obj?.applicant : this.getFullName(data);
  }
  checkIfURL(inputText:any) {
    // Regular expression to validate URL format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    // Check if the input text matches the URL pattern
    this.isURL = urlPattern.test(inputText);
    return this.isURL;
  }
  getCurrentLoanId()
  {
    const ext_loan_id = Number(this.router.url.split('/')[5]);
    return ext_loan_id;
  }
  onScroll() {

    if (this.isScrollAtBottom() && !this.isLoading && this.is_In_queue) {
     this.current_page_no++;
     this.isLoading=true;
    [null,'',undefined].includes(this.messageSearchKey) ? this.loadTextMessages() : this.loadTextMessages(this.messageSearchKey);
    }
    else  if (this.isScrollAtBottom() && !this.isLoading && !this.is_In_queue && this.ALLSMSPermission==='YES') {
      this.current_page_no++;
      this.isLoading=true;
     [null,'',undefined].includes(this.messageSearchKey) ? this.loadTextMessages() : this.loadTextMessages(this.messageSearchKey);
     }

  }

  private isScrollAtBottom(): boolean {
    const container:any = document.querySelector('.message-notification-body');
    let dif=container.scrollHeight - container.scrollTop;
    return   (dif+5 > container.clientHeight && dif-5 < container.clientHeight);
  }

  getCurrentApplicantObjecReadStatus()
  {
// Using the `find` method to search for the desired loan_applicant_id
const foundObject = this.apiTextMesasages?.find((item) => item.loan_applicant_id === this.current_applicant_id);

if (foundObject) {
 return foundObject?.read==0 ? true : false;
} else {
 return false;
}
  }
  getFullName(app_obj:any): string {
    // Trim and check for null or empty strings
    const trimmedFirstName = app_obj?.firstname?.trim() || '';
    const trimmedMiddleName = app_obj?.middlename?.trim() || '';
    const trimmedLastName = app_obj?.lastname?.trim() || '';

    // Concatenate the names
    return `${trimmedFirstName} ${trimmedMiddleName} ${trimmedLastName}`.trim();
  }


  getSanitizedSentence(msg: string, type: string): SafeHtml {
    const linkTemplate =
      type === 'OUTBOUND'
        ? '<u><a href="$1" target="_blank" style="color:white;">$1</a></u>'
        : '<u><a href="$1" target="_blank" style="color:white;">$1</a></u>';

    const sanitizedHtml = msg.replace(/((http|https):\/\/\S+)/g, linkTemplate);
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedHtml);
  }

  getTotalUnRead()
  {
   return this.total_unread===0?'' : this.total_unread+( this.total_unread==1 ? " Unread Message":" Unread Messages");
  }

  getFilterTextMessages()
  {

   let inputArray:Array<any>=[];
    if(!this.is_In_queue)
    {
    let curr_loan_id=this.getCurrentLoanId();

    inputArray= this.apiTextMesasages.filter(item => item.loan_id !== curr_loan_id);
    }
    else
    {
      inputArray= this.apiTextMesasages;
    }
    const uniqueObjectSet = new Set(inputArray.map(item => item.id));

    // Convert the Set back into an array of unique objects
     const uniqueObjects = Array.from(uniqueObjectSet).map(id => {
    return inputArray.find(item => item.id === id);
     });
     return uniqueObjects;
  }

  setscrollToTop() {

    setTimeout(()=>{
      const contentRef:any = document.querySelector('.message-notification-body');
      if(contentRef){
       let Height = document.querySelector('.message-notification-body')?.scrollHeight;
       if(Height){
        contentRef?.scrollTo( { left: 0, top:0, behavior: "auto" });
        this.is_scroll_chat=false;
       }
      }
      this.current_page_no--;
    },100);
  }

  otherUnreadMessagesPresent(id:any)
  {
    const hasOtherLoansWithDifferentId = this.apiTextMesasages?.some((loan:any) => loan.loan_id !== id);
    if(hasOtherLoansWithDifferentId)
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  loadTextMessagesForSearchBar(message?:string){
    this.total_pages = Math.ceil(this.total_messages / this.load_messages_per_page);

    let url='';
    this.apiTextMesasages = [];
    if(message){

      if(this.router.url.indexOf("servicing/queues/")!=-1){
        if(this.current_user_id){
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}&page=${this.current_page_no}`;
        }
        else{
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&skip_auth_user=false&page=${this.current_page_no}`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
      }
      else if(this.router.url.indexOf("servicing/queues-detail/")!=-1){
        let temp_loanID = Number(this.router.url.split('/')[5]);
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&skip_auth_user=false&loan_id=${temp_loanID}&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }
      else{
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&search=${encodeURIComponent(message)}&skip_auth_user=false&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }

    }
    else{
      // if(this.current_user_id){
      //   url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&user_id=${this.current_user_id}`;
      // }
      // else{
      //   if(this.router.url.indexOf("servicing/queues/")!=-1){
      //     url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&user_id=${''}`;
      //   }
      //   else{
      //     url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false`;
      //   }
      // }

      if(this.router.url.indexOf("servicing/queues/")!=-1){
        if(this.current_user_id){
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&user_id=${this.ALLSMSPermission=='YES'?'ALL':this.current_user_id}&page=${this.current_page_no}`;
        }
        else{
          url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false&page=${this.current_page_no}`;
          if(this.ALLSMSPermission=='YES'){
            url +="&user_id=ALL";
          }
        }
      }
      else if(this.router.url.indexOf("servicing/queues-detail/")!=-1){
        let temp_loanID = Number(this.router.url.split('/')[5]);
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false&loan_id=${temp_loanID}&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }
      else{
        url = `${PathConfig.TEXT_MESSAGES}?read=${this.loadAllMessages}&skip_auth_user=false&page=${this.current_page_no}`;
        if(this.ALLSMSPermission=='YES'){
          url +="&user_id=ALL";
        }
      }
    }
    this.loaderService.hideLoader();
    this.httpService.get(url).subscribe(
      (response) => {
        this.loaderService.hideLoader();
        //this.apiTextMesasages = response?.length==0?this.textMessages:response;
        // this.apiTextMesasages = response?.text_messages?.length === 0 ? this.textMessages : [...this.apiTextMesasages, ...response?.text_messages];
        this.apiTextMesasages =  response?.text_messages;

        this.is_loanTextMessagesInProcess=false;
        this.setscrollToTop();
      },
      (err) => {
        this.msgService.showError(err.error.message);
        this.loadingMessages=false;
        this.is_loanTextMessagesInProcess=false;
        this.isLoading=false;
      }
    );

  }
  //to do : remove
  getLastMessage(obj:any,type:any)
  {
    if(type)
    {
    if(obj?.last_message)
    {
      return (obj?.last_message?.title!='SBTMMS' ?
      obj?.last_message?.message :
      obj?.last_message?.s3_url?.
      substr( obj?.last_message?.s3_url?.indexOf("/")+1))
       ?? 'No Messages' ;
    }
    else
    {
      // return (obj?.text_messages[obj?.text_messages?.length-1]?.title!='SBTMMS' ?
      // obj?.text_messages[obj?.text_messages?.length-1]?.message :
      // obj?.text_messages[obj?.text_messages?.length-1]?.s3_url?.
      // substr( obj?.text_messages[obj?.text_messages?.length-1]?.s3_url?.indexOf("/")+1))
      //  ?? 'No Messages';
      return 'No Messages';

    }
  }
  else if(!type)
  {
    if(obj?.last_message)
    {
      return obj?.unread_count!=0 ?( obj?.last_message?.title!='SBTMMS' ?
      obj?.last_message?.message :
      obj?.last_message?.s3_url?.
      substr( obj?.last_message?.s3_url?.indexOf("/")+1) ) :
      ((obj?.unread_count??0) +' Unread Messages');
    }
    else
    {

      // return obj?.unread_count!=0 ?( obj?.text_messages[obj?.text_messages?.length-1]?.title!='SBTMMS' ?
      // obj?.text_messages[obj?.text_messages?.length-1]?.message :
      // obj?.text_messages[obj?.text_messages?.length-1]?.s3_url?.
      // substr( obj?.text_messages[obj?.text_messages?.length-1]?.s3_url?.indexOf("/")+1) ) :
      // ((obj?.unread_count??0) +' Unread Messages');
      return ((obj?.unread_count??0) +' Unread Messages');
    }
  }
  }

  getSmsServiceVendor() {
    const smsServiceVendor = `${PathConfig.CUSTOM_VENDOR_CONFIG}?config_data=sms_service`;
      this.httpService.get(smsServiceVendor).subscribe((res) => {
      this.auto_subscribe = res?.auto_subscribe;
      this.sms_service = res?.sms_service;
      if(res?.sms_service=="TMP")
      this.vendorConfig = res.tmxp_configuration;
      if(res?.sms_service=="SBT")
      this.vendorConfig = res.sbt_configuration;
    });
  }

  // on scroll get previous set of chat list data
  onInfiniteScroll(){

    if(!this.isScrolled && this.currentChatPage < this.totalPage) {

      this.isScrolled = true;
      this.currentChatPage++;
      this.getChatMessages(this.currentChatPage, this.current_user_type).subscribe((response) => {
        this.chatMessageList.unshift(...response?.reverse());
        setTimeout(()=>{
          const contentRef = document.getElementById('chat');
          if(contentRef){
           let Height = document?.getElementById('chat')?.scrollHeight;
           if(Height){
            contentRef?.scrollTo( { left: 0, top: (Height/this.currentChatPage < Height*0.157 ) ? Height*0.157 + Height/this.currentChatPage : Height/this.currentChatPage , behavior: "auto" });
            this.is_scroll_chat=false;
            this.isScrolled = false;
           }
          }
        },500);
      }, (err) => {
        this.isScrolled = false;
      });

    }
  }


  getChatMessages(page:number,applicant_type:any):Observable<any>{
    this.chatMessageListLoading = true;
    const obs = new Observable((observer) => {
      let applicant_id
      switch(applicant_type){
        case 'APPLICANT':
          applicant_id = this.apiLoanDetailResponse?.applicant?.id;
        break;
        case 'CO-APPLICANT':
          applicant_id = this.apiLoanDetailResponse?.co_applicant?.id;
        break;
        case 'CO-SIGNER-1':
          applicant_id = this.apiLoanDetailResponse?.co_signers[0]?.id;
        break;
        case 'CO-SIGNER-2':
          applicant_id = this.apiLoanDetailResponse?.co_signers[1]?.id;
        break;
        case 'COMPANY':
          applicant_id = this.apiLoanDetailResponse?.company?.id;
        break;
        case 'RECIPIENT':
          applicant_id = this.apiLoanDetailResponse?.recipient?.id;
        break;
        default:

        break;
      }

      let url;

      url = applicant_type != 'COMPANY' ?
      `${PathConfig.TEXT_MESSAGES_LOANS}?applicant_type=${applicant_type}&applicant_id=${applicant_id}&loan_id=${this.apiLoanDetailResponse?.id}&limit=${this.chatMessageLimit}&page=${page}` :
      `${PathConfig.TEXT_MESSAGES_LOANS}?applicant_type=${applicant_type}&company_id=${applicant_id}&loan_id=${this.apiLoanDetailResponse?.id}&limit=${this.chatMessageLimit}&page=${page}`;

      // const url = `${PathConfig.TEXT_MESSAGES_LOANS}?applicant_type=${applicant_type}&loan_reference_id=${''}&loan_applicant_id=${''}`;
      //  url = `${PathConfig.TEXT_MESSAGES_LOANS}?applicant_type=${applicant_type}&applicant_id=${applicant_id}&limit=${20}&page=${page}`;
      this.httpService.get(url).subscribe((response)=>{
        const messageList = response.data;
        const pagination = response.pagination;
        this.currentChatPage = pagination.current;
        this.totalPage = Math.ceil(pagination.total/pagination.size);
        this.chatMessageListLoading = false;
        observer.next(messageList);
        observer.complete();
      },(err)=>{
        this.msgService.showError(err.error.message);
        this.chatMessageListLoading = false;
        observer.error(err);
      })

    });
    return obs;
  }

  navigateTo() {

    let clientId = this.userService.getClientId();
    let url = `/home/servicing/${'add-edit-loan-information'}`;
    this.router.navigate([clientId + url]);

  }
  languages = [
    { languageValue: 'English', selectedLanguage: 'en', icon: '../assets/images/header/English.png' },
    // { languageValue: 'Spanish', selectedLanguage: 'es', icon: '../assets/images/header/spanish.png' },
    // { languageValue: 'Testing', selectedLanguage: 'test', icon: '../assets/images/header/french.png' }
  ];


  changeLanguage(language: any): void {
    this.selectedLanguage = language.selectedLanguage;
    this.selectedLanguageIcon = language.icon; // Update the icon
    localStorage.setItem('Selected_Language', this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
    this.dropdownOpen = false; // Close the dropdown after selection
    setTimeout(() => {
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID)
    }, 1000);
  }
  getSelectedLanguageValue(): string {
    const selectedLang = this.languages.find(lang => lang.selectedLanguage === this.selectedLanguage);
    return selectedLang ? selectedLang.languageValue : 'Please Select';
  }
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.dropdownOpen = false;
    }
  }

}
