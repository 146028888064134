import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { HttpService, MessageService,UserService, BroadcastService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { UserInfo } from 'src/app/core/models/user/user-info.model';
import { environment } from 'src/environments/environment';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-re-login',
  templateUrl: './re-login.component.html',
  styleUrls: ['./re-login.component.scss']
})
export class ReLoginComponent implements AfterViewInit,OnDestroy {

  loginForm!: UntypedFormGroup;
  isRemembered:boolean = false;
  eventSubscription:Array<Subscription> = [];
  submitted = false;
  loginUrl: string = environment.LOGIN_URL;
  userEmail:string = '';
  maintenance: any = 0;
  is2FAEnable:boolean = false;
  twoFAForm!: UntypedFormGroup;
  countDown:any = new Subscription();
  counter: number = 120;
  iscountDown: boolean = false;
  constructor(
    private httpService:HttpService,
    private msgService:MessageService,
    private userService:UserService,
    private clientDetailService:ClientDetailService,
    private activeModal: NgbActiveModal,
    private broadCastService:BroadcastService,
    private activatedRoute:ActivatedRoute,
    private route:Router,
    private translateService:TranslateService
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');

    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required,Validators.email]),
      password: new UntypedFormControl('', [Validators.required])
    });
    //two FA form controls
    this.twoFAForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required,Validators.email]),
      code : new UntypedFormControl('', [Validators.required])
    });

    }

  ngAfterViewInit(): void {

    //set email of logged in user
    setTimeout(()=>{
      this.userEmail = this.userService.getUserInfo('email') || '';
      this.loginForm.patchValue({
        email:this.userEmail
      });
    },1000);
    this.getMaintenanceStatus();
  }
  
  getMaintenanceStatus(){
    this.maintenance=this.clientDetailService.getClientInfo('maintenance_flag')||0;
  }

  //submit form
  onSubmit(event:any):void {

    this.loginForm.markAllAsTouched();
    if(this.loginForm.valid && !this.submitted) {
      this.submitted = true;
      const loginCredentials = this.loginForm.value;
      loginCredentials.is_client  = this.clientDetailService.getClientInfo('is_default') == 'YES' ? 'NO' : 'YES';
      this.httpService.post(PathConfig.AUTH_LOGIN_LOGOUT,loginCredentials).subscribe((response)=>{
        if('user' in response && 'enable_2fa' in response.user && response?.user?.enable_2fa=='YES'){
          this.is2FAEnable = true;
          this.twoFAForm.patchValue({email:response?.user?.email});
          this.submitted = false;
        }else{
          this.msgService.showSuccess('Logged In successfully.')
          this.userService.setAuthToken(response.access_token);
          this.userService.setAuthTokenExpiry(response.token_expiry);
          this.userService.setUserInfo(new UserInfo(response.user));
          const clientId = this.activatedRoute.snapshot.firstChild?.params['clientId'];
          if(clientId){
            this.userService.setPermissions(clientId,response.permissions);
          }
          this.broadCastService.broadcast(MsgConstantConfig.USER_LOGGED_IN);

          //if email not found redirect to dashboard
          if(!this.userEmail){
            this.route.navigate([clientId+'/home/landing']);
          }
          this.submitted = false;
          this.activeModal.close(true);
          window.location.reload();
        }
      },(err)=>{
        if(err.error.message){
          this.msgService.showError(err.error.message);
        }
        this.submitted = false;
      })

    }
  }

  on2FASubmit(event:any):void {
    this.twoFAForm.markAllAsTouched();
    if(this.twoFAForm.valid && !this.submitted) {
      this.submitted = true;
      const loginCredentials = this.twoFAForm.value;
      loginCredentials.is_client  = this.clientDetailService.getClientInfo('is_default') == 'YES' ? 'NO' : 'YES';
      this.httpService.post(PathConfig.AUTH_2FA_VERIFICATION,loginCredentials).subscribe((response)=>{
          this.msgService.showSuccess('Logged In successfully.')
          this.userService.setAuthToken(response.access_token);
          this.userService.setAuthTokenExpiry(response.token_expiry);
          this.userService.setUserInfo(new UserInfo(response.user));
          const clientId = this.activatedRoute.snapshot.firstChild?.params['clientId'];
          if(clientId){
            this.userService.setPermissions(clientId,response.permissions);
          }
          this.broadCastService.broadcast(MsgConstantConfig.USER_LOGGED_IN);
          //if email not found redirect to dashboard
          if(!this.userEmail){
            this.route.navigate([clientId+'/home/landing']);
          }
          this.submitted = false;
          this.activeModal.close(true);
          // window.location.reload();
      },(err)=>{
        if(err.error.message){
          this.msgService.showError(err.error.message);
        }
        this.submitted = false;
      })

    }
  }

  onResendCode(){
    this.httpService.post(PathConfig.AUTH_2FA_RESEND_CODE,{email:this.twoFAForm.value.email}).subscribe((response)=>{
        this.msgService.showSuccess('Verification code re-send successfully.');   
        this.otpTimer();   
    },(err)=>{
      if(err.error.message){
        this.msgService.showError(err.error.message);
      }
      this.submitted = false;
    });
  }

  refresh(){
    window.location.reload();
  }

  ngOnDestroy():void{
    if (this.countDown) { this.countDown.unsubscribe();  }
  }

  otpTimer() {
    this.iscountDown = true;
    this.countDown = timer(0, 1000)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        if (this.counter == 0) {
          this.counter = 120;
          this.iscountDown = false;
          this.countDown.unsubscribe();
        }
      });
  }
}
