import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ls-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class LSSearchFilterComponent implements OnInit, OnChanges {
  @Input() list: Array<any> = [];
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() selectedFilter: any = '';

  constructor(private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }
  ngOnChanges(changes: SimpleChanges): void {
    const list = changes['imgPath'];
    if (list && list.currentValue != list.previousValue) {
      this.list = list.currentValue;
    }
  }
  ngOnInit(): void {}
  onFilterSelected(item: any) {
    this.selectedFilter = item.id;
    this.onSelected.emit(item);
  }
}
