import { AbstractControl } from '@angular/forms';

/**
PIN Validation
--------------------
Valid formats:

5555
22222
454185

 */
export function ValidatePin(control: AbstractControl) {
  const regExp = /^.{4,6}$/;
  if (!regExp.test(control.value) && control.value) {
    return { invalidPin: true };
  }
  return null;
}