import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialWidgetModule } from "./modules/materialwidget/materialwidget.module";
import { CKEditorModule } from 'ckeditor4-angular';
import { ValdemortConfig, ValdemortModule } from 'ngx-valdemort';
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { LSFileUploadComponent } from "./components/file-upload/file-upload.component";
import { LSSearchFilterComponent } from "./components/search-filter/search-filter.component";
import { LSSearchInputComponent } from "./components/search-input/search-input.component";
import { LSNodataFoundComponent } from "./components/nodata-found/nodata-found.component";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from "../core/pipes/pipes.module";
import { NgApexchartsModule } from 'ng-apexcharts';
import { DataTablesModule } from "angular-datatables";
import { DataWarehouseComponent } from "./components/data-warehouse/data-warehouse.component";
import { LSUploadFileParserComponent } from "./components/upload-file-parser/upload-file-parser.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { CustomADateAdapter } from "./services/custom-date-adapter.service";
import { CustomDateParserFormatter } from "./services/custom-date-parser.service";
import { CustomDatePickerComponent } from "./components/custom-date-picker/custom-date-picker.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { CustomeMonthPickerComponent } from "./components/custome-month-picker/custome-month-picker.component";
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TrimOnBlurDirective } from "./directives/trim-on-blur.directive";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        MaterialWidgetModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        ValdemortModule,
        InfiniteScrollModule,
        NgbModule,
        PipesModule,
        NgApexchartsModule,
        DataTablesModule,
        NgxExtendedPdfViewerModule,
        NgSelectModule,
        BsDatepickerModule.forRoot(),
        HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    ],
    declarations: [
        ConfirmModalComponent,
        LSFileUploadComponent,
        LSSearchFilterComponent,
        LSSearchInputComponent,
        LSNodataFoundComponent,
        DataWarehouseComponent,
        LSUploadFileParserComponent,
        CustomDatePickerComponent,
        CustomeMonthPickerComponent,
        TrimOnBlurDirective
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomADateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    exports: [
        MaterialWidgetModule,
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        ValdemortModule,
        InfiniteScrollModule,
        ConfirmModalComponent,
        LSFileUploadComponent,
        LSSearchFilterComponent,
        LSSearchInputComponent,
        LSNodataFoundComponent,
        NgbModule,
        PipesModule,
        NgApexchartsModule,
        DataTablesModule,
        DataWarehouseComponent,
        LSUploadFileParserComponent,
        NgxExtendedPdfViewerModule,
        CustomDatePickerComponent,
        NgSelectModule,
        CustomeMonthPickerComponent,
        TrimOnBlurDirective
    ]
})
export class SharedModule {
  constructor(config: ValdemortConfig) {
    config.errorsClasses = 'text-danger';
  }
 }
