import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FooterComponent } from './core/modules/footer/footer.component';
import { HomeComponent } from './modules/home/home.component';
import { AuthComponent } from './modules/auth/auth.component';
import { QueueDetailComponent } from './modules/servicing/queues/queue-detail/queue-detail.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ErrorComponent } from './modules/error/error.component';
import { ErrorNotFoundComponent } from './modules/error/error-not-found/error-not-found.component';
import { ErrorNotAuthorizedComponent } from './modules/error/error-not-authorized/error-not-authorized.component';
import { ResetPinComponent } from './modules/auth/reset-pin/reset-pin.component';



import { IConfig, NgxMaskModule } from 'ngx-mask';
import { LoadingBarModalComponent } from './shared/components/loading-bar-modal/loading-bar-modal.component';
import { LandingComponent } from './modules/landing/landing.component';
import { RuleBuilderComponent } from './modules/servicing/settings/rule-builder/rule-builder.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    AuthComponent,
    QueueDetailComponent,
    ErrorComponent,
    ErrorNotFoundComponent,
    ErrorNotAuthorizedComponent,
    ResetPinComponent,
    // LoadingBarModalComponent,
    LandingComponent,
    RuleBuilderComponent,

  ],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    NgApexchartsModule,
    NgxMaskModule.forRoot(maskConfig),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [HttpClient],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
