import { NgModule, ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared';
import { RouterModule } from '@angular/router';
import { LeftNavigationComponent } from './left-navigation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule,
      HttpClientModule,
      TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
      }
    })
  ],
providers: [HttpClient],
    declarations: [LeftNavigationComponent],
    exports: [LeftNavigationComponent],
    bootstrap: [LeftNavigationComponent]
})
export class LeftNavigationModule {
  static forRoot(): ModuleWithProviders<LeftNavigationModule> {
    return {
      ngModule: LeftNavigationModule
    };
  }
}
