<form #f="ngForm" [formGroup]="form" novalidate>
    <div id="page-content-wrapper modal-builder" class="main">
        <div class="lms-card mb-3">
            <div class="row">
                <div class="col">
                    <h3 class="lms-card-heading">{{"Model Builder"|translate}}</h3>
                </div>
                <div class="col-auto">
                    <a href="javascript:;" class="btn-help-info is-hover">
                        <span>{{"Need help"|translate}}?</span>
                        <img src="../assets/images/icons/help.png" class="icon-dark" />
                        <img src="../assets/images/icons/help-dark.png" class="icon-light"/>
                    </a>
                </div>
            </div>
            <div class="lms-description">
                <p>
                    {{"A scorecard is a powerful statistical model that can help a business with mission-critical decision making. The modern scorecard has virtually transformed the credit-granting and collections industry in recent years. Scorecards are used to help organizations automate a multitude of important business decisions every day, some of which include: making approval/decline decisions on new applications for granting credit (they tell you who's likely to default before you authorize credit); to help guide collection efforts (they tell you which delinquent accounts are likely to self-cure, roll to deeper delinquency, or default altogether); and they're used in marketing to optimize who to send advertising mailers to (they tell you which consumers are most likely to respond); and they're used in many, many, other ways across an array of industries"|translate}}.
                </p>
                <p class="m-0">
                    {{"There are 2 types of scorecards supported by Lendisoft"|translate}}:  
                </p>
                <p class="m-0">    
                    (1)  {{"Expert Scorecards"|translate}} – {{"Are structured exactly like Machine Learning Models, but you use your business acumen and hard-earned experience to choose the variables and set the points (weights). These are also known in the industry as judgmental scoring models. You can build unlimited Expert Scoring models in Lendisoft, and use them to calculate our risk-based tools like Odds-of-Roll, Likelihood of Default, Likelihood of Collectability, and more. Expert Scores also drive our Risk Grades (A-E), which can be used to schedule email and SMS campaigns, and even control the timing around agents calling precise risk-segments within your past due accounts. Expert Scores also unlock the ability to do skill-based assignments, empowering you to assign your toughest accounts to your top collectors, etc"|translate}}.
                </p>
                <p class="">    
                    (2)  {{"ML Scorecards"|translate}} – {{"Are logistic regression derived models—powered by IBM SPSS, and use a “supervised machine learning” algorithm that automatically picks the most predictive variables and optimally sets the points (weights). ML models are known to be more powerful than expert scores since they are empirically derived models and machine-trained. IBM is a market leader in business analytics, and a proud partner of Lendisoft technologies"|translate}}. 
                </p>
                <p class="m-0">
                    {{"In either case, the use of scoring models in your day-to-day operations will help you optimize your decision making and drive efficiency"|translate}}. 
                </p>
                <p class="m-0">   
                    -  {{"If you intend to build Expert Scorecards, you can do so directly from the Input Scorecard menu, which is included in all 3 of Lendisoft's LMS product variations"|translate}}.
                </p>
                <p class="m-0">  
                    -  {{"If you require the enhanced power of ML models, you can build them right here on this page, interactively with the help of a Lendisoft risk analyst (for pricing, contact us today"|translate}}!).
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-9 position-static"  >
                <div *ngIf="refresh" class="page-content-inner">
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#DefineNotification" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Define Scorecard Template"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <div class="collapse show" id="DefineNotification">
                            <div class="card-form-content mt-2">
                                <div class="row">
                                    <div class="col-md-6">
                                        <!-- #1 Notification Code form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-7">
                                                <label class="form-group-label">{{"Scorecard Name"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-5">
                                                <input type="text" name="name" maxlength="64" value=""  class="form-control form-control-sm" formControlName="name"  />
                                            </div>
                                            <val-errors controlName="name">
                                                <ng-template valError="required"
                                                  > {{"Name is required"|translate}}</ng-template
                                                >
                                              </val-errors>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Documentation" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Describe the Purpose of this Scorecard"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Documentation">
                            <div class="card-form-content mt-2">
                                <!-- #1 Brief Description form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Brief Description"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <input type="text" name="description" maxlength="300" class="form-control form-control-sm" value=""
                                        formControlName="description"  />
                                        <span class="text-count">{{form.get('description')?.value?.length??0}}/300</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Project plan -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Stakeholders" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Project Plan"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <div class="collapse show" id="Stakeholders">
                            <div class="card-form-content mt-2">
                                <div class="row pl-3">
                                    <div class="col-md-6">
                                        <p class="sub-heading">{{"Lendisoft Stakeholders"|translate}}</p>
                                        <!-- #1 Analyst/Developer form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Analyst"|translate}}/{{"Developer"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="Developer" maxlength="64" formControlName="lendisoft_stackholder_analyst" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <!-- #2 Email form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Email"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="lendisoft_stackholder_email" maxlength="64" formControlName="lendisoft_stackholder_email" class="form-control form-control-sm" />
                                            </div>
                                            <val-errors controlName="lendisoft_stackholder_email">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}.</ng-template>
                                            </val-errors>
                                            <val-errors controlName="lendisoft_stackholder_email">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 1 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                       
                                        <!-- #3 cc: Stakeholders form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">cc: {{"Stakeholders"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="lendisoft_stackholder_cc" maxlength="256" formControlName="lendisoft_stackholder_cc" class="form-control form-control-sm" />
                                            </div>
                                            <val-errors controlName="lendisoft_stackholder_cc">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}.</ng-template>
                                            </val-errors>
                                            <val-errors controlName="lendisoft_stackholder_cc">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 10 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                       
                                        <!-- #4 Phone form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Phone"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="lendisoft_stackholder_phone" [mask]="US_PHONE_MASKING" maxlength="64" formControlName="lendisoft_stackholder_phone" class="form-control form-control-sm"/>
                                            </div>
                                            <val-errors controlName="lendisoft_stackholder_phone">
                                                <ng-template valError="invalidPhoneNumber"> {{"Phone number is not well formed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="sub-heading">{{"Client Stakeholders"|translate}}</p>
                                        <!-- #1 Analyst/Developer form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Analyst"|translate}}/{{"Developer"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="developer" maxlength="64" formControlName="client_stackholder_analyst" class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <!-- #2 Email form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Email"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="client_stackholder_email" maxlength="64" formControlName="client_stackholder_email" class="form-control form-control-sm" />
                                            </div>
                                            <val-errors controlName="client_stackholder_email">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}.</ng-template>
                                            </val-errors>
                                            <val-errors controlName="client_stackholder_email">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 1 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                        
                                        <!-- #3 cc: Stakeholders form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">cc: {{"Stakeholders"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="client_stackholder_cc" maxlength="256" formControlName="client_stackholder_cc" class="form-control form-control-sm" />
                                            </div>
                                            <val-errors controlName="client_stackholder_cc">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}.</ng-template>
                                            </val-errors>
                                            <val-errors controlName="client_stackholder_cc">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 10 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                        
                                        <!-- #4 Phone form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Phone"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="client_stackholder_phone" maxlength="64" [mask]="US_PHONE_MASKING" formControlName="client_stackholder_phone" class="form-control form-control-sm"/>
                                            </div>
                                            <val-errors controlName="client_stackholder_phone">
                                                <ng-template valError="invalidPhoneNumber">{{"Phone number is not well formed"|translate}}.</ng-template>
                                            </val-errors>
                                            <span class="form-group-label" style="font-size:11px; color:rgb(182, 182, 182)">{{"Note: Use a semi-colon without any spaces to separate email addresses"|translate}}.</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5 pt-3">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="notes" class="form-control form-control-sm" value="" formControlName="note1"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- start table code -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Stakeholders">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_1" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'PROJECT_PLAN')" style="display: none;" id="file_1">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Stakeholders">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions1">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('PROJECT_PLAN');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)" [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                          </table>
                        </div>
                    </div>
                    <!-- Framing the Model start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Notes1" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Framing the Model"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Notes1">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note2"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Notes1">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_2" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'FRAMING_THE_MODEL')" style="display: none;" id="file_2">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Notes1">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions2">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('FRAMING_THE_MODEL');trackBy:trackByIndex " class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                    </div>
                    <!-- Import Data form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Notes2" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Import Data"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Notes2">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note3"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Notes2">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_3" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'IMPORT_DATA')" style="display: none;" id="file_3">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Notes2">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions3">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('IMPORT_DATA');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Define Binary Target form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Notes3" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Define Binary Target"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Notes3">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note4"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Notes3">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_4" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'DEFINE_BINARY_TARGET')" style="display: none;" id="file_4">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Notes3">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions4">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('DEFINE_BINARY_TARGET');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Segmentation form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Segmentation" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Segmentation"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Segmentation">
                                <div class="card-form-content mt-2">
                                    <!-- #1 Notes form control  -->
                                    <div class="row gutters-5">
                                        <div class="form-group col-lg-5 col-xl-4">
                                            <label class="form-group-label">{{"Notes"|translate}}</label>
                                        </div>
                                        <div class="form-group col-lg-7 col-xl-8">
                                            <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note5"></textarea> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Card Collpase end -->
                            <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Segmentation">
                                <div class="btn-file" >
                                    <label class="btn btn-block btn-file-upload btn-duplicate" for="file_5" style="width:100px;">
                                        {{"Upload"|translate}}
                                        <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                    </label>
                                    <input type="file" name="upload_button"  (change)="onFileSelected($event,'SEGMENTATION')" style="display: none;" id="file_5">
                                </div>
                            </div>
                            <div class="table-responsive collapse show" id="Segmentation">
                                <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions5">
                                    <thead class="table-header-purple">
                                        <tr>
                                            <th scope="col" class="nosort text-white"></th>
                                            <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                            <th scope="col" class="nosort text-white"></th>
                                            <th scope="col" class="nosort text-white"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let file of getFilesByBuilderFileType('SEGMENTATION');trackBy:trackByIndex" class="category-listing">
                                            <td class="category-items"></td>
                                            <td class="category-items">{{file?.name}}</td>
                                            <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                            <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                            <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                            <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                                <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                            </td>
                                            <td *ngIf="!file?.id" class="category-items" >
                                                <span >  </span>
                                            </td>
                                           <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                                <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                    <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                            </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Sample Selection form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#SampleSelection" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Sample Selection"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="SampleSelection">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes"  class="form-control form-control-sm" value="" formControlName="note6"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="SampleSelection">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_6" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'DATA_CLEANSING')" style="display: none;" id="file_6">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="SampleSelection">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions6">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('DATA_CLEANSING');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Data Cleansing form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#DataCleansing" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Data Cleansing"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="DataCleansing">
                                <div class="card-form-content mt-2">
                                    <!-- #1 Notes form control  -->
                                    <div class="row gutters-5">
                                        <div class="form-group col-lg-5 col-xl-4">
                                            <label class="form-group-label">{{"Notes"|translate}}</label>
                                        </div>
                                        <div class="form-group col-lg-7 col-xl-8">
                                            <textarea type="text" rows="3" style="resize: none;" name="Notes"  class="form-control form-control-sm" value="" formControlName="note7"></textarea> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Card Collpase end -->
                            <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="DataCleansing">
                                <div class="btn-file" >
                                    <label class="btn btn-block btn-file-upload btn-duplicate" for="file_7" style="width:100px;">
                                        {{"Upload"|translate}}
                                        <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                    </label>
                                    <input type="file" name="upload_button"  (change)="onFileSelected($event,'SAMPLE_SELECTION')" style="display: none;" id="file_7">
                                </div>
                            </div>
                            <div class="table-responsive collapse show" id="DataCleansing">
                                <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions7">
                                    <thead class="table-header-purple">
                                        <tr>
                                            <th scope="col" class="nosort text-white"></th>
                                            <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                            <th scope="col" class="nosort text-white"></th>
                                            <th scope="col" class="nosort text-white"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let file of getFilesByBuilderFileType('SAMPLE_SELECTION');trackBy:trackByIndex" class="category-listing">
                                            <td class="category-items"></td>
                                            <td class="category-items">{{file?.name}}</td>
                                            <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                            <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                            <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                            <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                                <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                            </td>
                                            <td *ngIf="!file?.id" class="category-items" >
                                                <span >  </span>
                                            </td>
                                           <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                                <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                    <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                              </table>
                            </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Binning by WoE form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#BinningbyWoE" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Binning by WoE"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="BinningbyWoE">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note8"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="BinningbyWoE">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_8" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'BINNING_BY_WOE')" style="display: none;" id="file_8">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="BinningbyWoE">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions8">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('BINNING_BY_WOE');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Results Summary form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#ResultsSummary" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Results Summary"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="ResultsSummary">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note9"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="ResultsSummary">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_9" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'RESULT_SUMMARY')" style="display: none;" id="file_9">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="ResultsSummary">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions9">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('RESULT_SUMMARY');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Rejects form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Rejects" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Rejects"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Rejects">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note10"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Rejects">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_10" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'REJECTS')" style="display: none;" id="file_10">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Rejects">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions10">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('REJECTS');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Logistic Regression form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#LogisticRegression" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Logistic Regression"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="LogisticRegression">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note11"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="LogisticRegression">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_11" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'LOGISTIC_REGRESSION')" style="display: none;" id="file_11">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="LogisticRegression">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions11">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('LOGISTIC_REGRESSION');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Final Scorecard form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#FinalScorecard" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Final Scorecard"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="FinalScorecard">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note12"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="FinalScorecard">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_12" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'FINAL_SCORECARD')" style="display: none;" id="file_12">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="FinalScorecard">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions12">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('FINAL_SCORECARD');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Gains Table & KS Statistic form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#GainsTable" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Gains Table & KS Statistic"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="GainsTable">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note13"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="GainsTable">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_13" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'GAINS_TABLE_&_K_S_STATISTIC')" style="display: none;" id="file_13">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="GainsTable">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions13">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('GAINS_TABLE_&_K_S_STATISTIC');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Bad Rate Conversion Table form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#BadRate" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Bad Rate Conversion Table"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="BadRate">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note14"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="BadRate">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_14" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'BAD_RATE_CONVERSION_TABLE')" style="display: none;" id="file_14">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="BadRate">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions14">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('BAD_RATE_CONVERSION_TABLE');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Validations form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Validations" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Validations"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="Validations">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes" class="form-control form-control-sm" value="" formControlName="note15"></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isModelBuilderReadOnly" class="float-right collapse show pt-3"   id="Validations">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_15" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'VALIDATIONS')" style="display: none;" id="file_15">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Validations">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions15">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('VALIDATIONS');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isModelBuilderReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isModelBuilderReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Form Action Button -->
                    <div class="row justify-content-end my-3" *ngIf="isEditMode">
                        <div class="col-auto" *ngIf="!isModelBuilderReadOnly">
                        <button
                            type="button"
                            class="btn btn-block btn-delete"
                            (click)="onDeleteTemplate(selected_template_id)"
                            routerLink="add"
                        >
                            {{"Delete Project"|translate}}
                            <img
                            src="../assets/images/icons/close-icon.png"
                            alt=""
                            aria-hidden="true"
                            />
                        </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!refresh">
                    {{"Loading"|translate}}...
                </div>
                <!-- Form Action Button -->
                <div class="new-action-btn form-action-buttons row justify-content-end mt-3">
                    <div class="col-md-8" >
                        <ng-container *ngIf="isEditMode && refresh">

                        <div  class="row">
                            <div class="form-group col-md-3 mb-0">
                                <label class="form-group-label">{{"Saved Date"|translate}}</label>
                            </div>
                            <div class="form-group col-md-4 mb-0">
                            {{ (updated_at | date: 'short')??'' }}
                            </div>
                        </div>
                        <div  class="row" float-left>
                            <div class="form-group col-md-3">
                                <!--Saved by -->
                                <label class="form-group-label">{{"Saved By"|translate}}</label>
                            </div>
                            <ng-container>
                            <div class="form-group col-md-4">

                                {{(updated_by?.firstname || ('System'|translate)) + " " + (updated_by?.lastname || '') | titlecase }}


                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="form-group col-md-4">

                                </div>
                                </ng-container>
                        </div>
                    </ng-container>
                    </div>
                    <div *ngIf="!isModelBuilderReadOnly && refresh"  class="col-md-4 mB20">
                        <div class="row justify-content-end">
                            <!-- <ng-container *ngIf="this.isSubCatManageByUser || isAddMode"> -->
                            <div class="col-6 col-sm-auto">
                            <button
                                type="reset"
                                (click)="onCancel()"
                                class="btn btn-primary btn-sm btn-full btn-md-auto btn-150 mb-3"
                            >
                                {{"Cancel"|translate}}
                            </button>
                            </div>
                            <div class="col-6 col-sm-auto">
                            <button
                                type="submit"
                                (click)="onFormSubmit()"
                                class="btn btn-primary btn-sm btn-full btn-md-auto btn-150 mb-3"
                            >
                                {{"Save"|translate}}
                            </button>
                            </div>
                        <!-- </ng-container> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <!-- Report Archive Sidebar -->
                <div class="lms-notification-sidebar">
                    <div class="lms-card-header-primary bg-dark-purple text-white text-center py-1" data-toggle="collapse" data-target="#ReportArchive" role="button" aria-expanded="true">
                        {{"Scorecard Templates"|translate}}
                    </div>
                    <div class="collapse show" id="ReportArchive">
                        <div class="lms-notification-sidebar-content">
                            <div class="lms-notification-header border-bottom mb-2 pb-2">
                                <div class="text-right mb-add" >
                                    <button *ngIf="isEditMode && selected_template_id!='' && selected_template_id!=null && !isModelBuilderReadOnly" class="btn btn-duplicate" (click)="onDuplicateTemplate(duplicateModal)">
                                        {{"Duplicate"|translate}}
                                        <img src="../assets/images/icons/duplicate-icon.png" alt="" aria-hidden="true" />
                                    </button>
                                    <!-- Add New Control  -->
                                    <button *ngIf="!isModelBuilderReadOnly" class="btn btn-add-new btn-add-new-success mt-0 ml-2" type="button" routerLink="add" (click)="addTemplate()">
                                        {{"Add New"|translate}}
                                        <img src="../assets/images/icons/plus.png" alt="" aria-hidden="true" />
                                    </button>
                                </div>
                                <!-- Search Start -->
                                <div class="search">
                                    <!-- <input type="text" class="form-control search-control" placeholder="Search"> -->
                                    <app-ls-search-input (onSearch)="onListSearch($event)"></app-ls-search-input>

                                </div>
                                <!-- Search End -->
                                <nav class="nav client-nav border-bottom border-0">
                                    <button type="button" class="btn nav-item active">
                                        <span >{{"All"|translate}}</span>
                                    </button>
                                   <!-- <button type="button" class="btn nav-item active">
                                       <span>Active</span>
                                    </button>
                                    <button type="button" class="btn nav-item">
                                        <span >Inactive</span>
                                    </button> -->
                                </nav>
                            </div>
                            <!-- Notification Body -->
                            <div class="lms-notification-body pb-3">
                                <div *ngIf="!isModelBuilderReadOnly" class="text-right px-3 py-2">
                                    <a  routerLink="add"  (click)="onAddCategory(addCategoryModal)" class="font-size-14 lms-text-dark text-underline">{{'Add Category'|translate}}</a>
                                </div>
                                <!-- Show Category Nested Listing -->
                                <ngb-accordion #accordion="ngbAccordion" class="category-listing  level-1" [closeOthers]="false" 
                                [activeIds]="activeCategory">
                                    <ng-container  *ngFor="let item of category_list;let firstIndex = first; let lastIndex = last;trackBy:trackByIndex">
                                        <ng-container>
                                            <ngb-panel [id]="'cat' + item?.id">
                                                <ng-template ngbPanelHeader>
                                                    <button ngbPanelToggle class="btn btn-link">{{ item?.name }}</button>
                                                    <div class="category-actions">
                                                        <ng-container *ngIf="item?.is_default=='NO'">
                                                            <button *ngIf="!isModelBuilderReadOnly" type="button" class="btn-icon btn-edit" 
                                                                (click)="onEditCategory(item?.id,item?.name, addCategoryModal)">
                                                                <img src="../assets/images/icons/edit-icon.png" alt="" aria-hidden="true"/>
                                                            </button>
                                                            <button *ngIf="!isModelBuilderReadOnly" type="button" class="btn-icon btn-remove"
                                                                (click)="onDeleteCategory(item?.id)">
                                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                                            </button>
                                                        </ng-container>    
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="category-items">
                                                        <ng-container *ngFor="let template of template_list;trackBy:trackByIndex">
                                                            <div *ngIf="template?.category?.id==item?.id" class="position-relative">
                                                                <div class="category-sort-icon">
                                                                    <button class="btn btn-icon btn-category-sort-up"
                                                                    *ngIf="!firstIndex && selected_template_id==template?.id && selected_template?.managed_by!='SYSTEM' && !isModelBuilderReadOnly"
                                                                    (click)="moveToCategory('up',item,template)">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8"
                                                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M5.12899 0.0024264C5.31242 0.107045 5.47545 0.264544 5.60592 0.463177L9.74978 5.8898C9.84894 5.99595 9.92189 6.13814 9.95945 6.29848C9.99702 6.45881 9.99752 6.63012 9.96089 6.79082C9.92587 6.94578 9.85534 7.0829 9.75885 7.18363C9.66235 7.28435 9.54456 7.34381 9.4214 7.35396H0.727109C0.575944 7.3834 0.421694 7.34203 0.291537 7.23714C0.161381 7.13225 0.0636678 6.97057 0.0156154 6.78058C-0.0166298 6.66054 -0.0249363 6.5317 -0.00851729 6.40627C0.00790173 6.28085 0.0485024 6.16299 0.109439 6.06386L0.250174 5.86932L4.38622 0.452938C4.51669 0.254305 4.67972 0.0968062 4.86315 -0.0078125L5.12899 0.0024264Z"
                                                                                fill="#938F8F" />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                    *ngIf="!lastIndex && selected_template_id==template?.id && selected_template?.managed_by!='SYSTEM' && !isModelBuilderReadOnly"
                                                                    (click)="moveToCategory('down',item,template)" class="btn btn-icon btn-category-sort-down">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8"
                                                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M4.9961 0.689025H9.29633C9.43925 0.671938 9.58242 0.718143 9.70291 0.820243C9.8234 0.922343 9.91425 1.07443 9.96091 1.25217C9.99459 1.37362 10.0037 1.50444 9.98721 1.63185C9.97077 1.75926 9.92938 1.87891 9.86709 1.97913C9.82697 2.04867 9.78251 2.11374 9.73417 2.17367L5.48867 7.65148C5.43011 7.75926 5.35272 7.84714 5.26296 7.90777C5.17319 7.96841 5.07365 8.00004 4.97264 8.00004C4.87164 8.00004 4.77209 7.96841 4.68233 7.90777C4.59256 7.84714 4.51517 7.75926 4.45661 7.65148L0.242383 2.10199C0.130716 1.98044 0.0548225 1.8118 0.027718 1.62498C0.000613496 1.43816 0.0239867 1.24482 0.0938291 1.0781C0.144619 0.964575 0.216299 0.869497 0.302334 0.801538C0.38837 0.733579 0.486027 0.694899 0.586402 0.689025H4.9961Z"
                                                                                fill="#938F8F" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <a [ngClass]="{
                                                                    activetemplate: selected_template_id == template?.id}" routerLink="edit/{{ template?.id }}" (click)="editTemplate(item?.id,template?.id,'selectTemplate')"> <span>{{ template?.id }}</span>{{ template?.name }}</a>
                                                            </div>
                                                        </ng-container>

                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ng-container>
                                    </ng-container>
                                </ngb-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Add category Modal Popup -->
  <ng-template #addCategoryModal let-modal>
    <form  [formGroup]="categoryForm" novalidate>
        <div class="modal-header">
            <h6 class="modal-title" id="modal-basic-title">{{"Category"|translate}}</h6>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body py-0">
            <div class="row">
                <div class="form-group col-12">
                    <label class="form-group-label">{{"Name"|translate}}</label>
                    <input type="text" name="name" maxlength="64" formControlName="name" class="form-control form-control-sm"
                        value="" />
                    <val-errors controlName="name">
                        <ng-template valError="required">{{"Name is required"|translate}}.</ng-template>
                    </val-errors>
                </div>
            </div>
        </div>
        <div class="modal-footer flex-nowrap">
            <button type="reset" (click)="onCancelCategory()"  class="btn btn-sm btn-secondary btn-block my-0">
                {{"Cancel"|translate}}
            </button>
            <button type="submit" (click)="onCategorySubmit()" class="btn btn-sm btn-primary btn-block my-0">
                {{"Save"|translate}}
            </button>
        </div>
    </form>
  </ng-template>
   <!-- End -->

      <!-- Duplicate  Modal Popup -->
<ng-template #duplicateModal let-modal>
    <form
      
      [formGroup]="duplicateForm"
      
      novalidate
    >
      <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{"DUPLICATE TEMPLATE"|translate}}</h6>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body py-0">
        <div class="row">
          <div class="form-group col-12">
            <label class="form-group-label">{{"New Name"|translate}}</label>
          </div>
          <div class="form-group col-12">
            <input
              type="text"
              name="name"
              maxlength="64"
              formControlName="name"
              class="form-control form-control-sm"
              value=""
            />
            <val-errors controlName="name">
              <ng-template valError="required">{{"Name is required"|translate}}.</ng-template>
            </val-errors>
          </div>
        </div>
      </div>
      <div class="modal-footer flex-nowrap">
        <button type="reset" (click)="modal.dismiss('Cross click')" class="btn btn-sm btn-secondary btn-block my-0">
          {{"Cancel"|translate}}
        </button>
        <button type="submit" (click)="onDuplicateSubmit()" class="btn btn-sm btn-primary btn-block my-0">
          {{"Save"|translate}}
        </button>
      </div>
    </form>
</ng-template>
<!-- End -->
