export enum UserType {
  USER = '1',
  ADMIN = '2'
}

export enum UserStatusType {
  ACTIVE = '1',
  INACTIVE = '2',
  TERMINATED = '3'
}

export enum PageID {
  LS_EDIT_CLIENT = 'LS_EDIT_CLIENT',
  LS_EDIT_USER = 'LS_EDIT_USER',
  LS_EDIT_ROLE = 'LS_EDIT_ROLE',
  LS_EDIT_NOTIFICATION = 'LS_EDIT_NOTIFICATION',
  LS_EDIT_FILES = 'LS_EDIT_FILES',
  LS_EDIT_DATA_WAREHOUSE = 'LS_DATA_WAREHOUSE',
  LS_EDIT_REPORTS = 'LS_EDIT_REPORTS',
  LS_EDIT_ASSIGNMENT = 'LS_EDIT_ASSIGNMENT',
  LS_EDIT_CAMPAIGN_BUILDER = 'LS_EDIT_CAMPAIGN_BUILDER',
  LS_EDIT_QUERIES = 'LS_EDIT_QUERIES',
  LS_ADD_QUERIES = 'LS_ADD_QUERIES',

  LS_EDIT_INPUT_SCORECARD = 'LS_EDIT_INPUT_SCORECARD',
  LS_EDIT_CHAMPION_CHALLENGER = 'LS_EDIT_CHAMPION_CHALLENGER',
  LS_EDIT_MODEL_BUILDER = 'LS_EDIT_MODEL_BUILDER',
  LS_ADD_MODEL_BUILDER = 'LS_ADD_MODEL_BUILDER',
  LS_EDIT_DATA_MINER = 'LS_EDIT_DATA_MINER',
  LS_ADD_DATA_MINER = 'LS_ADD_DATA_MINER',
  LS_EDIT_VALUATIONS = 'LS_EDIT_VALUATIONS',
  LS_ADD_VALUATIONS = 'LS_ADD_VALUATIONS',

}

export enum MODULES {
  CONFIGURATIONS = 'configurations',
  USER = 'users',
  USER_MAINTENANCE = 'user_maintenance',
  NOTIFICATIONS = 'notifications',
  ROLES = 'roles',
  LENDISOFT_CONFIGURATIONS= 'lendisoft_configurations',
  LENDISOFT_CLIENTS = 'lendisoft_clients',
  LENDISOFT_CLIENTS_MAINTENANCE = 'lendisoft_maintenance',
  DASHBOARD = 'dashboard',
  SCORECARD_BUILDER ='input_scorecard_builder',
  NBAR_BUILDER ='nbar_builder',
  PBAR_BUILDER ='pbar_builder',
  ODDS_OF_ROLL_BUILDER ='odds_of_roll_builder',
  ASSIGNMENTS ='assignments',
  STANDARD_SETTINGS ='standard_settings',
  VENDOR_CONFIGURATION ='vendor_configurations',
  CAMPAING_BUILDER ='campaign_builder',
  ACTIVE = 'active',
  DEFICIENCY = 'deficiency',
  PROCESSING = 'processing',
  DATA_IMPORT = 'imports',
  DATA_IMPORTS_MAPPING_TOOL = 'mappingtool',
  DATA_IMPORTS_IMPORT_TOOL = 'importtool',
  PORTFOLIO_MAINTENANCE = 'portfolio_maintenance',
  FEE_SETTINGS = 'fee_settings',
  SSN = 'ssn_permissions',
  QUEUES_ASSIGNED = 'assigned',
  QUEUES_ROUTED_TO_YOU = 'routed_to_you',
  AT_RISK_RECOVERY ='queues_at_risk_of_recovery',
  REPORTS_QUERIES = 'queries',
  REPORTS_REPORTS = 'reports_reports',
  REPORTS_CHAMPION_CHALLENGER = 'champion_challenger',
  FILES='files',
  DATA_WAREHOUSE = 'data_warehouse',
  MODEL_BUILDER = 'model_builder',
  DATA_MINER = 'data_miner',
  VALUATIONS = 'valuations',
  COMPANY_ID = 'can_edit_company_id',
  ADD_EDIT_LOAN = 'add-edit-loan-information'
}


export enum SSN_PERM_TYPE {
  EDIT = 'EDIT',
  VIEW_LAST_4_ONLY = 'VIEW_LAST_4_ONLY',
  READ_ONLY = 'READ_ONLY',
}

export enum COMPANY_ID_PERM_TYPE {
  EDIT = 'YES',
  READ_ONLY = 'NO',
}

export enum MAPPING_DATA_TYPES {
  TEXT = '',
  DATE = 'date',
  TIME = 'time',
  STRING = 'string',
  PHONENO='phone_number',
  NUMBER = 'number',
  FLOAT = 'float',
  INTEGER = 'integer',
  CATEGORIAL = 'CATEGORIAL',
  SSN = "ssn",
  ZIPCODE ="zipcode",
  IDSTRING = "id_string"
}


export enum RULE_COMP_TYPES {
  LIST = 'LIST',
  NLIST = 'NLIST'
}
