import { AbstractControl } from '@angular/forms';

/**
US Phone Validation
--------------------
Valid formats:

(123) 456-7890
(123)456-7890
123-456-7890
123.456.7890
1234567890
+31636363634
075-63546725
 */
export function ValidateUSPhone(control: AbstractControl) {
  const regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (!regExp.test(control.value) && control.value) {
    return { invalidPhoneNumber: true };
  }
  return null;
}