<div class="modal-header pb-0">
  <h6 class="modal-title font-size-20">{{confirmationBoxTitle |translate}}</h6>
  <button *ngIf="showCloseButton" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="showConfirmationNoButton" class="font-size-16" [innerHTML]="confirmationMessage">{{confirmationMessage|translate}}</div>
  <div *ngIf="!showConfirmationNoButton" class="font-size-16 word-break" >{{confirmationMessage|translate}}</div>
  <div *ngIf="confirmationMessage2" class="font-size-16 word-break mt-3" >
    
    <small>
      {{confirmationMessage2|translate}}
    </small>
  </div>
</div>
<div class="modal-footer flex-nowrap confirm-box-btn">
    <button *ngIf="showConfirmationYesButton && !swapConfirmationButton" type="button" class="btn btn-sm btn-primary btn-block" (click)="activeModal.close(true)">
        {{confirmationYesButton||('Yes'|translate)}}  
    </button>
    <button *ngIf="showConfirmationNoButton" type="button" class="btn btn-sm btn-secondary btn-block my-0" (click)="activeModal.close(false)">
        {{confirmationNoButton||('No'|translate)}}
    </button>
    <button *ngIf="showConfirmationYesButton && swapConfirmationButton" type="button" class="btn btn-sm btn-primary btn-block my-0" (click)="activeModal.close(true)">
      {{confirmationYesButton||('Yes'|translate)}}  
    </button>
    
</div>
