import { FormGroup } from '@angular/forms';

export function DueDateValidator(due_date: string, first_due_date: string,maturity_date: string='maturity_date'){
    return (formGroup: FormGroup) => {
        const firstDueDateFormControl = formGroup.controls[first_due_date];
        const dueDateFormControl = formGroup.controls[due_date];
        // const maturityDateFormControl = formGroup.controls[maturity_date];

        let dueDate = dueDateFormControl?.value;
        let firstDueDate = firstDueDateFormControl?.value;
        // let maturityDate = maturityDateFormControl?.value;

        if(dueDate) {
        
            if (firstDueDate && firstDueDate > dueDate) {

                dueDateFormControl.setErrors({invalid_first_due_date_before_due_date: true });

                dueDateFormControl.markAsTouched();

            }
            // As per client request on 26-01-25 we are removing maturity date validation from side notes due date

            // else if (maturityDate && maturityDate <= dueDate ) {

            //     dueDateFormControl.setErrors({invalid_maturity_date_before_due_date: true });

            //     dueDateFormControl.markAsTouched();

            // }

            else
                dueDateFormControl.setErrors(null);
        }
        else {
            dueDateFormControl.setErrors({required:true});
        }
    }
}