
<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<form #f="ngForm" [formGroup]="form" novalidate>
    <div class="modal-header align-items-center " >
        <h6 class="modal-title modal-title-success">{{'Due Date Change'|translate}}</h6>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="lms-module-card">
            <div class="card-body">
                <div class="card-form-content card-form-success">
                    <div class="bg-note-info p-2 mb-3">
                        {{'Select a new due date for this loan'|translate}}.

                    </div>


                    <div class="row gutters-5 mb-3">
                        <div class="form-group col-lg-5">
                          <label class="form-group-label">{{'New Due Date'|translate}} </label>
                        </div>
                        <div class="form-group col-lg-5">

                          <app-date-picker
                          #curdate
                          name="current_due_date"
                           formControlName="current_due_date"
                           (dateSelected)="getDif(curdate.value)"
                           ></app-date-picker>
                           <val-errors controlName="current_due_date">
                            <ng-template valError="required">{{'New due date is required'|translate}}</ng-template>
                          </val-errors>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm table-no-bg table-align-middle mb-0">
                            <thead>
                                <tr>
                                <td class="border-0">{{'Field Name'|translate}}</td>
                                <td class="border-0">{{'Before'|translate}}</td>
                                <td class="border-0"></td>
                                <td class="border-0">{{'After'|translate}}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>{{'Due Date'|translate}}:</td>
                                <td>{{ current_due_date | date: DATE_FORMAT_MMDDYYYY }}</td>
                                <td>
                                    <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                    <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                </td>
                                <td class="lms-text-light-purple">{{ this.new_due_date | date: DATE_FORMAT_MMDDYYYY }}</td>
                                </tr>

                                <!-- <tr>
                                  <td>Billing Date:</td>
                                  <td>{{ this.loanDetail.billing_date | date: DATE_FORMAT_MMDDYYYY }}</td>
                                  <td>
                                    <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                    <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                  </td>
                                  <td class="lms-text-light-purple">{{ this.nextmonthdate | date: DATE_FORMAT_MMDDYYYY }}</td>
                                  </tr> -->

                                  <tr>
                                    <td>{{'Amount Due'|translate}}:</td>
                                    <td>{{ this.loanDetail.current_amount_due | currency }}</td>
                                    <td>
                                        <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                                        <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                                    </td>
                                    <td class="lms-text-light-purple">{{ this.loanDetail.current_amount_due | currency }}</td>
                                    </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer justify-content-between">
        <div>
            <!-- <span class="d-block font-size-14 lms-text-light-pink">
                You don’t have the permission to change due date.
            </span> -->

            <span class="d-block font-size-14 lms-text-light-pink" *ngIf="this.is_new_due_date_past_date">
            {{'New due date cannot be in the past'|translate}}.
          </span>
            <span class="d-block font-size-14 lms-text-light-pink" *ngIf="this.is_new_due_date_invalid">
                {{'New due date cannot be the same or a date prior to due date'|translate}}.
            </span>


          <span class="d-block font-size-14 lms-text-light-pink" *ngIf="this.is_new_due_date_grater_29days">
           {{'New due date cannot be greater than 29 days from current due date'|translate}}.
        </span>
        <span *ngIf="loanDetail?.any_active_side_note_exist" class="d-block font-size-14 lms-text-light-pink">
            Loan modification is not allowed since there are side note(s) or CPI associated with this loan.
         </span>
        </div>
        <div >
            <button type="button" (click)="onFormSubmit()" [ngClass]="{'btn-success-light': this.is_new_due_date_invalid == false && this.is_new_due_date_past_date==false && this.is_new_due_date_grater_29days==false && this.is_new_due_date_not_selected==false,'btn-secondary': this.is_new_due_date_invalid == true || this.is_new_due_date_past_date==true || this.is_new_due_date_grater_29days==true || this.is_new_due_date_not_selected==true || loanDetail.any_active_side_note_exist == true}" [disabled]="this.is_new_due_date_invalid || this.is_new_due_date_past_date || this.is_new_due_date_grater_29days==true || this.is_new_due_date_not_selected==true || loanDetail.any_active_side_note_exist" class="btn  btn-150">
                {{'Submit'|translate}}
            </button>
            <!-- <button type="button" class="btn btn-pink btn-150">
                Route
            </button>
            <button type="button" class="btn btn-pink btn-150">
                Get Approval
            </button> -->
        </div>
    </div>
</form>
</div>
</div>
