<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<ng-container>
    <div class="modal-header align-items-center " >{{'Submit Repo Review'|translate}}
        <h6 class="modal-title modal-title-success"></h6>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="lms-module-card">
          <div class="card-body">
            <div class="card-form-content card-form-yellow">
              <div class="bg-note-info p-2 mb-3">
                {{'Please confirm before submitting to Repo Review'|translate}}.
              </div>
              <div class="table-responsive">
                <table class="table table-sm table-no-bg table-align-middle mb-0">
                  <thead>
                    <tr>
                      <td class="border-0">{{'Field Name'|translate}}</td>
                      <td class="border-0">{{'Before'|translate}}</td>
                      <td class="border-0"></td>
                      <td class="border-0">{{'After'|translate}}</td>
                      <!-- <td class="border-0">Before</td>
                      <td *ngIf="isDataRecived" class="border-0"></td>
                      <td class="border-0" *ngIf="isDataRecived">After</td> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{'Sub-Status'|translate}}</td>
                      <td>
                        <!-- {{  (loanDetail?.sub_status_label!=null && loanDetail?.sub_status_label
                        !="")?  conver_To_Camel_Case(loanDetail?.sub_status_label
                        .replace('_',' ')) : '-'}} -->
                        {{submitRepoReviewPreviewData?.before?.sub_status|| '-'}}
                      </td>
                      <td>
                        <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                        <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                      </td>
                      <td class="lms-text-light-purple">{{submitRepoReviewPreviewData?.after?.sub_status|| '-'}}</td>
                      </tr>
                     <tr>
                          <td>{{'Assigned To'|translate}}</td>
                          <td>
                            <!-- {{loanDetail?.assignee?.employee_initials}}-{{loanDetail?.assignee?.firstname}} {{loanDetail?.assignee?.lastname}} -->
                            {{submitRepoReviewPreviewData?.before?.user || '-'}}
                          </td>
                          <td>
                            <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                            <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                          </td>
                          <td class="lms-text-light-purple">
                            <!-- {{loanDetail?.assignee?.employee_initials}}-{{loanDetail?.assignee?.firstname}} {{loanDetail?.assignee?.lastname}} -->
                          {{submitRepoReviewPreviewData?.after?.user || '-'}}
                          </td>
                      </tr>

                      <tr>
                        <td>{{'Last Repo Review Date'|translate}}</td>
                        <td>
                          <!-- {{ convertLocalTimeZone(loanDetail?.latest_repo_review?.after?.last_repo_review_date) }} -->
                          {{convertLocalTimeZoneWithoutTime(submitRepoReviewPreviewData?.before?.last_repo_review_date) || '-'}}
                        </td>
                        <td>
                          <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                          <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                        </td>
                        <td class="lms-text-light-purple">
                          <!-- {{  convertLocalTimeZoneWithoutTime(curDate) }} -->
                          {{convertLocalTimeZoneWithoutTime(submitRepoReviewPreviewData?.after?.last_repo_review_date) || '-'}}
                        </td>
                      </tr>



                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
          <span *ngIf="!this.isRePermission" class="d-block font-size-14 lms-text-light-pink">{{"You don't have the permission to submit repo review"|translate}}.</span>
        </div>
        <div>
          <button *ngIf="this.isRePermission" type="button"   class="btn btn-success-light btn-150" (click)="submitRepoReview()">
            {{'Submit'|translate}}
          </button>

          <button *ngIf="!this.isRePermission"  type="button" class="btn btn-pink btn-150" (click)="openGetApprovalModal(GetApprovalModal)">
            {{'Get Approval'|translate}}
          </button>
        </div>
      </div>
</ng-container>
</div>
</div>



<!-------------Modal----------------->
  <!-- Override Modal -->
  <ng-template #GetApprovalModal let-modal>
    <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
      <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
    <div class="modal-header " >
      <h6 class="modal-title text-center mb-3">{{'Get Approval'|translate}}</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form #f="ngForm" [formGroup]="appform" (ngSubmit)="verifyPin()" novalidate>
    <div class="modal-body pb-0 px-0">


      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form form-vertical">
            <div class="form-group">
              <label class="form-group-label">{{'Initials'|translate}}</label>
              <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
              <val-errors controlName="employee_initials">
                <ng-template valError="required">{{'Initial is required'|translate}}</ng-template>
              </val-errors>
            </div>
            <div class="form-group">
              <label class="form-group-label">{{'PIN'|translate}}</label>
              <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
              <val-errors controlName="pin">
                <ng-template valError="required">{{'PIN is required'|translate}}</ng-template>
              </val-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="this.appform.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
        {{'Get Approval'|translate}}
      </button>
    </div>
  </form>
  </div>
  </div>
  </ng-template>
