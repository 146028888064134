import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { MessageService } from 'src/app/core';

@Component({
  selector: 'app-upload-file-parser',
  templateUrl: './upload-file-parser.component.html',
  styleUrls: ['./upload-file-parser.component.scss']
})
export class LSUploadFileParserComponent implements OnInit {
  @ViewChild('fileInput') fileInput!:ElementRef;
  @Input() fileName:string = '';
  @Input() acceptFiles:string = '';
  @Input() acceptFileSizeInMB:string = '500';
  @Input() isEditPermission:boolean = true;
  @Output() fileSelected:EventEmitter<any> = new EventEmitter();
  @Output() fileReset:EventEmitter<any> = new EventEmitter();
  uniqueID:string  = _.uniqueId('file_csv_upload_');
  constructor(private msgService:MessageService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }
  ngOnChanges(changes:SimpleChanges):void{
    const name = changes['fileName'];
    if(name &&  (name.currentValue!= name.previousValue)){
      this.fileName = name.currentValue || '' ;
    }

    const isEdit = changes['isEditPermission'];
    if(isEdit &&  (isEdit.currentValue!= isEdit.previousValue)){
      this.isEditPermission = isEdit.currentValue;
    }

  }

  ngOnInit(): void {
   
}

  onUploadBtnClick(){
    this.fileInput.nativeElement.click();
  }
  // on file upload
  onFileChange(event:any) {
    if(event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.fileName = file.name || '';
      let extension = file?.name?.substr(file?.name?.lastIndexOf('.')+1)
      // let extension = this.fileName.split('.')[1];
      const size = file.size / 1024 / 1024 ;
      if(!this.acceptFiles.split(',').includes(extension.toLowerCase())){
        this.fileName = '';
        this.fileInput.nativeElement.value = '';
        this.msgService.showError(`${this.acceptFiles} extension only allowed`);
      }else if((size > Number(this.acceptFileSizeInMB))){
        this.fileName = '';
        this.fileInput.nativeElement.value = '';
        this.msgService.showError(`Up to ${this.acceptFileSizeInMB} MB file size allowed`);
      } else {
        var reader = new FileReader();
        const thisObj = this;
        reader.onloadend = function (e) {
          if(e  && e.target){
            let csvdata = e.target.result; 
            let parsedata = [];
            if(csvdata != null && csvdata != undefined && typeof (csvdata)== "string"  ) {
              // let newLinebrk = csvdata.split("\n");
              // let validDataFile = true;
              // for(let i = 0; i < newLinebrk.length; i++) {
              //     if(newLinebrk[i].split(",").length>=2){
              //       thisObj.msgService.showError(`File should be in correct format`);
              //       validDataFile = false;
              //       break;
              //     }
              // }
              // if(validDataFile == true) {
              //   thisObj.fileSelected.emit(file);
              // }
              thisObj.fileSelected.emit(file);
              
            }
          }  
        };

        let bytes = 50000;
        let blob = file.slice(0, bytes);
        reader.readAsBinaryString(file);
        this.fileName = '';
        this.fileInput.nativeElement.value = '';
      }
    }
  }
  // on file reset
  onFileReset() {
    this.fileReset.emit(true);
    this.fileName = '';
    this.fileInput.nativeElement.value = '';
  }
}
