import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ssnFormat'
})
export class SsnFormatPipe implements PipeTransform {
    transform(value: any, digit?: any): any {
        value = value.toString();
        if (value) {
            let newVal = value.replace(/^\d{1,5}/,(m:any) => m.replace(/\d/g, '*'));
            // if (value.length === 9) {
                value = newVal.substring(0, 3) + "-" + newVal.substring(3, 5) + "-" + newVal.substring(5, 9);
            // }
        }
        return value;
    }
}
