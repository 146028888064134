import { Pipe, PipeTransform } from '@angular/core';
import { extractDeepPropertyByMapKey, isFunction } from './helpers';
/*

Returns object of grouped by items by discriminator, and supports nested properties.

Usage: array | groupBy: [string[] | string | Function]: [delimiter: string | optional, default = '|']

this.arrayObject = [
  {id: 1, elm: 'foo', value: 0}, 
  {id: 2, elm: 'bar', value: 1}, 
  {id: 3, elm: 'foo', value: 2}, 
  {id: 4, elm: 'foo', value: 2}
];
 
this.arrayNestedObject = [
  {id: 1, prop: { deep: 'foo' }},
  {id: 2, prop: { deep: 'bar' }},
  {id: 3, prop: { deep: 'foo' }},
  {id: 4, prop: { deep: 'bar' }}
];
<p>{{ arrayObject | groupBy: 'elm' }}</p> 
<!-- Output: "{foo: [{id: 1, elm: 'foo', value: 0}, {id: 3, elm: 'foo', value: 2}, {id: 4, elm: 'foo', value: 2}], bar: [{id: 2, elm: 'bar', value: 1}]}" -->
 
<p>{{ arrayObject | groupBy: ['elm', 'value'] }}</p> 
<!-- Output: "{'foo|0': [{elm: foo, value: 0}], 'bar|1': [{elm:bar,value: 1}], 'foo|2': [{elm:foo, value: 2}], 'bar|3': [{elm:bar, value: 3}]}" -->
 
<p>{{ arrayObject | groupBy: ['elm', 'value']: '_' }}</p> 
<!-- Output: "{foo_0: [{elm: foo, value: 0}], bar_1: [{elm:bar,value: 1}], foo_2: [{elm:foo, value: 2}], bar_3: [{elm:bar, value: 3}]}" -->
 
<p>{{ arrayNestedObject | groupBy: 'prop.deep' }}</p> 
<!-- Output:{foo: [{id: 1, prop: {deep: foo}}, {id: 3, prop: {deep: foo}}], bar: [{id: 2, prop: {deep: bar}}, {id: 4, prop: {deep: bar}}]}" -->
*/

@Pipe({ name: 'groupBy' })
export class GroupByPipe implements PipeTransform {
  transform(input: any, discriminator: any = [], delimiter: string = '|'): any {
    if (!Array.isArray(input)) {
      return input;
    }

    return this.groupBy(input, discriminator, delimiter);
  }

  private groupBy(list: any[], discriminator: any, delimiter: string) {
    return list.reduce((acc: any, payload: string) => {
      const key = this.extractKeyByDiscriminator(discriminator, payload, delimiter);

      acc[key] = Array.isArray(acc[key]) ? acc[key].concat([payload]) : [payload];

      return acc;
    }, {});
  }

  private extractKeyByDiscriminator(discriminator: any, payload: string, delimiter: string) {
    if (isFunction(discriminator)) {
      return (<Function>discriminator)(payload);
    }

    if (Array.isArray(discriminator)) {
      return discriminator.map(k => extractDeepPropertyByMapKey(payload, k)).join(delimiter);
    }

    return extractDeepPropertyByMapKey(payload, <string>discriminator);
  }
}