import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { GlobalErrorHandler } from '../services/error.handler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientDetailService } from '../services/client-detail-service';
import { AuthService } from '../services/auth-service';
import { MsgConstantConfig } from '../config/message.constant.config';
import { BroadcastService, MessageService } from '..';

@Injectable()
export class HttpInterceptorClass implements HttpInterceptor {
  isChangeUserPasswordErrorDisplayed:boolean = false;
  disableApiCalls:boolean = false;


  constructor(
    private globalHandler: GlobalErrorHandler ,
    private clientDetailService:ClientDetailService,
    private authService:AuthService,
    private broadCastService:BroadcastService,
    private msgService: MessageService,
    private router:Router ,
    private route:ActivatedRoute) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if(!this.disableApiCalls){

      return next.handle(req).pipe(
        tap(
        (response) => {

          if (response instanceof HttpResponse) {

            this.disableApiCalls == false;

          }
        },
        (error) => {

          const slug = this.clientDetailService.CLIENT_ID;
          const isAuthenticated = this.authService.isAuthenticated();
          const status = error?.status || '';
          console.log(status)
          const url = !isAuthenticated ? `/${slug}` : `/${slug}/home`;
          switch(status){
            case 404:
              window.location.href =  `${url}/error-404?fp=${(isAuthenticated ? 'H' : 'F')}`;
              this.disableApiCalls == false;
            break;
            case 403:
              window.location.href = `${url}/error-403?fp=${(isAuthenticated ? 'H' : 'F')}`;
              this.disableApiCalls == false;
            break;
            case 401:
              if(error?.error?.error==="password_expired") {
                if(error?.error?.message && this.isChangeUserPasswordErrorDisplayed == false) {
                  this.isChangeUserPasswordErrorDisplayed = true;
                  this.msgService.showError(error?.error?.message);
                }

              
                this.broadCastService.broadcast(MsgConstantConfig.CHANGE_USER_PASSWORD);
              }

              else if(this.authService.isAuthenticated()) {
                this.broadCastService.broadcast(MsgConstantConfig.AUTH_TOKEN_EXPIRED);
              }
              else { }
              this.disableApiCalls == false;

              break;

            // case 400 :
            //   this.disableApiCalls = true;
            //   this.broadCastService.broadcast(MsgConstantConfig.SERVER_ERROR,'Error Code 400 - Bad request.');
            //   break;

            // case 500 :
            //   this.disableApiCalls = true;
            //   this.broadCastService.broadcast(MsgConstantConfig.SERVER_ERROR,'Error Code 500 - Database error');
            //   break;

            case 503 :
              // window.location.href = `${url}/error-503?fp=${(isAuthenticated ? 'H' : 'F')}`;

              this.disableApiCalls = true;
              this.broadCastService.broadcast(MsgConstantConfig.SERVER_ERROR,'Error Code 503 - Server is currently unable to handle the incoming requests.');
              break;
            
            case 504 :
              // window.location.href = `${url}/error-503?fp=${(isAuthenticated ? 'H' : 'F')}`;

              this.disableApiCalls = true;
              this.broadCastService.broadcast(MsgConstantConfig.SERVER_ERROR,'Error Code 504 - Server timed out.');
              break;
              
          }

          // verify for expired token
          if(this.authService.isAuthenticated() && this.authService.isTokenExpired()){
            this.broadCastService.broadcast(MsgConstantConfig.AUTH_TOKEN_EXPIRED);
          }
          //global error handler
          this.globalHandler.logError(error);
          return throwError(error);
        }
        ),
        catchError(
          (error: HttpErrorResponse): Observable<any> => {
            // const slug = this.clientDetailService.CLIENT_ID;
            // const isAuthenticated = this.authService.isAuthenticated();
            const status = error?.status || '';
            console.log(status)
            // const url = !isAuthenticated ? `/${slug}` : `/${slug}/home`;
              // we expect 404, it's not a failure for us.
              // if (error.status === 404) {
              //     return of(null); // or any other stream like of('') etc.
              // }
              // if(this.disableApiCalls == false) {
              if(this.disableApiCalls == false && error.status != 404 && error.status != 403 && error.status != 401 && error.status != 400 && error.status != 500 && error.status != 422 ) {
                // window.location.href = `${url}/error-503?fp=${(isAuthenticated ? 'H' : 'F')}`;
                this.broadCastService.broadcast(MsgConstantConfig.SERVER_ERROR,'Service not available.');
                this.disableApiCalls = true;
              }
              
              // other errors we don't know how to handle and throw them further.
              return throwError(error);
          },
      ));
    // }
    // else{
    //   return throwError(() => new Error('error'));

    // }
  }
}