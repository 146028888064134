import { AbstractControl } from '@angular/forms';

/**
US Fax Validation
--------------------
Valid formats:

(xxx) xxx-xxxx

 */
export function ValidateUSFax(control: AbstractControl) {
  const regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (!regExp.test(control.value) && control.value) {
    return { invalidFaxNumber: true };
  }
  return null;
}