import { AbstractControl } from '@angular/forms';

export function noZeroPrefixValidator(control: AbstractControl) {
  const value: string | null = control.value as string;

  if (value && typeof value === 'string' && value?.startsWith('0')) {
    return { noZeroPrefix: true };
  }

  return null;
}
