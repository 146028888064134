import { Injectable } from '@angular/core';

@Injectable()
export class ClientDetailService {
  clientDetail!:any;
  constructor() { }

  /**
   * set Client Object
   * @param- clientObj
   */
  public setClientInfo(clientObj: any) {
    this.clientDetail = clientObj;
  }


  /**
   * get client info
   * @param- field
   * @returns- {string}
   */
  getClientInfo(field: string = ''):string | any {
    //  client detail
    const data =  this.clientDetail ? this.clientDetail[field] : '';
    return data;
  }


  /**
   * get Favicon
   * @returns- {string}
   */
  public get FAVICON(): string {
    const data = this.clientDetail ? this.clientDetail['favicon_temp_url'] : '';
    return data ? data : '';
  }

  /**
   * get Logo Light
   * @returns- {string}
   */
   public get LOGO_LIGHT(): string {
    const data = this.clientDetail ? this.clientDetail['logo_light_temp_url'] : '';
    return data ? data : '';
  }
  /**
   * get Logo Dark
   * @returns- {string}

   */
   public get LOGO_DARK(): string {
    const data = this.clientDetail ? this.clientDetail['logo_dark_temp_url'] : '';
    return data ? data : '';
  }

  /**
   * get Title
   * @returns- {string}
   */
   public get TITLE(): string {
    const data = this.clientDetail ? this.clientDetail['name'] : '';
    return data ? data : '';
  }

   /**
   * get client ID
   * @returns- {string}
   */
    public get CLIENT_ID(): string {
      const data = this.clientDetail ? this.clientDetail['slug']:'';
      return data ? data : '';
    }

   /**
   * get is default?
   * @returns- {string}
   */
    public get IS_DEFAULT(): string {
      const data = this.clientDetail ? this.clientDetail['is_default'] : '';
      return data;
    }

}
