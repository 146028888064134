import { Injectable } from '@angular/core';
import { UserService } from '..';

@Injectable()
export class PermissionService {
    constructor(private userService: UserService) {}

    isAccess(moduleName: string): boolean {
        let isAllowed = false;
        const permisions: Array<any> = this.userService.PERMISSIONS;

        permisions.forEach((item: string) => {
            const modulePermissionViewEdit = `${moduleName}:view_edit`;
            const modulePermissionViewOnly = `${moduleName}:read_only`;
            if ([modulePermissionViewEdit,modulePermissionViewOnly].includes(item)) {
                isAllowed = true;
            }
        });

        return isAllowed;
    }

    isEdit(moduleName:string):boolean{
        let isAllowed = false;
        const permisions: Array<any> = this.userService.PERMISSIONS;
        permisions.forEach((item: string) => {
            const modulePermission = `${moduleName}:view_edit`
            if (item == modulePermission) {
                isAllowed = true;
            }
        })
        return isAllowed;
    }

    isReadOnly(moduleName:string):boolean{

        let isAllowed = false;
        const permisions: Array<any> = this.userService.PERMISSIONS;
        permisions.forEach((item: string) => {
            const modulePermission = `${moduleName}:read_only`
            if (item == modulePermission) {
                isAllowed = true;
            }
        })
        return isAllowed;
    }

    isSpecialPermission(moduleName:string):boolean{
        

        let isAllowed = false;
        const permisions: Array<any> = this.userService.PERMISSIONS;
        permisions.forEach((item: string) => {
            const modulePermission = `specialpermission:${moduleName}`
            if (item.indexOf(modulePermission) > - 1 && item.indexOf('YES')>-1) {

                isAllowed = true;
            }
            // if (item.indexOf(modulePermission) > - 1 && item.indexOf('NO')>-1)
            // {
            //   isAllowed = false;
            // }

        });
        return isAllowed;
    }

    specialPermissionDetail(moduleName:string):string{
        const permisions: Array<any> = this.userService.PERMISSIONS;

        // const detail = permisions.find((item)=> item.indexOf(`specialpermission:${moduleName}`) > - 1);

        let detail;
        permisions.forEach((item: string) => {

       if(item.indexOf(`specialpermission:${moduleName}`) > - 1)
       {
        detail=item;
       }

        });

        const value =  (detail+"").split('|');
        return value?.length > 0 ? value[1] : '';
    }
}


