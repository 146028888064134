import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class EncryptDecryptService {
    encryptSecretKey:string = 'zqmijplyuufzzgktaarwboqzqigmcssq';
    constructor() { }

    // encrypt data
    encrypt(data:any):string {
        return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey).toString();
      }
    
    // decrypt data
    decrypt(data:any):string {
        const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey);
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return '';
    }
}
