<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<ng-container>
    <div class="modal-header align-items-center " >
      <h6 class="modal-title modal-title-success">{{'Route Account'|translate}}</h6>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0" *ngIf="isRoutedPermission">
      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form-content card-form-success">
            <div class="bg-note-info p-2 mb-3">
              {{'Input the field values for reamortizing the loan'|translate}}.
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-no-bg table-align-middle mb-0">
                <thead>
                  <tr>
                    <td class="border-0">{{'Field Name'|translate}}</td>
                    <td class="border-0">{{'Before'|translate}}</td>
                    <td class="border-0"></td>
                    <td class="border-0">{{'After'|translate}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{'Routed To'|translate}}:</td>
                    <td>{{routed_to_name}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td class="">
                      <select name="routed-to" [(ngModel)]="routed_to" (change)="getselectedrout($event)" class="form-control form-control">
                        <option value="">{{'-Select-'|translate}}</option>
                        <option
                          *ngFor="let item of getFilter(userList)"
                          [ngValue]="item.id"
                        >
                        {{item?.employee_initials}} - {{ item.firstname }} {{ item.lastname }}
                        </option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div>

        <span>
          <a href="javascript:void(0)" class="text-underline font-size-12" (click)="isRouteShowHistory = !isRouteShowHistory">{{isRouteShowHistory ? ("Hide History"|translate) : ("Show History"|translate)}}</a>
        </span>
        <!-- <span *ngIf="!isRoutedPermission" class="d-inline-block font-size-14 lms-text-light-pink ml-2">
          You don’t have the permission to route account.
        </span> -->
      </div>
      <div>
        <button *ngIf="isRoutedPermission && this.isRoutNotSelected==false" type="button" class="btn btn-success-light btn-150" (click)="routeAccount()">
          {{'Route'|translate}}
        </button>
        <button *ngIf="this.isRoutNotSelected && isRoutedPermission"  type="button" class="btn btn-secondary btn-150" [disabled]="this.isRoutNotSelected">
          {{'Route'|translate}}
        </button>
        <!-- <button *ngIf="!isRoutedPermission"  type="button" class="btn btn-pink btn-150" (click)="openGetApprovalModal(GetApprovalModal)">
          Get Approval
        </button> -->
      </div>
    </div>
    <div class="lms-module-card">
      <div class="card-body">

        <div class="table-responsive" *ngIf="isRouteShowHistory">
          <table datatable [dtOptions]="routeDtOptions" class="table table-sm table-no-bg table-align-middle mb-0">
            <thead class="table-header-success">
              <tr>
                <td class="border-0">{{'Date'|translate}}</td>
                <td class="border-0">{{'Routed By'|translate}}</td>
                <td class="border-0"></td>
                <td class="border-0">{{'Routed To'|translate}}</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of routeAccountList">
                <td>{{ (convertLocalTimeZone(item?.updated_at)) | date:DATE_FORMAT }}</td>
                <td>{{item?.from_user?.employee_initials}} - {{item?.from_user?.firstname}} {{item?.from_user?.lastname}}</td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" alt=""/>
                </td>
                <td>{{item?.to_user?.employee_initials}} - {{item?.to_user?.firstname}} {{item?.to_user?.lastname}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</div>
</div>

  <!-- Override Modal -->
<ng-template #GetApprovalModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
  <div class="modal-header " >
    <h6 class="modal-title text-center mb-3">{{'Get Approval'|translate}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form #f="ngForm" [formGroup]="appform" (ngSubmit)="verifyPin()" novalidate>
  <div class="modal-body pb-0 px-0">


    <div class="lms-module-card">
      <div class="card-body">
        <div class="card-form form-vertical">
          <div class="form-group">
            <label class="form-group-label">{{'Initials'|translate}}</label>
            <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
            <val-errors controlName="employee_initials">
              <ng-template valError="required">{{'Initial is required'|translate}}</ng-template>
            </val-errors>
          </div>
          <div class="form-group">
            <label class="form-group-label">{{'PIN'|translate}}</label>
            <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
            <val-errors controlName="pin">
              <ng-template valError="required">{{'PIN is required'|translate}}</ng-template>
            </val-errors>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" (click)="this.appform.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
      {{'Get Approval'|translate}}
    </button>
  </div>
</form>
</div>
</div>
</ng-template>
