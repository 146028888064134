<div class="file-upload-block">
    <h5 class="file-upload-heading">{{heading|translate}}</h5>
    <div class="row gutters-5 align-items-center">
      <div class="col upload-image">
        <img
          *ngIf="imgPath && imgType === 'Favicon'"
          class="favicon-preview rounded-circle"
          [src]="imgPath"
          width="56px"
          height="56px"
        />
        <img
          *ngIf="imgPath && imgType === 'Profile'"
          class="favicon-preview rounded-circle"
          [src]="imgPath"
          width="56px"
          height="56px"
        />
        <img
          *ngIf="imgPath && imgType === 'Logo-Light'"
          class="logo-preview dark-logo img-fluid"
          [src]="imgPath"
          height="56px"
        />
        <img
          *ngIf="imgPath && imgType === 'Logo-Dark'"
          class="logo-preview light-logo img-fluid"
          [src]="imgPath"
          height="56px"
        />
      </div>
      <div class="col-auto btn-file" *ngIf="isEditPermission">
        <label
          for="{{uniqueID|translate}}"
          class="btn btn-block btn-file-upload"
        >
          {{"Upload"|translate}}
          <img
            src="../assets/images/icons/upload-icon.png"
            alt=""
            aria-hidden="true"
            (click)="onUploadBtnClick()"
          />
        </label>
        <input
          #fileInput
          type="file"
          id="{{uniqueID}}"
          style="display: none"
          (change)="onFileChange($event)"
        />
        <button
          (click)="onFileReset()"
          type="button"
          class="btn btn-block btn-file-remove"
        >
          {{"Remove"|translate}}
          <img
            src="../assets/images/icons/close-icon.png"
            alt=""
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  </div>
