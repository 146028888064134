<div class="bg-form-content p-4">
        <label *ngIf="showTitle" class="mb-3" >{{"Update Password"|translate}}</label>
    <form class="change-password-form"  ngForm [formGroup]="updateForm" (ngSubmit)="onSubmit($event)" novalidate>
      <div class="form-group">
        <label>{{"Current Password"|translate}}</label>
        <input type="password" formControlName="old_password" (blur)="verifyPassword($event)" class="form-control" value="" />
        <ng-container>
          <small class="form-error text-danger" *ngIf="updateForm.controls.old_password.touched && updateForm.controls.old_password.errors?.required">
            <li>{{"Please enter current password"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.old_password.touched && updateForm.controls.old_password.errors?.minlength">
            <li>{{"Please enter valid current password"|translate}}.</li>
          </small>
        </ng-container>
      </div>
      <div class="form-group">
        <label>{{"New Password"|translate}}</label>
        <input type="password" formControlName="password" (keyup)="verifyPassword($event)" class="form-control" value="" />
        <ng-container>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password.touched && updateForm.controls.password.errors?.required">
            <li>{{"Please enter password"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password.touched && updateForm.controls.password.errors?.minlength">
            <li>{{"Password must be of minimum 8 characters"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password.touched && updateForm.controls.password.hasError('passwordStrength')">
            <li>{{"Password must contain one number, one upper case, one lower case character and one special character"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password.touched && updateForm.controls.password.errors?.maxlength">
            <li>{{"Password must be of maximum 16 characters"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password.touched && updateForm.controls.password.hasError('whitespace')">
            <li>{{"Whitespace not allowed in password"|translate}}.</li>
          </small>
        </ng-container>
      </div>
      <div class="form-group">
        <label>{{"Confirm Password"|translate}}</label>
        <input type="password" name="password_confirmation" (keyup)="verifyPassword($event)"  formControlName="password_confirmation" class="form-control" value="" />
        <ng-container >
          <small class="form-error text-danger" *ngIf="updateForm.controls.password_confirmation.touched && updateForm.controls.password_confirmation.errors?.required">
            <li>{{"Please enter password"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password_confirmation.touched && updateForm.controls.password_confirmation.errors?.minlength">
            <li>{{"Password must be of minimum 8 characters"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password_confirmation.touched && updateForm.controls.password_confirmation.hasError('passwordStrength')">
            <li>{{"Password must contain one number, one upper case, one lower case character and one special character"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password_confirmation.touched && updateForm.controls.password_confirmation.errors?.maxlength">
            <li> {{"Password must be of maximum 16 characters"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password_confirmation.touched && updateForm.controls.password_confirmation.errors?.matched">
            <li> {{"Confirm Password and Password mismatch"|translate}}.</li>
          </small>
          <small class="form-error text-danger" *ngIf="updateForm.controls.password_confirmation.touched && updateForm.controls.password_confirmation.hasError('whitespace')">
            <li> {{"Whitespace not allowed in password"|translate}}.</li>
          </small>
        </ng-container>
      </div>
      <div class="mt-3" >
        <small class="form-error text-danger" *ngIf="errorArr?.length">
          <div *ngFor="let item of errorArr; let i=index;" class="mt-3">
            <li>{{item}}</li>          
          </div>
        </small>
        <small class="lms-text-light-green" *ngIf="verifiedMessage">
          <div class="mt-3">
            <li>{{verifiedMessage}}</li>          
          </div>
        </small>
      </div>
      <div class="mt-3">
        <button [disabled]="disableSubmit" type="submit" class="btn btn-primary btn-block text-uppercase">
          {{"Update Password"|translate}}
        </button>
      </div>
    </form>
  </div>
