 <div class="page-navbar main-left-menu collapse show" id="sidebar">
  <ngb-accordion class="navbar-submenu" [closeOthers]="true" [activeIds]="defaultTab">
    <ng-container *ngIf="(
      permissionService.isAccess(MODULES.DASHBOARD) ||
      permissionService.isAccess(MODULES.QUEUES_ASSIGNED) ||
      permissionService.isAccess(MODULES.QUEUES_ROUTED_TO_YOU) ||
      permissionService.isAccess(MODULES.AT_RISK_RECOVERY) ||
      permissionService.isAccess(MODULES.SCORECARD_BUILDER) ||
      permissionService.isAccess(MODULES.NBAR_BUILDER) ||
      permissionService.isAccess(MODULES.PBAR_BUILDER) ||
      permissionService.isAccess(MODULES.ODDS_OF_ROLL_BUILDER) ||
      permissionService.isAccess(MODULES.CAMPAING_BUILDER) ||
      permissionService.isAccess(MODULES.ASSIGNMENTS) ||
      permissionService.isAccess(MODULES.DATA_IMPORTS_MAPPING_TOOL) ||
      permissionService.isAccess(MODULES.DATA_IMPORTS_IMPORT_TOOL)  ||
      permissionService.isAccess(MODULES.STANDARD_SETTINGS) ||
      permissionService.isAccess(MODULES.VENDOR_CONFIGURATION)
    )">
      <ngb-panel id="menu-servicing" cardClass="{{
        validateRoute('/home/servicing/dashboard') ||
        validateRoute('/home/servicing/queues') ||
        validateRoute('/home/servicing/settings')
        ? 'active module-menu' : 'module-menu'}}">
        <ng-template ngbPanelTitle cardTitleClass="dropdown">
          <img class="menu-icon icon-light" src="../assets/images/sidebar/service-dark.png" alt="" />
          <img class="menu-icon icon-dark" src="../assets/images/sidebar/service.png" alt="" />
          {{"Servicing"|translate}}
        </ng-template>
        <ng-template ngbPanelContent>
          <ul class="submenu-items">
            <li>
              <a *ngIf="canAccessLoanInformation"
                [class.active]="validateRoute('/home/servicing/add-edit-loan-information')"
                path="/home/servicing/add-edit-loan-information"
                class="nav-path"
                (click)="goToRoute('/home/servicing/add-edit-loan-information')">Add Loan
              </a>
            </li>
            <li>
              <a *ngIf="permissionService.isAccess(MODULES.DASHBOARD)"
                [class.active]="validateRoute('/home/servicing/dashboard')"
                path="/home/servicing/dashboard"
                class="nav-path"
                (click)="goToRoute('/home/servicing/dashboard')">{{"Dashboard"|translate}}</a>
            </li>
            <ng-container *ngIf="(permissionService.isAccess(MODULES.QUEUES_ASSIGNED) ||
          permissionService.isAccess(MODULES.QUEUES_ROUTED_TO_YOU) ||
          permissionService.isAccess(MODULES.AT_RISK_RECOVERY)
          )">
              <li expandmenu [class.active]="queueMenu">
                <a (click)="queueMenu = !queueMenu" [attr.aria-expanded]="!queueMenu">{{'Queues'|translate}}</a>
                <ul class="submenu">
                  <li>
                    <a *ngIf="permissionService.isAccess(MODULES.QUEUES_ASSIGNED)"
                      path="/home/servicing/queues/assigned"
                      class="nav-path"
                      [class.active]="validateRoute('/home/servicing/queues/assigned')"
                      (click)="goToRoute('/home/servicing/queues/assigned')">{{"Assigned"|translate}}</a>
                    <a *ngIf="permissionService.isAccess(MODULES.QUEUES_ROUTED_TO_YOU)"
                      path="/home/servicing/queues/unassigned"
                      class="nav-path"
                      [class.active]="validateRoute('/home/servicing/queues/unassigned')"
                      (click)="goToRoute('/home/servicing/queues/unassigned')">{{"Routed, Unassigned"|translate}}</a>
                    <a *ngIf="permissionService.isAccess(MODULES.AT_RISK_RECOVERY)"
                       path="/home/servicing/queues/recovery"
                       class="nav-path"
                      [class.active]="validateRoute('/home/servicing/queues/recovery')"
                      (click)="goToRoute('/home/servicing/queues/recovery')">{{"At Risk of Recovery"|translate}}</a>
                  </li>
                </ul>
              </li>
            </ng-container>

            <ng-container *ngIf="(
            permissionService.isAccess(MODULES.SCORECARD_BUILDER) ||
            permissionService.isAccess(MODULES.NBAR_BUILDER) ||
            permissionService.isAccess(MODULES.PBAR_BUILDER) ||
            permissionService.isAccess(MODULES.ODDS_OF_ROLL_BUILDER) ||
            permissionService.isAccess(MODULES.CAMPAING_BUILDER) ||
            permissionService.isAccess(MODULES.ASSIGNMENTS) ||
            permissionService.isAccess(MODULES.DATA_IMPORTS_MAPPING_TOOL) ||
            permissionService.isAccess(MODULES.DATA_IMPORTS_IMPORT_TOOL)  ||
            permissionService.isAccess(MODULES.STANDARD_SETTINGS) ||
            permissionService.isAccess(MODULES.VENDOR_CONFIGURATION)
          )">
              <li expandmenu [class.active]="settingMenu">
                <a (click)="settingMenu = !settingMenu" [attr.aria-expanded]="!settingMenu">{{"Settings"|translate}}</a>
                <ul class="submenu">
                  <ng-container *ngIf="(permissionService.isAccess(MODULES.SCORECARD_BUILDER) ||
                permissionService.isAccess(MODULES.NBAR_BUILDER) ||
                permissionService.isAccess(MODULES.PBAR_BUILDER) ||
                permissionService.isAccess(MODULES.ODDS_OF_ROLL_BUILDER)
                )">
                    <li expandMenu [class.active]="scoringModuleMenu">
                      <a (click)="scoringModuleMenu = !scoringModuleMenu"
                        [attr.aria-expanded]="!scoringModuleMenu">{{"Scoring Module"|translate}}</a>
                      <ul class="submenu nbar-submenu">
                        <li>
                          <a *ngIf="permissionService.isAccess(MODULES.SCORECARD_BUILDER)"
                          path="/home/servicing/settings/scoring-module/input-scorecard"
                          class="nav-path"
                            [class.active]="validateRoute('/home/servicing/settings/scoring-module/input-scorecard')"
                            (click)="goToRoute('/home/servicing/settings/scoring-module/input-scorecard')">
                            {{"Input Scorecard"|translate}}</a>
                        </li>
                        <li>
                          <a *ngIf="permissionService.isAccess(MODULES.NBAR_BUILDER)"
                          path="/home/servicing/settings/scoring-module/nbar-builder"
                          class="nav-path"
                            [class.active]="validateRoute('/home/servicing/settings/scoring-module/nbar-builder')"
                            (click)="goToRoute('/home/servicing/settings/scoring-module/nbar-builder')">{{"NBAR Builder"|translate}}</a>
                        </li>
                        <li>
                          <a *ngIf="permissionService.isAccess(MODULES.PBAR_BUILDER)"
                          path="/home/servicing/settings/scoring-module/pbar-builder"
                          class="nav-path"
                            [class.active]="validateRoute('/home/servicing/settings/scoring-module/pbar-builder')"
                            (click)="goToRoute('/home/servicing/settings/scoring-module/pbar-builder')">{{"PBAR Builder"|translate}}</a>
                        </li>
                        <li>
                          <a *ngIf="permissionService.isAccess(MODULES.ODDS_OF_ROLL_BUILDER)"
                          path="/home/servicing/settings/scoring-module/odds-roll-builder"
                          class="nav-path"
                            [class.active]="validateRoute('/home/servicing/settings/scoring-module/odds-roll-builder')"
                            (click)="goToRoute('/home/servicing/settings/scoring-module/odds-roll-builder')">
                            {{"Odds of Roll Builder"|translate}}</a>
                        </li>
                      </ul>
                    </li>
                  </ng-container>
                  <li>
                    <a *ngIf="permissionService.isAccess(MODULES.CAMPAING_BUILDER)"
                    path="/home/servicing/settings/campaign-builder"
                    class="nav-path"
                      [class.active]="validateRoute('/home/servicing/settings/campaign-builder')"
                      (click)="goToRoute('/home/servicing/settings/campaign-builder')">{{"Campaign Builder"|translate}}</a>
                  </li>
                  <li>
                    <a *ngIf="permissionService.isAccess(MODULES.ASSIGNMENTS)"
                    path="/home/servicing/settings/assignments"
                    class="nav-path"
                      [class.active]="validateRoute('/home/servicing/settings/assignments')"
                      (click)="goToRoute('/home/servicing/settings/assignments')">{{"Assignments"|translate}}</a>
                  </li>
                  <ng-container *ngIf="(permissionService.isAccess(MODULES.DATA_IMPORTS_MAPPING_TOOL) ||
                permissionService.isAccess(MODULES.DATA_IMPORTS_IMPORT_TOOL)
                )">
                    <li expandMenu [class.active]="importsMenu">
                      <a (click)="importsMenu = !importsMenu" [attr.aria-expanded]="!importsMenu">{{"Imports"|translate}}</a>
                      <ul class="submenu">
                        <li>
                          <a *ngIf="permissionService.isAccess(MODULES.DATA_IMPORTS_MAPPING_TOOL)"
                          path="/home/servicing/settings/import/mapping-tool"
                          class="nav-path"
                            [class.active]="validateRoute('/home/servicing/settings/import/mapping-tool')"
                            (click)="goToRoute('/home/servicing/settings/import/mapping-tool')">{{"Mapping Tool"|translate}}</a>
                        </li>
                        <li>
                          <a *ngIf="permissionService.isAccess(MODULES.DATA_IMPORTS_IMPORT_TOOL)"
                          path="/home/servicing/settings/import/import-tool"
                          class="nav-path"
                            [class.active]="validateRoute('/home/servicing/settings/import/import-tool')"
                            (click)="goToRoute('/home/servicing/settings/import/import-tool')">{{"Import Tool"|translate}}</a>
                        </li>
                      </ul>
                    </li>
                  </ng-container>
                  <li>
                    <a *ngIf="permissionService.isAccess(MODULES.STANDARD_SETTINGS)"
                    path="/home/servicing/settings/standard-settings"
                    class="nav-path"
                      [class.active]="validateRoute('/home/servicing/settings/standard-settings')"
                      (click)="goToRoute('/home/servicing/settings/standard-settings')">{{"Standard Settings"|translate}}</a>
                  </li>
                  <li class="nbar-submenu">
                    <a *ngIf="permissionService.isAccess(MODULES.VENDOR_CONFIGURATION)"
                    path="/home/servicing/settings/vendor-configuration"
                    class="nav-path"
                      [class.active]="validateRoute('/home/servicing/settings/vendor-configuration')"
                      (click)="goToRoute('/home/servicing/settings/vendor-configuration')">{{"Vendor Configuration"|translate}}</a>
                  </li>
                </ul>
              </li>
            </ng-container>


          </ul>
        </ng-template>
      </ngb-panel>
    </ng-container>


    </ngb-accordion>

    <ng-container *ngIf="permissionService.isAccess(MODULES.MODEL_BUILDER)" >
        <div class="navbar-submenu">
            <div class="card new-block">
                <a *ngIf="permissionService.isAccess(MODULES.MODEL_BUILDER)"
                        path="/home/model-builder"
                        class="nav-path pl-2"
                        [class.active]="validateRoute('/home/model-builder')"
                        (click)="goToRoute('/home/model-builder')">
                    <div class=" mr-left">
                        <img class="menu-icon icon-light" src="../assets/images/sidebar/Model Builder Icon_dark.svg" alt="" />
                        <img class="menu-icon icon-dark" src="../assets/images/sidebar/Model Builder Icon.svg" alt="" />
                        <div class="submenu-items ">
                            <a  class="pl-1">{{"Model Builder"|translate}}</a>
                        </div>
                    </div>
                </a>
            </div>
        </div>
      </ng-container>

      <ng-container *ngIf="permissionService.isAccess(MODULES.DATA_MINER)" >
        <div class="navbar-submenu">
            <div class="card new-block">
                <a *ngIf="permissionService.isAccess(MODULES.DATA_MINER)"
                path="/home/data-miner"
                class="nav-path pl-2"
                [class.active]="validateRoute('/home/data-miner')"
                (click)="goToRoute('/home/data-miner')">
                    <div class=" mr-left">
                        <img class="menu-icon icon-light" src="../assets/images/sidebar/Data Miner Icon_dark.svg" alt="" />
                        <img class="menu-icon icon-dark" src="../assets/images/sidebar/Data Miner Icon.svg" alt="" />
                        <div class="submenu-items ">
                            <a  class="pl-1"> {{"Data Miner"|translate}}</a>
                        </div>
                    </div>
                </a>
            </div>
        </div>
      </ng-container>

      <ng-container *ngIf="permissionService.isAccess(MODULES.VALUATIONS)" >
        <div class="navbar-submenu" >
            <div class="card new-block">
                <a *ngIf="permissionService.isAccess(MODULES.VALUATIONS)"
                        path="/home/valuations"
                        class="nav-path pl-2"
                        [class.active]="validateRoute('/home/valuations')"
                        (click)="goToRoute('/home/valuations')">
                    <div class=" mr-left">
                        <img class="menu-icon icon-light" src="../assets/images/sidebar/Valuations Icon_black.svg" alt="" />
                        <img class="menu-icon icon-dark" src="../assets/images/sidebar/Valuations Icon.svg" alt="" />
                        <div class="submenu-items "><a class="pl-1"> {{"Valuations"|translate}}</a>
                        </div>
                    </div>
                </a>
            </div>
        </div>
      </ng-container>

    <!-- Lendisoft new modules-->
    <!-- <ng-container *ngIf="(permissionService.isAccess(MODULES.LENDISOFT_CLIENTS) ||
    permissionService.isAccess(MODULES.LENDISOFT_CLIENTS_MAINTENANCE)) && clientId === 'lendisoft'"> -->


    <!-- <ng-container *ngIf="permissionService.isAccess(MODULES.DATA_MINER)" >

      <ngb-panel id="menu-lendisoft-consulting-services2" cardClass="{{
        validateRoute('/home/lendisoft-configuration/data-miner') ||
        validateRoute('/home/lendisoft-configuration/data-miner')
        ? 'active' : ''}}">
        <ng-template ngbPanelTitle>
          <img class="menu-icon icon-light" src="../assets/images/sidebar/lms-configurations-dark.png" alt="" />
          <img class="menu-icon icon-dark" src="../assets/images/sidebar/lms-configurations.png" alt="" />
          Data Miner
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="submenu-items">
            <a *ngIf="permissionService.isAccess(MODULES.DATA_MINER)"
            path="/home/data-miner"
            class="nav-path"
              [class.active]="validateRoute('/home/data-miner')"
              (click)="goToRoute('/home/data-miner')">Data Miner</a>

          </div>
        </ng-template>
      </ngb-panel>
    </ng-container> -->

    <!-- <ng-container *ngIf="permissionService.isAccess(MODULES.VALUATIONS)" >

      <ngb-panel id="menu-lendisoft-consulting-services3" cardClass="{{
        validateRoute('/home/lendisoft-configuration/valuations') ||
        validateRoute('/home/lendisoft-configuration/valuations')
        ? 'active' : ''}}">
        <ng-template ngbPanelTitle>
          <img class="menu-icon icon-light" src="../assets/images/sidebar/lms-configurations-dark.png" alt="" />
          <img class="menu-icon icon-dark" src="../assets/images/sidebar/lms-configurations.png" alt="" />
          Valuations
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="submenu-items">
            <a *ngIf="permissionService.isAccess(MODULES.VALUATIONS)"
            path="/home/valuations"
            class="nav-path"
              [class.active]="validateRoute('/home/valuations')"
              (click)="goToRoute('/home/valuations')">Valuations</a>

          </div>
        </ng-template>
      </ngb-panel>
    </ng-container> -->




  <ngb-accordion class="navbar-submenu" [closeOthers]="true" [activeIds]="defaultTab">
    <ng-container *ngIf="(permissionService.isAccess(MODULES.REPORTS_QUERIES) || permissionService.isAccess(MODULES.FILES) ||  permissionService.isAccess(MODULES.REPORTS_REPORTS ||  permissionService.isAccess(MODULES.DATA_WAREHOUSE)))">
        <ngb-panel id="menu-report" cardClass="
      {{
      validateRoute('/home/reports/queries') ||
      validateRoute('/home/reports/queries')
      ? 'active' : ''}}">
          <ng-template ngbPanelTitle>
            <img class="menu-icon icon-light" src="../assets/images/sidebar/reports-dark.png" alt="" />
            <img class="menu-icon icon-dark" src="../assets/images/sidebar/reports.png" alt="" />
            {{"Reports"|translate}}
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="submenu-items">


              <a *ngIf="permissionService.isAccess(MODULES.FILES)"
              path="/home/reports/files"
              class="nav-path"
                [class.active]="validateRoute('/home/reports/files')"
                (click)="goToRoute('/home/reports/files')">{{"Files"|translate}}</a>



  <!--
              <a *ngIf="permissionService.isAccess(MODULES.REPORTS_DAILY_GOAL)"
              path="/home/reports/daily-goal"
              class="nav-path"
                [class.active]="validateRoute('/home/reports/daily-goal')"
                (click)="goToRoute('/home/reports/daily-goal')">Daily Goal</a> -->




              <!-- <a *ngIf="permissionService.isAccess(MODULES.REPORTS_CHAMPION_CHALLENGER)"
              path="/home/reports/champion-challenger"
              class="nav-path"
                [class.active]="validateRoute('/home/reports/champion-challenger')"
                (click)="goToRoute('/home/reports/champion-challenger')">Champion / Challenger</a> -->

                <a *ngIf="permissionService.isAccess(MODULES.REPORTS_REPORTS)"
                path="/home/reports/champion-challenger"
                class="nav-path"
                  [class.active]="validateRoute('/home/reports/reports')"
                  (click)="goToRoute('/home/reports/reports')">{{"Reports"|translate}}</a>

                  <a *ngIf="permissionService.isAccess(MODULES.DATA_WAREHOUSE)"
                path="/home/reports/data-warehouse"
                class="nav-path"
                  [class.active]="validateRoute('/home/reports/data-warehouse')"
                  (click)="goToRoute('/home/reports/data-warehouse')">Data Warehouse</a>

                  <!-- <a *ngIf="permissionService.isAccess(MODULES.REPORTS_QUERIES)"
                  path="/home/reports/queries"
                  class="nav-path disabled-link"
                  [class.active]="validateRoute('/home/reports/queries')"
                  (click)="goToRoute('/home/reports/queries')">{{"Queries"|translate}}</a>  -->
            </div>
          </ng-template>
        </ngb-panel>
      </ng-container>
    <!--Configuration-->
    <ng-container *ngIf="(permissionService.isAccess(MODULES.USER) ||
     permissionService.isAccess(MODULES.USER_MAINTENANCE) ||
     permissionService.isAccess(MODULES.NOTIFICATIONS) ||
     permissionService.isAccess(MODULES.ROLES)
     )">
      <ngb-panel id="menu-configuration" cardClass="
        {{
        validateRoute('/home/configuration/users') ||
        validateRoute('/home/configuration/user-maintenance') ||
        validateRoute('/home/configuration/notifications') ||
        validateRoute('/home/configuration/roles')
        ? 'active' : ''}}">
        <ng-template ngbPanelTitle>
          <img class="menu-icon icon-light" src="../assets/images/sidebar/configurations-dark.png" alt="" />
          <img class="menu-icon icon-dark" src="../assets/images/sidebar/configurations.png" alt="" />
          {{"Configurations"|translate}}
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="submenu-items">
            <a *ngIf="permissionService.isAccess(MODULES.USER)"
            path="/home/configuration/users"
            class="nav-path"
              [class.active]="validateRoute('/home/configuration/users')"
              (click)="goToRoute('/home/configuration/users')">{{"Users"|translate}}</a>
            <a *ngIf="permissionService.isAccess(MODULES.USER_MAINTENANCE)"
            path="/home/configuration/user-maintenance"
            class="nav-path"
              [class.active]="validateRoute('/home/configuration/user-maintenance')"
              (click)="goToRoute('/home/configuration/user-maintenance')">{{"User Maintenance"|translate}}</a>
            <a *ngIf="permissionService.isAccess(MODULES.NOTIFICATIONS)"
            path="/home/configuration/notifications"
            class="nav-path"
              [class.active]="validateRoute('/home/configuration/notifications')"
              (click)="goToRoute('/home/configuration/notifications')">{{"Notifications"|translate}}</a>
            <a *ngIf="permissionService.isAccess(MODULES.ROLES)"
            path="/home/configuration/roles"
            class="nav-path"
              [class.active]="validateRoute('/home/configuration/roles')"
              (click)="goToRoute('/home/configuration/roles')">{{"Roles"|translate}}</a>
          </div>
        </ng-template>
      </ngb-panel>

    </ng-container>
    <!-- Lendisoft Configuration-->
    <ng-container *ngIf="(permissionService.isAccess(MODULES.LENDISOFT_CLIENTS) ||
    permissionService.isAccess(MODULES.LENDISOFT_CLIENTS_MAINTENANCE)) && clientId === 'lendisoft'">

      <ngb-panel id="menu-lendisoft-configuration" cardClass="{{
        validateRoute('/home/lendisoft-configuration/clients') ||
        validateRoute('/home/lendisoft-configuration/maintenance')
        ? 'active' : ''}}" *ngIf="!isLendisoftClient">
        <ng-template ngbPanelTitle>
          <img class="menu-icon icon-light" src="../assets/images/sidebar/lms-configurations-dark.png" alt="" />
          <img class="menu-icon icon-dark" src="../assets/images/sidebar/lms-configurations.png" alt="" />
          {{"Lendisoft Configurations"|translate}}
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="submenu-items">
            <a *ngIf="permissionService.isAccess(MODULES.LENDISOFT_CLIENTS)"
            path="/home/lendisoft-configuration/clients"
            class="nav-path"
              [class.active]="validateRoute('/home/lendisoft-configuration/clients')"
              (click)="goToRoute('/home/lendisoft-configuration/clients')">{{"Lendisoft Clients"|translate}}</a>
            <a *ngIf="permissionService.isAccess(MODULES.LENDISOFT_CLIENTS_MAINTENANCE)"
            path="/home/lendisoft-configuration/maintenance"
            class="nav-path"
              [class.active]="validateRoute('/home/lendisoft-configuration/maintenance')"
              (click)="goToRoute('/home/lendisoft-configuration/maintenance')">{{"Lendisoft Maintenance"|translate}}</a>
          </div>
        </ng-template>
      </ngb-panel>
    </ng-container>



  </ngb-accordion>
  <ng-container *ngIf="![null,'',undefined].includes(this.qb_token)">
    <div class="navbar-submenu">
        <div class="card new-block">
            <a
            path="#"
            class="nav-path pl-2"
            (click)="showConfirmation()">
                <div class=" mr-left">
                    <img class="menu-icon icon-light"  src="../assets/images/sidebar/open-book-black.svg" width="20px" alt="" />
                    <img class="menu-icon icon-dark" src="../assets/images/sidebar/open-book.svg" width="20px" alt="" />
                    <div class="submenu-items ">
                        <a  class="pl-1">&nbsp;{{"QB Connector"|translate}}</a>
                    </div>
                </div>
            </a>
        </div>
    </div>
  </ng-container>
</div>
