import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HttpService, PermissionService } from '../..';
import { MODULES } from '../../config/enum';
import { ClientDetailService } from '../../services/client-detail-service';
import { UserService } from '../../services/user.service';
import { ClientUtils } from '../../utils/clientUtil';
import { QBTokenChangeService } from '../../services/qb_token_change-service';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PathConfig } from '../../config/path.config';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss']

})

export class LeftNavigationComponent implements OnInit , AfterViewInit{
  defaultTab:string = 'menu-servicing';
  private subscription!: Subscription
  clientId: string;
  isLendisoftClient: boolean = false;
  USER_PERMISSIONS:Array<any> = [];
  MODULES = MODULES;
  settingMenu:boolean = false;
  queueMenu: boolean = false;
  scoringModuleMenu:boolean = false;
  importsMenu:boolean = false;
  vendorConfigurationMenu:boolean = false;
  qb_url: string = '';
  qb_token: any = null;
  canAccessLoanInformation:boolean = false;


  constructor(
    private router:Router, private activeRoute: ActivatedRoute , private clientDetailService:ClientDetailService,
     private userService:UserService,
     public permissionService:PermissionService,
     private qbSharingService: QBTokenChangeService,
     private modalService: NgbModal,
     private httpService: HttpService,
     private translateService:TranslateService
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
  // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
   this.clientId = this.clientDetailService.CLIENT_ID;
   this.qb_token = this.clientDetailService?.clientDetail?.token ?? null;

   //default tab to be opened
   this.defaultTab = this.getParentGroup(router.url);

   //user permissions
   this.USER_PERMISSIONS = this.userService.PERMISSIONS;
   this.getQboUrl();
    //  this.canAccessLoanInformation = this.permissionService.isEdit('loan_information');
    this.canAccessLoanInformation = this.permissionService.isSpecialPermission('can_add_new_loans');
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page
    });
    this.isLendisoftClient = ClientUtils.isLoginAsClient(this.clientDetailService, this.userService);

    this.subscription = this.qbSharingService.dataObservable$.subscribe((data) => {
        this.qb_token = this.clientDetailService?.clientDetail?.token ?? null;
        this.qb_url=data[1];
    });
  }

  ngAfterViewInit(): void {
    // redirect to available module
    const route = document?.querySelector('.page-navbar  a.nav-path')?.getAttribute('path') || 'home';
    // this.router.navigate([this.clientId + route]);
  }

  // get parent category
  getParentGroup(url:string):string{
    let defaultTab = 'menu-servicing';
    if(url.indexOf('/home/servicing') > -1){
      defaultTab = 'menu-servicing';

      //reselect the menu as per selected module
      if(url.indexOf('/home/servicing/queues') > -1){
        this.queueMenu = true;
      }else if(url.indexOf('/home/servicing/add-edit-loan-information') > -1){
        this.queueMenu = true;
      }else if(url.indexOf('/home/servicing/settings') > -1){
        this.settingMenu = true;
        if(url.indexOf('/home/servicing/settings/scoring-module') > -1){
          this.scoringModuleMenu = true;
        }else if(url.indexOf('/home/servicing/settings/import') > -1){
          this.importsMenu = true;
        }
      }

    } else if(url.indexOf('/home/report') > -1){
      defaultTab = 'menu-report';
    } else if(url.indexOf('/home/configuration') > -1){
      defaultTab = 'menu-configuration';
    }else if(url.indexOf('/home/lendisoft-configuration') > -1){
      defaultTab = 'menu-lendisoft-configuration';
    }
    return defaultTab;
  }

  goToRoute(route: string): void {
   this.router.navigate([this.clientId + route]);
  //  window.location.href = this.clientId + route;
  }

  validateRoute(route: string){
    return this.router.url.includes(route);
  }


  showConfirmation() {
    if (![null,undefined,''].includes(this.qb_url)) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'sm',
      });
      modalRef.componentInstance.confirmationBoxTitle = this.translateService.instant('Are you sure?');
      modalRef.componentInstance.confirmationMessage =
        `${this.translateService.instant('All You are being directed to an external website. Please be aware that you are leaving our platform, and the content or security of the destination site may not be under our control.')}<br><br>${this.translateService.instant('Do you want to proceed?')}`;
      modalRef.result.then((response) => {
        if (response) {
          window.open(this.qb_url+'/'+this.qb_token, '_blank');
        }

      });
    }
  }

  getQboUrl() {
    const qbo_URL = `${PathConfig.CUSTOM_VENDOR_CONFIG}?config_data=qb`;
      this.httpService.get(qbo_URL).subscribe((res) => {
      this.qb_url = res?.qb_configuration?.url;
    });
  }
}
