<div class="login-form-content m-auto" *ngIf='maintenance==1'>
  <h4 class="text-center mb-5 form-heading"> {{'Application is in Maintenance Mode. Estimated completion time is 2:00am Pacific Time.'|translate}}</h4>
    <div class="mt-3">
      <button type="button" (click)="refresh()"
      class="btn btn-primary btn-block text-uppercase text-white font-weight-bold">
      {{"Refresh"}}
    </button>
    </div>
</div>
<div class="login-form-content" *ngIf='maintenance==0 && !is2FAEnable'>
  <form class="login-form m-auto" ngForm [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" novalidate>
    <h4 class="text-center mb-5 form-heading">{{"Login"|translate}}</h4>
    <div class="form-group">
      <label>{{"Email"|translate}}</label>
      <!-- <input type="text" formControlName="email"  autocomplete="new-password" [readonly]="userEmail" class="form-control" value="" />
       -->
       <input type="text" formControlName="email"  autocomplete="new-password"  class="form-control" value="" />

      <ng-container *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required">
          <small class="form-error text-danger">
              {{"Please enter email"|translate}}.
          </small>
      </ng-container>
      <ng-container *ngIf="loginForm.controls.email && loginForm.controls.email.errors?.email">
        <small class="form-error text-danger">
              {{"Email address not well formed"|translate}}.
        </small>
    </ng-container>
    </div>
    <div class="form-group mt-2">
      <label>{{"Password"|translate}}</label>
      <input type="password"  autocomplete="new-password" formControlName="password" class="form-control" value="" />
      <ng-container *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required">
          <small class="form-error text-danger">
              {{"Please enter password"|translate}}.
          </small>
      </ng-container>
    </div>

    <div class="mt-3">
      <button
      [disabled]="submitted"
        class="btn btn-primary btn-block text-uppercase text-white font-weight-bold">
        {{"Login"|translate}}
      </button>
    </div>


  </form>
</div>
<div class="login-form-content" *ngIf='maintenance==0 && is2FAEnable'>
  <form class="login-form m-auto" ngForm [formGroup]="twoFAForm" (ngSubmit)="on2FASubmit($event)" novalidate>
    <h4 class="text-center mb-5 form-heading">{{"Two-Factor Authentication"|translate}}</h4>
    <div class="form-group mt-2">
      <label>{{"Verification Code"|translate}}</label>
      <input type="password"  autocomplete="new-password" formControlName="code" class="form-control" value="" />
      <ng-container *ngIf="twoFAForm.controls.code.touched && twoFAForm.controls.code.errors?.required">
          <small class="form-error text-danger">
              {{"Please enter verification code"|translate}}.
          </small>
      </ng-container>
    </div>
    <div class="mt-3">
      <button [disabled]="submitted" class="btn btn-primary btn-block text-uppercase text-white font-weight-bold">
        {{"Verify"|translate}}
      </button>
    </div>
    <div class="text-right font-size-14 mt-3">
      <button type="button" *ngIf="!iscountDown" (click)="onResendCode()" class="btn btn-link text-underline">{{"Re-send"|translate}}</button>
      <span *ngIf="iscountDown" >{{ counter | otpCountDown }}</span>
    </div>
  </form>
</div>
