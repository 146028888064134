import { Component, OnDestroy, OnInit, Output,EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BroadcastService, HttpService, LocalStorageService, MessageService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import { REG_EXP_VALUES } from 'src/app/core/constants/constant';
import { Utility } from 'src/app/core/utils/utility';
import { ConfirmedValidator } from 'src/app/core/validators';
import { PasswordStrengthValidator, PasswordWhitespaceValidator } from 'src/app/core/validators/password-strength.validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ClientUtils } from 'src/app/core/utils/clientUtil';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit,OnDestroy {
  updateForm!:UntypedFormGroup;
  eventSubscription:Array<Subscription> = [];
  submitted:boolean = false;
  showTitle:boolean = false;
  disableSubmit:boolean = true;
  errorArr:any = [];
  verifiedMessage:any = null
  @Output() onPasswordChanged:EventEmitter<boolean> = new EventEmitter();
  constructor(private fb: UntypedFormBuilder,
    private httpService:HttpService,
    private router:Router,
    private msgService:MessageService,
    private modalService:NgbModal,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private broadCastService: BroadcastService,
    private clientDetailService: ClientDetailService,
    private translateService:TranslateService
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
  }

  ngOnInit(): void {
    // reset form
    this.updateForm = this.fb.group({
      'old_password': new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(4)])
      ),
        'password': new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(8), Validators.maxLength(16), PasswordStrengthValidator, PasswordWhitespaceValidator])
      ),
        'password_confirmation': new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(8), Validators.maxLength(16), PasswordStrengthValidator, PasswordWhitespaceValidator])),
      }, {
        validator: ConfirmedValidator('password', 'password_confirmation')
      });
  }

  //submit form
  onSubmit(event:any):void {

    this.updateForm.markAllAsTouched();
    if(this.updateForm.valid && !this.submitted) {
      this.submitted = true;
      const param = {...this.updateForm.value};
      this.httpService.put(PathConfig.UPDATE_PASSWORD,param)
      .subscribe((response)=>{
        this.msgService.showSuccess(response.message);
        this.submitted = false;
        this.updateForm.reset();
        this.onPasswordChanged.emit(true);
        this.modalService.dismissAll();
        if(this.showTitle)
        this.removeUserInfo();
      },(err)=>{
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.submitted = false;
      });

    }
  }

  ngOnDestroy():void{
    //remove all events
    this.eventSubscription.forEach((event)=>event.unsubscribe());
  }

   //verifyPassword
   verifyPassword(event:any):void {
    this.disableSubmit = true;
    this.errorArr = [];
    this.verifiedMessage = null;

    this.updateForm.markAllAsTouched();
    if(this.updateForm.valid && !this.submitted) {
      this.submitted = true;
      let param = {...this.updateForm.value};
      param.operation_type = 'VALIDATE';
      this.httpService.put(PathConfig.UPDATE_PASSWORD,param)
      .subscribe((response)=>{
        // this.msgService.showSuccess(response.message);
        this.submitted = false;
        this.disableSubmit = false;
        this.verifiedMessage = response.message
        // this.updateForm.enable();
        // this.onPasswordChanged.emit(true);
        // this.modalService.dismissAll();
      },(err)=>{
        // msgList.forEach((msg) => this.msgService.showError(msg));
        this.errorArr =  this.getErrorList(err?.error) ?? [];
        if(err.error.message) {
        }
        else {
          this.errorArr = ['Server Error, Please try again after some time.'];
        }
        this.updateForm.reset();
        this.submitted = false;
        this.disableSubmit = this.errorArr?.length ? true:false;
        // this.updateForm.disable();
      });

    }
  }

  getErrorList(err:any) {
    let errorMsg = [err];
    if(_.isPlainObject(err?.errors)){
      errorMsg = _.chain(err?.errors)?.values()?.flatten()?.value();
    }
    const msgList = errorMsg?.map((msg)=>{
      return msg;
    });
    return msgList;

  }

  //reset user information
  removeUserInfo(): void {
    const clientSlug = ClientUtils.getClientSlug(this.route, this.router);
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.AUTH_TOKEN_EXPIRY
    );
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.AUTH_TOKEN
    );
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.USER_INFO
    );
    this.localStorageService.remove(
      clientSlug + '_' + ConstantConfig.SELECTED_THEME
    );
    this.broadCastService.broadcast(MsgConstantConfig.USER_LOGGED_OUT);

    const clientID = this.clientDetailService.CLIENT_ID;
    this.router.navigate(['/', clientID]);
  }

}
