import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { BroadcastService } from './broadcaster.service';
import { MsgConstantConfig } from '../config/message.constant.config';

@Injectable()
export class MessageService {

    hideError:boolean  = false;

    constructor(
        private toastr: ToastrService,
        private broadCastService: BroadcastService) {

        this.broadCastService.on(MsgConstantConfig.SERVER_ERROR).subscribe((res:any) => {  

            this.hideError = true;
            const config:Partial<IndividualConfig> = {disableTimeOut:true}
            this.toastr.error(res, '', config);
        });
    }

    //show success
    showSuccess(message:string  ,config:Partial<IndividualConfig> = { timeOut: 5000,disableTimeOut:false}) {
        this.toastr.success(message,'',config);
    }

    //show error
    showError(message:string,config:Partial<IndividualConfig> = {disableTimeOut:true}) {
        // if (!['Unauthenticated.'].includes(message) && !this.hideError && message ) {
        if (!['Unauthenticated.'].includes(message) && message ) {
            this.toastr.error(message, '', config);
        }
    }

    //hide all notification
    hideAllMessage() {
        this.toastr.clear();
    }  
}


