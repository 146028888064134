import { Component, OnInit } from '@angular/core';
import {  EventEmitter, forwardRef, Input, Optional, Output, Provider, Self, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-custome-month-picker',
  templateUrl: './custome-month-picker.component.html',
  styleUrls: ['./custome-month-picker.component.scss']
})
export class CustomeMonthPickerComponent implements OnInit {

  modelDate!: Date;
  minAllowedDate: Date;
  maxAllowedDate: Date;
  selectedMonthFormatted!: string;
  @Output() monthSelected: EventEmitter<any> = new EventEmitter();
  @Input()  placeholder:string = 'mm/yyyy';
  constructor() {
    const currentDate = new Date();
    this.maxAllowedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // Set selectedDate to the first day of the current month
    this.modelDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    this.selectedMonthFormatted = formatDate(this.modelDate, 'yyyy-MM', 'en-US');
    this.selectedMonthFormatted ? this.monthSelected.emit(this.selectedMonthFormatted) : '';
    this.minAllowedDate = new Date(this.maxAllowedDate.getFullYear() - 2, this.maxAllowedDate.getMonth(), this.maxAllowedDate.getDate());
  }

  ngOnInit(): void {
  }

  onSelectedDateChange(event: Date) {
    // Format the selected date to 'YYYY-MM'
    this.selectedMonthFormatted = formatDate(event, 'yyyy-MM', 'en-US');
    this.selectedMonthFormatted ? this.monthSelected.emit(this.selectedMonthFormatted) : '';
    this.emitMonthSelected();
  }

  private emitMonthSelected() {
    this.monthSelected.emit(this.selectedMonthFormatted);
  }
}
