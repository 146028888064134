import { Component, Input, OnInit } from '@angular/core';
import { BroadcastService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';

@Component({
  selector: 'app-loan-in-progress-modal',
  templateUrl: './loan-in-progress-modal.component.html',
  styleUrls: ['./loan-in-progress-modal.component.scss']
})
export class LoanInProgressModalComponent implements OnInit {

  @Input() refreshUrl = '';
  @Input() queuesUrl = '';
  @Input() nextUrl = '';
  @Input() showNext = true;

  constructor(
    private broadCastService:BroadcastService,
  ) { }

  ngOnInit(): void {
    this.broadCastService.on(MsgConstantConfig.REFRESH_LOAN_IN_PROGRESS_POP_UP).subscribe((res:any) => {
      //refresh the list
    });
  }

}
