import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstantConfig } from '../config/constant.config';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import moment from 'moment';
@Injectable()
export class AuthService {

    constructor(private localStorageService:LocalStorageService, private userService:UserService) {}
    public isAuthenticated(): boolean {
        const token = this.userService.AUTH_TOKEN;
        // TBD
        // Check whether the token is expired and return
        // true or false
         return token ? true : false;
      }

    public isTokenExpired(): boolean {
        const expiryTime  = this.userService.getAuthTokenExpiry();
        const currentTime = new Date().getTime(); 
        const leftTime = expiryTime - currentTime;
  
        /** TIME LEFT */
        const d = moment.duration(leftTime, 'milliseconds');
        const hours = Math.floor(d.asHours());
        const mins = Math.floor(d.asMinutes()) - hours * 60;
         /** TIME LEFT */

        return leftTime <=0 ? true : false;
  
    }

    public isClientValid(clientID:string): Observable<any> {
        // TBD
        // Check whether the client exist or not // call API here
        // true or false
        return new Observable((observer)=>{
            if(clientID){
                observer.next(true);
            }else{
                observer.next(false);
            }
            observer.complete();
        })
      }
}


