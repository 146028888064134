<div *ngIf="!draggable"  style="z-index: 999999;">
    <div class="modal-header">
        <h6 class="modal-title modal-title-success" id="modal-basic-title">{{message}}...</h6>
        <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">x</span>
        </button> -->
    </div>
        
    <div class="modal-body py-0 font-size-14 categorical-modal-body">
        <div class="card-form-content card-form-success">
            <div class="progress-record d-inline-block w-75 ">
                 <p><ngb-progressbar class="green-bg" type="success" [value]="percentage" style="background-color: #ffffff !important;"></ngb-progressbar></p>
            </div>
            <div  class="progress-text d-inline-block w-25 pl-3">{{percentage}}%</div>
        
            <div class="progress-error pl-4">  
                <p>{{message2}}</p>  
            </div>
        </div>  
    </div>
</div>
<div *ngIf="draggable" class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
        <div  style="z-index: 999999;">
            <div class="modal-header">
                <h6 class="modal-title modal-title-success" id="modal-basic-title">{{message}}...</h6>
                <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">x</span>
                </button> -->
            </div>
                
            <div class="modal-body py-0 font-size-14 categorical-modal-body">
                <div class="card-form-content card-form-success">
                    <div class="progress-record d-inline-block w-75 ">
                         <p><ngb-progressbar class="green-bg" type="success" [value]="percentage" style="background-color: #ffffff !important;"></ngb-progressbar></p>
                    </div>
                    <div  class="progress-text d-inline-block w-25 pl-3">{{percentage}}%</div>
                
                    <div class="progress-error pl-0">  
                        <p class="mt-2">{{message2}}</p>
                        <p class="mt-2">{{message3}}</p>  
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>