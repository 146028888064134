import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ERROR_PAGES } from 'src/app/core/constants/constant';
import { AuthService } from 'src/app/core/services/auth-service';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { ClientUtils } from 'src/app/core/utils/clientUtil';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './error-not-found.component.html',
  styleUrls: ['./error-not-found.component.scss'],
})
export class ErrorNotFoundComponent implements OnInit {
  currentYear: string = moment().format('YYYY');
  sectionTitle: string = '';
  eventSubscription:Array<Subscription> = [];
  showFullPage:boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientDetailService:ClientDetailService,
    private authService:AuthService
  ) {
    this.sectionTitle = this.route.snapshot.data?.title || '';
    this.showFullPage = this.route.snapshot.queryParams?.fp == 'F' ? true : false;
  }

  ngOnInit(): void {
    // const clientId = ClientUtils.getClientSlug(this.route, this.router);
    // const client_id = this.clientService.CLIENT_ID;
    // if((!clientId || ERROR_PAGES.includes(clientId)) && client_id){
    //   this.router.navigate(["", client_id, 'error-404']);
    // }

  }

  goBack():void{
    const slug = this.clientDetailService.CLIENT_ID;
    if(slug){
      const isAuthenticated = this.authService.isAuthenticated();
      const url = !isAuthenticated ? `/${slug}` : `/${slug}/home`;
      this.router.navigate([url]);
    }else{
      window.location.href = '/';
    }
  }
}
