<form #f="ngForm" [formGroup]="form" novalidate>
    <div id="page-content-wrapper modal-builder" class="main">
        <div class="lms-card mb-3">
            <div class="row">
                <div class="col">
                    <h3 class="lms-card-heading">{{"Data Miner"|translate}}</h3>
                </div>
                <div class="col-auto">
                    <a href="javascript:;" class="btn-help-info is-hover">
                        <span>{{"Need help"|translate}}?</span>
                        <img src="../assets/images/icons/help.png" class="icon-dark" />
                        <img src="../assets/images/icons/help-dark.png" class="icon-light"/>
                    </a>
                </div>
            </div>
            <div class="lms-description">
                <p>
                    {{"Through our decades of real-world lending experience, we’ve identified some of the most important KPIs and analysis a lender needs to do to properly monitor and evolve their operation. Cumulative Net Loss (CNL) is perhaps the most important KPI of all, since it represents how much principal goes unrealized in a loan portfolio’s performance. Our Data Miner service offers an overall view of how your loan porfolio is performing, and provides priceless intel to help shape your future credit decision-making. Lendisoft's Data Miner is a tremendous value"|translate}}!
                </p>
                <p>
                    {{"Need to identify your top, most predictive variables? Need to know precisely where each variable’s most predictive breakpoints are? Could you benefit from instantly knowing your most dangerous, underperforming customer segments? Or perhaps you’d like to statistically identify your top, best performing customer segments to unlock new opportunities for pricing-in discounts in your lowest-risk customer segments. All of this is easily done with our amazing CNL-based segmentation tool. This amazing data mining tool really delivers value"|translate}}!
                </p>
                <p>
                    {{"Moreover, we also include a Campaign Builder tool that lets you specify custom criteria to quickly build populations of your customer-base to learn how they perform (loan count, loan size in dollars, and each segment’s CNL percentage). This service is an add-on service to existing Lendisoft clients, or can be purchased independently. For pricing, contact us today"|translate}}!
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-9 position-static"  >
                <div *ngIf="refresh" class="page-content-inner">
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#scorecard_name" role="button"aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Define Data Miner Template"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <div class="collapse show" id="scorecard_name">
                            <div class="card-form-content mt-2">
                                <div class="row">
                                    <div class="col-md-8">
                                        <!-- #1 Scorecard Name form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-7">
                                                <label class="form-group-label">{{"Data Miner Project Name"|translate}}*</label>
                                            </div>
                                            <div class="form-group col-lg-5">
                                                <input type="text" name="name" maxlength="64" value=""  class="form-control form-control-sm" formControlName="name"  appTrimOnBlur/>
                                            </div>
                                            <val-errors controlName="name">
                                                <ng-template valError="required" 
                                                  > {{"Name is required"|translate}}</ng-template
                                                >
                                              </val-errors>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#description" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Describe the Purpose of this Analysis"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="description">
                            <div class="card-form-content mt-2">
                                <!-- #1 Brief Description form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Brief Description"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <input type="text" name="description" class="form-control form-control-sm" value="" maxlength="300" appTrimOnBlur
                                        formControlName="description"  />
                                        <span class="text-count">{{form.get('description')?.value?.length??0}}/300</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Project plan -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#Stakeholders" role="button"aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Project Plan"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <div class="collapse show" id="Stakeholders">
                            <div class="card-form-content mt-2">
                                <div class="row pl-3">
                                    <div class="col-md-6">
                                        <p class="sub-heading">{{"Lendisoft Stakeholders"|translate}}</p>
                                        <!-- #1 Analyst/Developer form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Analyst"|translate}}/{{"Developer"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="Developer" maxlength="64" formControlName="lendisoft_stackholder_analyst" class="form-control form-control-sm" appTrimOnBlur />
                                            </div>
                                        </div>
                                        <!-- #2 Email form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Email"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="lendisoft_stackholder_email" maxlength="64" formControlName="lendisoft_stackholder_email" class="form-control form-control-sm" appTrimOnBlur />
                                            </div>
                                            <val-errors controlName="lendisoft_stackholder_email">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}</ng-template>
                                            </val-errors>
                                            <val-errors controlName="lendisoft_stackholder_email">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 1 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                       
                                        <!-- #3 cc: Stakeholders form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">cc: {{"Stakeholders"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="lendisoft_stackholder_cc" maxlength="256" formControlName="lendisoft_stackholder_cc" class="form-control form-control-sm" appTrimOnBlur />
                                            </div>
                                            <val-errors controlName="lendisoft_stackholder_cc">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}</ng-template>
                                            </val-errors>
                                            <val-errors controlName="lendisoft_stackholder_cc">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 10 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                       
                                        <!-- #4 Phone form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Phone"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="lendisoft_stackholder_phone" [mask]="US_PHONE_MASKING" maxlength="64" formControlName="lendisoft_stackholder_phone" class="form-control form-control-sm"/>
                                            </div>
                                            <val-errors controlName="lendisoft_stackholder_phone">
                                                <ng-template valError="invalidPhoneNumber"> {{"Phone number is not well formed"|translate}}</ng-template>
                                            </val-errors>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <p class="sub-heading">{{"Client Stakeholders"|translate}}</p>
                                        <!-- #1 Analyst/Developer form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Analyst"|translate}}/{{"Developer"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="developer" maxlength="64" formControlName="client_stackholder_analyst" class="form-control form-control-sm" appTrimOnBlur />
                                            </div>
                                        </div>
                                        <!-- #2 Email form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Email"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="client_stackholder_email" maxlength="64" formControlName="client_stackholder_email" class="form-control form-control-sm" appTrimOnBlur />
                                            </div>
                                            <val-errors controlName="client_stackholder_email">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}</ng-template>
                                            </val-errors>
                                            <val-errors controlName="client_stackholder_email">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 1 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                        
                                        <!-- #3 cc: Stakeholders form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">cc: {{"Stakeholders"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="client_stackholder_cc" maxlength="256" formControlName="client_stackholder_cc" class="form-control form-control-sm" appTrimOnBlur />
                                            </div>
                                            <val-errors controlName="client_stackholder_cc">
                                                <ng-template valError="invalidEmail">{{"Invalid email address"|translate}}</ng-template>
                                            </val-errors>
                                            <val-errors controlName="client_stackholder_cc">
                                                <ng-template valError="maxOccurenceExceed">{{"Maximum 10 email address allowed"|translate}}.</ng-template>
                                            </val-errors>
                                        </div>
                                        
                                        <!-- #4 Phone form control  -->
                                        <div class="row gutters-5">
                                            <div class="form-group col-lg-5">
                                                <label class="form-group-label">{{"Phone"|translate}}</label>
                                            </div>
                                            <div class="form-group col-lg-7">
                                                <input type="text" name="client_stackholder_phone" maxlength="64" [mask]="US_PHONE_MASKING" formControlName="client_stackholder_phone" class="form-control form-control-sm"/>
                                            </div>
                                            <val-errors controlName="client_stackholder_phone">
                                                <ng-template valError="invalidPhoneNumber">{{"Phone number is not well formed"|translate}}</ng-template>
                                            </val-errors>                                                               
                                            <span style="font-size:11px; color:rgb(182, 182, 182)">{{"Note: Use a semi-colon without any spaces to separate email addresses"|translate}}.</span>
                                        </div>
                                    </div>
                                </div>
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5 pt-3">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="notes"   class="form-control form-control-sm" value="" formControlName="note1" appTrimOnBlur></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- start table code -->
                        <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="Stakeholders">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_1" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'PROJECT_PLAN')" style="display: none;" id="file_1">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="Stakeholders">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions1">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('PROJECT_PLAN');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                          </table>
                        </div>
                    </div>
                    <!-- Import Data start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#ImportData" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Import Data"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="ImportData">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes"   class="form-control form-control-sm" value="" formControlName="note2" appTrimOnBlur></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="ImportData">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_2" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'IMPORT_DATA')" style="display: none;" id="file_2">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="ImportData">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions2">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('IMPORT_DATA');trackBy:trackByIndex " class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                        <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                    </div>
                    <!-- Data Cleansing form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#DataCleansing" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Data Cleansing"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="DataCleansing">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes"   class="form-control form-control-sm" value="" formControlName="note3" appTrimOnBlur></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="DataCleansing">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_3" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'DATA_CLEANSING')" style="display: none;" id="file_3">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="DataCleansing">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions3">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('DATA_CLEANSING');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Binning by CNL form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#BinningbyCNL" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Binning by CNL"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="BinningbyCNL">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes"   class="form-control form-control-sm" value="" formControlName="note4" appTrimOnBlur></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="BinningbyCNL">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_4" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'BINNING_BY_CNL')" style="display: none;" id="file_4">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="BinningbyCNL">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions4">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('BINNING_BY_CNL');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Results Summary form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#ResultsSummary" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Results Summary"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="ResultsSummary">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes"   class="form-control form-control-sm" value="" formControlName="note5" appTrimOnBlur></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="ResultsSummary">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_5" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'RESULTS_SUMMARY')" style="display: none;" id="file_5">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="ResultsSummary">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions5">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('RESULTS_SUMMARY');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    <!-- Card Collpase end -->
                    </div>
                    <!-- Data Miner form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#DataMiner" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Data Miner"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="DataMiner">
                            <div class="card-form-content mt-2">
                                <!-- #1 Notes form control  -->
                                <div class="row gutters-5">
                                    <div class="form-group col-lg-5 col-xl-4">
                                        <label class="form-group-label">{{"Notes"|translate}}</label>
                                    </div>
                                    <div class="form-group col-lg-7 col-xl-8">
                                        <textarea type="text" rows="3" style="resize: none;" name="Notes"   class="form-control form-control-sm" value="" formControlName="note6" appTrimOnBlur></textarea> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Card Collpase end -->
                        <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="DataMiner">
                            <div class="btn-file" >
                                <label class="btn btn-block btn-file-upload btn-duplicate" for="file_6" style="width:100px;">
                                    {{"Upload"|translate}}
                                    <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                </label>
                                <input type="file" name="upload_button"  (change)="onFileSelected($event,'DATA_MINER')" style="display: none;" id="file_6">
                            </div>
                        </div>
                        <div class="table-responsive collapse show" id="DataMiner">
                            <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions6">
                                <thead class="table-header-purple">
                                    <tr>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                        <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                        <th scope="col" class="nosort text-white"></th>
                                        <th scope="col" class="nosort text-white"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let file of getFilesByBuilderFileType('DATA_MINER');trackBy:trackByIndex" class="category-listing">
                                        <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                          </table>
                        </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Campaign Builder form start -->
                    <div class="lms-card mb-3">
                        <!-- card Header Start -->
                        <div class="lms-card-header" data-toggle="collapse" data-target="#CampaignBuilder" role="button" aria-expanded="true">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="lms-card-heading mb-0">{{"Campaign Builder"|translate}}</h3>
                                </div>
                                <div class="col-auto">
                                    <img class="icon-arrow-right" src="../assets/images/sidebar/right-arrow-dark.png" />
                                    <img class="icon-arrow-down" src="../assets/images/sidebar/bottom-arrow-dark.png" />
                                </div>
                            </div>
                        </div>
                        <!-- card Header End -->
                        <!-- Card Collpase Start -->
                        <div class="collapse show" id="CampaignBuilder">
                                <div class="card-form-content mt-2">
                                    <!-- #1 Notes form control  -->
                                    <div class="row gutters-5">
                                        <div class="form-group col-lg-5 col-xl-4">
                                            <label class="form-group-label">{{"Notes"|translate}}</label>
                                        </div>
                                        <div class="form-group col-lg-7 col-xl-8">
                                            <textarea type="text" rows="3" style="resize: none;" name="Notes"   class="form-control form-control-sm" value="" formControlName="note7" appTrimOnBlur></textarea> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Card Collpase end -->
                            <div *ngIf="!isDataMinerReadOnly" class="float-right collapse show pt-3"   id="CampaignBuilder">
                                <div class="btn-file" >
                                    <label class="btn btn-block btn-file-upload btn-duplicate" for="file_7" style="width:100px;">
                                        {{"Upload"|translate}}
                                        <img src="../assets/images/icons/upload-icon.png" alt="" aria-hidden="true">
                                    </label>
                                    <input type="file" name="upload_button"  (change)="onFileSelected($event,'CAMPAING_BUILDER')" style="display: none;" id="file_7">
                                </div>
                            </div>
                            <div class="table-responsive collapse show" id="CampaignBuilder">
                                <table class="table table-align-middle table-striped"  datatable [dtOptions]="dtOptions7">
                                    <thead class="table-header-purple">
                                        <tr>
                                            <th scope="col" class="nosort text-white"></th>
                                            <th scope="col" class="sorting text-white">{{"Name"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Date & Time"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Type"|translate}}</th>
                                            <th scope="col" class="text-white">{{"Size"|translate}}</th>
                                            <th scope="col" class="nosort text-white"></th>
                                            <th scope="col" class="nosort text-white"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let file of getFilesByBuilderFileType('CAMPAING_BUILDER');trackBy:trackByIndex" class="category-listing">
                                            <td class="category-items"></td>
                                        <td class="category-items">{{file?.name}}</td>
                                        <td class="category-items">{{file?.created_at || file?.date | date:'short'}}</td>
                                        <td class="category-items">{{file?.mime_type||file?.file_type||file?.type}}</td>
                                        <td class="category-items">{{(convertoMemoryUnit(file?.file_size))}}</td>
                                        <td *ngIf="file?.id" class="category-items category-items-hover" style="cursor: pointer" (click)="exportReportByUrl(file?.id,file?.name)">
                                            <span  style="cursor: pointer"> {{"Export"|translate}} </span>
                                        </td>
                                        <td *ngIf="!file?.id" class="category-items" >
                                            <span >  </span>
                                        </td>
                                       <td (click)="deleteFileByID(file?.id,file?.created_at)"  [ngClass]="{'cursor-pointer':isDataMinerReadOnly == false }" class="category-items">
                                            <button type="button" class="btn-icon btn-remove"  [ngClass]="{'display-none': isDataMinerReadOnly == true }" 
                                            (click)="deleteFileByID(file?.id,file?.created_at)">
                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                            </button>
                                        </td>
                                        </tr>
                                    </tbody>
                              </table>
                            </div>
                        <!-- Card Collpase end -->
                    </div>
                    <!-- Form Action Button -->
                    <div class="row justify-content-end my-3" *ngIf="isEditMode">
                        <div class="col-auto" *ngIf="!isDataMinerReadOnly">
                        <button
                            type="button"
                            class="btn btn-block btn-delete"
                            (click)="onDeleteTemplate(selected_template_id)"
                            routerLink="add"
                        >
                            {{"Delete Project"|translate}}
                            <img
                            src="../assets/images/icons/close-icon.png"
                            alt=""
                            aria-hidden="true"
                            />
                        </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="!refresh">
                    {{"Loading"|translate}}...
                </div>
                <!-- Form Action Button -->
                <div class="new-action-btn form-action-buttons row justify-content-end mt-3">
                    <div class="col-md-8" >
                        <ng-container *ngIf="isEditMode && refresh">

                        <div  class="row">
                            <div class="form-group col-md-3 mb-0">
                                <label class="form-group-label">{{"Saved Date"|translate}}</label>
                            </div>
                            <div class="form-group col-md-4 mb-0">
                            {{ (updated_at | date: 'short')??'' }}
                            </div>
                        </div>
                        <div  class="row" float-left>
                            <div class="form-group col-md-3">
                                <!--Saved by -->
                                <label class="form-group-label">{{"Saved By"|translate}}</label>
                            </div>
                            <ng-container>
                            <div class="form-group col-md-4">

                                {{(updated_by?.firstname || ('System'|translate)) + " " + (updated_by?.lastname || '') | titlecase }}


                                </div>
                            </ng-container>
                            <ng-container>
                                <div class="form-group col-md-4">

                                </div>
                                </ng-container>
                        </div>
                    </ng-container>
                    </div>
                    <div *ngIf="!isDataMinerReadOnly && refresh" class="col-md-4 mB20">
                        <div class="row justify-content-end">
                            <!-- <ng-container *ngIf="this.isSubCatManageByUser || isAddMode"> -->
                            <div class="col-6 col-sm-auto">
                            <button
                                type="reset"
                                (click)="onCancel()"
                                class="btn btn-primary btn-sm btn-full btn-md-auto btn-150 mb-3"
                            >
                                {{"Cancel"|translate}}
                            </button>
                            </div>
                            <div class="col-6 col-sm-auto">
                            <button
                                type="submit"
                                (click)="onFormSubmit()"
                                class="btn btn-primary btn-sm btn-full btn-md-auto btn-150 mb-3"
                            >
                                {{"Save"|translate}}
                            </button>
                            </div>
                        <!-- </ng-container> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <!-- Report Archive Sidebar -->
                <div class="lms-notification-sidebar">
                    <div class="lms-card-header-primary bg-dark-purple text-white text-center py-1" data-toggle="collapse" data-target="#ReportArchive" role="button" aria-expanded="true">
                        {{"Data Miner Templates"|translate}}
                    </div>
                    <div class="collapse show" id="ReportArchive">
                        <div class="lms-notification-sidebar-content">
                            <div class="lms-notification-header border-bottom mb-2 pb-2">
                                <div class="text-right mb-add" >
                                    <button *ngIf="isEditMode && selected_template_id!='' && selected_template_id!=null && !isDataMinerReadOnly" class="btn btn-duplicate" (click)="onDuplicateTemplate(duplicateModal)">
                                        {{"Duplicate"|translate}}
                                        <img src="../assets/images/icons/duplicate-icon.png" alt="" aria-hidden="true" />
                                    </button>
                                    <!-- Add New Control  -->
                                    <button *ngIf="!isDataMinerReadOnly" class="btn btn-add-new btn-add-new-success mt-0 ml-2" type="button" routerLink="add" (click)="addTemplate()">
                                        {{"Add New"|translate}}
                                        <img src="../assets/images/icons/plus.png" alt="" aria-hidden="true" />
                                    </button>
                                </div>
                                <!-- Search Start -->
                                <div class="search">
                                    <!-- <input type="text" class="form-control search-control" placeholder="Search"> -->
                                    <app-ls-search-input (onSearch)="onListSearch($event)"></app-ls-search-input>
                                </div>
                                <!-- Search End -->
                                <nav class="nav client-nav border-bottom border-0">
                                    <button type="button" class="btn nav-item active">
                                        <span >{{"All"|translate}}</span>
                                    </button>
                                   <!-- <button type="button" class="btn nav-item active">
                                       <span>Active</span>
                                    </button>
                                    <button type="button" class="btn nav-item">
                                        <span >Inactive</span>
                                    </button> -->
                                </nav>
                            </div>
                            <!-- template Body -->
                            <div class="lms-notification-body pb-3">
                                <div *ngIf="!isDataMinerReadOnly" class="text-right px-3 py-2">
                                    <a  routerLink="add"  (click)="onAddCategory(addCategoryModal)" class="font-size-14 lms-text-dark text-underline">{{"Add Category"|translate}}</a>
                                </div>
                                <!-- Show Category Nested Listing -->
                                <ngb-accordion #accordion="ngbAccordion" class="category-listing  level-1" [closeOthers]="false" 
                                [activeIds]="activeCategory">
                                    <ng-container  *ngFor="let item of category_list;let firstIndex = first; let lastIndex = last;trackBy:trackByIndex">
                                        <ng-container>
                                            <ngb-panel [id]="'cat' + item?.id">
                                                <ng-template ngbPanelHeader>
                                                    <button ngbPanelToggle class="btn btn-link">{{ item?.name }}</button>
                                                    <div class="category-actions">
                                                        <ng-container *ngIf="item?.is_default=='NO'">
                                                            <button *ngIf="!isDataMinerReadOnly" type="button" class="btn-icon btn-edit" 
                                                                (click)="onEditCategory(item?.id,item?.name, addCategoryModal)">
                                                                <img src="../assets/images/icons/edit-icon.png" alt="" aria-hidden="true"/>
                                                            </button>
                                                            <button *ngIf="!isDataMinerReadOnly" type="button" class="btn-icon btn-remove"
                                                                (click)="onDeleteCategory(item?.id)">
                                                                <img src="../assets/images/icons/remove-icon.png" alt="" aria-hidden="true"/>
                                                            </button>
                                                        </ng-container>    
                                                    </div>
                                                </ng-template>
                                                <ng-template ngbPanelContent>
                                                    <div class="category-items">
                                                        <ng-container *ngFor="let template of template_list;trackBy:trackByIndex">
                                                            <div *ngIf="template?.category?.id==item?.id" class="position-relative">
                                                                <div class="category-sort-icon">
                                                                    <button class="btn btn-icon btn-category-sort-up"
                                                                    *ngIf="!firstIndex && selected_template_id==template?.id && selected_template?.managed_by!='SYSTEM' && !isDataMinerReadOnly"
                                                                    (click)="moveToCategory('up',item,template)">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8"
                                                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M5.12899 0.0024264C5.31242 0.107045 5.47545 0.264544 5.60592 0.463177L9.74978 5.8898C9.84894 5.99595 9.92189 6.13814 9.95945 6.29848C9.99702 6.45881 9.99752 6.63012 9.96089 6.79082C9.92587 6.94578 9.85534 7.0829 9.75885 7.18363C9.66235 7.28435 9.54456 7.34381 9.4214 7.35396H0.727109C0.575944 7.3834 0.421694 7.34203 0.291537 7.23714C0.161381 7.13225 0.0636678 6.97057 0.0156154 6.78058C-0.0166298 6.66054 -0.0249363 6.5317 -0.00851729 6.40627C0.00790173 6.28085 0.0485024 6.16299 0.109439 6.06386L0.250174 5.86932L4.38622 0.452938C4.51669 0.254305 4.67972 0.0968062 4.86315 -0.0078125L5.12899 0.0024264Z"
                                                                                fill="#938F8F" />
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                    *ngIf="!lastIndex && selected_template_id==template?.id && selected_template?.managed_by!='SYSTEM' && !isDataMinerReadOnly"
                                                                    (click)="moveToCategory('down',item,template)" class="btn btn-icon btn-category-sort-down">
                                                                        <svg width="10" height="8" viewBox="0 0 10 8"
                                                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M4.9961 0.689025H9.29633C9.43925 0.671938 9.58242 0.718143 9.70291 0.820243C9.8234 0.922343 9.91425 1.07443 9.96091 1.25217C9.99459 1.37362 10.0037 1.50444 9.98721 1.63185C9.97077 1.75926 9.92938 1.87891 9.86709 1.97913C9.82697 2.04867 9.78251 2.11374 9.73417 2.17367L5.48867 7.65148C5.43011 7.75926 5.35272 7.84714 5.26296 7.90777C5.17319 7.96841 5.07365 8.00004 4.97264 8.00004C4.87164 8.00004 4.77209 7.96841 4.68233 7.90777C4.59256 7.84714 4.51517 7.75926 4.45661 7.65148L0.242383 2.10199C0.130716 1.98044 0.0548225 1.8118 0.027718 1.62498C0.000613496 1.43816 0.0239867 1.24482 0.0938291 1.0781C0.144619 0.964575 0.216299 0.869497 0.302334 0.801538C0.38837 0.733579 0.486027 0.694899 0.586402 0.689025H4.9961Z"
                                                                                fill="#938F8F" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <a [ngClass]="{
                                                                    activetemplate: selected_template_id == template?.id}" routerLink="edit/{{ template?.id }}" (click)="editTemplate(item?.id,template?.id,'selectTemplate')"> <span>{{ template?.id }}</span>{{ template?.name }}</a>
                                                            </div>
                                                        </ng-container>

                                                    </div>
                                                </ng-template>
                                            </ngb-panel>
                                        </ng-container>
                                    </ng-container>
                                </ngb-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<!-- Add category Modal Popup -->
<ng-template #addCategoryModal let-modal>
    <form  [formGroup]="categoryForm" novalidate>
        <div class="modal-header">
            <h6 class="modal-title" id="modal-basic-title">{{"Category"|translate}}</h6>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body py-0">
            <div class="row">
                <div class="form-group col-12">
                    <label class="form-group-label">{{"Name"|translate}}</label>
                    <input type="text" name="name" maxlength="64" formControlName="name" class="form-control form-control-sm"
                        value="" appTrimOnBlur />
                    <val-errors controlName="name">
                        <ng-template valError="required">{{"Name is required"|translate}}</ng-template>
                    </val-errors>
                </div>
            </div>
        </div>
        <div class="modal-footer flex-nowrap">
            <button type="button" (click)="onCancelCategory()"  class="btn btn-sm btn-secondary btn-block my-0">
                {{"Cancel"|translate}}
            </button>
            <button type="button" (click)="onCategorySubmit()" class="btn btn-sm btn-primary btn-block my-0">
                {{"Save"|translate}}
            </button>
        </div>
    </form>
  </ng-template>
   <!-- End -->

   <!-- Duplicate  Modal Popup -->
<ng-template #duplicateModal let-modal>
    <form
      
      [formGroup]="duplicateForm"
      
      novalidate
    >
      <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title">{{"DUPLICATE TEMPLATE"|translate}}</h6>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body py-0">
        <div class="row">
          <div class="form-group col-12">
            <label class="form-group-label">{{"New Name"|translate}}</label>
          </div>
          <div class="form-group col-12">
            <input
              type="text"
              name="name"
              maxlength="64"
              formControlName="name"
              class="form-control form-control-sm"
              value="" appTrimOnBlur
            />
            <val-errors controlName="name">
              <ng-template valError="required">{{"Name is required"|translate}}</ng-template>
            </val-errors>
          </div>
        </div>
      </div>
      <div class="modal-footer flex-nowrap">
        <button type="reset" (click)="modal.dismiss('Cross click')" class="btn btn-sm btn-secondary btn-block my-0">
          {{"Cancel"|translate}}
        </button>
        <button type="submit" (click)="onDuplicateSubmit()" class="btn btn-sm btn-primary btn-block my-0">
          {{"Save"|translate}}
        </button>
      </div>
    </form>
  </ng-template>
  <!-- End -->