import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService, HttpService, MessageService, UserService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import * as _ from 'lodash';
import { Utility } from 'src/app/core/utils/utility';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import moment from 'moment';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import "moment-timezone";
import Decimal from 'decimal.js';


@Component({
  selector: 'app-take-payment',
  templateUrl: './take-payment.component.html',
  styleUrls: ['./take-payment.component.scss']
})
export class TakePaymentComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() id!: any;
  @Input() editMode!: boolean;
  @Input() transactionDetails!: any;
  @Input() previousTransaction!: any;
  submitted: boolean = false;
  isManualTakePayment: boolean = false;
  loanTransactionDetails!: any;
  paymentCodeList: any;
  paymentMethodList: any;
  isButtonEnabled: boolean = false;
  selectedPaymentCode:any=null;
  isChargedOFF:boolean=false;

  // balanceAfter = {
  //   principal_payment: 0,
  //   interest_payment: 0,
  //   late_fees_payment: 0,
  //   nsf_fees_payment: 0,
  //   other_fees_payment: 0,
  //   // down_payment: 0,
  //   total_payment: 0
  // }

   balanceAfter: any = {
      principal_payment: 0,
      interest_payment: 0,
      late_fees_payment: 0,
      nsf_fees_payment: 0,
      other_fees_payment: 0,
      down_payment: 0,
      total_payment: 0
    }

  @ViewChild('paymentForm') formElement!: NgForm;
  takePaymentForm! : UntypedFormGroup
  isDirty:boolean = false;
  @ViewChild('totalFee') totalFee! : ElementRef;
  isOverAmount: boolean = false;
  isAmountValueExist: boolean = true;
  codetype:string='';
  current_date:string='';
  isFutureDate:boolean=false;
  disablePastDate:boolean=false;

  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private broadCastService:BroadcastService,
    private userService: UserService
  ) {

    this.codetype=this.isSpecialPermission();

  }

  ngOnInit(): void {
    // this.current_date=moment(new Date()).format("YYYY-MM-DD");
    this.current_date= moment(new Date()).tz('America/Los_Angeles').format("YYYY-MM-DD")
    this.getDif(this.current_date);
    if(this.editMode){
    this.takePaymentForm = new UntypedFormGroup({
      payment_info_code_id: new UntypedFormControl(null, [Validators.required]),
     // payment_effective_date: new FormControl(this.loanDetail?.last_nightly_process_date, [Validators.required]),
      payment_effective_date: new UntypedFormControl(this.current_date, [Validators.required]),

      payment_method_id: new UntypedFormControl(null, [Validators.required]),
      payment_amount: new UntypedFormControl('0.00', [Validators.required]),
      posting_method: new UntypedFormControl('ROLL_DUE_DATE', [Validators.required]),
      reference_note: new UntypedFormControl('',[Validators.maxLength(200),Validators.pattern('^(?!\\s*$).+'),Validators.minLength(4)]),
      principal_payment: new UntypedFormControl('0.00'),
      interest_payment: new UntypedFormControl('0.00'),
      late_fees_payment: new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      nsf_fees_payment: new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      other_fees_payment: new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      down_payment   : new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      total_payment: new UntypedFormControl('0.00'),
      payment_edit_reason: new UntypedFormControl('',[Validators.required,Validators.maxLength(100),Validators.pattern('^(?!\\s*$).+'),Validators.minLength(4)]),
      payment_received_method : new UntypedFormControl('RECEIVED_IN_MAIL', []),
    })
    this.takePaymentForm.valueChanges.subscribe((e) => (this.isDirty = true));
    this.getFirstTransactionDate();
   }
   if(!this.editMode){
    this.takePaymentForm = new UntypedFormGroup({
      payment_info_code_id    : new UntypedFormControl(null, [Validators.required]),
   // payment_effective_date: new FormControl(this.loanDetail?.last_nightly_process_date, [Validators.required]),
      payment_effective_date  : new UntypedFormControl(this.current_date, [Validators.required]),
      payment_method_id       : new UntypedFormControl(null, [Validators.required]),
      payment_amount          : new UntypedFormControl('0.00', [Validators.required]),
      posting_method          : new UntypedFormControl('ROLL_DUE_DATE', [Validators.required]),
      reference_note          : new UntypedFormControl('',[Validators.maxLength(200),Validators.pattern('^(?!\\s*$).+'),Validators.minLength(4)]),
      principal_payment       : new UntypedFormControl('0.00'),
      interest_payment        : new UntypedFormControl('0.00'),
      late_fees_payment       : new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      nsf_fees_payment        : new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      other_fees_payment      : new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      down_payment            : new UntypedFormControl('0.00', [Validators.required,this.feesRangeValidator]),
      total_payment           : new UntypedFormControl('0.00'),
      payment_edit_reason     : new UntypedFormControl(''),
      payment_received_method : new UntypedFormControl('RECEIVED_IN_MAIL', []),
    })
    this.takePaymentForm.valueChanges.subscribe((e) => (this.isDirty = true));
   }

    this.getPaymentCode();
    this.getPaymentMethod();
    this.getLoanTransactionDetails();
    // this.compareToPstDateTime();
  }

  getFirstTransactionDate(){
    const url = `${PathConfig.LOANS}/${this.loanID}`;
      this.httpService.get(url).subscribe((res) => {
        this.loanTransactionDetails.import_transaction_date = res?.import_transaction_date??'';
      })
  }


  // compareToPstDateTime(){
  //   const date = new Date();
  //   // var options = {year: 'numeric', month: 'long', day: 'numeric',timeZone: 'America/Los_Angeles',
  //   // hour: '2-digit',
  //   // minute: '2-digit',
  //   // hour12: false };
  //   const pst = date?.toLocaleString('en-US', {year: 'numeric', month: 'numeric', day: 'numeric',timeZone: 'America/Los_Angeles'});
  //   return pst;
  // }


  //function To Validate Payment Effective Date with 1st import date and future dates
  getDif(effdate:string){

    let firstImportDate=0;
    let today=moment.tz("America/Los_Angeles").format('YYYY-MM-DD');
    console.log(today);
    let tod=Date.parse(today?.toString());
    // console.log(tod)

    let dpast=tod;
    let selectedDate = Date.parse(effdate);

    if(this.loanTransactionDetails?.import_transaction_date){

      dpast = Date.parse(this.loanTransactionDetails?.import_transaction_date);
      let dpastTimeStamp = moment(new Date((dpast)))?.tz('America/Los_Angeles')?.format("YYYY-MM-DD");
      firstImportDate = new Date(dpastTimeStamp)?.getTime();
    }

    if(selectedDate>tod){

      this.isFutureDate=true;
      this.disablePastDate= false;
    }
    //converting selected date to PST for comparing
    else if(firstImportDate>(selectedDate-1000*60*60*8)){

      this.isFutureDate=false;
      this.disablePastDate=true;
    }
    else {

      this.isFutureDate=false;
      this.disablePastDate=false;
    }
  }



  isSpecialPermission():string{


            let codeType ='';
            const permisions: Array<any> = this.userService.PERMISSIONS;
            permisions.forEach((item: string) => {
                const modulePermission = `specialpermission:post_payment`
                if (item.indexOf(modulePermission) > - 1 ) {
                 if(item.indexOf('NON_CASH_PAYMENTS_ONLY') > -1)
                  codeType="NON-CASH";
                  else if(item.indexOf('CASH_PAYMENTS_ONLY') > -1)
                  codeType="CASH";
                  else if(item.indexOf('YES') > -1)
                  codeType="YES";
                  else
                  codeType="NO";

                }

            });
            return codeType;
        }

  // get loan detail
  getPaymentCode() {
    const url = `${PathConfig.PAYMENT_INFO_CODES}`;
    this.httpService.get(url).subscribe((res) => {

      let resp=res.filter((item:any)=>{
        return item.status==='ACTIVE';
      });

      this.paymentCodeList =this.codetype=='YES'?resp:resp?.filter((item:any)=>{
        return item.code_type===this.codetype;
      });

      // If payment code is INACTIVE then add this code in paymentCodeList
      if(this.editMode)
      {
        if(!this.isPaymentCodeExistInList(this.transactionDetails?.payment_info_code_id))
         {
          this.paymentCodeList.push(this.transactionDetails?.payment_info_code);
          // Sort the array in ascending order based on the 'code' property
          this.paymentCodeList.sort((a:any, b:any) => a.code.localeCompare(b.code));
         }
      }

    });
  }

  // get loan detail
  getPaymentMethod() {
    const url = `${PathConfig.PAYMENT_METHODS}`;
    this.httpService.get(url).subscribe((res) => {
      this.paymentMethodList = res;
    })
  }

  // get loan detail
  getLoanTransactionDetails() {
    this.isManualTakePayment = (this.transactionDetails?.default_breakdown == "NO" ? true : false);
    if(this.editMode) {
    this.isManualTakePayment = (this.transactionDetails?.payment_info_code?.slug == "WAIVE" ? true : this.isManualTakePayment);


      this.loanTransactionDetails = this.transactionDetails;
      if(this.loanTransactionDetails){
      if(this.loanTransactionDetails?.payment_info_code?.id){
        this.loanTransactionDetails.payment_info_code_id = this.loanTransactionDetails?.payment_info_code?.id;
      } else{
        this.loanTransactionDetails.payment_info_code_id = null;
      }
      if(this.loanTransactionDetails?.payment_method?.id){
        this.loanTransactionDetails.payment_method_id = this.loanTransactionDetails?.payment_method?.id;
      } else{
        this.loanTransactionDetails.payment_method_id = null;
      }
    }

      if(this.loanTransactionDetails.payment_amount != '' && this.loanTransactionDetails.payment_amount != null)
      {(this.loanTransactionDetails.payment_amount = parseFloat(this.loanTransactionDetails.payment_amount).toFixed(2));}
      else{this.loanTransactionDetails.payment_amount = '0.00';}
      if(this.loanTransactionDetails.principal_payment != '' && this.loanTransactionDetails.principal_payment != null)
      {(this.loanTransactionDetails.principal_payment = parseFloat(this.loanTransactionDetails.principal_payment).toFixed(2));}
      else{this.loanTransactionDetails.principal_payment = '0.00';}
      if(this.loanTransactionDetails.interest_payment != '' && this.loanTransactionDetails.interest_payment != null)
      {(this.loanTransactionDetails.interest_payment = parseFloat(this.loanTransactionDetails.interest_payment).toFixed(2));}
      else{this.loanTransactionDetails.interest_payment = '0.00';}
      if(this.loanTransactionDetails.late_fees_payment != '' && this.loanTransactionDetails.late_fees_payment != null)
      {(this.loanTransactionDetails.late_fees_payment = parseFloat(this.loanTransactionDetails.late_fees_payment).toFixed(2));}
      else{this.loanTransactionDetails.late_fees_payment = '0.00';}
      if(this.loanTransactionDetails.nsf_fees_payment != '' && this.loanTransactionDetails.nsf_fees_payment != null)
      {(this.loanTransactionDetails.nsf_fees_payment = parseFloat(this.loanTransactionDetails.nsf_fees_payment).toFixed(2));}
      else{this.loanTransactionDetails.nsf_fees_payment = '0.00';}
      if(this.loanTransactionDetails.other_fees_payment != '' && this.loanTransactionDetails.other_fees_payment != null)
      {(this.loanTransactionDetails.other_fees_payment = parseFloat(this.loanTransactionDetails.other_fees_payment).toFixed(2));}
      else{this.loanTransactionDetails.other_fees_payment = '0.00';}
      if(this.loanTransactionDetails.down_payment != '' && this.loanTransactionDetails.down_payment != null)
      {(this.loanTransactionDetails.down_payment = parseFloat(this.loanTransactionDetails.down_payment).toFixed(2));}
      else{this.loanTransactionDetails.down_payment = '0.00';}
      if(this.loanTransactionDetails.total_payment != '' && this.loanTransactionDetails.total_payment != null)
      {(this.loanTransactionDetails.total_payment = parseFloat(this.loanTransactionDetails.total_payment).toFixed(2));}
      else{this.loanTransactionDetails.total_payment = '0.00';}

      //this.loanTransactionDetails.payment_received_method = this.loanTransactionDetails.payment_received_method ?? 'RECEIVED_IN_MAIL';
      console.log(this.loanTransactionDetails);
      this.takePaymentForm.patchValue(this.loanTransactionDetails);


      let effectivedate;
      if(![null,undefined,''].includes(this.loanTransactionDetails?.payment_effective_date) && this.loanTransactionDetails?.payment_info_code?.slug=='WAIVE')
      {
      var temp_date = moment(this.loanTransactionDetails?.payment_effective_date, 'YYYY-MM-DD')  ;
      effectivedate=  temp_date?.format('MM/DD/YYYY')??'';
      this.takePaymentForm.patchValue({payment_effective_date:effectivedate});
      this.takePaymentForm.updateValueAndValidity();
      }

      const url = `${PathConfig.LOANS}/${this.loanID}`;
      this.httpService.get(url).subscribe((res) => {
        this.loanDetail = res;

        this.isChargedOFF=this.loanDetail?.status=='CHARGED-OFF'?true:false;

        // if(this.loanTransactionDetails?.payment_info_code?.slug=='WAIVE') {

        //   this.takePaymentForm?.controls?.payment_amount?.clearValidators();
        //   this.takePaymentForm?.controls?.payment_amount?.setValidators([Validators.required,this.totalFeesRangeValidator]);

        //   this.balanceAfter.principal_payment = ((this.previousTransaction?.remaining_principal_balance + parseFloat(this.loanTransactionDetails?.principal_payment)).toFixed(2));
        //   this.balanceAfter.interest_payment = ((this.previousTransaction?.remaining_interest_balance + parseFloat(this.loanTransactionDetails?.interest_payment)).toFixed(2));
        //   this.balanceAfter.late_fees_payment = ((this.previousTransaction?.remaining_late_fees_balance + parseFloat(this.loanTransactionDetails?.late_fees_payment)).toFixed(2));
        //   this.balanceAfter.nsf_fees_payment = ((this.previousTransaction?.remaining_nsf_fees_balance + parseFloat(this.loanTransactionDetails?.nsf_fees_payment)).toFixed(2));
        //   this.balanceAfter.other_fees_payment = ((this.previousTransaction?.remaining_other_fees_balance + parseFloat(this.loanTransactionDetails?.other_fees_payment)).toFixed(2));
        //   // this.balanceAfter.down_payment = ((this.previousTransaction?.remaining_down_payment_balance + parseFloat((this.loanTransactionDetails?.down_payment)).toFixed(2));

        // }
        // else {

          this.takePaymentForm?.controls?.payment_amount?.clearValidators();
          this.takePaymentForm?.controls?.payment_amount?.setValidators([Validators.required]);

          this.balanceAfter.principal_payment = parseFloat((this.previousTransaction?.remaining_principal_balance - this.loanTransactionDetails?.principal_payment).toFixed(2));
          this.balanceAfter.interest_payment = parseFloat((this.previousTransaction?.remaining_interest_balance - this.loanTransactionDetails?.interest_payment).toFixed(2));
          this.balanceAfter.late_fees_payment = parseFloat((this.previousTransaction?.remaining_late_fees_balance - this.loanTransactionDetails?.late_fees_payment).toFixed(2));
          this.balanceAfter.nsf_fees_payment = parseFloat((this.previousTransaction?.remaining_nsf_fees_balance - this.loanTransactionDetails?.nsf_fees_payment).toFixed(2));
          this.balanceAfter.other_fees_payment = parseFloat((this.previousTransaction?.remaining_other_fees_balance - this.loanTransactionDetails?.other_fees_payment).toFixed(2));
          this.balanceAfter.down_payment = parseFloat((this.previousTransaction?.remaining_down_payment_balance - this.loanTransactionDetails?.down_payment).toFixed(2));

        // }

        this.takePaymentForm?.controls?.payment_amount?.updateValueAndValidity();

      })
      this.customValidators();
    } else {
      this.balanceAfter.principal_payment = parseFloat((this.previousTransaction?.remaining_principal_balance).toFixed(2));
      this.balanceAfter.interest_payment = parseFloat((this.previousTransaction?.remaining_interest_balance).toFixed(2));
      this.balanceAfter.late_fees_payment = parseFloat((this.previousTransaction?.remaining_late_fees_balance).toFixed(2));
      this.balanceAfter.nsf_fees_payment = parseFloat((this.previousTransaction?.remaining_nsf_fees_balance).toFixed(2));
      this.balanceAfter.other_fees_payment = parseFloat((this.previousTransaction?.remaining_other_fees_balance).toFixed(2));
      this.balanceAfter.down_payment = parseFloat((this.previousTransaction?.remaining_down_payment_balance).toFixed(2));

      const url = `${PathConfig.LOANS}/${this.loanID}`;
      this.httpService.get(url).subscribe((res) => {
        this.loanTransactionDetails = res;
      })
    }
    this.checkMainAmount();
    if(this.loanTransactionDetails?.posting_method){
      this.takePaymentForm.patchValue({posting_method:this.loanTransactionDetails?.posting_method});
    }
  }

  toggleTakeMethodType() {
    this.isManualTakePayment = !this.isManualTakePayment;
  }

  checkMainAmount () {
    let val = this.takePaymentForm.get('payment_amount')?.value;
    this.takePaymentForm.get('payment_amount')?.setValue(this.checkForNan(val));

    this.isAmountValueExist = (val > 0 || val != '') ? true : false;
    if(this.isAmountValueExist) {
      this.takePaymentForm.get('principal_payment')?.enable();
      this.takePaymentForm.get('interest_payment')?.enable();
      this.takePaymentForm.get('late_fees_payment')?.enable();
      this.takePaymentForm.get('nsf_fees_payment')?.enable();
      this.takePaymentForm.get('other_fees_payment')?.enable();
      this.takePaymentForm.get('down_payment')?.enable();
    } else {
      this.takePaymentForm.get('principal_payment')?.disable();
      this.takePaymentForm.get('interest_payment')?.disable();
      this.takePaymentForm.get('late_fees_payment')?.disable();
      this.takePaymentForm.get('nsf_fees_payment')?.disable();
      this.takePaymentForm.get('other_fees_payment')?.disable();
      this.takePaymentForm.get('down_payment')?.disable();
    }
    let total = this.getTotal();

    this.isOverAmount = (this.takePaymentForm.get('payment_amount')?.value == total?.toFixed(2)) ? false : true;

  }

  calculateBalance(e: any) {

    let name = e.target.name;

    // //waive type transaction
    // if(this.loanTransactionDetails?.payment_info_code?.slug=='WAIVE') {

    //   switch (name) {
    //     // case 'principal_payment':
    //     //   this.balanceAfter.principal_payment = (e.target.value !== '' && e.target.value !== 0) ?
    //     //   ((this.previousTransaction?.remaining_principal_balance + parseFloat(e.target.value))) : parseFloat(this.previousTransaction?.remaining_principal_balance);
    //     //   this.takePaymentForm.get('principal_payment')?.setValue(this.checkForNan(e.target.value));
    //     //   break;
    //     // case 'interest_payment':
    //     //   this.balanceAfter.interest_payment = (e.target.value !== '' && e.target.value !== 0  ) ?
    //     //   ((this.previousTransaction?.remaining_interest_balance + parseFloat(e.target.value))) : parseFloat(this.previousTransaction?.remaining_interest_balance);
    //     //   this.takePaymentForm.get('interest_payment')?.setValue(this.checkForNan(e.target.value));
    //     //   break;
    //     case 'late_fees_payment':
    //       this.balanceAfter.late_fees_payment = (e.target.value !== '' && e.target.value !== 0 ) ?
    //       ((this.previousTransaction?.remaining_late_fees_balance + parseFloat(e.target.value))) : parseFloat(this.previousTransaction?.remaining_late_fees_balance);
    //       this.takePaymentForm.get('late_fees_payment')?.setValue(this.checkForNan(e.target.value));
    //       this.balanceAfter.late_fees_payment = this.checkForNan(this.balanceAfter.late_fees_payment);

    //       break;
    //     case 'nsf_fees_payment':
    //       this.balanceAfter.nsf_fees_payment = (e.target.value !== '' && e.target.value !== 0 ) ?
    //       ((this.previousTransaction?.remaining_nsf_fees_balance + parseFloat(e.target.value))) : parseFloat(this.previousTransaction?.remaining_nsf_fees_balance);
    //       this.takePaymentForm.get('nsf_fees_payment')?.setValue(this.checkForNan(e.target.value));
    //       this.balanceAfter.nsf_fees_payment = this.checkForNan(this.balanceAfter.nsf_fees_payment);

    //       break;
    //     case 'other_fees_payment':
    //       this.balanceAfter.other_fees_payment = (e.target.value !== '' && e.target.value !== 0 ) ?
    //       ((this.previousTransaction?.remaining_other_fees_balance + parseFloat(e.target.value))) : parseFloat(this.previousTransaction?.remaining_other_fees_balance);
    //       this.takePaymentForm.get('other_fees_payment')?.setValue(this.checkForNan(e.target.value));
    //       this.balanceAfter.other_fees_payment = this.checkForNan(this.balanceAfter.other_fees_payment);

    //       break;
    //     // case 'down_payment':
    //     //   this.balanceAfter.down_payment = (e.target.value !== '' && e.target.value !== 0) ?
    //     //   parseFloat((this.previousTransaction?.remaining_down_payment_balance + e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_down_payment_balance);
    //     //   this.takePaymentForm.get('down_payment')?.setValue(this.checkForNan(e.target.value));
    //     //   break;

    //   }

    // }

    // //all other payment types
    // else {

      switch (name) {
        case 'principal_payment':
          this.balanceAfter.principal_payment = (e.target.value !== '' && e.target.value !== 0) ?
          parseFloat((this.previousTransaction?.remaining_principal_balance - e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_principal_balance);
          this.takePaymentForm.get('principal_payment')?.setValue(this.checkForNan(e.target.value));
          break;
        case 'interest_payment':
          this.balanceAfter.interest_payment = (e.target.value !== '' && e.target.value !== 0  ) ?
          parseFloat((this.previousTransaction?.remaining_interest_balance - e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_interest_balance);
          this.takePaymentForm.get('interest_payment')?.setValue(this.checkForNan(e.target.value));
          break;
        case 'late_fees_payment':
          this.balanceAfter.late_fees_payment = (e.target.value !== '' && e.target.value !== 0   ) ?
          parseFloat((this.previousTransaction?.remaining_late_fees_balance - e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_late_fees_balance);
          this.takePaymentForm.get('late_fees_payment')?.setValue(this.checkForNan(e.target.value));
          break;
        case 'nsf_fees_payment':
          this.balanceAfter.nsf_fees_payment = (e.target.value !== '' && e.target.value !== 0   ) ?
          parseFloat((this.previousTransaction?.remaining_nsf_fees_balance - e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_nsf_fees_balance);
          this.takePaymentForm.get('nsf_fees_payment')?.setValue(this.checkForNan(e.target.value));
          break;
        case 'other_fees_payment':
          this.balanceAfter.other_fees_payment = (e.target.value !== '' && e.target.value !== 0 ) ?
          parseFloat((this.previousTransaction?.remaining_other_fees_balance - e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_other_fees_balance);
          this.takePaymentForm.get('other_fees_payment')?.setValue(this.checkForNan(e.target.value));
          break;
        case 'down_payment':
          this.balanceAfter.down_payment = (e.target.value !== '' && e.target.value !== 0) ?
          parseFloat((this.previousTransaction?.remaining_down_payment_balance - e.target.value).toFixed(2)) : parseFloat(this.previousTransaction?.remaining_down_payment_balance);
          this.takePaymentForm.get('down_payment')?.setValue(this.checkForNan(e.target.value));
          break;

      }

    // }

    let total = this.getTotal();

    this.takePaymentForm.get('total_payment')?.setValue(this.checkForNan(total));

    this.isOverAmount = (this.takePaymentForm.get('payment_amount')?.value == total?.toFixed(2)) ? false : true;

  }

  checkForNan(num:any){
    if(!isNaN(num) && num!=''){
      return this.roundToDecimal(parseFloat(num),2);
    }
    else {
      return parseFloat('0.00')?.toFixed(2);
    }
  }

  roundToDecimal(num:any, length:any) {
    return (new Decimal(Number(num))).toFixed(parseFloat(length) || 0);
  }

    getTotal () {

      let total = 0;
      total += (!isNaN(this.takePaymentForm.get('principal_payment')?.value) && this.takePaymentForm.get('principal_payment')?.value != ''
      && this.takePaymentForm.get('principal_payment')?.value != null) ? parseFloat(this.takePaymentForm.get('principal_payment')?.value) : 0;

      total += (!isNaN(this.takePaymentForm.get('interest_payment')?.value) && this.takePaymentForm.get('interest_payment')?.value != ''
      && this.takePaymentForm.get('interest_payment')?.value != null) ? parseFloat(this.takePaymentForm.get('interest_payment')?.value) : 0;

      total += (!isNaN(this.takePaymentForm.get('late_fees_payment')?.value) && this.takePaymentForm.get('late_fees_payment')?.value != ''
      && this.takePaymentForm.get('late_fees_payment')?.value != null) ? parseFloat(this.takePaymentForm.get('late_fees_payment')?.value) : 0;

      total += (!isNaN(this.takePaymentForm.get('nsf_fees_payment')?.value) && this.takePaymentForm.get('nsf_fees_payment')?.value != ''
      && this.takePaymentForm.get('nsf_fees_payment')?.value != null) ? parseFloat(this.takePaymentForm.get('nsf_fees_payment')?.value) : 0;

      total += (!isNaN(this.takePaymentForm.get('other_fees_payment')?.value) && this.takePaymentForm.get('other_fees_payment')?.value != ''
      && this.takePaymentForm.get('other_fees_payment')?.value != null) ? parseFloat(this.takePaymentForm.get('other_fees_payment')?.value) : 0;

      total += (!isNaN(this.takePaymentForm.get('down_payment')?.value) && this.takePaymentForm.get('down_payment')?.value != '' 
      && this.takePaymentForm.get('down_payment')?.value != null) ? parseFloat(this.takePaymentForm.get('down_payment')?.value) : 0;

      return total;

    }

  onTakePaymentSubmit () {
    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

  onSubmit () {
    this.isButtonEnabled = (this.takePaymentForm.valid && !this.isOverAmount)? true : false;

    console.log(this.isOverAmount,this.isManualTakePayment)

    if(this.takePaymentForm.valid && !this.submitted && (!this.isOverAmount || !this.isManualTakePayment)) {

      this.submitted = true;
      const params = this.takePaymentForm.value;
      params.loan_id = this.loanID;
      params.default_breakdown = this.isManualTakePayment ? 'NO' : 'YES';
      params.tax_payment=0;
      if(params.payment_effective_date){
        params.payment_effective_date_local= params.payment_effective_date;
        params.payment_effective_date_pst=moment(new Date(params.payment_effective_date)).tz('America/Los_Angeles').format("YYYY-MM-DD");
        // if(params.payment_effective_date!=){
        // params.payment_effective_date=moment(new Date(params.payment_effective_date)).tz('America/Los_Angeles').format("YYYY-MM-DD");
        // }
      }
      if(this.loanTransactionDetails?.payment_info_code?.slug=='WAIVE')
      {
        delete params.payment_received_method;
        delete params.posting_method;
      }

      if(!this.isManualBreakdownAllowed){
        params.default_breakdown = 'YES';
        this.isManualTakePayment = false;
        console.log("params.default_breakdown = 'YES';");
      }
      params.cpi_fees_payment = 0;
      params.side_note_1_fees_payment = 0;
      params.side_note_2_fees_payment = 0;
      params.side_note_3_fees_payment = 0;
      params.side_note_4_fees_payment = 0;
      params.side_note_5_fees_payment = 0;
      // params.posting_method=this.loanDetail?.status=='CHARGED-OFF'?'DO_NOT_ROLL_DUE_DATE':params.posting_method;
      const url = this.editMode ? `${PathConfig.LOAN_TRANSACTIONS}/${this.id}` : `${PathConfig.LOAN_TRANSACTIONS}`;
      const http = this.editMode ? this.httpService.put(url, params) : this.httpService.post(url, params)

      http.subscribe((response: any) => {
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
        this.modalService.dismissAll();
        this.msgService.showSuccess(response?.message);
        this.submitted = false;
        this.isDirty = false;
        // window.location.reload();
      },
        (err) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.submitted = false;
        });
    }
  }

  customValidators(){
    const selectedPaymentCodeId = this.takePaymentForm?.controls?.payment_info_code_id?.value;

    this.selectedPaymentCode = this.paymentCodeList?.find((item: any) => item?.id === selectedPaymentCodeId);

    if(this.selectedPaymentCode == undefined){
      this.selectedPaymentCode = this.loanTransactionDetails.payment_info_code;
    }
    if(this.selectedPaymentCode?.code_type === "CASH"){
      this.takePaymentForm?.controls?.payment_method_id?.setValidators([Validators.required]);
    }
    else{
      this.takePaymentForm?.controls?.payment_method_id?.clearValidators();
    }

    if(this.selectedPaymentCode?.posting_method){
      this.takePaymentForm.patchValue({posting_method:this.selectedPaymentCode?.posting_method});
    }

    if(this.selectedPaymentCode){

      if(!this.isManualBreakdownAllowed()){
        this.isManualTakePayment = false;
      }

    }


    this.takePaymentForm?.controls?.payment_method_id?.updateValueAndValidity();
  }

  onPaymentInfoCodeChange(){

  }


  feesRangeValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = value == '' || ( value >= -10000 && value <= 10000 )  ;
    return isValid ? null : { currencyRange: true };
  }

  totalFeesRangeValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = value == '' || ( value >= -30000 && value <= 30000 )  ;
    return isValid ? null : { currencyRange: true };
  }

  //To check Payment Code is exist or not in paymentCodeList Array
  isPaymentCodeExistInList(targetId: number){
    const targetObject = this.paymentCodeList.find((item:any) => item.id === targetId);
    if (targetObject) {
      return true;
    } else {
      return false;
    }
  }
  
  isManualBreakdownAllowed(){
    if(this.editMode){

      // if(this.loanTransactionDetails?.manual_waterfall_breakdown_allowed){
        if(this.loanDetail?.status=='ACTIVE' || this.loanDetail?.status=='PAID-OFF'){
          if(this.selectedPaymentCode?.manual_waterfall_allowed_for_active){
            return true;
          }
          else 
            return false;
        }
        else if(this.loanDetail?.status=='CHARGED-OFF'){
          if(this.selectedPaymentCode?.manual_waterfall_allowed_for_charge_off){
            return true;
          }
          else
            return false;
        }
        else
          return false;
      // }
      // else
      //   return false;
    }
    else{

      if(this.loanDetail?.status=='ACTIVE' || this.loanDetail?.status=='PAID-OFF'){
        if(this.selectedPaymentCode?.manual_waterfall_allowed_for_active){
          return true;
        }
        else 
          return false;
      }
      else if(this.loanDetail?.status=='CHARGED-OFF'){
        if(this.selectedPaymentCode?.manual_waterfall_allowed_for_charge_off){
          return true;
        }
        else
          return false;
      }
      else
        return false;
    }
  }

}

