<div class="lms-card mb-3">
    <div class="row">
      <div class="col">
        <h3 class="lms-card-heading module-heading-success">{{"Welcome To Lendisoft"|translate}}!</h3>
      </div>      
    </div>
    <div class="lms-description">
      <p>{{"Select a page from the menu on the left"|translate}}. </p>
    </div>
  </div>

<!--<h1 class="text-center pt-5">Welcome To Lendisoft !</h1>-->
