import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth-service';
import { ClientDetailService } from '../services/client-detail-service';

@Injectable({
  providedIn: 'root'
})
class PermissionsService {
  clientId!: string;
  constructor(private auth: AuthService, private router: Router,private clientDetailService:ClientDetailService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const clientId = this.clientDetailService.CLIENT_ID;
    const isResetPasswordURL = state.url.indexOf('reset_password') > -1 ? true : false;
    const isResetPinURL = state.url.indexOf('resetpin') > -1 ? true : false;
    if (this.auth.isAuthenticated() && !isResetPasswordURL && !isResetPinURL) {
      this.router.navigate(['/', clientId, 'home']);
      return of(false);
    }
    return of(true);
  }
}
export const VerifyAuthenticationGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  return inject(PermissionsService).canActivate(next, state);
}
