import { ActivatedRoute, Router } from "@angular/router";
import { ClientDetailService } from "../services/client-detail-service";
import { UserService } from "../services/user.service";

export class ClientUtils {

    constructor() { }

    public static getClientSlug(activatedRoute: ActivatedRoute, router :Router){
        let clientId;
        activatedRoute.paramMap.subscribe(params => {
            clientId = params.get('clientId') || '';
        });
        if(!clientId){
            let url = router.url.split("/");
            if(url.length > 1){
                clientId = url[1];
            }
        }
        return clientId;
    }

    public static isLoginAsClient(clientDetailService : ClientDetailService, userService : UserService){
        return clientDetailService.IS_DEFAULT == 'NO' && userService.getLoginType(clientDetailService.CLIENT_ID);
    }
}
