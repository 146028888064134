<section class="login-section">
    <!-- Login Header Top Start -->
    <div class="login-header">
      <div class="row">
        <div class="col-sm">
          <div class="header">
            <img [src]="logo" height="38" />
          </div>
        </div>
        <div class="language-dropdown">
          <div class="dropdown">
            <button class="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false" (click)="toggleDropdown()">
              <img class="icon-custom" [src]="selectedLanguageIcon" alt="Selected Language Icon" aria-hidden="true" />
              <span class="lang-text">{{ getSelectedLanguageValue() }}</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" [class.show]="dropdownOpen">
              <a class="dropdown-item" *ngFor="let language of languages" 
                 (click)="changeLanguage(language)" 
                 [class.selected]="selectedLanguage === language.selectedLanguage"
                 [title]="language.languageValue"
                 href="javascript:void(0)">
                <span>
                  <img class="icon-custom" src="{{language.icon}}" [alt]="language.languageValue">
                </span>
                {{language.languageValue}}
              </a>
            </div>
          </div>
        </div>
        <div>
        <div class="col-sm-auto">
          <div class="date-time text-white">
              <span class="date ">{{currentDate}}, </span>
              <span class="time">{{currentTime}}</span>
          </div>
        </div>

        </div>
      </div>
    </div>
    <!-- Login Header Top End -->

    <!-- Main Page Heading Start -->
    <div class="main-page-heading text-center d-none">
      <div class="row">
        <div class="col-md-12">
          <h1 class="page-heading mb-0">{{sectionTitle|translate}}</h1>
        </div>
      </div>
    </div>
    <!-- Main Page Heading End -->
    <!-- Login Form Wrapper -->
    <div class="login-form-wrapper">
        <div class="row no-gutters login-form-inner">
          <div class="col-xl-8 col-lg-7">
            <div class="login-detail-content text-white p-5">
              <h3 class="sub-title">{{"Introducing"|translate}}</h3>
              <h2 class="title mb-5">{{"Lendisoft Software"|translate}}</h2>
              <div class="description">
                <p>
                  {{"Built by a team of risk management professionals with decades of experience in the lending industry"|translate}}.
                </p>
                <p>
                  {{"Simply put, Lendisoft gives you the ultimate competitive advantage!"|translate}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>

    <!-- Footer Block -->
    <div class="login-footer text-center">
      <div class="row">
        <div class="col-md-12">
          &copy; {{currentYear}} {{"Lendisoft. All rights reserved."|translate}}
        </div>
      </div>
    </div>
  </section>
