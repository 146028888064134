// src/app/shared/directives/trim-on-blur.directive.ts
import { Directive, ElementRef, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[appTrimOnBlur]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TrimOnBlurDirective,
      multi: true
    }
  ]
})
export class TrimOnBlurDirective implements ControlValueAccessor {
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  constructor(private el: ElementRef) {}

  @HostListener('blur')
  onBlur() {
    const trimmedValue = (this.el.nativeElement.value || '').trim();
    this.el.nativeElement.value = trimmedValue;
    this.onChange(trimmedValue);  // Update form control with trimmed value
    this.onTouched(); 
  }

  writeValue(value: string): void {
    this.el.nativeElement.value = value || '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.el.nativeElement.disabled = isDisabled;
  }
}
