import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import * as _ from 'lodash';
import { Utility } from 'src/app/core/utils/utility';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import Decimal from 'decimal.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-waive-fee',
  templateUrl: './waive-fee.component.html',
  styleUrls: ['./waive-fee.component.scss']
})
export class WaiveFeeComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  submitted: boolean = false;

  @ViewChild('waiveFeeForm') formElement!: NgForm;
  waiveFeesForm!: UntypedFormGroup;
  isWaiveFeeExceed: boolean = false;
  balanceAfter: any = {
    late_fees: 0,
    nsf_fees: 0,
    other_fees: 0
  }

  lfees:any=0;
  nfees:any=0;
  ofees:any=0;
  isLower:boolean=false;


  isDirty:boolean = false;
  errorMsg: string = '';
  waivePermistion:boolean=false;
  form!: UntypedFormGroup;
  boadCasterService: any;
  queueType:string = '';
  sortBy:string='';
  orderBy:string='';
  pageLimit:number=0;
  currentPage:number=0;
  isBeforFees:boolean=false;
  loanLateFee: any = 0;
  loanNsfFee: any = 0;
  loanOtherFee: any = 0;

  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    public activeModal: NgbActiveModal,
    private route: Router,
    private permissionService:PermissionService,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    this.waivePermistion=this.permissionService.isSpecialPermission('waive_fees');

    this.activatedRoute.queryParams
    .subscribe(params => {
      this.queueType = params['qtype']==null?"":params['qtype'];
      this.sortBy=params['sortby']==null?"":params['sortby'];
      this.orderBy=params['orderby']==null?"":params['orderby'];
      this.pageLimit=params['limit']==null?"":params['limit'];
      this.currentPage=params['page']==null?"":params['page'];

    });
  }

  ngOnInit(): void {
    this.waiveFeesForm = new UntypedFormGroup({
      late_fees: new UntypedFormControl('0.00', [this.currencyRangeValidator,Validators.required,Validators.pattern("-?\\d+(?:\\.\\d+)?")]),
      nsf_fees: new UntypedFormControl('0.00', [this.currencyRangeValidator,Validators.required,Validators.pattern("-?\\d+(?:\\.\\d+)?")]),
      other_fees: new UntypedFormControl('0.00', [this.currencyRangeValidator,Validators.required,Validators.pattern("-?\\d+(?:\\.\\d+)?")]),
      reference_note: new UntypedFormControl('',[Validators.maxLength(200),Validators.pattern('^(?!\\s*$).+'),Validators.minLength(4)]),
    });
    this.waiveFeesForm.valueChanges.subscribe((e) => (this.isDirty = true));
    this.loanLateFee = parseFloat(this.loanDetail?.account_late_fees_balance);
    this.loanNsfFee = parseFloat(this.loanDetail?.account_nsf_fees_balance);
    this.loanOtherFee = parseFloat(this.loanDetail?.account_other_fees_balance);


    //form controls

this.form = new UntypedFormGroup({
  // id: new FormControl(null, [Validators.required]),
  employee_initials: new UntypedFormControl(null, [Validators.required]),
   pin: new UntypedFormControl(null, [Validators.required]),
 });


 this.loanID = Number(this.route.url.split('/')[5]);
 this.getLoanDetailByID(this.loanID);
  }


 // get loan detail
 getLoanDetailByID(id: Number) {

  const url = `${PathConfig.LOANS}/${id}?sort_by=${this.sortBy}&sort_order=${this.orderBy}&limit=${this.pageLimit}&${this.queueType}=1&page=${this.currentPage}`;

  this.httpService.get(url).subscribe((res) => {
    this.loanDetail = res;
    // this.nfees=this.loanDetail?.nsf_fees_payment;
    // this.lfees=this.loanDetail?.late_fees_payment;
    // this.ofees=this.loanDetail?.other_fees_payment ;

    setTimeout(() => this.updateAfterValues(), 500);

  });
}


feesEntered(e:any,ftype:string)
{
  // this.checkFees();
  this.updateAfterValues();
}

  updateAfterValues() {
    let lateFee = this.waiveFeesForm.get('late_fees')?.value?.trim();
    let nsfFee = this.waiveFeesForm.get('nsf_fees')?.value?.trim();
    let otherFee = this.waiveFeesForm.get('other_fees')?.value?.trim();

    if(lateFee !=null && !isNaN(lateFee) && lateFee!= '' ) {
      lateFee = parseFloat(lateFee);
      this.waiveFeesForm.get('late_fees')?.setValue(this.checkForNan(lateFee));
      this.balanceAfter.late_fees = this.loanLateFee + lateFee;
    }

    if(nsfFee != null && !isNaN(nsfFee) && nsfFee != '' ) {
      nsfFee = parseFloat(nsfFee);
      this.waiveFeesForm.get('nsf_fees')?.setValue(this.checkForNan(nsfFee));
      this.balanceAfter.nsf_fees = this.loanNsfFee + nsfFee;
    }

    if(otherFee != null && !isNaN(otherFee) && otherFee != '' ) {
      otherFee = parseFloat(otherFee);
      this.waiveFeesForm.get('other_fees')?.setValue(this.checkForNan(otherFee));
      this.balanceAfter.other_fees = this.loanOtherFee + otherFee;
    }
  }

  onWaiveSubmit() {
    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

  verifyPin()
  {
    if(this.form.valid)
      {
        const params = this.form.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.waivePermistion=true;
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.form.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }


  }


  openOverrideModal(content:any)
  {
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'sm',
      windowClass:'draggable-container',
      backdrop:false
    });
  }


  // checkFees () {
  //   let lateFee = parseFloat(this.waiveFeesForm.get('late_fees')?.value);
  //   let nsfFee = parseFloat(this.waiveFeesForm.get('nsf_fees')?.value);
  //   let otherFee = parseFloat(this.waiveFeesForm.get('other_fees')?.value);



  //   this.isWaiveFeeExceed = true;
  //   if((this.loanLateFee > 0 && lateFee > this.loanLateFee) || lateFee < 0) {
  //     this.errorMsg = 'Late Fee Waive can not be more than the balance or less than 0'
  //     this.isWaiveFeeExceed = true;
  //   } else if ((this.loanNsfFee > 0 && nsfFee > this.loanNsfFee) || nsfFee < 0) {
  //     this.errorMsg = 'NSF Fee Waive can not be more than the balance  or less than 0'
  //     this.isWaiveFeeExceed = true;
  //   } else if ((this.loanOtherFee > 0 && otherFee > this.loanOtherFee) || otherFee < 0) {
  //     this.errorMsg = 'Other Fee Waive can not be more than the balance  or less than 0'
  //     this.isWaiveFeeExceed = true;
  //   }
  //   else if (lateFee === 0 && nsfFee === 0 && otherFee === 0){
  //     this.errorMsg = 'Nothing to waive off, please enter at least some amount'
  //     this.isWaiveFeeExceed = true;
  //   }
  //   else {
  //     this.isWaiveFeeExceed = false;
  //   }
  // }

  onSubmit() {
    // this.checkFees()
    if(this.waiveFeesForm.valid && !this.submitted ) {
      this.submitted = true;
      const paramsWaiveFee = this.waiveFeesForm.value;
      paramsWaiveFee.loan_id = this.loanID;
      const url = `${PathConfig.WAIVE_OFF_FEE}/${this.loanID}`;
      this.httpService.post(url, paramsWaiveFee).subscribe((response: any) => {
        // if(response && response.forecasted_payment) {
        //   this.balanceAfter = {
        //     late_fees: response.forecasted_payment.late_fees,
        //     nsf_fees: response.forecasted_payment.nsf_fees,
        //     other_fees: response.forecasted_payment.other_fees,
        //   }
        // }
        // this.modalService.dismissAll();
        this.msgService.showSuccess(response?.message);
        this.submitted = false;
        this.isDirty = false;
        // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
        this.activeModal.dismiss('Cross click');
      },
        (err) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.submitted = false;
          this.errorMsg = 'Unable to waive fees, something went wrong';
          this.isWaiveFeeExceed = true;
        });
    }
  }

  currencyRangeValidator(control: AbstractControl) {
    const value = control.value;
    // let string = value??'';
    // let findString = ".";
    // let index = string.lastIndexOf(".");
    // let validCurrency = true;
    // let count = string.split(findString).length - 1;
    // if(count == 1 ) {
    //   validCurrency = false;
    //   if(((string.length-1)-index) <= 2 ){
    //     validCurrency = true;
    //   }
    // }
    const isValid = value == '' || ( value >= -10000 && value <= 10000 )  ;
    return isValid ? null : { currencyRange: true };
  }

  checkForNan(num:any){
    if(!isNaN(num) && num!=''){
      return this.roundToDecimal(parseFloat(num),2);
    }
    else {
      return parseFloat('0.00')?.toFixed(2);
    }
  }

  roundToDecimal(num:any, length:any) {
    return (new Decimal(Number(num))).toFixed(parseFloat(length) || 0);
  }

}
