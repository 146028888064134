import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
Total Validator
 */
export function TotalValidator(total: number,type:string,field:string = ''): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let groupTotal:any  = 0;
    if(type == 'form-group'){
      groupTotal = Object.values(control.value).reduce((previousValue:any, currentValue:any) => Number(previousValue) + Number(currentValue),0);
    }else{
      groupTotal = control.value.reduce((previousValue:any, currentValue:any) => Number(previousValue[field]) + Number(currentValue[field]),0);
    }
    if (groupTotal != total) {
      return {arrayTotal: true};
    }
    return null;
  }; 
}