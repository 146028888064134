import { Component, Input, OnInit } from '@angular/core';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PathConfig } from 'src/app/core/config/path.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { Utility } from 'src/app/core/utils/utility';

@Component({
  selector: 'app-start-stop-fee-accruals',
  templateUrl: './start-stop-fee-accruals.component.html',
  styleUrls: ['./start-stop-fee-accruals.component.scss']
})
export class StartStopFeeAccrualsComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() isRouteShowHistory : boolean = false;
  modalTitle:string='';
  modalSubTitle:string='';
  constructor(

    private httpService: HttpService,
    private msgService: MessageService,
    private permissionService: PermissionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private broadCastService:BroadcastService

  ) { }

  ngOnInit(): void {
    this.modalTitle=this.loanDetail?.late_fee_accrual_on_yn=='YES'?'Stop Fee Accruals':'Start Fee Accruals';
    this.modalSubTitle=this.loanDetail?.late_fee_accrual_on_yn=='YES'?'Click submit to stop fee accruals.':'Click submit to start fee accruals.';
  }

  submit(){


    const params = {loan_id:this.loanID,payment_info_code_slug:this.loanDetail?.late_fee_accrual_on_yn=='YES'?'STOP_FEE':'START_FEE'};
    const url = `${PathConfig.LOAN_TRANSACTIONS_START_STOP_ACCURAL}`;
    this.httpService.post(url, params).subscribe({
      next : (res) => {
        this.msgService.showSuccess(res?.message);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);

      },
      error : (err) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
      }
    });
       this.modalService.dismissAll();
  }

}


