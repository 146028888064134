export class UserInfo {
    id: number;
    firstname: string = '';
    middlename: string = '';
    lastname: string = '';
    profile_picture: string = '';
    profile_picture_temp_url: string = '';
    job_description:number = 0;
    theme_mode:string = '';
    email:string = '';
    department:Array<any> = [];
    branch:Array<any> = [];
    constructor(obj:UserInfo){
        this.id = obj.id;
        this.firstname = obj.firstname;
        this.middlename = obj.middlename;
        this.lastname = obj.lastname;
        this.profile_picture = obj.profile_picture;
        this.profile_picture_temp_url=obj.profile_picture_temp_url;
        this.job_description = obj.job_description;
        this.theme_mode = obj.theme_mode;
        this.email = obj.email;
        this.department = obj.department;
        this.branch = obj.branch;
    }
}
