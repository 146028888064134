<app-header></app-header>

<!-- Other Sections-->
<ng-container [ngSwitch]="pageType" *ngIf="pageType != 'ERROR'">
  <!-- Queue Detail -->
  <ng-container *ngSwitchCase="'QUEUE_DETAIL_SECTION'">
    <app-queue-detail></app-queue-detail>
  </ng-container>

  <!-- Default -->
  <ng-container *ngSwitchDefault>
    <!-- Main Page Heading Start -->
    <div class="main-page-heading text-center d-none">
      <div class="row">
        <div class="col-md-12">
          <h1 class="page-heading mb-0">{{ sectionTitle }}</h1>
        </div>
      </div>
    </div>
    <!-- Main Page Heading End -->

    <!-- Page Menubar Start -->
   

    <!-- Default Section-->
    <div class="row flex-nowrap no-gutters">
      <div class="col-auto sidebar">
        <app-left-navigation></app-left-navigation>
      </div>
      <div class="col">
        <div class="main-page-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Error Section-->
<ng-container *ngIf="pageType == 'ERROR'">
  <div class="row no-gutters">
    <div class="col-12">
      <!-- Main Page Heading Start -->
      <div class="main-page-heading text-center d-none">
        <div class="row">
          <div class="col-md-12">
            <h1 class="page-heading mb-0">{{ sectionTitle }}</h1>
          </div>
        </div>
      </div>
      <!-- Main Page Heading End -->
    </div>
    <div class="col-12">
      <div class="error-page-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<app-footer></app-footer>
