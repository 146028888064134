<ng-container>
  <!-- Data Warehouse Sidebar -->
  <div class="lms-card-header-primary text-white text-center py-1 mt-3" [ngClass]="
      isModuleServicing == true ? 'lms-card-header-success' : 'bg-dark-blue'
    "
    data-toggle="collapse"
    data-target="#DataWarehouse"
    role="button"
    aria-expanded="true"
  >
    {{"Data Warehouse"|translate}}
  </div>
  <div class="collapse show" id="DataWarehouse">
    <div class="lms-notification-sidebar-content">
      <div class="lms-datawarehouse-header">
        <!-- Search Start -->
        <div class="search">
          <app-ls-search-input
            placeholder="{{'Category Search'|translate}}"
            (onSearch)="onListSearch($event, 'category')"
          >
          </app-ls-search-input>
        </div>
        <!-- Search End -->
        <!-- Search Start -->
        <div class="search mt-2">
          <app-ls-search-input
            placeholder="{{'Field Search'|translate}}"
            (onSearch)="onListSearch($event, 'field')"
          >
          </app-ls-search-input>
        </div>
        <!-- Search End -->
      </div>
      <!-- Data Warehouse Body -->
      <div class="lms-datawarehouse-body border-top mt-3 pb-3">
        <!-- Show Category Nested Listing -->
        <ngb-accordion class="category-listing level-1 level-bg-success" [ngClass]="
            isModuleServicing == true ? 'category-listing-success' : ''
          " [closeOthers]="true" [activeIds]="openPanels">
          <ng-container *ngFor="let warehouse of dataWarehouseList">
            <ngb-panel id="warehouse-{{ warehouse.id }}">
              <ng-template ngbPanelHeader>
                <button ngbPanelToggle class="btn btn-link">
                  {{ warehouse.name }}
                </button>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="category-items">
                  <ng-container *ngFor="let content of warehouse.attributes;TrackById">
                    <a (click)="fieldSelected(content)">{{ content?.name }} </a>
                  </ng-container>
                  <!-- No Data Found -->
                  <app-ls-nodata-found
                    message="{{'No Fields Found'|translate}}"
                    *ngIf="
                      !isDataWareHouseLoading &&
                      warehouse?.attributes?.length == 0
                    "></app-ls-nodata-found>
                </div>
              </ng-template>
            </ngb-panel>
          </ng-container>
        </ngb-accordion>
        <!-- No Data Found -->
        <app-ls-nodata-found
          message="{{'No Records Found'|translate}}"
          *ngIf="!isDataWareHouseLoading && dataWarehouseList?.length == 0"
        ></app-ls-nodata-found>
      </div>
    </div>
  </div>
</ng-container>
