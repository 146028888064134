import * as _ from 'lodash';
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
export class Utility {
    static getErrorMessage(err:any){
        let errorMsg = [err];
        if(_.isPlainObject(err?.errors)){
            errorMsg = _.chain(err?.errors)?.values()?.flatten()?.value();
        }

        const msgList = errorMsg?.map((msg)=>{
         return  _.isString(msg) ? Utility.getPlainMessageTmpl(msg) :  Utility.getMessageTmpl(msg);
        });
        return msgList;
    }

    static getMessageTmpl(err:any){
      let errorMsg = `<div>`;
      if(err?.code){
         errorMsg += `<div>Error Code:${err?.code}</div>`;
      }
      errorMsg += `<div>${err?.message}</div>`;
      errorMsg += `<div>Please contact support with the error code for further assistance.</div>`;
      errorMsg += `</div>`;
      return errorMsg;
  }

  static getPlainMessageTmpl(msg:any){
    let errorMsg = `<div>`;
    errorMsg += `<div>${msg}</div>`;
    errorMsg += `</div>`;
    return errorMsg;
}
    static isValueEqual(value1:any , value2:any) : boolean{
      return (value1 === value2);
  }

  static getAgeInYrs(dt:string){
    const dob = new Date(dt);

    //calculate month difference from current date in time
    const month_diff = Date.now() - dob.getTime();
    //convert the calculated difference in date format
    const age_dt = new Date(month_diff);
    //extract year from date
    const year = age_dt.getUTCFullYear();
    //now calculate the age of the user
    const age = Math.abs(year - 1970);
    return age;
  }


  static getAgeInYrsWithDecease(dt:string,dt2:string){
    const dob = new Date(dt);
    const dec = new Date(dt2);
    //calculate month difference from current date in time
    const month_diff = ['',null,undefined].includes(dt2)?(Date.now() - dob.getTime()):(dec.getTime()-dob.getTime());
    //convert the calculated difference in date format
    const age_dt = new Date(month_diff);
    //extract year from date
    const year = age_dt.getUTCFullYear();
    //now calculate the age of the user
    const age = Math.abs(year - 1970);
    return Number.isNaN(age)?'':age;
  }


  /**
   * Export JSON Array to excel
   * @param name
   * @returns
   */
  static getFileName(name: string) {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "report";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };
  static exportArrayToExcel(arr: any[], name: string = '') {
    let { sheetName, fileName } = Utility.getFileName(name);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static exportURLToExcel(metaInfo:any, name: string = '') {
    let { sheetName, fileName } = Utility.getFileName(name);
    //to download
    const a = document.createElement("a");
    a.href = metaInfo?.url;
    a.download = fileName;
    a.click();

  }

  static  async downloadURI(uri:any, name:string='') {
    const response = await fetch(uri);
    
    // Storing data in form of text
    var data = await response.blob();

    var content:any = data;
    // any kind of extension (.txt,.cpp,.cs,.bat)
    // var filename = name;
    var blob = new Blob([content], {
    type: "text/plain;charset=utf-8"
    });

    saveAs(blob, name);
  }

  static downloadFileFromURL(url:string, fileName: string) {
    saveAs(url,fileName);
  }

  static getRandomColor(){
    return '#'+(0x1000000+Math.random()*0xffffff).toString(16).substr(1,6);
  }

  static ROUNDUP(number:any, digits:any) {
    number = parseFloat(number);
    digits = parseFloat(digits);
    const sign = number > 0 ? 1 : -1
    return (sign * Math.ceil(Math.abs(number) * Math.pow(10, digits))) / Math.pow(10, digits);
  }

 static conver_To_Camel_Case(str:string)
  {
    let STR = str.toLowerCase()
		.trim()
		.split(' ')
		.map(word => word.replace(word[0], word[0].toString().toUpperCase()))
		.join(' ');
	return STR;
  }

 static getDefaultCategoryObject(catArr:any)
  {
  return catArr.find((item:any)=> item.is_default=='YES');
  }

 static isCategoryObjectIndexExist(catArr:any,id:number)
  {
  return catArr.map((item:any)=>item.id).indexOf(id);
  }

  static initialOfString(str:string)
  {

     let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
     return matches?.join(''); // JSON

  }

  static getNextVersion(versionList:any)
  {

    let tbdList=versionList?.filter((item:any)=>{return item.version_status==='DRAFT'});
    let activeList=versionList?.filter((item:any)=>{return item.version_status==='ACTIVE'});
    tbdList.reverse();
    if(tbdList?.length>0)
    {
    return  tbdList[0].id;
    }
    else if(activeList.length>0)
    {
    return  activeList[0].id;
    }
    else
    {
      return -1;
    }

  }

  static getDType(tString:any)
  {
    if(tString.match(/^([0-9]*)$/)){
      return 'integer';
    }else if(tString.match(/^[+-]?([0-9]*[.])?[0-9]+$/)){
      return 'float';
    }else if(tString.match(/^\d{2}\/\d{2}\/\d{4}$/) || tString.match(/^\d{2}\/\d{2}\/\d{2}$/) || tString.match(/^\d{1}\/\d{1}\/\d{4}$/) || tString.match(/^\d{1}\/\d{1}\/\d{2}$/) || tString.match(/^\d{1}\/\d{2}\/\d{2}$/) || tString.match(/^\d{1}\/\d{2}\/\d{4}$/)){
      return 'date';
    }else if(['TRUE','FALSE','true','false','yes','no','YES','NO'].includes(tString)){
      return 'boolean';
    }else{
      return "string";
    }
  }


}
