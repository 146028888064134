import { AbstractControl } from '@angular/forms';

/**
Float Validation
--------------------
Valid formats:
23.75
 */
export function ValidateFloat(control: AbstractControl) {
  const regExp = /^[+-]?[0-9]*\.[0-9]{2}$/;  //[+-]
  if (control.value && !regExp.test(control.value)) {
    return { invalidFloat: true };
  }
  return null;
}
