import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  msg:string = '';
  clientName:string = '';
  constructor(private activateRoute:ActivatedRoute,private router:Router) { }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params)=>{
      this.msg = params['msg'] || 'Something went Wrong';
    })
  }

  onLogin():void{
    window.location.href= `/${this.clientName}`;
  }
  

}
