import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { Utility } from 'src/app/core/utils/utility';
import { LoanDetail } from 'src/app/shared/models/loan/loan.detail.model';

@Component({
  selector: 'app-kickabck-review',
  templateUrl: './kickabck-review.component.html',
  styleUrls: ['./kickabck-review.component.scss']
})
export class KickabckReviewComponent implements OnInit {
  @Input() loanID!: string;
  @Input() loanDetail!:LoanDetail;
  @ViewChild('f') formElement!: NgForm;
  form!: UntypedFormGroup;
  submitted: boolean = false;
  isDirty:boolean = false;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  afterInfo!:any;
  iskickBackPermission:boolean = false;
  curDate = new Date();
  before_assignee_name='';
  after_assignee_name='';
  kickbackRepoReviewPreviewData: any = null;

  constructor(
    private httpService:HttpService ,
    private msgService:MessageService,
    private modalService:NgbModal,
    private broadCastService:BroadcastService,
    private permissionService:PermissionService,
    public modal:NgbActiveModal,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
      this.afterInfo = '';
      this.iskickBackPermission = true;//this.permissionService.isSpecialPermission('process_extensions');
    }

  ngOnInit(): void {

    this.form = new UntypedFormGroup({
      loan_id: new UntypedFormControl(this.loanID, []),
      remark: new UntypedFormControl('', [Validators.required, Validators.maxLength(500)])
    });
    this.form.valueChanges.subscribe((e) => (this.isDirty = true));
    this.getKickbackRepoReviewPreview();
    this.before_assignee_name=this.loanDetail?.latest_kickback_repo_review?.before?.user?.employee_initials+" "+this.loanDetail?.latest_kickback_repo_review?.before?.user?.firstname+" "+this.loanDetail?.latest_kickback_repo_review?.before?.user?.lastname;
    this.after_assignee_name=(this.afterInfo?.user?.employee_initials || this.loanDetail?.assignee?.employee_initials)+" "+(this.afterInfo?.user?.firstname || this.loanDetail?.assignee?.firstname)+" "+ (this.afterInfo?.user?.lastname || this.loanDetail?.assignee?.lastname);

  }
  convertLocalTimeZone(d:any)
  {
    if(d==''||d==null){return null}
    var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
    return moment(testDateUtc).local().format("MM/DD/YYYY hh:mm:ss A");
  }
  convertLocalTimeZoneWithoutTime(d:any)
  {
    if(d==''||d==null){return null}
    var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
    return moment(testDateUtc).local().format("MM/DD/YYYY");
  }
  conver_To_Camel_Case(str:string)
  {
    let STR = str.toLowerCase()
		.trim()
		.split(' ')
		.map(word => word.replace(word[0], word[0].toString().toUpperCase()))
		.join(' ');
	return STR;
  }

  // on submit form
  onSubmit() {

    if (this.form.valid && !this.submitted) {
      this.submitted = true;
      const params = this.form.value;

      const url = `${PathConfig.LOAN_REPO_REVIEWS_KICKBACK}`;
      this.httpService.post(url, params)
      .subscribe((response:any) => {
        this.msgService.showSuccess(response.message);
        this.afterInfo = response?.loan_repo_review?.after || '';
        this.submitted = false;
        this.isDirty = false;
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
      //  this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);

        this.modalService.dismissAll();
      },
      (err:any) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.submitted = false;
      });
    }
  }

  // on Form submit
  onFormSubmit(){
    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

  // get approval
  openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }

  getKickbackRepoReviewPreview()
  {
    const url = `${PathConfig.SUBMIT_KICKBACK_PREVIEW}?loan_id=${this.loanID}&type=KICKBACK_REPO_REVIEW`;
    this.httpService.get(url).subscribe(
      (response) => {

        this.kickbackRepoReviewPreviewData = response?.preview_data;

      },
      (err) => {
        this.msgService.showError(err.error.message);
      }
    );
  }

  onNoteValueChange(e:any){
    var val=e?.target?.value;
    if(val)
    this.form.controls.remark.patchValue(val?.trim());
    this.form.controls.remark.markAsTouched();
    this.form.controls.remark.updateValueAndValidity();
  }

}
