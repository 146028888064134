import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import { TakePaymentComponent } from '../take-payment/take-payment.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-take-pmt',
  templateUrl: './take-pmt.component.html',
  styleUrls: ['./take-pmt.component.scss']
})
export class TakePmtComponent implements OnInit {
  environment = environment;
  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() id!: any;
  @Input() editMode!: boolean;
  @Input() transactionDetails!: any;
  @Input() previousTransaction!: any;
  @Input() take_payment_url:string='';
  activePaymentPortals: any[]=[];

  constructor(
    private modalService: NgbModal,
    public modal: NgbActiveModal,
    private httpService: HttpService,private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');

  }

  ngOnInit(): void {
    this.getActivePaymentPortalList();
  }
  
  getActivePaymentPortalList(){
    const url = `${PathConfig.PAYMENT_PORTALS}`;
    this.httpService.get(url).subscribe(
      (Response)=>{
        this.activePaymentPortals=Response;
      }
    );
  }

  openTakePaymentModal(content: any) {
    const url =`${PathConfig.LOAN_PREVIOUS_TRANSACTION}?loan_id=${this.loanID}`;
    this.httpService.get(url).subscribe((res)=>{
      this.modalService.dismissAll();
      this.previousTransaction = res;
    const modalRef = this.modalService.open(TakePaymentComponent, {
      centered: true,
      size: 'xl',
      windowClass:'draggable-container',
      backdrop:false,
    });
    modalRef.componentInstance.previousTransaction = this.previousTransaction;
    modalRef.componentInstance.loanDetail = this.loanDetail;
    modalRef.componentInstance.loanID = this.loanID;
    })
    //this.modalService.open(content, { centered: true, size: 'xl' });
  }

}
