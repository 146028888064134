<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content" >
<ng-container>
  <form #waiveFeeForm = "ngForm" [formGroup]="waiveFeesForm" novalidate>
    <div class="modal-header align-items-center cursor-pointer" cdkDragHandle >
  <h6 class="modal-title modal-title-success">{{'Adjust Fee Balances'|translate}}</h6>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <div class="lms-module-card">
    <div class="card-body">
      <div class="card-form-content card-form-success">
        <div class="bg-note-info p-2 mb-3">
          {{'Input the fee amount to adjust'|translate}}.
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-no-bg table-align-middle mb-0">
            <thead>
              <tr>
                <td class="border-0">{{'Fee Type'|translate}}</td>
                <td class="border-0">{{'Before'|translate}}</td>
                <td class="border-0"></td>
                <td class="border-0">(+/-){{'Adjust'|translate}}</td>
                <td class="border-0"></td>
                <td class="border-0">{{'After'|translate}}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{'Late Fees'|translate}}</td>
                <td>{{ (loanLateFee  || 0) | currency}}</td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                </td>
                <td class="lms-text-light-purple position-relative">
                  <!-- <input type="text" class="form-control form-control-sm"
                   name="late_fees" formControlName="late_fees"
                   prefix="$"
                   mask="separator.2"
                   (keyup)="feesEntered($event,'late')"
                   [readonly]="isBeforFees || loanLateFee <= 0 || !loanLateFee"
                   /> -->
                   <span class="dollar-class">$</span>
                   <input type="text" class="form-control form-control-sm"
                   name="late_fees" formControlName="late_fees"
                   (blur)="feesEntered($event,'late')"
                   />
                  <val-errors controlName="late_fees">
                      <ng-template valError="required">{{'Required field'|translate}}</ng-template>
                      <ng-template valError="pattern">{{'Invalid Value'|translate}}.</ng-template>
                  </val-errors>
                  <val-errors *ngIf="!waiveFeesForm.controls.late_fees.errors?.pattern" controlName="late_fees">
                    <ng-template valError="currencyRange">{{'Late Fees must be between -10000 and 10000'|translate}}</ng-template>
                </val-errors>
                </td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                </td>
                <td class="lms-text-light-purple">
                    <!-- <span *ngIf="balanceAfter.late_fees>=0">{{(balanceAfter?.late_fees || 0) | currency}}</span> -->
                    <span >{{(balanceAfter?.late_fees || 0) | currency}}</span>
                    <!-- <span  class="text-danger"  *ngIf="balanceAfter.late_fees<0">{{(balanceAfter?.late_fees || 0 )| currency}}</span> -->
                </td>
              </tr>
              <tr>
                <td>{{'NSF Fees'|translate}}</td>
                <td>{{ (loanNsfFee  || 0) | currency}}</td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                </td>
                <td class="lms-text-light-purple position-relative">
                  <!-- <input type="text" class="form-control form-control-sm"
                  prefix="$"
                  mask="separator.2"
                  (keyup)="feesEntered($event,'nsf')"
                  name="nsf_fees" formControlName="nsf_fees"
                  [readonly]="isBeforFees || loanNsfFee <= 0 || !loanNsfFee"
                  /> -->
                  <span class="dollar-class">$</span>
                  <input type="text" class="form-control form-control-sm"
                  (blur)="feesEntered($event,'nsf')"
                  name="nsf_fees" formControlName="nsf_fees"
                  />
                  <val-errors controlName="nsf_fees">
                    <ng-template valError="required">{{'Required field'|translate}}</ng-template>
                    <ng-template valError="pattern">{{'Invalid Value'|translate}}.</ng-template>
                  </val-errors>
                  <val-errors *ngIf="!waiveFeesForm.controls.nsf_fees.errors?.pattern" controlName="nsf_fees">
                    <ng-template valError="currencyRange">{{'NSF Fees must be between -10000 and 10000'|translate}}</ng-template>
                  </val-errors>
                </td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                </td>
                <td class="lms-text-light-purple">
                  <!-- <span *ngIf="balanceAfter.nsf_fees>=0">{{(balanceAfter?.nsf_fees || 0) | currency}}</span> -->
                  <span >{{(balanceAfter?.nsf_fees || 0) | currency}}</span>

                  <!-- <span  class="text-danger" *ngIf="balanceAfter.nsf_fees<0">{{(balanceAfter?.nsf_fees || 0) | currency}}</span> -->
                </td>
              </tr>
              <tr>
                <td>{{'Other Fees'|translate}}</td>
                <td>{{ (loanOtherFee  || 0) | currency}}</td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                </td>
                <td class="lms-text-light-purple position-relative">
                  <!-- <input type="text" class="form-control form-control-sm"
                  prefix="$"
                  mask="separator.2"
                  (keyup)="feesEntered($event,'other')"
                  name="other_fees" formControlName="other_fees"
                  [readonly]="isBeforFees || loanOtherFee <= 0 || !loanOtherFee"
                  /> -->
                  <span class="dollar-class">$</span>
                  <input type="text" class="form-control form-control-sm d-inline-block"
                  (blur)="feesEntered($event,'other')"
                  name="other_fees" formControlName="other_fees"
                  />
                  <val-errors controlName="other_fees">
                    <ng-template valError="required">{{'Required field'|translate}}</ng-template>
                    <ng-template valError="pattern">{{'Invalid Value'|translate}}.</ng-template>
                  </val-errors>
                  <val-errors *ngIf="!waiveFeesForm.controls.other_fees.errors?.pattern" controlName="other_fees">
                    <ng-template valError="currencyRange">{{'Other Fees must be between -10000 and 10000'|translate}}</ng-template>
                  </val-errors>
                </td>
                <td>
                  <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                  <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                </td>
                <td class="lms-text-light-purple">
                  <!-- <span *ngIf="balanceAfter.other_fees>=0">{{(balanceAfter?.other_fees || 0) | currency}}</span> -->
                  <span >{{(balanceAfter?.other_fees || 0) | currency}}</span>
                  <!-- <span class="text-danger" *ngIf="balanceAfter.other_fees<0">{{ (balanceAfter.other_fees>=0?balanceAfter.other_fees:0 || 0) | currency}}</span> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 pl-1 form-group">
          <label>{{'Reference Note'|translate}}</label>
          <input type="text" class="form-control form-control-sm" name="reference_note" formControlName="reference_note" value="Check #123" appTrimOnBlur />
          <val-errors controlName="reference_note">
            <ng-template valError="pattern"
            >{{'Reference Note is not well formed'|translate}}</ng-template>
            <ng-template valError="maxlength">{{'Reference Note cannot be longer than 200 characters'|translate}}</ng-template>
            <ng-template valError="minlength">{{'Reference Note cannot be less than 4 characters'|translate}}</ng-template>
          </val-errors>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer justify-content-between cursor-pointer" cdkDragHandle>
  <div>
    <!-- <span class="d-block font-size-14 lms-text-light-pink" *ngIf="isWaiveFeeExceed">
      {{errorMsg}}
    </span> -->
    <span class="d-block font-size-14 lms-text-light-pink" *ngIf="!waivePermistion">
      {{"You don’t have the permission to adjust fee balances"|translate}}.
    </span>
    <span class="d-block font-size-14 lms-text-light-pink" *ngIf="isLower">
      {{'You cannot waive more than the balance'|translate}}.
    </span>
  </div>
  <div>


    <button type="button" class="btn btn-success-light btn-150" 
    [disabled]="(waiveFeesForm.invalid ) || (
    (loanLateFee?.toFixed(2) == balanceAfter?.late_fees?.toFixed(2))&&
    (loanNsfFee?.toFixed(2) == balanceAfter?.nsf_fees?.toFixed(2))&&
    (loanOtherFee?.toFixed(2) == balanceAfter?.other_fees?.toFixed(2)))"
    (click)="onWaiveSubmit()" *ngIf="waivePermistion">
      {{'Submit'|translate}}
    </button>
    <!-- <button type="button" class="btn btn-pink btn-150" (click)="openGetApprovalModal(GetApprovalModal)"> -->
  <button type="button" class="btn btn-pink btn-150" [disabled]="waiveFeesForm.invalid || isBeforFees" *ngIf="!waivePermistion" (click)="openOverrideModal(GetApprovalModal)">
      {{'Get Approval'|translate}} {{waiveFeesForm.invalid}}
    </button>
  </div>
</div>
  </form>
</ng-container>
</div>
</div>






<!-----------Modal----------->

<!-- Override Modal -->
<ng-template #GetApprovalModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<div class="modal-header " >
    <h6 class="modal-title text-center mb-3">{{'Get Approval'|translate}}</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <form #f="ngForm" [formGroup]="form" (ngSubmit)="verifyPin()" novalidate>
  <div class="modal-body pb-0 px-0">


    <div class="lms-module-card">
      <div class="card-body">
        <div class="card-form form-vertical">
          <div class="form-group">
            <label class="form-group-label">{{'Initials'|translate}}</label>
            <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
            <val-errors controlName="employee_initials">
              <ng-template valError="required">{{'Initial is required'|translate}}</ng-template>
            </val-errors>
          </div>
          <div class="form-group">
            <label class="form-group-label">{{'PIN'|translate}}</label>
            <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
            <val-errors controlName="pin">
              <ng-template valError="required">{{'PIN is required'|translate}}</ng-template>
            </val-errors>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" (click)="this.form.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
      {{'Get Approval'|translate}}
    </button>
  </div>
</form>
</div>
</div>
</ng-template>
