import { AbstractControl, ValidationErrors } from "@angular/forms"

export const PasswordStrengthValidator = function (control: AbstractControl): { passwordStrength: string; } | null  {

    let value: string = control.value || '';
    let msg = "";
    if (!value) {
        return null;
    }

    let upperCaseCharacters = /[A-Z]+/g;
    let lowerCaseCharacters = /[a-z]+/g;
    let numberCharacters = /[0-9]+/g;
    let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (upperCaseCharacters.test(value) === false || lowerCaseCharacters.test(value) === false || numberCharacters.test(value) === false || specialCharacters.test(value) === false) {
        return {
            passwordStrength: 'Password must contain at least two of the following: numbers, lowercase letters, uppercase letters, or special characters.'
        };

    }
    return null;
}

export const PasswordWhitespaceValidator = function (control: AbstractControl): { whitespace: string; } | null  {

    let value: string = control.value || '';
    let msg = "";
    if (!value) {
        return null;
    }
    let whitespace = /\s/;
    if(whitespace.test(value) === true){
        return { 
            whitespace: 'Whitespace not allowed in password'
        }
    }
    return null;
}