import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excludeSelectedItems'
})
export class ExcludeSelectedItemsPipe implements PipeTransform {
  transform(allItems: any[], selectedIds: number[]): any[] {
    return allItems.filter(item => !selectedIds.includes(item.id));
  }
}
