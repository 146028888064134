import { Component, Input, OnInit, SimpleChanges, Renderer2, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpService, MessageService, UserService } from 'src/app/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PathConfig } from 'src/app/core/config/path.config';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { ValidateUSPhone } from 'src/app/core/validators/us-phone.validator';
import moment from 'moment';
import { isFutureDate } from 'src/app/core/validators/isfuturedate-validator';
import { param } from 'jquery';

@Component({
  selector: 'app-caller',
  templateUrl: './caller.component.html',
  styleUrls: ['./caller.component.scss']
})
export class CallerComponent implements OnInit {
  phoneForm: FormGroup;
  eventSubscription: Array<Subscription> = [];
  @Input() maskOff: boolean = false;
  @Input() dailing_no?: any;
  call!: UntypedFormGroup;
  isDirty: boolean = false;
  dailing_number?: any;
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;
  subscription: any;
  loanDetail: any;
  dropdownOpen: boolean = false;
  selectedContact: string | null = null;
  selectedValue: string | null = null;
  contactList: any[] = [];
  loanID: any;
  isLoanApiPatching: boolean = false;
  selecteduser: any;
  selectedMainStatus: any;
  selected_sub_status: any;
  selectedpriority: any;
  pri: any;
  selectedPortfolio: any;
  isAvgChartRenderd: boolean = false;
  clientInfo = {
    name: '',
  };
  queueType: any;
  sortBy: any;
  orderBy: any;
  pageLimit: any;
  currentPage: any;
  sectionTitle: any;
  isAssestRecoverySection: boolean = false;
  selectedPhoneNumber: any;
  selectedApplicantId: any;
  isDNC: boolean = false;
  disableCallButton: boolean = true;
  agentStatusInterval: any;
  activityTypes: any;
  addActivityForm!: UntypedFormGroup;
  selectedItem: any;
  selectedDate: any;
  curamt: any;
  isPastDate: boolean=false;
  errormsg: any;
  isButtonDisable: boolean=false;
  dateerrormsg: string='';
  isAcivityPTPActive: boolean = false;
  disableHangupAndEndCallButton: boolean = true;
  isTransferCallActive :boolean = false
  agentsList :any[]=[];
  isAgentDisabled:boolean = false;
  isPhoneDisabled:boolean = true;
  isExtDisabled:boolean = true;
  transferringMsg :any;
  transferCallForm!: UntypedFormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private translateService: TranslateService,
    private msgService: MessageService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private clientDetailService: ClientDetailService,
    private httpService: HttpService, private fb: FormBuilder
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');

    this.clientInfo.name = this.clientDetailService.getClientInfo('name');
    const routeEvents = this.activatedRoute.queryParams
      .subscribe(params => {
        this.queueType = params['qtype'] == null ? "" : params['qtype'];
        this.sortBy = params['sortby'] == null ? "" : params['sortby'];
        this.orderBy = params['orderby'] == null ? "" : params['orderby'];
        this.pageLimit = params['limit'] == null ? "" : params['limit'];
        this.currentPage = params['page'] == null ? "" : params['page'];
      });
    this.eventSubscription.push(routeEvents);
    this.loanID = Number(this.route.url.split('/')[5]);
    if (this.loanID) {
      this.getLoanDetailByID(this.loanID);
    }
    
    this.phoneForm = this.fb.group({
      selectedPhoneNumber: ['', [Validators.required, ValidateUSPhone]]
    });
    
    // Conditionally add the fields only if loanId is not present
    if (!this.loanID) {
      this.phoneForm.addControl('name', this.fb.control('', [Validators.required]));
      this.phoneForm.addControl('loan_id', this.fb.control('', [Validators.minLength(2),Validators.maxLength(100),Validators.pattern('^(?=.*[a-zA-Z0-9])[a-zA-Z0-9]+([.](?!.*[.])[a-zA-Z0-9]+)*$')]));
    }
    
  }

  ngOnInit(): void {
    this.call = new UntypedFormGroup({
      dailing_number: new UntypedFormControl('', [Validators.required]),
    })
    this.call.valueChanges.subscribe((e) => (this.isDirty = true));
    this.getdailingnumber();
    this.startAgentCallStatusPolling();
    this.getAgentList();
    this.getActivityCodes();
    this.initiliseActivityForm();
  
    this.transferCallForm = new UntypedFormGroup({
      transferOption: new UntypedFormControl('agent', [Validators.required]),
      agent_id: new UntypedFormControl(null, [Validators.required]),
      phone_number: new UntypedFormControl('', [Validators.required, ValidateUSPhone]),
      phone_extension: new UntypedFormControl('', [Validators.required, Validators.maxLength(6),Validators.pattern("^[0-9]*$")]),
    })
    this.onRadioChange(this.transferCallForm.controls.transferOption.value);
    this.transferCallForm.controls.agent_id.valueChanges.subscribe(() => {
      this.updateTransferringMessage();
    });
  
    this.transferCallForm.controls.phone_number.valueChanges.subscribe(() => {
      this.updateTransferringMessage();
    });
  
    this.transferCallForm.controls.phone_extension.valueChanges.subscribe(() => {
      this.updateTransferringMessage();
    });
  }
  onRadioChange(option: string) {
    switch (option) {
      case 'agent':
        this.transferCallForm.controls.agent_id.enable();
        this.transferCallForm.controls.phone_number.disable();
        this.transferCallForm.controls.phone_extension.disable();
        this.transferCallForm.controls.transferOption.patchValue('agent');
        this.updateTransferringMessage();
        this.isAgentDisabled = false;
        this.isPhoneDisabled = true;
        this.isExtDisabled = true;
        break;
  
      case 'phone':
        this.transferCallForm.controls.agent_id.disable();
        this.transferCallForm.controls.phone_number.enable();
        this.transferCallForm.controls.phone_extension.disable();
        this.transferCallForm.controls.transferOption.patchValue('phone');
        this.updateTransferringMessage();
        this.isAgentDisabled = true;
        this.isPhoneDisabled = false;
        this.isExtDisabled = true;
        break;
  
      case 'ext':
        this.transferCallForm.controls.agent_id.disable();
        this.transferCallForm.controls.phone_number.disable();
        this.transferCallForm.controls.phone_extension.enable();
        this.transferCallForm.controls.transferOption.patchValue('ext');
        this.updateTransferringMessage();
        this.isAgentDisabled = true;
        this.isPhoneDisabled = true;
        this.isExtDisabled = false;
        break;
    }
  }
  
  // Method to update the transferring message
  updateTransferringMessage() {
    const transferOption = this.transferCallForm.controls.transferOption.value;
  
    if (transferOption === 'agent') {
      const agentId = this.transferCallForm.controls.agent_id.value;
      this.transferringMsg = agentId ? `${this.getAgentName(agentId)}` : "Agent";
    } else if (transferOption === 'phone') {
      const phoneNumber = this.transferCallForm.controls.phone_number.value;
      this.transferringMsg = phoneNumber ? `${phoneNumber}` : "Phone Number";
    } else if (transferOption === 'ext') {
      const phoneExt = this.transferCallForm.controls.phone_extension.value;
      this.transferringMsg = phoneExt ? `${phoneExt}` : "Phone Extension";
    }
  }
  
  // Helper method to get the agent name from the agent ID
  getAgentName(agentId: string): string {
    const agent = this.agentsList.find(a => a.id === agentId);
    return agent ? agent.agent_name : '';
  }
  
  

  onClickTransferCall() {
    const url = `${PathConfig.TRANSFER_CALL}`;
    let param: any = {};
    if (this.transferCallForm.controls.transferOption.value === 'agent') {
      param.agent_id = this.transferCallForm.controls.agent_id.value;
    }
    else if (this.transferCallForm.controls.transferOption.value === 'phone') {
      param.phone_number = this.transferCallForm.controls.phone_number.value;
    }
    else if (this.transferCallForm.controls.transferOption.value === 'ext') {
      param.phone_extension = this.transferCallForm.controls.phone_extension.value;
    }

    this.httpService.post(url, param).subscribe(
      (response) => {
        this.msgService.showSuccess(response?.message);
      },
      (error) => {
        const errorMsg = error?.error?.message;
        this.msgService.showError(errorMsg);
      }
    )

  }
 
  initiliseActivityForm(){
    this.addActivityForm = new UntypedFormGroup({
      code: new UntypedFormControl(null, [Validators.required]),
      comments: new UntypedFormControl('', [Validators.required, Validators.maxLength(10000) ]),
      ptp_date: new UntypedFormControl('', [isFutureDate]),
      ptp_amount: new UntypedFormControl('0.00', []),
    });
  }
  startAgentCallStatusPolling() {
    // Call immediately once and then start polling every 5 seconds
    this.getAgentCallStatus();
    this.agentStatusInterval = setInterval(() => {
      this.getAgentCallStatus();
    }, 5000); // 5000ms = 5 seconds
  }
  
  getAgentCallStatus() {
    const url = `${PathConfig.AGENT_CALL_STATUS}`;
    this.httpService.get(url, true).subscribe(
      (response) => {
        const getStatus = response;
        if (getStatus.status === 1) {
          if (getStatus.agent_status === "Ready") {
            this.disableCallButton = false;
          } else {
            this.disableCallButton = true;
          }
  
          if (getStatus.agent_status === "Talk") {
            this.disableHangupAndEndCallButton = false;
          } else {
            this.disableHangupAndEndCallButton = true;
          }
        } else if (getStatus.status === 0) {
          this.stopAgentCallStatusPolling();
          this.msgService.showError(getStatus.message);
        }
      },
      (error) => {
        this.stopAgentCallStatusPolling();
        const errorMsg = error?.error?.message;
        this.msgService.showError(errorMsg);
      }
    );
  }
  
  stopAgentCallStatusPolling() {
    if (this.agentStatusInterval) {
      clearInterval(this.agentStatusInterval);
      this.agentStatusInterval = null; // Optional: Prevent further clearing attempts
    }
  }
  getAgentList(){
    const url = `${PathConfig.AGENTS_LIST}`;
    this.httpService.get(url, true).subscribe(
      (response) => {
        
          this.agentsList = response;
        
      },
      (error) => {
        const errorMsg = error?.error?.message;
        this.msgService.showError(errorMsg);
      }
    )
  }
  getActivityCodes() {
    const url = `${PathConfig.ACTIVITY_CODES}`;
    this.httpService.get(url, true).subscribe(
      (response) => {
        if (response.status === 1) {
          this.activityTypes = response.cod_act_array
        }
      },
      (error) => {
        const errorMsg = error?.error?.message;
        this.msgService.showError(errorMsg);
      }
    )

  }
  getdailingnumber() {
    this.dailing_no != null ? this.dailing_no : '';
  }

  // get loan detail
  getLoanDetailByID(id: Number) {
    this.isLoanApiPatching = true;
    this.selecteduser = this.userService.getUserInfo("id");
    this.activatedRoute.queryParams
      .subscribe(params => {
        let priority_status = params['account_work_activity_priority_status'];
        this.selectedMainStatus = [null, '', undefined].includes(params['loan_status']) ? null : params['loan_status'];
        this.selected_sub_status = [null, '', undefined].includes(params['loan_sub_status_label']) ? null : params['loan_sub_status_label'];
        this.selecteduser = [null, undefined].includes(params['user_id']) ? this.selecteduser?.toString() : params['user_id']?.toString();
        this.selectedpriority = [null, '', undefined].includes(params['account_work_activity_priority_status']) ? null : priority_status?.split(',');
        this.pri = this.selectedpriority?.length > 0 ? this.selectedpriority?.toString() : '';
        this.selectedPortfolio = [null, '', undefined].includes(params['portfolio_id']) ? null : params['portfolio_id'];
      })
    this.isAvgChartRenderd = false;
    const url = `${PathConfig.LOANS}/${id}?sort_by=${this.sortBy}&sort_order=${this.orderBy}&limit=${this.pageLimit}&${this.queueType}=1&page=${this.currentPage}&account_work_activity_priority_status=${this.pri ?? ''}&user_id=${this.selecteduser}&loan_status=${this.selectedMainStatus ?? ''}&loan_sub_status_label=${this.selected_sub_status ?? ''}&portfolio=${this.selectedPortfolio ?? ''}`;
    this.httpService.get(url).subscribe((res: any) => {
      this.loanDetail = res;
      this.populateContactList();
    })

  }
  populateContactList(): void {
    // Clear the contactList in case this method is called multiple times
    this.contactList = [];
    // Add Applicant
    if (this.loanDetail.applicant) {
      this.contactList.push(this.loanDetail.applicant);
    }

    // Add Co-Applicant
    if (this.loanDetail.co_applicant) {
      this.contactList.push(this.loanDetail.co_applicant);
    }

    // Add Co-Signers
    if (this.loanDetail.co_signers && this.loanDetail.co_signers.length > 0) {
      this.loanDetail.co_signers.forEach((coSigner: any) => {
        this.contactList.push(coSigner);
      });
    }

    // Add Recipient
    if (this.loanDetail.recipient) {
      this.contactList.push(this.loanDetail.recipient);
    }

    // Add Company
    if (this.loanDetail.company) {
      const company = {
        applicant_type: 'COMPANY',
        id: this.loanDetail.company.id,
        firstname: this.loanDetail.company.company_name,
        lastname: '',
        mobile_phone: this.loanDetail.company.mobile_phone,
        home_phone: this.loanDetail.company.home_phone,
        work_phone: this.loanDetail.company.work_phone
      };
      this.contactList.push(company);
    }
    // Initialize a flag to check if dialing_no is found
    let dialingNumberFound = false;

    // Iterate over contactList to find the matching dialing_no
    for (let contact of this.contactList) {
      if (contact.mobile_phone === this.dailing_no) {
        this.selectedApplicantId = contact.id;
        this.selectedPhoneNumber = contact.mobile_phone;
        this.setDNCStatus(contact, 'mobile');
        dialingNumberFound = true;
        break;
      } else if (contact.home_phone === this.dailing_no) {
        this.selectedApplicantId = contact.id;
        this.selectedPhoneNumber = contact.home_phone;
        this.setDNCStatus(contact, 'home');
        dialingNumberFound = true;
        break;
      } else if (contact.work_phone === this.dailing_no) {
        this.selectedApplicantId = contact.id;
        this.selectedPhoneNumber = contact.work_phone;
        this.setDNCStatus(contact, 'work');
        dialingNumberFound = true;
        break;
      }
    }

    // If the dialing number was not found
    if (!dialingNumberFound) {

      let defaultContact;

      // If loan_type is "BUSINESS", set the company as the default contact
      if (this.loanDetail.loan_type === 'BUSINESS' && this.loanDetail.company) {
        defaultContact = this.contactList.find(contact => contact.applicant_type === 'COMPANY');
      } else {
        // Else, use contactList[0] as the default contact
        defaultContact = this.contactList[0];
      }

      // Check the preferred phone first
      if (defaultContact.preferred_phone === 'MOBILE' && defaultContact.mobile_phone) {
        this.selectedApplicantId = defaultContact.id;
        this.selectedPhoneNumber = defaultContact.mobile_phone;
        this.setDNCStatus(defaultContact, 'mobile');
      } else if (defaultContact.preferred_phone === 'HOME' && defaultContact.home_phone) {
        this.selectedApplicantId = defaultContact.id;
        this.selectedPhoneNumber = defaultContact.home_phone;
        this.setDNCStatus(defaultContact, 'home');
      } else if (defaultContact.preferred_phone === 'WORK' && defaultContact.work_phone) {
        this.selectedApplicantId = defaultContact.id;
        this.selectedPhoneNumber = defaultContact.work_phone;
        this.setDNCStatus(defaultContact, 'work');
      } else if (defaultContact.mobile_phone) {
        this.selectedApplicantId = defaultContact.id;
        this.selectedPhoneNumber = defaultContact.mobile_phone;
        this.setDNCStatus(defaultContact, 'mobile');
      } else if (defaultContact.home_phone) {
        this.selectedApplicantId = defaultContact.id;
        this.selectedPhoneNumber = defaultContact.home_phone;
        this.setDNCStatus(defaultContact, 'home');
      } else if (defaultContact.work_phone) {
        this.selectedApplicantId = defaultContact.id;
        this.selectedPhoneNumber = defaultContact.work_phone;
        this.setDNCStatus(defaultContact, 'work');
      }
    }
  }


  // Utility function to set the DNC status based on contact type and phone type
  setDNCStatus(contact: any, phoneType: string): void {
    // Reset DNC status before setting it again
    this.isDNC = false;

    if (phoneType === 'mobile') {
      this.isDNC = (contact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a1 === 'TRUE') ||
        (contact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a2 === 'TRUE') ||
        (contact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_mobile_calling_c1 === 'TRUE') ||
        (contact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_mobile_calling_c2 === 'TRUE') ||
        (contact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_mobile_calling_c === 'TRUE') ||
        (contact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_mobile_calling_r === 'TRUE');
    } else if (phoneType === 'home') {
      this.isDNC = (contact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a1 === 'TRUE') ||
        (contact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a2 === 'TRUE') ||
        (contact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_home_phone_calling_c1 === 'TRUE') ||
        (contact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_home_phone_calling_c2 === 'TRUE') ||
        (contact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_home_phone_calling_c === 'TRUE') ||
        (contact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_home_phone_calling_r === 'TRUE');
    } else if (phoneType === 'work') {
      this.isDNC = (contact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a1 === 'TRUE') ||
        (contact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a2 === 'TRUE') ||
        (contact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_work_phone_calling_c1 === 'TRUE') ||
        (contact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_work_phone_calling_c2 === 'TRUE') ||
        (contact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_work_phone_calling_c === 'TRUE') ||
        (contact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_work_phone_calling_r === 'TRUE');
    }
  }

  onPhoneSelect(event: any) {
    const selectedValue = event.target.value; // e.g., "10028-mobile"
    const [applicantId, phoneType] = selectedValue.split('-');
    this.selectedApplicantId = applicantId;
    // Find the selected contact by applicantId
    const selectedContact = this.contactList.find(contact => contact.id === Number(applicantId));

    if (selectedContact) {
      // Reset DNC flag before setting it again
      this.isDNC = false;

      // Check for mobile, home, or work phone based on the selected option
      if (phoneType === 'mobile') {
        this.selectedPhoneNumber = selectedContact.mobile_phone;

        // Check DNC for APPLICANT and CO-APPLICANT
        this.isDNC = (selectedContact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a1 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_mobile_calling_a2 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_mobile_calling_c1 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_mobile_calling_c2 === 'TRUE') ||
          (selectedContact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_mobile_calling_c === 'TRUE') ||
          (selectedContact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_mobile_calling_r === 'TRUE');
      } else if (phoneType === 'home') {
        this.selectedPhoneNumber = selectedContact.home_phone;
        this.isDNC = (selectedContact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a1 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_home_phone_calling_a2 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_home_phone_calling_c1 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_home_phone_calling_c2 === 'TRUE') ||
          (selectedContact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_home_phone_calling_c === 'TRUE') ||
          (selectedContact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_home_phone_calling_r === 'TRUE');
      } else if (phoneType === 'work') {
        this.selectedPhoneNumber = selectedContact.work_phone;

        // Check DNC for APPLICANT and CO-APPLICANT
        this.isDNC = (selectedContact.applicant_type === 'APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a1 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-APPLICANT' && this.loanDetail.dnc_list_work_phone_calling_a2 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-SIGNER-1' && this.loanDetail.dnc_list_work_phone_calling_c1 === 'TRUE') ||
          (selectedContact.applicant_type === 'CO-SIGNER-2' && this.loanDetail.dnc_list_work_phone_calling_c2 === 'TRUE') ||
          (selectedContact.applicant_type === 'COMPANY' && this.loanDetail.dnc_list_work_phone_calling_c === 'TRUE') ||
          (selectedContact.applicant_type === 'RECIPIENT' && this.loanDetail.dnc_list_work_phone_calling_r === 'TRUE');
      }
    }
  }

  formatName(person: any): string {
    return `${person.firstname} ${person.middlename ? person.middlename + ' ' : ''}${person.lastname}`;
  }
  phoneCallStart() {
    // Ensure a contact and phone number are selected
    const url = `${PathConfig.MAKE_CALL}`;
    this.selectedPhoneNumber = this.phoneForm.getRawValue().selectedPhoneNumber;
    let param: any = {
      phone_number: this.selectedPhoneNumber // Same phone number is used
    };
    if (!this.loanID) {
      param.name = this.phoneForm.getRawValue().name;
    }
    if (this.selectedApplicantId && this.loanID) {
      const selectedContact = this.contactList.find(contact => contact.id === Number(this.selectedApplicantId));
      if (selectedContact) {
        // Add either company_id or applicant_id based on applicant type
        if (selectedContact.applicant_type === 'COMPANY') {
          param.company_id = this.selectedApplicantId;
        } else {
          param.applicant_id = this.selectedApplicantId;
        }
      }
    }
    // Send POST request to make a phone call
    this.httpService.post(url, param).subscribe(
      (response) => {
        this.msgService.showSuccess(response?.message);
        this.isTransferCallActive = true;
      },
      (error) => {
        this.isTransferCallActive = false;
        const errorMsg = error?.error?.message;
        this.msgService.showError(errorMsg);
      }
    );
  }

  onSubmit() {
    // Validate the form and call phoneCallStart if valid
    if (this.phoneForm.valid) {
      this.phoneCallStart();
    }
  }
  callHangupAndPostdisposition(){
    if(this.addActivityForm.valid){
    const url = `${PathConfig.CALL_HANG_UP_AND_POST_DISPOSITION}`;
    let param: any = this.addActivityForm.getRawValue();
    if (!this.loanID) {
      param.loan_id = this.phoneForm.getRawValue().loan_id;
    }
    if (this.loanID) {
      param.loan_id = this.loanDetail.loan_id;

    }
    this.selectedPhoneNumber = this.phoneForm.getRawValue().selectedPhoneNumber;
    param.phone_number = this.selectedPhoneNumber;
    this.httpService.post(url, param).subscribe(
      (response) => {
        this.msgService.showSuccess(response?.message);
        this.resetForm();

            // Additional UI/State reset
            this.selectedItem = null;
            this.getSelectedItem(null);
            this.disableHangupAndEndCallButton = true;
            this.isTransferCallActive =false;
      },
      (error) => {
        const errorMsg = error?.error?.message;
        this.msgService.showError(errorMsg);
      }
    );
  }

  }
resetForm() {
  this.addActivityForm.reset();

  // Iterate over each control and clear validators, mark as pristine and untouched
  Object.keys(this.addActivityForm.controls).forEach(key => {
      const control = this.addActivityForm.get(key);
      if (control) {
          control.clearValidators();
          control.updateValueAndValidity();
          control.markAsPristine();
          control.markAsUntouched();
      }
  });

  // Finally, reset any custom validators or states on the form group level
  this.addActivityForm.clearValidators();
  this.addActivityForm.updateValueAndValidity();
}
  onNoteValueChange(e:any){
    var val=e.target.value;
    this.addActivityForm.controls.comments.patchValue(val.trim());
    this.addActivityForm.controls.comments.markAsTouched();
    this.addActivityForm.controls.comments.updateValueAndValidity();
  }
  getSelectedItem(e: any) {
    if (e === null) {
        this.isAcivityPTPActive = false;
        this.addActivityForm.get('ptp_date')?.clearValidators();
        this.addActivityForm.get('ptp_date')?.updateValueAndValidity();
        return;
    }
    
    this.selectedItem = e.target.value;
    // Assuming "3: 102" corresponds to the "Promised to Pay" activity type
    if (this.selectedItem === "3: 102") {
        this.isAcivityPTPActive = true;
        // Set ptp_date as required if activity type is Promised to Pay
        this.addActivityForm.get('ptp_date')?.setValidators([Validators.required, isFutureDate]);
    } else {
        this.isAcivityPTPActive = false;
        // Remove the required validator if not Promised to Pay
        this.addActivityForm.get('ptp_date')?.setValidators([isFutureDate]);
    }
    this.addActivityForm.get('ptp_date')?.updateValueAndValidity();
}
  futureDateCheck(e?:Date){
    if(e){
      this.selectedDate=this.addActivityForm.controls.ptp_date.value;
      let amt=this.curamt;

      if((this.addActivityForm.controls.ptp_date.value!='' && this.addActivityForm.controls.ptp_date.value!=undefined && 
        this.addActivityForm.controls.ptp_date.value!=null)){

        let cDate = moment(new Date()).format("YYYY-MM-DD");
        let sDate = moment(e).format("YYYY-MM-DD")

        if(sDate>cDate){
          this.isPastDate=false;
          this.errormsg=this.errormsg==this.translateService.instant('PTP Date cannot be blank if there is a PTP Amount.')?'':this.errormsg;
          this.errormsg=(amt==0 ||amt=='' ||amt==undefined)? this.translateService.instant('PTP amount cannot be zero or blank if there is a PTP Date.'):'';

          this.isButtonDisable=this.errormsg!=''?true:false;
        }
        else{
        this.isPastDate=true;
        this.errormsg=(amt==''||amt==undefined || amt==0)?this.translateService.instant('PTP amount cannot be zero or blank if there is a PTP Date.'):'';
        this.dateerrormsg="";
        this.isButtonDisable=true;
        }

      }

      if((this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==undefined || 
        this.addActivityForm.controls.ptp_date.value==null)&&(amt=='' || amt==undefined || amt==null)){

        this.errormsg='';
        this.isPastDate=false;
        this.isButtonDisable=false;
      }
      if((this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==undefined || 
        this.addActivityForm.controls.ptp_date.value==null)&&(amt!='' && amt!=undefined && amt!=null)){
        this.isPastDate=false;
        this.errormsg=this.translateService.instant('PTP Date cannot be blank if there is a PTP Amount.');
        this.isButtonDisable=this.errormsg!=''?true:false;
      }
    }
    else{
      this.errormsg='';
      this.isButtonDisable=false;
    }
    let amt=this.addActivityForm.controls.ptp_amount.value;
      this.getAmount(amt,2);
  }

  getAmount(e:any,type:number){
    let amt;
    if(type==2){
      amt=e;
      this.curamt=e;
    }
    
    else if(type==1){
      amt=e.target.value.slice(1);
      this.curamt=amt;
    }
    if(((this.selectedDate==undefined || this.selectedDate=='' || this.selectedDate==null) && (amt=='')) || 
      ((this.addActivityForm.controls.ptp_date.value!=undefined && this.addActivityForm.controls.ptp_date.value!='' && 
      this.addActivityForm.controls.ptp_date.value!=null) && (amt!='' && amt!=0) )){

      this.isButtonDisable=false;
      this.errormsg='';
    // this.isPastDate=false;
    }
    else if((this.addActivityForm.controls.ptp_date.value!=undefined && this.addActivityForm.controls.ptp_date.value!='' && 
      this.addActivityForm.controls.ptp_date.value!=null) && (amt=='' || amt==0)){

      this.errormsg="PTP amount cannot be zero or blank if there is a PTP Date.";
      this.isButtonDisable=true;
    }
    else if((this.addActivityForm.controls.ptp_date.value==undefined || this.addActivityForm.controls.ptp_date.value=='' || 
      this.addActivityForm.controls.ptp_date.value==null) && (amt!='' && amt!=0 && amt!=undefined) ){

      this.errormsg="PTP Date cannot be blank if there is a PTP Amount.";
      this.isButtonDisable=true;
    }
    else{
      this.errormsg=""
      this.isButtonDisable=false;
    }
    if(this.isPastDate){

      this.isButtonDisable=true;
    }


          /***********convert to decimal***************** */

    let data:any= this.addActivityForm.controls.ptp_amount.value;

    this.addActivityForm.controls.ptp_amount.patchValue( data==''?'':parseFloat(data).toFixed(2));
    this.addActivityForm.controls.ptp_amount.updateValueAndValidity();

    /***************************************** */
  
  }


  ngOnDestroy(): void {
    // Clear interval to avoid memory leaks
    if (this.agentStatusInterval) {
      clearInterval(this.agentStatusInterval);
    }
    this.eventSubscription.forEach(sub => sub.unsubscribe());
  }

}
