export class MsgConstantConfig {
  public static REFRESH_CLIENT_LIST:string   = 'LS_REFRESH_CLIENT_LIST';
  public static REFRESH_USER_LIST:string   = 'LS_REFRESH_USER_LIST';
  public static REFRESH_ROLE_LIST:string   = 'LS_REFRESH_ROLE_LIST';
  public static REFRESH_ROLE:string   = 'LS_REFRESH_ROLE';
  public static REFRESH_ACTIVITY:string   = 'LS_REFRESH_ACTIVITY';
  public static QUEUE_DETAIL_SEC_HEADING:string   = 'LS_QUEUE_DETAIL_SEC_HEADING';
  public static USER_LOGGED_IN:string   = 'LS_USER_LOGGED_IN';
  public static USER_LOGGED_OUT:string   = 'LS_USER_LOGGED_OUT';
  public static AUTH_TOKEN_EXPIRED:string   = 'LS_AUTH_TOKEN_EXPIRED';
  public static REFRESH_NOTIFICATION_LIST:string   = 'LS_REFRESH_NOTIFICATION_LIST';
  public static REFRESH_NOTIFICATION:string   = 'LS_REFRESH_NOTIFICATION';
  public static REFRESH_LOGGEDIN_USER_DETAIL:string   = 'LS_REFRESH_LOGGEDIN_USER_DETAIL';
  public static REFRESH_MAPPING_LIST:string   = 'LS_REFRESH_MAPPING_LIST';
  public static NOTIFICATION_FIELD:string   = 'LS_NOTIFICATION_FIELD';
  public static REFRESH_LOAN:string   = 'LS_REFRESH_LOAN';
  public static REFRESH_NOTIFICATION_LOG:string   = 'LS_REFRESH_NOTIFICATION_LOG';
  public static REFRESH_INPUT_CATEGORY_LIST:string   = 'LS_REFRESH_INPUT_CATEGORY_LIST';
  public static REFRESH_LOAN_TRANSACTION_LIST:string   = 'LS_REFRESH_LOAN_TRANSACTION_LIST';
  public static REFRESH_ACCOUNT_HISTORY_LIST:string   = 'LS_REFRESH_ACCOUNT_HISTORY_LIST';
  public static REFRESH_ASSIGNMENT_LIST:string   = 'LS_REFRESH_ASSIGNMENT_LIST';
  public static REFRESH_PORTFOLIO_LIST:string   = 'LS_REFRESH_PORTFOLIO_LIST';

  public static REFRESH_ASSIGNMENT:string   = 'LS_REFRESH_ASSIGNMENT';
  public static USER_REMOVE_NOTIFICATION:string = 'LS_USER_REMOVE_NOTIFICATION';
  public static REFRESH_CB_BUILDER:string = 'LS_REFRESH_CB_BUILDER';
  public static REFRESH_DAILY_GOAL_REPORT:string = 'LS_REFRESH_DAILY_GOAL_REPORT';
  public static REFRESH_CHAMPION_CHALLENGER_REPORT:string = 'LS_REFRESH_CHAMPION_CHALLENGER_REPORT';
  public static REFRESH_STANDARD_COFIGURATION_DATA:string = 'LS_REFRESH_STANDARD_COFIGURATION_DATA';
  public static SHOW_HIDE_CLIENT_PROGRESS_BAR:string = 'LS_SHOW_HIDE_CLIENT_PROGRESS_BAR';
  public static REFRESH_LOAN_IN_PROGRESS_POP_UP:string = 'LS_REFRESH_LOAN_IN_PROGRESS_POP_UP';
  public static REFRESH_FILES_LIST:string   = 'LS_REFRESH_FILES_LIST';
  public static REFRESH_QUEUE_DETAIL_DATA:string   = 'LS_REFRESH_QUEUE_DETAIL_DATA';
  public static REFRESH_VIEW_USER_ID:string   = 'LS_REFRESH_VIEW_USER_ID';
  public static CHANGE_USER_PASSWORD:string   = 'LS_CHANGE_USER_PASSWORD';
  public static RESET_DATA_WAREHOUSE_ACCORDION_SEARCH:string   = 'LS_RESET_DATA_WAREHOUSE_ACCORDION_SEARCH';
  public static SERVER_ERROR:string   = 'LS_SERVER_ERROR';
  public static REFRESH_VIEW_LANGUAGE_ID:string   = 'LS_REFRESH_VIEW_LANGUAGE_ID';
  public static RESET_LOADING_BAR_PROGRESS:string = 'LS_RESET_LOADING_BAR_PROGRESS';


}
