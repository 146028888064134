import { AbstractControl } from '@angular/forms';

/**
Number Validation
--------------------
Valid formats:
12354
52
 */
export function ValidateNumber(control: AbstractControl) {
  const regExp = /^[0-9]*$/;
  if (control.value && !regExp.test(control.value)) {
    return { invalidNumber: true };
  }
  return null;
}