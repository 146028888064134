import { AbstractControl } from '@angular/forms';

/**
US Zip Validation
--------------------
Valid formats:
20521-9000
42223
85254
 */
export function ValidateUSZip(control: AbstractControl) {
  const regExp = /^[0-9]{5}(?:-[0-9]{4})?$/;
  if (control.value && !regExp.test(control.value)) {
    return { invalidZip: true };
  }
  return null;
}