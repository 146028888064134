import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  @Input() confirmationBoxTitle ='';
  @Input() confirmationMessage = '';
  @Input() confirmationYesButton = ''; 
  @Input() confirmationNoButton = '';
  @Input() showConfirmationYesButton = true;
  @Input() showConfirmationNoButton = true;
  @Input() showCloseButton = true;
  @Input() confirmationMessage2 = null;
  @Input() swapConfirmationButton = false;
  constructor(public activeModal: NgbActiveModal,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }

  ngOnInit(): void {
  }

}
