import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { DirtyComponent } from '../models/dirty-component/dirty.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckGuard {
  constructor(private modalService: NgbModal, private translateService:TranslateService
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');}

  canDeactivate: CanDeactivateFn<DirtyComponent> = (
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    if (component?.canDeactivate()) {
      return this.openConfirmModal();
    } else {
      return true;
    }
  }

  openConfirmModal(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'lg',
      });
      modalRef.componentInstance.confirmationBoxTitle = this.translateService.instant('Save Changes?');
      modalRef.componentInstance.confirmationMessage =
        this.translateService.instant('You have made a change to this page. Do you want to save your changes?');
      modalRef.componentInstance.confirmationYesButton = this.translateService.instant('- Go back and Save before leaving this page');
      modalRef.componentInstance.confirmationNoButton = this.translateService.instant('- Discard changes and leave this page');
      modalRef.result.then((response) => {
        resolve(!response);
      });
    });
  }
}
