<div class="login-form-content">
    <form *ngIf="showForm" class="login-form m-auto" autocomplete="off"  ngForm [formGroup]="resetForm" (ngSubmit)="onSubmit($event)"
        novalidate>
        <h4 class="text-center mb-5 form-heading">{{"Reset Pin"|translate}}</h4>
        <div class="form-group">
            <div class="form-control" readonly>{{queryParams.email}}</div>
        </div>
        <div class="form-group">
            <label>{{"New Pin"|translate}}</label>
            <input type="password"  autocomplete="new-password" formControlName="pin" class="form-control" value="" />
        </div>
        <ng-container>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin.touched &&
            resetForm.controls.pin.errors?.required
          ">
                    {{"Please enter New Pin"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin.touched &&
            resetForm.controls.pin.errors?.minlength
          ">
                    {{"New Pin must be of minimum 4 digit"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin.touched &&
            resetForm.controls.pin.errors?.maxlength
          ">
                    {{"New Pin must be of maximum 6 digit"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
          resetForm.controls.pin.touched &&
          resetForm.controls.pin.errors?.invalidNumber
        ">
                    {{"New Pin must be a number"|translate}}.
                </small>
            </div>
        </ng-container>
        <div class="form-group">
            <label>{{"Confirm Pin"|translate}}</label>
            <input type="password"  autocomplete="new-password" name="pin_confirmation" formControlName="pin_confirmation" class="form-control"
                value="" />
        </div>
        <ng-container>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin_confirmation.touched &&
            resetForm.controls.pin_confirmation.errors?.required
          ">
                    {{"Please enter Confirm Pin"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin_confirmation.touched &&
            resetForm.controls.pin_confirmation.errors?.minlength
          ">
                    {{"Confirm Pin must be of minimum 4 digit"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin_confirmation.touched &&
            resetForm.controls.pin_confirmation.errors?.maxlength
          ">
                    {{"Confirm Pin must be of maximum 6 digit"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
            resetForm.controls.pin_confirmation.touched &&
            resetForm.controls.pin_confirmation.errors?.matched
          ">
                    {{"Confirm Pin and Pin mismatch"|translate}}.
                </small>
            </div>
            <div>
                <small class="form-error text-danger" *ngIf="
          resetForm.controls.pin_confirmation.touched &&
          resetForm.controls.pin_confirmation.errors?.invalidNumber
        ">
                    {{"Confirm Pin must be a number"|translate}}.
                </small>
            </div>

        </ng-container>
        <div class="mt-3">
            <button type="submit" class="btn btn-primary btn-block text-uppercase">
                {{"Reset Pin"|translate}}
            </button>
        </div>
    </form>
    <div class="login-form m-auto" *ngIf="!showForm">
        <h4 class="text-center mb-5 form-heading">{{"Reset Pin"|translate}}</h4>
        <div class="alert alert-danger">
            {{ errorMessage }}
        </div>
        <div class="text-center font-size-14 mt-2">
            <button type="button" (click)="goToRoute('/')" class="btn btn-primary btn-block">
                {{"Back to Login"|translate}}
            </button>
        </div>
    </div>
</div>