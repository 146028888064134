import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BroadcastService, HttpService, LocalStorageService, MessageService, UserService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { ConfirmedValidator } from 'src/app/core/validators';
import { environment } from 'src/environments/environment';
import { ValidateNumber } from '../../../core/validators/number.validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reset-pin',
  templateUrl: './reset-pin.component.html',
  styleUrls: ['./reset-pin.component.scss']
})
export class ResetPinComponent implements OnInit,OnDestroy,AfterViewInit {

  resetForm!:UntypedFormGroup;
  eventSubscription:Array<Subscription> = [];
  queryParams!:any;
  submitted:boolean = false;
  showForm:boolean = false;
  errorMessage: string = '';
  loginUrl: string = environment.LOGIN_URL;
  constructor(private fb: UntypedFormBuilder,
    private httpService:HttpService,
    private route:ActivatedRoute,
    private router:Router,
    private msgService:MessageService,
    private userService: UserService,
    private localStorageService:LocalStorageService,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    this.route.queryParams.subscribe(params => {
      //get query params
      this.queryParams = params;
    });
  }

  ngAfterViewInit(){
    //remove all logged in user
    //this.removeLoggedInUser();
  }

  ngOnInit(): void {
    this.httpService.post(PathConfig.AUTH_RESET_PIN_TOKEN_VALIDATE,this.queryParams)
    .subscribe((response)=>{
      this.showForm = true;
    },(err)=>{
      this.errorMessage = err.error.message;
      this.showForm = false;
    });
    // reset form
    this.resetForm = this.fb.group({
      'pin': new UntypedFormControl('', [
        Validators.required, Validators.minLength(4), Validators.maxLength(6), ValidateNumber]),
      'pin_confirmation': new UntypedFormControl('', Validators.compose([
        Validators.required, Validators.minLength(4), Validators.maxLength(6),ValidateNumber])),
    }, {
      validator: ConfirmedValidator('pin', 'pin_confirmation')
    });
  }

  /**
   * goToRoute
   */
   public goToRoute(route:any) {
    let clientId = this.userService.getClientId();
    this.router.navigate([clientId + route]);
  }

  //submit form
  onSubmit(event:any):void {
    this.resetForm.markAllAsTouched();
    if(this.resetForm.valid && !this.submitted) {
      this.submitted = true;
      const param = {...this.queryParams,...this.resetForm.value};
      this.httpService.post(PathConfig.AUTH_RESET_PIN,param)
      .subscribe((response)=>{
        this.msgService.showSuccess(response.message);
        this.goToRoute('/');
        this.submitted = false;
        this.resetForm.reset();
      },(err)=>{
        this.msgService.showError(err.error.message);
        this.submitted = false;
      });

    }
  }

  ngOnDestroy():void{
    //remove all events
    this.eventSubscription.forEach((event)=>event.unsubscribe());
  }

  //remove all logged in user
  removeLoggedInUser(){
    this.broadCastService.broadcast(MsgConstantConfig.USER_REMOVE_NOTIFICATION);
    const items = { ...localStorage };
    //const clientSlug = ClientUtils.getClientSlug(this.route, this.router);
    for(let key in items){
      if((key.indexOf('LS') > -1) || (key.indexOf('theme') > -1) || (key.indexOf('ng2Idle') > -1)){
        this.localStorageService.remove(key);
      }
    }
  }

}
