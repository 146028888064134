import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth-service';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';

@Component({
  selector: 'app-error-not-authorized',
  templateUrl: './error-not-authorized.component.html',
  styleUrls: ['./error-not-authorized.component.scss']
})
export class ErrorNotAuthorizedComponent implements OnInit {

  currentYear: string = moment().format('YYYY');
  sectionTitle: string = '';
  eventSubscription:Array<Subscription> = [];
  showFullPage:boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientDetailService:ClientDetailService,
    private authService:AuthService
  ) {
    this.sectionTitle = this.route.snapshot.data?.title || '';
    this.showFullPage = this.route.snapshot.queryParams?.fp == 'H' ? false : true;
  }

  ngOnInit(): void {}

  goBack():void{
    const slug = this.clientDetailService.CLIENT_ID;
    if (slug) {
      const isAuthenticated = this.authService.isAuthenticated();
      const url = !isAuthenticated ? `/${slug}` : `/${slug}/home`;
      this.router.navigate([url]);
    } else {
      window.location.href = '/';
    }
  }
}
