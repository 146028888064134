import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ls-nodata-found',
  templateUrl: './nodata-found.component.html',
  styleUrls: ['./nodata-found.component.scss'],
})
export class LSNodataFoundComponent implements OnInit {
  @Input() message: string = 'No Records Found!';
  constructor(private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }

  ngOnInit(): void {}
}
