import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
    name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    transform(value: any, format?: any): any {
        value = value.replace(/\s/g, "T");
        if (!format) {
            format = 'dd/MM/YYYY';
        }
        return super.transform(value, format);
    }
}
