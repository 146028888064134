import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import {map, mergeMap ,filter} from 'rxjs/internal/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  pageType:string = 'DEFAULT';
  sectionTitle:string = '';
  constructor(private router:Router, private activatedRoute:ActivatedRoute) { 

    //get child events
    router.events
          .pipe(filter(event => event instanceof NavigationEnd),
            map(() => {
              let route = activatedRoute.firstChild;
              let child = route;
              while (child) {
                if (child.firstChild) {
                  child = child.firstChild;
                  route = child;
                } else {
                  child = null;
                }
              }
              return route;
            }),
            mergeMap((route:any) => route.data)
          )
          .subscribe((data:any) => {
            this.pageType = data?.pageType || 'DEFAULT';
          });    

          const routeEvents = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
              this.sectionTitle  = this.activatedRoute.snapshot.firstChild?.data?.title || '';
            }
          });
     
      
  }

  ngOnInit(): void {
  }

}
