import { AbstractControl } from '@angular/forms';
import moment from 'moment';

/**
futuredate Validation
--------------------
Valid formats:
YYYY-MM-DD
 */

export function isFutureDate(control: AbstractControl) {
   let e=control.value;
   let cDate = moment(new Date()).format("YYYY-MM-DD");
   let sDate = moment(e).format("YYYY-MM-DD");

  if (control.value && sDate<=cDate) {
    return { invalidPTPDate: true };
  }
  return null;

}