
import { Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class BaseService implements OnDestroy {
  
  constructor() {
  }

  ngOnDestroy() {
   
  }
}
