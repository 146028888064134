<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
        <div class="modal-header align-items-center " >
            <h6 class="modal-title modal-title-success">{{'Take Payment'|translate}}</h6>
            <button type="button" class="close mx-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body px-0 pt-0">
            <div class="lms-module-card">
                <div class="card-body">
                    <div class="card-form-content card-form-success">
                        <div class="col-12 mb-4">
                           <div class="text-center">
                                <button type="button" class="btn btn-block btn-success-light w-50 mx-auto"    (click)="openTakePaymentModal('')">
                                {{'Input manual payment'|translate}}
                                </button>
                           </div>
                        </div>
                        <div class="col-auto text-center or-text">
                            <span>{{'OR'|translate}}</span>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-12 ">
                                <!-- <a [href]="take_payment_url" *ngIf="take_payment_url!=''" (click)="modal.dismiss('Cross click')" target="_blank" style="color:white;text-decoration:none;">
                                <button type="button" class="btn btn-block btn-success-light" >
                                {{'Go to Payment Portal'|translate}}
                                </button> </a> -->
                                <!-- <a [href]="take_payment_url"  *ngIf="take_payment_url==''" (click)="modal.dismiss('Cross click')" target="_blank" style="color:white;text-decoration:none;pointer-events: none;">
                                <button type="button" [disabled]="true" class="btn btn-block btn-success-light" >
                                {{'Go to Payment Portal'|translate}}
                                </button> </a> -->
                                <h6 class="modal-title modal-title-success mb-3">{{'Go to Payment Portal'|translate}}</h6>
                                <div class="payment-list-wrapper text-center">
                                    <div class="ml-0 payment-icon" *ngFor="let portal of activePaymentPortals">
                                        <ng-container *ngIf="portal.active">
                                            <a [href]="portal.payment_portal_url" target="_blank" (click)="modal.dismiss('Cross click')"> 
                                                <!-- <img [src]="portal.logo_url"> -->
                                                <img *ngIf="portal.tmp_logo_url"  [src]="portal.tmp_logo_url">
                                                <span class="ml-1">{{ portal.name }}</span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>                                
                            </div>    
                        </div>
                        <div class="row pt-4">
                            <div class="col-12">
                                <p class="note-font mb-0">Note: Clicking on any payment portal will redirect you to a third-party website.</p>
                            </div>
                        </div>
                        <!--------------------------------->
                        <!-- <br> -->
                        <!-- <div class="row align-items-center">
                            <div class="col-sm-8">
                            
                            
                              <span *ngIf="!this.TakePaymentPermission" class="d-inline-block font-size-14 lms-text-light-pink ml-2">
                                You don’t have the permission to route account.
                              </span>
                            </div>
                            
                            <div class="col-sm-2">
                              <button *ngIf="!TakePaymentPermission"  type="button" class="btn btn-pink btn-150" (click)="openGetApprovalModal(GetApprovalModalTakePayment)">
                                Get Approval
                              </button>
                            </div>
                            </div> -->
                        <!------------------------>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>