import { AbstractControl } from '@angular/forms';

/**
Initials Validation
--------------------
Valid formats:
AA
A345
 */
export function ValidateInitials(control: AbstractControl) {
  const regExp = /^[a-zA-Z0-9]{2,4}$/;
  if (control.value && !regExp.test(control.value)) {
    return { invalidInitials: "Invalid Initials" };
  }
  return null;
}