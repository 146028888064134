<div class="file-upload-list">
    <div class="file-upload-item row align-items-center">
      <div class="col">
        <div class="file-name text-truncate">
          {{fileName}}
          <button class="btn btn-icon btn-remove btn-sm">
            <img
             *ngIf="fileName"
              src="../assets/images/icons/remove-icon.png"
              alt=""
              aria-hidden="true"
              (click)="onFileReset()"
            />
          </button>
        </div>
      </div>
      <div class="col-auto" *ngIf="isEditPermission">
        <label
          class="
            btn btn-block btn-file-upload btn-icon-success
            mb-0
          "
        >
        <input
          #fileInput
          type="file"
          id="{{uniqueID}}"
          style="display: none"
          (change)="onFileChange($event)"
        />
          {{'Upload'|translate}}
          <img
            src="../assets/images/icons/upload-icon.png"
            alt=""
            aria-hidden="true"
            (click)="onUploadBtnClick()"
          />
        </label>
      </div>
    </div>
  </div>