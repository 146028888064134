import { HttpClient } from '@angular/common/http';
import { Injectable }  from  '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UserService } from '..';
import { PathConfig } from '../config/path.config';
import { ClientDetailService } from './client-detail-service';

@Injectable({
  providedIn: 'root' 
})
export class AppInitService { 
    constructor(private http:HttpClient,private router:Router,private clientDetailService:ClientDetailService) {
    }   
     
    init():Promise<boolean> {
        return new Promise((resolve, reject) => {
         
            const windowURL = window.location.pathname.split("/");
            const clientId = (windowURL.length > 1) ?  windowURL[1] : '';
            
            const url = `${PathConfig.LS_CLIENTS_DETAIL}/${clientId}`;
            if(!clientId){
              resolve(false);
              window.location.href = environment.LOGIN_URL;

            }
            this.http.get(url).subscribe((response) => {
                 this.clientDetailService.setClientInfo(response);
                 resolve(true);
            }, (err) => {
                resolve(false);
                const status = err?.status || '';
                switch(status){
                  case 404:
                    this.router.navigate(['/error-404'], { queryParams: { fp:'F'} });
                  break;
                  case 403:
                    this.router.navigate(['/error-403'], { queryParams: { fp:'F'} });
                  break;
                  default:
                    this.router.navigate(['/error-404'], { queryParams: { fp:'F'} });
                  break;
                }
               
            })
        });
    }
}