import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, NgForm, Validators, UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { Utility } from 'src/app/core/utils/utility';
import { DueDateValidator } from 'src/app/core/validators/due-date.validator';
import { LoanDetail } from 'src/app/shared/models/loan/loan.detail.model';

@Component({
  selector: 'app-reamortize-loan',
  templateUrl: './reamortize-loan.component.html',
  styleUrls: ['./reamortize-loan.component.scss']
})
export class ReamortizeLoanComponent implements OnInit {
  @Input() loanID!: string;
  @Input() loanDetail!:LoanDetail;
  @ViewChild('f') formElement!: NgForm;
  form!: UntypedFormGroup;
  submitted: boolean = false;
  isDirty:boolean = false;
  appform!: UntypedFormGroup;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  isReamortizePermission:boolean = false;

  after_apr:number=0;
  after_term:number=0;
  after_principal:number=0;
  after_payment:string='0';
  aprBeforeValue: any;


  constructor(
    private httpService:HttpService ,
    private msgService:MessageService,
    private broadCastService:BroadcastService,
    private modalService:NgbModal,
    private permissionService:PermissionService,
    public modal:NgbActiveModal,
    private fb:UntypedFormBuilder,
  ) {
    this.isReamortizePermission = this.permissionService.isSpecialPermission('reamortize');

    }

  ngOnInit(): void {


    this.form =  this.fb.group({

      loan_id:new UntypedFormControl(this.loanID, []),
      principal_balance: new UntypedFormControl('0', [Validators.required]),
      interest_balance: new UntypedFormControl('0', []),
      late_fees: new UntypedFormControl('0', [this.feesRangeValidator]),
      nsf_fees: new UntypedFormControl('0', [this.feesRangeValidator]),
      other_fees: new UntypedFormControl('0', [this.feesRangeValidator]),
      apr: new UntypedFormControl('0', [this.percentageValidator]),
      term_remaining: new UntypedFormControl('0', []),
      frequency: new UntypedFormControl('', [Validators.required]),
      due_date: new UntypedFormControl('', [Validators.required]),
      amount_due: new UntypedFormControl('0', [Validators.required,this.amountDueRangeValidator]),
      payments: new UntypedFormControl('0', []),
      first_due_date: new UntypedFormControl('', []),
    },{validator: [
      DueDateValidator('due_date','first_due_date'),
    ]});
    this.appform = new UntypedFormGroup({
      // id: new FormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
       pin: new UntypedFormControl(null, [Validators.required]),
     });

     this.form.controls.first_due_date.patchValue(this.loanDetail.first_due_date);
     console.log(this.form.controls.first_due_date.value);

    this.form.valueChanges.subscribe((e) => (this.isDirty = true));


    this.form.valueChanges.subscribe(
      (val) => {
          let pmt;
          this.after_apr= val.apr/100;//this.loanDetail?.prev_apr==null?this.loanDetail?.current_apr:this.loanDetail?.prev_apr;
          this.after_term= val.term_remaining??0;//this.loanDetail?.remaining_term || 0;
          this.after_principal=val.principal_balance??0;  //his.loanDetail?.current_principal || 0;
          switch (val.frequency) {
            case 'WEEKLY':
              pmt=this.getPMT(this.after_apr/52,this.after_term,-this.after_principal);
              break;
            case 'BIWEEKLY':
              pmt=this.getPMT(this.after_apr/26,this.after_term,-this.after_principal);
              break;
            case 'MONTHLY':
              pmt=this.getPMT(this.after_apr/12,this.after_term,-this.after_principal);
              break;
            case 'SEMIMONTHLY':
              pmt=this.getPMT(this.after_apr/24,this.after_term,-this.after_principal);
              break;
            case 'ANNUAL':
              pmt=this.getPMT(this.after_apr/1,this.after_term,-this.after_principal);
              break;
            case 'SEMIANNUAL':
              pmt=this.getPMT(this.after_apr/2,this.after_term,-this.after_principal);
              break;
            default:
              break;
          }
           this.after_payment =parseFloat(pmt+'').toFixed(2);
           this.after_payment=isNaN(parseFloat(this.after_payment))? '0': this.after_payment;
           this.after_payment=(this.after_principal==0||this.after_term==0)?'0':this.after_payment;

      });








    /********************************** */
  //  this.after_apr= this.loanDetail?.prev_apr==null?this.loanDetail?.current_apr:this.loanDetail?.prev_apr;
  //  this.after_term= this.loanDetail?.remaining_term || 0;
  //  this.after_principal=this.loanDetail?.current_principal || 0;

  //  this.after_payment=this.getPMT(this.after_apr/12,this.after_term,-this.after_principal);
   /****************************** */



   this.aprBeforeValue = (this.loanDetail?.prev_apr == null ? this.converData(this.loanDetail?.current_apr) : this.converData(this.loanDetail?.prev_apr)) || '0.00';

    if(this.loanDetail?.any_active_side_note_exist)
      this.form.disable();

  }

  amountDueRangeValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = value == '' || ( value >= -99999999 && value <= 99999999 );
    return isValid ? null : { currencyRange: true };
  }

  converData(d:any)
  {

    return    d==null?null: (parseFloat(d)*100).toFixed(2);


  }
  getPMT(ir:any, np:any, pv:any)
  {

      /*
       * ir   - interest rate per month
       * np   - number of periods (months)
       * pv   - present value
       * fv   - future value
       * type - when the payments are due:
       *        0: end of the period, e.g. end of month (default)
       *        1: beginning of period
       */
      let pmt, pvif;

      let fv = 0;
      let type = 0;

      if (ir === 0)
          return -(pv + fv)/np;

      pvif = Math.pow(1 + ir, np);
      pmt = - ir * (pv * pvif + fv) / (pvif - 1);

      if (type === 1)
          pmt /= (1 + ir);

      return pmt;

  }

  // on submit form
  onSubmit() {

    if (this.form.valid && !this.submitted && !this.loanDetail?.any_active_side_note_exist) {
      this.submitted = true;
      const params = this.form.value;
      params.payments=this.after_payment;
      // params.frequency = this.loanDetail?.payment_frequency || '';

      delete params.first_due_date;

      const url = `${PathConfig.LOAN_REAMORTIZATIONS}`;
      this.httpService.post(url, params)
      .subscribe((response:any) => {
        this.msgService.showSuccess(response.message);
        // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
        this.submitted = false;
        this.isDirty = false;
        this.modalService.dismissAll();
      },
      (err:any) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.submitted = false;
      });
    }
  }

  // on Form submit
  onFormSubmit(){
    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

  openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }




  verifyPin()
  {
    if(this.appform.valid)
      {
        const params = this.appform.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.isReamortizePermission=true;
          this.onSubmit();
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.appform.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }


  }

  percentageValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = value == '' || ( value >= 0 && value <= 500 );
    return isValid ? null : { percentageRange: true };
  }

  feesRangeValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = value == '' || ( value >= 0 && value <= 99999999 );
    return isValid ? null : { currencyRange: true };
  }

}
