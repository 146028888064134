import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultilingualService {

  constructor() { }

  private languageResponse = new Subject();
  currentLanguageResponse = this.languageResponse.asObservable();


  updateSelectedLanguageResponse(languageResponse:any) {
    this.languageResponse.next(languageResponse)
  }

}
