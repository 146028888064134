import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { finalize, tap } from 'rxjs/operators';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  private loaderActive: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Reset loaderActive flag to false to show loader instantly on navigation start
        this.loaderActive = false;
        // Show loader on route navigation start
        this.loaderService.showLoader();
      } else if (event instanceof NavigationEnd) {
        // Hide loader on route navigation end
        this.loaderService.hideLoader();
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Hide loader for requests with hideLoader header
    if (req.headers.has('hideLoader')) {
      // console.log('bar : ',req?.url.indexOf('bar'))
      // console.log('chart : ',req?.url.indexOf('chart'))
      if((req?.url.indexOf('dashboard')!=-1 
      // || req?.url.indexOf('loan_activity_note')!=-1
      // || req?.url.indexOf('bar')!=-1
      // || req?.url.indexOf('chart')!=-1
      // || req?.url.indexOf('activity_types')!=-1
    ) && req?.method=='GET') {
        this.loaderService.hideLoader();
        this.loaderActive = false;
      }
      const headers = req.headers.delete('hideLoader');
      req = req.clone({ headers });
    } else {
      // Show loader only if no other requests are ongoing or if the loader is not active
        this.loaderService.showLoader();
        this.loaderActive = true;
    }

    // console.log('url : ',req?.url);
    // console.log('loader active : ',this.loaderActive);

    this.requests.push(req);

    return next.handle(req).pipe(
      tap(
        (response: any) => {
          if (response instanceof HttpResponse) {
            this.removeRequest(req);
          }
        },
        (error) => {
          const status = error?.status || '';
          switch(status){
            case 401:
              this.clearAllRequest();
              break;
            default:
              this.removeRequest(req);
              break;
          }
          // Handle error
        }
      ),
      finalize(() => {
        this.removeRequest(req);
        // Hide loader only if all requests are completed
        if (this.requests.length === 0 && this.loaderActive) {
          this.loaderService.hideLoader();
          this.loaderActive = false;
        }
      })
    );
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    if(this.loaderActive) {
      this.loaderService.isLoading.next(this.requests.length > 0);
    }
  }

  clearAllRequest() {
    this.requests = [];
    this.loaderService.isLoading.next(this.requests.length > 0);
  }
}
