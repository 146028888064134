import { AbstractControl } from '@angular/forms';

export function SpaceValidator(control: AbstractControl) {
    let value: string = control.value || '';
    let msg = "";
    if (!value) {
        return null;
    }
    let whitespace = /\s/;
    if(whitespace.test(value) === true){
        return { whitespace: true };
    }
    return null;
}