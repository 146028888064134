<nav class="nav client-nav border-bottom">
  <ng-container *ngFor="let item of list">
    <button
      type="button"
      (click)="onFilterSelected(item)"
      class="btn nav-item"
      [ngClass]="{ active: selectedFilter == item.id }"
    >
      <span>{{ item.label |translate }}</span>
    </button>
  </ng-container>
</nav>
