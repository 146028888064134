import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear:string = moment().format('YYYY');
  constructor( private translateService:TranslateService
  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }

  ngOnInit(): void {
  }

}
