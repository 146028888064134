import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from './truncate.pipe';
import { FilterPipe } from './filter.pipe';
import { SearchFilterPipe } from './filter.pipe';
import { SafePipe } from './safe.pipe.';
import { GroupByPipe } from './group-by.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { CustomDatePipe } from './date.pipe';
import { SsnFormatPipe } from './ssn.pipe';
import { ExcludeSelectedItemsPipe } from './exclude-selected-item.pipe';
import { OtpCountDownPipe } from './otp-count-down.pipe';
import { TitleCasePipe } from './convert-slug-to-titlecase.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterPipe,
    SafePipe,
    SearchFilterPipe,
    TruncatePipe,
    GroupByPipe,
    SlugifyPipe,
    CustomDatePipe,
    SsnFormatPipe,
    ExcludeSelectedItemsPipe,
    OtpCountDownPipe,
    TitleCasePipe
  ],
  exports: [TruncatePipe, FilterPipe, SearchFilterPipe, SafePipe,GroupByPipe,SlugifyPipe, CustomDatePipe, SsnFormatPipe,ExcludeSelectedItemsPipe,OtpCountDownPipe,TitleCasePipe]
})
export class PipesModule {
  static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule
    };
  }
}
