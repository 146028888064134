import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DataTableDirective } from 'angular-datatables';
import S3 from 'aws-sdk/clients/s3';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { HttpService, MessageService, PermissionService, BroadcastService, LoaderService } from 'src/app/core';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { PageID, MODULES } from 'src/app/core/config/enum';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { MultilingualService } from 'src/app/core/modules/header/multilingual.service';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { Utility } from 'src/app/core/utils/utility';
import { EmailConditionalValidator, EmailOccurenceValidator, ValidateUSPhone } from 'src/app/core/validators';
import { ConfirmModalComponent } from 'src/app/shared/components/confirm-modal/confirm-modal.component';
import { LoadingBarModalComponent } from 'src/app/shared/components/loading-bar-modal/loading-bar-modal.component';

@Component({
  selector: 'app-data-miner',
  templateUrl: './data-miner.component.html',
  styleUrls: ['./data-miner.component.scss']
})
export class DataMinerComponent implements OnInit {

  form!: UntypedFormGroup;
  categoryForm!: UntypedFormGroup;
  duplicateForm!: UntypedFormGroup;
  @ViewChild('f') formElement!: NgForm;
  isAddMode:boolean = false;
  isEditMode:boolean = false;
  type:any = '';
  name:any = '';
  categoryFormSubmitted: boolean = false;
  duplicateFormSubmitted: boolean = false;
  category_list: Array<any> = [];
  formSubmitted:boolean = false;
  template_list: Array<any> = [];
  selected_category_id:any = null;
  selected_template_id:any = null;
  selected_template:any = {};
  updated_by:any='';
  updated_at:any='';
  api_keys:any=[];
  modalRef:any;
  fileList:Array<any> = [];
  refresh:boolean = true;
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};
  dtOptions4: DataTables.Settings = {};
  dtOptions5: DataTables.Settings = {};
  dtOptions6: DataTables.Settings = {};
  dtOptions7: DataTables.Settings = {};
  isDataMinerReadOnly:boolean = true;
  activeCategory:Array<string> = [];
  filterText:any ='';
  selectedLanguage: any;
  eventSubscription: Array<Subscription> = [];
  languageUrl:any = '';
  loadingEnded:boolean = true;
  private dataTable: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  languageSelected:any;

  


  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private modalService: NgbModal,
    public permissionService: PermissionService,
    private broadCastService: BroadcastService,
    private loaderService: LoaderService,
    private router: Router,
    route: ActivatedRoute,
    private clientDetailService:ClientDetailService,
    private translateService:TranslateService,
    private multilingualService:MultilingualService

  ) {

    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    

    this.dtOptionsGlobal();

    //checking it is edit mode / add mode
    const pageID = route.snapshot.data['id'];
    const currentUrl = this.router.url;
    const isLS_EDIT_DATA_MINER = currentUrl.includes('data-miner/edit') ? true : false;
    if (pageID == PageID.LS_EDIT_DATA_MINER || isLS_EDIT_DATA_MINER) {

      const regex = /\/edit\/(\d+)/;
      const match = regex.exec(currentUrl);
      if (match && match.length > 1) {
        const id  = match[1];
        this.selected_template_id = id;
        this.isEditMode = true;

          // get data-miner data
          this.getCategoryList();
          this.getSubCategories();
          this.editTemplate('', this.selected_template_id);

      }
    } else {

      this.getCategoryList();
      this.getSubCategories();

      this.isEditMode = false;
    }

    const routeEvent1 = this.multilingualService.currentLanguageResponse.subscribe((data : any) => {
      if(data && this.loadingEnded){
        this.loadingEnded = false;
        this.refresh = false;
        // let table = $('#filesDataTable').DataTable(); // Assuming 'yourDataTable' is the ID of your DataTable
        this.selectedLanguage=data??'';
        this.translateService.use(localStorage.getItem('Selected_Language') || 'en');

        // this.dataTable = $('#filesDataTable').DataTable;
        this.languageSelected =  this.selectedLanguage == "es" ?  { 
            search: 'Buscar',
            emptyTable: 'No hay datos disponibles en la tabla',
            info: 'Mostrando _START_ a _END_ de _TOTAL_ entradas',
            infoEmpty: 'Mostrando 0 a 0 de 0 entradas',
            lengthMenu: 'Mostrar _MENU_ entradas',
            loadingRecords: 'Cargando...',
            processing: 'Procesando...',
            zeroRecords: 'No se encontraron registros coincidentes',
            paginate: {
              first: 'Primero',
              last: 'Último',
              next: 'Siguiente',
              previous: 'Anterior'
            }} : this.selectedLanguage == "test" ? {
              search: '11111',
              emptyTable: '111111111111111',
              info: '11111',
              infoEmpty: '11111',
              lengthMenu: '11111',
              loadingRecords: '1111111111',
              processing: '11111',
              zeroRecords: '11111',
              paginate: {
                first: '11111',
                last: '11111',
                next: '11111',
                previous: '1111111111'
              }
            }:  {
            search: 'Search',
            emptyTable: 'No data available in table',
            info: 'Showing _START_ to _END_ of _TOTAL_ entries',
            infoEmpty: 'Showing 0 to 0 of 0 entries',
            lengthMenu: 'Show _MENU_ entries',
            loadingRecords: 'Loading...',
            processing: 'Processing...',
            zeroRecords: 'No matching records found',
            paginate: {
              first: 'First',
              last: 'Last',
              next: 'Next',
              previous: 'Previous'
            }
          }

          this.dtOptions1.language = this.languageSelected;
          this.dtOptions2.language = this.languageSelected;
          this.dtOptions3.language = this.languageSelected;
          this.dtOptions4.language = this.languageSelected;
          this.dtOptions5.language = this.languageSelected;
          this.dtOptions6.language = this.languageSelected;
          this.dtOptions7.language = this.languageSelected;


        setTimeout(() => {

          // this.dtElement?.dtInstance?.then(dtInstance => {
          //   // Destroy the table first
          //   dtInstance.destroy();
          //   // Call the dtTrigger to rerender again
          //   this.dtTrigger1?.next(null);
          //   this.dtTrigger2?.next(null);
          //   this.dtTrigger3?.next(null);
          //   this.dtTrigger4?.next(null);
          //   this.dtTrigger5?.next(null);
          //   this.dtTrigger6?.next(null);
          //   this.dtTrigger7?.next(null);
          //   this.dtTrigger8?.next(null);
          //   this.dtTrigger9?.next(null);
          //   this.dtTrigger10?.next(null);
          //   this.dtTrigger11?.next(null);
          //   this.dtTrigger12?.next(null);
          //   this.dtTrigger13?.next(null);
          //   this.dtTrigger14?.next(null);
          //   this.dtTrigger15?.next(null);

          //   // dtInstance.draw();
          // }),


          this.dtOptionsGlobal();
          this.loadingEnded = true

          // this.dataTable.destroy();
          // this.dataTable.ajax.reload(null,false);
          setTimeout(() => {
            this.refresh = true;
          }, 1000);
          

        }, 1000);
      }
    });

    this.eventSubscription.push(routeEvent1);
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', []),
      lendisoft_stackholder_analyst: new UntypedFormControl('', []),
      client_stackholder_analyst: new UntypedFormControl('', []),
      lendisoft_stackholder_email: new UntypedFormControl('', [EmailConditionalValidator,
        EmailOccurenceValidator(1)]),
      client_stackholder_email: new UntypedFormControl('', [EmailConditionalValidator,
        EmailOccurenceValidator(1)]),
      client_stackholder_cc: new UntypedFormControl('', [EmailConditionalValidator,
        EmailOccurenceValidator(10)]),
      lendisoft_stackholder_cc: new UntypedFormControl('', [EmailConditionalValidator,
        EmailOccurenceValidator(10)]),
      lendisoft_stackholder_phone: new UntypedFormControl('', [ValidateUSPhone]),
      client_stackholder_phone: new UntypedFormControl('', [ValidateUSPhone]),
      note1: new UntypedFormControl('', []),
      note2: new UntypedFormControl('', []),
      note3: new UntypedFormControl('', []),
      note4: new UntypedFormControl('', []),
      note5: new UntypedFormControl('', []),
      note6: new UntypedFormControl('', []),
      note7: new UntypedFormControl('', []),
    })

    //category form controls
    this.categoryForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      id: new UntypedFormControl('', []),

    });

    //duplicate form controls
    this.duplicateForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      id: new UntypedFormControl('', []),
    });


    //get_keys
    this.httpService.get(PathConfig.API_KEYS).subscribe((response)=>{
      this.api_keys = response?.data;
    },(err)=>{
      this.msgService.showError(err.error.message);
    });

    // this.dtOptionsFilter('');

    this.isDataMinerReadOnly = this.permissionService.isReadOnly(MODULES.DATA_MINER);

    if(this.isDataMinerReadOnly) {
      this.form.disable();
    }

  }

  dtOptionsGlobal() {

    //cols which are mapped to show + these used for getting sort/order by fields name
    const cols = [
      { data: '' },
      { data: 'name' },
      { data: 'date_and_time' },
      { data: 'file_type' },
      { data: 'file_size' },
      { data: 'export' },
      { data: ' ' },
    ];

    this.dtOptions1 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

    this.dtOptions2 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

    this.dtOptions3 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

    this.dtOptions4 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

    this.dtOptions5 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

    this.dtOptions6 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

    this.dtOptions7 = {
      language: {
       
        emptyTable: this.translateService.instant("No data available in table"),
        info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
        infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
        infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
        lengthMenu: this.translateService.instant("Show _MENU_ entries"),
        loadingRecords: this.translateService.instant("Loading..."),
        processing: this.translateService.instant("Processing..."),
        zeroRecords: this.translateService.instant("No matching records found"),
        paginate: {
          first: this.translateService.instant("First"),
          last: this.translateService.instant("Last"),
          next: this.translateService.instant("Next"),
          previous:this.translateService.instant("Previous")
        },
      },
      pagingType: 'full_numbers',
      serverSide: false,
      pageLength: 10,
      searching: false,
      dom: 'bfrtip',
      processing: false,
      responsive: true,
      // stateSave: true,
      columns: cols,
      order:[[2, 'desc']],
      columnDefs: [
        {
          defaultContent: '',
          targets: '_all',
        },
      ]
    };

  }

  // on Add Category
  onAddCategory(content: any) {
    this.type='';
    this.name='';
    this.categoryForm.reset();
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'sm',
    });
  }

  // on Edit Category
  onEditCategory(cid: any,cname:string, content: any) {
    if (cid) {
      this.isEditMode = true;
      let obj = {id:cid,name:cname};
      this.categoryForm.patchValue(obj);
      this.categoryForm.updateValueAndValidity();
      this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'sm',
      });
    }
  }

  //fetch category list 
  getCategoryList() {
    const url = `${PathConfig.BUILDER_CATEGORIES}?type=${'DM'}`;
    this.httpService.get(url).subscribe(
      (response) => {
        this.category_list = response || [];
        // if(this.category_list?.length)
        // this.activeCategory[0]='cat'+this.category_list[0]?.id;
        setTimeout(()=> this.refresh = true,1000);
      },
      (err) => {
        this.msgService.showError(err.error.message);
      }
    );
  }

  // Add/edit category name
  onCategorySubmit() {

    if (this.categoryForm.valid && !this.categoryFormSubmitted) {
      this.categoryFormSubmitted = true;
      const id = this.categoryForm.value.id;
      let url = '';
      let service;
      const params = { type: 'DM', name : this.categoryForm.controls.name.value };


      if(id){
        url = `${PathConfig.BUILDER_CATEGORIES}/${id}`;
        service = this.httpService.put(url, params);

      }
      else{
        url = `${PathConfig.BUILDER_CATEGORIES}`;
        service = this.httpService.post(url, params);

      }

      service.subscribe(
        (response: any) => {
          this.msgService.showSuccess(response.message);
          this.getCategoryList();
          this.modalService.dismissAll();
          this.categoryForm.reset();

          this.categoryFormSubmitted = false;
        },
        (err: any) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.categoryFormSubmitted = false;
        }
      );
    }
  }

  // Deletes Category
  onDeleteCategory(id: any) {

    if(id) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'sm',
      });
      modalRef.componentInstance.confirmationBoxTitle = 'Confirmation?';
      modalRef.componentInstance.confirmationMessage =
        this.translateService.instant('Are you sure you want to delete?');

      modalRef?.result?.then((response) => {
        if (response) {
          // delete  category
          const url = `${PathConfig.BUILDER_CATEGORIES}/${id}`;
          this.httpService.delete(url).subscribe(
            (response) => {
              this.refresh = false;
              this.form.reset();
              this.getCategoryList();
              this.getSubCategories();
              this.modalService.dismissAll();
              this.categoryForm.reset();
              this.duplicateForm.reset();
              this.editTemplate('',this.selected_template_id);
              setTimeout(()=> this.refresh = true,1000);
              this.msgService.showSuccess(response.message);
            },
            (err) => {
              this.msgService.showError(err.error.message);
            }
          );
        }
      });
    }
  }

  onCancelCategory() {

    this.categoryForm.reset('');
    this.categoryForm.updateValueAndValidity();
    this.modalService.dismissAll();
  }

  addTemplate() {
    this.refresh = false;
    this.dtOptionsGlobal();

    this.updated_by='';
    this.updated_at='';

    this.isAddMode=true;
    this.isEditMode=false;
    this.selected_category_id = null;
    this.selected_template_id = null;
    this.fileList = [];

    this.form.reset('');
    this.form.updateValueAndValidity();

    setTimeout(()=> this.refresh = true,1000);


  }

  getSubCategories(id?:any) {

    let url = '';

    if(id)
    url =`${PathConfig.CONSULTING_SERVICE_BUILDERS}/${id}?type=DM&search=${this.filterText}`;
    else 
    url =`${PathConfig.CONSULTING_SERVICE_BUILDERS}?type=DM&search=${this.filterText}`;

    this.httpService.get(url).subscribe(
      (response) => {
        this.template_list = response || [];
        if(id)
        this.selected_template = response || {};

        if(this.template_list?.length) {

          let catId = 0;
          
          this.activeCategory=[];

          // for (let a of this.template_list) {
          for (let i = 0;i<this.template_list?.length;i++) {
            if (this.template_list[i]?.category_id!=null) {

              catId = this.template_list[i]?.category_id;

              this.activeCategory[i]='cat'+catId;

            }
          }
        }

        setTimeout(()=> this.refresh = true,1000);

      },
      (err) => {
        this.msgService.showError(err.error.message);
      }
    );

  }

  // on form submit
  onFormSubmit() {

    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

  onSubmit() {
    if (this.form.valid && !this.formSubmitted) {
      this.formSubmitted = true;

      let params = this.form.getRawValue();
      params.category_id=this.selected_category_id;

      params.notes = [];

      this.fileList.forEach(object => {
        delete object['temp_path'];
        delete object['created_at'];
      });


      params.notes = [
        { note:  params.note1, 'builder_file_type':'PROJECT_PLAN', 'type': 'DM'},
        { note:  params.note2, 'builder_file_type':'IMPORT_DATA', 'type': 'DM'},
        { note:  params.note3, 'builder_file_type':'DATA_CLEANSING', 'type': 'DM'},
        { note:  params.note4, 'builder_file_type':'BINNING_BY_CNL', 'type': 'DM'},
        { note:  params.note5, 'builder_file_type':'RESULTS_SUMMARY', 'type': 'DM'},
        { note:  params.note6, 'builder_file_type':'DATA_MINER', 'type': 'DM'},
        { note:  params.note7, 'builder_file_type':'CAMPAING_BUILDER', 'type': 'DM'},
      ];

      let url = '';
      let service: any;


      url = this.isEditMode? `${PathConfig.CONSULTING_SERVICE_BUILDERS}/${this.selected_template_id}?type=DM` :
      `${PathConfig.CONSULTING_SERVICE_BUILDERS}?type=DM&name=${this.form.controls.name.value}`;

      params.files = this.fileList;



      service = this.isEditMode ? this.httpService.put(url,params) : this.httpService.post(url, params);

      service.subscribe(
        (response: any) => {

          this.msgService.showSuccess(response?.message);
          this.getSubCategories();
          this.form.reset();
          this.editTemplate(this.selected_category_id,response?.id ?? this.selected_template_id);
          this.formSubmitted = false;

          this.closeLoadingbar();
        },
        (err: any) => {
          this.refresh = false ;
          this.form.reset();
          this.isEditMode == true ? this.editTemplate(this.selected_category_id,this.selected_template_id) : this.fileList = [];
          
          
          this.closeLoadingbar();
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.formSubmitted = false;

          setTimeout(()=> this.refresh = true,1000);
        }
      );
    }
    else{
      // this.msgService.showError('Invalid Form Data')
      this.closeLoadingbar();
    }
  }

  editTemplate(category_id:any,template_id:any,resetDtOptions?:string) {

    if(template_id) {

      if(resetDtOptions=='selectTemplate')
      this.dtOptionsGlobal();

      this.isEditMode = true;
      this.refresh = false;

      this.fileList = [];
      this.selected_template = {};


      this.selected_category_id = category_id;
      this.selected_template_id = template_id;

      const url =`${PathConfig.CONSULTING_SERVICE_BUILDERS}/${template_id}?type=DM`; 

      this.httpService.get(url).subscribe(
        (response) => {
          this.refresh = false;
        
          this.selected_template = response || {};

          this.selected_category_id = this.selected_template?.category_id;

          if(this.selected_template) {

            this.updated_at = this.selected_template?.updated_at??'';
            this.updated_by = this.selected_template?.updated_by??'';

            this.fileList = this.selected_template?.files ?? [];
            
            this.form.patchValue(this.selected_template);

            if(this.selected_template?.notes?.length) {

              this.form.patchValue({

                note1:this.selected_template?.notes[0]?.note??'',
                note2:this.selected_template?.notes[1]?.note??'',
                note3:this.selected_template?.notes[2]?.note??'',
                note4:this.selected_template?.notes[3]?.note??'',
                note5:this.selected_template?.notes[4]?.note??'',
                note6:this.selected_template?.notes[5]?.note??'',
                note7:this.selected_template?.notes[6]?.note??'',
              });
            }
            
            setTimeout(()=> this.refresh = true,1000);
            if(this.selected_category_id)
            this.activeCategory[0]='cat'+this.selected_category_id;

          }
        },
        (err) => {
          setTimeout(()=> this.refresh = true,1000);
          this.msgService.showError(err.error.message);
        }
      );  
    }

    // this.dtOptionsFilter('');

  }

  getFilesByBuilderFileType(builder_file_type:string){

    if(this.fileList?.length){

      return this.fileList?.filter((files:any)=> files?.builder_file_type === builder_file_type && files?.is_deleted === false);

    }
    else return [];

  }

  exportReportByUrl(ID:any,filename:any){

    if(ID && filename) {

      const clientSlug = this.clientDetailService.CLIENT_ID ;

      const url = `${PathConfig.FILE_DOWNLOAD}/${ID}?db=${clientSlug}`;

      Utility.downloadFileFromURL(url,filename);

      // let extension='';
  
      // extension = filename?.substring(filename?.lastIndexOf(".")+1);
      // extension = extension?.toLowerCase();

    
      // if(['xlsx','docx','xls','doc','xlsb','xlsm'].includes(extension) && extension && extension!='') {
      //   Utility.downloadFileFromURL(url,filename);
      // }
      // else{
      //   Utility.downloadURI(url,filename);
      // }

    }
    
  }

  onCancel() {

    if(this.isEditMode){
      this.isAddMode=false;
      this.isEditMode=true;
      this.dtOptionsGlobal();

      this.form.reset();
      this.editTemplate(this.selected_category_id,this.selected_template_id);
    }
    else{

      this.isAddMode=true;
      this.isEditMode=false;
      this.dtOptionsGlobal();

      this.updated_by='';
      this.updated_at='';
      this.form.patchValue({});
      this.form.reset();
      this.selected_category_id = null;
      this.selected_template_id = null;
    }

    this.form.updateValueAndValidity();
  }

  onAddNew() {

    this.form.patchValue({});

    this.form.reset;

    this.form.updateValueAndValidity();
  }


  //on file upload
  onFileSelected(e:any,builder_file_type:any) {
    this.refresh = false;

    const file = e?.target?.files[0];
    


    if(file && (( this.selected_template_id && this.selected_category_id) || this.form.controls.name.value )) {

      let isValidFile : boolean = false;
      let extension = file?.name?.substring(file?.name?.lastIndexOf('.')+1, file?.name?.length)
      extension = extension.toLowerCase();
      isValidFile= ['xlsx','csv','pdf','txt','jpg','png','docx','xls','doc','xlsb','xlsm'].includes(extension?.toLowerCase())?true:false;

      if(isValidFile) {


        this.loadingBarModal();

        const bucket = new S3({
          accessKeyId: this.api_keys?.AWS_ACCESS_KEY_ID,
          secretAccessKey: this.api_keys?.AWS_SECRET_ACCESS_KEY,
          region:  this.api_keys?.AWS_DEFAULT_REGION,
        });

        let formdata = new FormData();
        let contentType = file.type;

        switch(extension){
          case 'xlsx':
            contentType = 'Microsoft Excel worksheet';
            break;
          case 'csv':
            contentType = 'CSV document';
            break;
          case 'pdf':
            contentType = 'PDF document';
           break;
          case 'txt':
            contentType = 'Plain text document';
            break;
          case 'jpg':
            contentType = 'JPG image';
            break;
          case 'png':
            contentType = 'PNG image';
            break;
          case 'docx':
            contentType = 'Microsoft Word document';
            break;
          case 'xls':
            contentType = 'Microsoft Excel worksheet';
           break;
          case 'doc':
            contentType = 'Microsoft Word document';
            break;
          case 'xlsb':
            contentType = 'Microsoft Excel worksheet';
            break;
          case 'xlsm':
            contentType = 'Microsoft Excel worksheet';
            break;
          default:
            contentType = '';
            break;
        }

        formdata.append('type', 'DM');
        formdata.append('file', file, file.name);
        formdata.append('file_mime_type', contentType);
        formdata.append('file_size', file.size);
        formdata.append('file_name', file.name);

        const params_s3 = {
          Bucket: this.api_keys?.AWS_BUCKET,
          Key: file?.name,
          Body: file,
          ContentType: contentType
        };
        let thisObj = this;
        bucket.upload(params_s3,  (err: any, data: any) => {
          if (err) {

            const msgList = Utility.getErrorMessage(err?.error);
            msgList.forEach((msg) => thisObj.msgService.showError(msg));
            e.target.value='';
            this.closeLoadingbar();
            setTimeout(()=> this.refresh = true,1000);

          }
          if(data) {
            this.broadCastService.broadcast(MsgConstantConfig.SHOW_HIDE_CLIENT_PROGRESS_BAR,true);
            this.afterUpload(data,builder_file_type,file.size,contentType);
            e.target.value='';
          }
        });
      }
      else {
        this.msgService.showError("xlsx, xls, csv, pdf, txt, docx, doc, png, jpg, xlsb and xlsm extension allowed.");
        e.target.value='';
        setTimeout(()=> this.refresh = true,1000);
      }

    }
    
    else{
      this.msgService.showError("To upload a file, enter Project's name.");   
      e.target.value='';
      setTimeout(()=> this.refresh = true,1000);
    }
    setTimeout(()=> this.refresh = true,1000);

  }

  afterUpload(data:any,builder_file_type:string,size:any,mime_type:any) {

    var local = moment().valueOf();

      let uploaded_data = {
        builder_id:this.selected_template_id,
        builder_file_type:builder_file_type,
        type: 'DM',
        file_type:mime_type,
        file_size:size,
        name : data?.Key,
        path : data?.Key,
        is_deleted : false,
        created_at : local,
      }

      this.fileList.push(uploaded_data);

      this.selected_template.files = this.fileList;

    setTimeout(()=> this.onFormSubmit(),1000);

  }

  //  Open loading bar Modal
  loadingBarModal() {
    this.loaderService.hideLoader();
    this.modalRef = this.modalService.open(LoadingBarModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      backdrop: 'static',
      keyboard: false,
      size: 'md',
    });

      this.modalRef.componentInstance.message=this.translateService.instant('Uploading Data');
      this.modalRef.componentInstance.message2=this.translateService.instant('Please wait for few minutes while we upload and Process..');

  }

  closeLoadingbar() {
    this.modalRef?.close();
  }

  dtOptionsFilter(type:string) {
    this.refresh = false;
    setTimeout(()=> this.refresh = true,1000);

  }

  convertoMemoryUnit(number:any){
    if(number)
    return ((Number(number))/1000000)?.toFixed(3)+' MB';

    else return '0 MB'
  }

  // optimizing rendering of list
  trackByIndex(index: any) {
    return index;
  }

  //move the selected template up and down in categories
  moveToCategory(key:string,category:any,template:any) {

    let categoryList = this.category_list?.map((item:any) => item?.id);
    let categoryindex = categoryList?.indexOf(category?.id);
    let new_category_id:number = -1;

    if(key=='up') {
      new_category_id = this.category_list[categoryindex-1]?.id;
    }
    if(key=='down') {
      new_category_id = this.category_list[categoryindex+1]?.id;
    }

    const params = { name: template?.name, category_id: new_category_id };

    let url = '';
    let service: any;

    url = `${PathConfig.CONSULTING_SERVICE_BUILDERS}/${template?.id}?type=DM`;
    service = this.httpService.put(url, params);

    service.subscribe(
      (response: any) => {
        this.refresh = false;
        this.formSubmitted = false;
        this.getCategoryList();
        this.getSubCategories();

        this.activeCategory.push('cat'+new_category_id);
        this.msgService.showSuccess("Sub Categories Updated Successfully");
        this.selected_category_id = new_category_id;
      },
      (err: any) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.formSubmitted = false;
        setTimeout(()=> this.refresh = true,1000);
      }
    );
  }

  // on Duplicate template
  onDuplicateTemplate(content: any) {
    this.duplicateForm.reset();
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'sm',
    });
  }

  // on Duplicate template submit
  onDuplicateSubmit() {
    if (this.duplicateForm.valid && !this.duplicateFormSubmitted) {
      this.refresh = false;
      this.duplicateFormSubmitted = true;
      //get Detail By ID
      const url = `${PathConfig.CONSULTING_SERVICE_BUILDERS}/${this.selected_template_id}?type=DM`;
      this.httpService.get(url).subscribe((response) => {

        const url = `${PathConfig.CONSULTING_SERVICE_BUILDERS}?type=DM&name=${this.duplicateForm.controls.name.value}`;

        response.name = this.duplicateForm.value.name;

        const params = response;

        this.httpService.post(url,params).subscribe((response:any)=>{

          this.modalService.dismissAll();
          this.categoryForm.reset();
          this.duplicateForm.reset();
          this.form.reset();
          this.getSubCategories();
          this.editTemplate(this.selected_category_id,response?.id);

          
          this.duplicateFormSubmitted = false;
          this.categoryFormSubmitted = false;
          setTimeout(()=> this.refresh = true,1000);
          this.msgService.showSuccess(response.message);

        },(err:any)=>{
          setTimeout(()=> this.refresh = true,1000);
          this.modalService.dismissAll();
          this.categoryFormSubmitted = false;
          this.duplicateFormSubmitted = false;

          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg)=>this.msgService.showError(msg));


        });

        },
        (err) => {
          setTimeout(()=> this.refresh = true,1000);
          this.modalService.dismissAll();

          this.msgService.showError(err.error.message);
        }
      );
    }
  }

  deleteFileByID(id:any,created_at:any) {

    if(!this.isDataMinerReadOnly) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'sm',
      });
      modalRef.componentInstance.confirmationBoxTitle = 'Confirmation?';
      modalRef.componentInstance.confirmationMessage =
        this.translateService.instant("Are you sure you want to delete this file?");;
      modalRef.componentInstance.confirmationMessage2 =
        this.translateService.instant("Note : Remember to click 'Save' in order to keep your changes.");;

      modalRef?.result?.then((response) => {
        if (response) {
          this.openLoader();
          this.refresh = false;
          let index = -1;
          index = this.fileList?.findIndex((item:any)=>  item?.id == id)??-1;
      
      
          if(index!== -1)
            this.fileList[index].is_deleted = true;
          else {
            this.msgService.showError('Invalid File ID');
          }
      
          setTimeout(()=> this.refresh = true,1000);
          setTimeout(()=> this.loaderService.hideLoader(),2000);
          
        }
      });
    }
  }

   // Deletes Template
   onDeleteTemplate(id: any) {

    if(id) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'sm',
      });
      modalRef.componentInstance.confirmationBoxTitle = 'Confirmation?';
      modalRef.componentInstance.confirmationMessage =
      this.translateService.instant("Are you sure you want to delete this template?");

      modalRef?.result?.then((response) => {
        if (response) {
          // delete  Template
          const url =`${PathConfig.CONSULTING_SERVICE_BUILDERS}/${this.selected_template_id}?type=DM`;
          this.httpService.delete(url).subscribe(
            (response) => {
              this.refresh = false;
              this.form.reset();
              this.getCategoryList();
              this.getSubCategories();
              this.modalService.dismissAll();
              this.categoryForm.reset();
              this.duplicateForm.reset();
              this.addTemplate();
              setTimeout(()=> this.refresh = true,1000);
              this.msgService.showSuccess(response.message);
            },
            (err) => {
              this.refresh = false;
              this.form.reset();
              this.getCategoryList();
              this.getSubCategories();
              this.modalService.dismissAll();
              this.categoryForm.reset();
              this.duplicateForm.reset();
              this.addTemplate();
              setTimeout(()=> this.refresh = true,1000);
              this.msgService.showError(err.error.message);
            }
          );
        }
      });
    }
  }

  onListSearch(text: string): void {

    this.filterText = text;

    this.getSubCategories();

  }

  openLoader() {
    this.loaderService.showLoader();
  }

  ngOnDestroy(): void {
    //remove all events
    this.eventSubscription.forEach((event) => event.unsubscribe());
  }

}
