// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.development.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false ,
  API_URL:'https://dev-api.lendisoft.com/api',
  API_URL2:'http://127.0.0.1:8000/api',
  IMG_URL:'https://lms-dev-media.s3.us-west-2.amazonaws.com/',
  LOGIN_URL : "https://www.lendisoft.com/",
  LOGIN_TIMEOUT_IN_SEC : 600,
  PRODUCT_GUIDE_URL:'https://lms-public-place.s3.us-west-2.amazonaws.com/Product+Guide/Lendisoft%2BProduct%2BGuide.pdf',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
