import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import { UserDetail } from 'src/app/shared/models/user/user-detail.model';
import * as _ from 'lodash';
import { Utility } from 'src/app/core/utils/utility';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { RouteLog } from 'src/app/shared/models/loan/route.logs.model';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-route-accounts',
  templateUrl: './route-accounts.component.html',
  styleUrls: ['./route-accounts.component.scss']
})
export class RouteAccountsComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() isRouteShowHistory : boolean = false;

  /**
   * Route Account
   */
  userList: Array<UserDetail> = [];
  isRoutedPermission: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  routeDtOptions: DataTables.Settings = {};
  routePaginationLimit: number = 10;
  routeAccountList: Array<RouteLog> = [];
  routePageNumber: number = 1;
  routePagination: any = {};
  routed_to!:any;
  submitted: boolean = false;
  isDirty:boolean = false;
  DATE_FORMAT = ConstantConfig.DATE_FORMAT;
  isRoutNotSelected:boolean=false;
  appform!: UntypedFormGroup;
  routed_to_name:string='System';
  dataTableText:any = {};

  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private permissionService: PermissionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    //fetch user list
    this.getUserList();
    this.isRoutedPermission = this.permissionService.isSpecialPermission('route_account');
  }

  ngOnInit(): void {

    this.setDataTableText();
    this.translateService.onLangChange.subscribe(() => {
      this.setDataTableText();
    });
    this.appform = new UntypedFormGroup({
      // id: new FormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
       pin: new UntypedFormControl(null, [Validators.required]),
     });
    // initialize datatable
    this.routeDtOptions = {
      language: this.dataTableText,
      pagingType: 'full_numbers',
      serverSide: true,
      searching: false,
      dom: 'frtip',
      processing: false,
      responsive: true,
      ordering: false,
      stateSave: false,
      columnDefs: [{
        "defaultContent": "",
        "targets": "_all"
      }],
      ajax: (dataTablesParameters: any, callback) => {

        this.routePageNumber = (dataTablesParameters.start / dataTablesParameters.length) + 1;
        const url = `${PathConfig.LOANS}/route_logs?loan_id=${this.loanID}&limit=${this.routePaginationLimit}&page=${this.routePageNumber}`;
        this.httpService.get(url).subscribe((response) => {

          this.routeAccountList = response.data;
          this.routePagination = response.pagination;
          this.routePagination.size = Number(this.routePagination.size);

          callback({
            recordsTotal: response.pagination.total,
            recordsFiltered: response.pagination.total,
            data: (this.routeAccountList.length > 0 ? [{ id: '' }] : [])
          });

        }, (err) => {
          this.msgService.showError(err.error.message);
        });
      }
    };

this.routed_to_name=this.loanDetail?.routed==null?'System':this.loanDetail?.routed?.employee_initials+" - "+this.loanDetail?.routed?.firstname+" "+this.loanDetail?.routed?.lastname;

  }


  verifyPin()
  {
    if(this.appform.valid)
      {
        const params = this.appform.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.isRoutedPermission=true;
          this.routeAccount();
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.appform.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }


  }



  getselectedrout(e:any)
  {
    if(e.target.value=='' || e.target.value==null||e.target.value==undefined)
    {
      this.isRoutNotSelected=true;
    }
    else
    {
      this.isRoutNotSelected=false;
    }

  }

 /**
  * Feth User List
  */
  getUserList() {
    const url = `${PathConfig.USERS}?status=ACTIVE`;
    this.httpService.get(url).subscribe((response) => {
      this.userList = _.orderBy(response,'employee_initials');
      // this.routed_to = this.userList?.length > 0 ? this.userList[0].id : '';
      this.routed_to = '';
      if(this.routed_to==''|| this.routed_to==undefined||this.routed_to==null)
      {
        this.isRoutNotSelected=true;
      }
    }, (err) => {
      this.msgService.showError(err.error.message);
    });

  }

  /**
   * Get Approval Modal
   * @param content
   */
  openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }

  /**
   * Route Account
   */
  routeAccount(){
    const url =`${PathConfig.LOAN_ROUTE}/${this.loanID}` ;
    const params = {routed_to:this.routed_to};
    this.httpService.post(url, params).subscribe((response:any) => {
      this.msgService.showSuccess(response?.message);
      this.modalService.dismissAll();
     // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN,this.loanID);
     this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
    },
    (err) => {
      const msgList = Utility.getErrorMessage(err.error);
      msgList.forEach((msg) => this.msgService.showError(msg));
      this.modalService.dismissAll();
    });
  }

  convertLocalTimeZone(d:any)
  {
    if(d==''||d==null){return null}
    var testDateUtc = moment.tz(d,ConstantConfig.SERVER_TIMEZONE);
    return moment(testDateUtc).local().format("MM/DD/YYYY hh:mm:ss A");
  }

  getFilter (arr: any) {
    let selected ="";
    selected = this.loanDetail?.routed?.employee_initials;
    const newArr = arr.filter((object: { employee_initials: any; }) => {
      return object.employee_initials !== selected;
    });
    return newArr;
  }

  setDataTableText(){
    this.dataTableText ={
     search:"",
     searchPlaceholder:this.translateService.instant("Search"),
     emptyTable: this.translateService.instant("No data available in table"),
     info: this.translateService.instant("Showing _START_ to _END_ of _TOTAL_ entries"),
     infoEmpty: this.translateService.instant("Showing 0 to 0 of 0 entries"),
     infoFiltered: this.translateService.instant("(filtered from _MAX_ total entries)"),
     lengthMenu: this.translateService.instant("Show _MENU_ entries"),
     loadingRecords: this.translateService.instant("Loading..."),
     processing: this.translateService.instant("Processing..."),
     zeroRecords: this.translateService.instant("No matching records found"),
     paginate: {
       first: this.translateService.instant("First"),
       last: this.translateService.instant("Last"),
       next: this.translateService.instant("Next"),
       previous:this.translateService.instant("Previous")
     },
   };
 }
 
}
