
import { AbstractControl, ValidatorFn } from '@angular/forms';
    
export function ArrayLengthValidator(minimumLength: number): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (control?.value?.length < minimumLength) {
        return {arrayLength: true};
      }
      return null;
    }; 
  }