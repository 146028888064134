import { Component, Input, OnInit } from '@angular/core';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PathConfig } from 'src/app/core/config/path.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { Utility } from 'src/app/core/utils/utility';
@Component({
  selector: 'app-start-stop-interest-accrual',
  templateUrl: './start-stop-interest-accrual.component.html',
  styleUrls: ['./start-stop-interest-accrual.component.scss']
})
export class StartStopInterestAccrualComponent implements OnInit {
  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() isRouteShowHistory : boolean = false;
  modalTitle:string='';
  modalSubTitle:string='';
  constructor(

    private httpService: HttpService,
    private msgService: MessageService,
    private permissionService: PermissionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private broadCastService:BroadcastService

  ) { }

  ngOnInit(): void {
    this.modalTitle=this.loanDetail?.interest_accrual_on_yn=='YES'?'Stop Interest Accrual':'Start Interest Accrual';
    this.modalSubTitle=this.loanDetail?.interest_accrual_on_yn=='YES'?'Click submit to stop interest accrual.':'Click submit to start interest accrual.';
  }

  submit(){


    const params = {loan_id:this.loanID,payment_info_code_slug:this.loanDetail?.interest_accrual_on_yn=='YES'?'STOP_INT':'START_INT'};
    const url = `${PathConfig.LOAN_TRANSACTIONS_START_STOP_ACCURAL}`;
    this.httpService.post(url, params).subscribe({
      next : (res) => {
        this.msgService.showSuccess(res?.message);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);

      },
      error : (err) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
      }
    });
       this.modalService.dismissAll();
  }

}
