import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { BroadcastService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { MultilingualService } from 'src/app/core/modules/header/multilingual.service';
import { ClientDetailService } from 'src/app/core/services/client-detail-service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit,OnDestroy {
  currentDate:string = '';
  currentTime:string = '';
  currentYear:string = moment().format('YYYY');
  eventSubscription:Array<Subscription> = [];
  sectionTitle:string = '';
  logo:string='';
  selectedLanguage: any;
  selectedLanguageIcon: any;
  dropdownOpen: boolean = false;
  selected_range: any;

  constructor(private route:ActivatedRoute, private router:Router,private clientDetailService:ClientDetailService,
    private broadCastService: BroadcastService,
    private translateService:TranslateService,
    private el: ElementRef,
    private multilingualService:MultilingualService
  ) {
    
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');

    const routeEvents = this.router.events.subscribe(event => {
      const storedLanguage = localStorage.getItem('Selected_Language') ?? 'en';
      if (event instanceof NavigationEnd) {
        this.sectionTitle  = this.route.snapshot.firstChild?.data?.title || '';
      }
      if (storedLanguage) {
        const language = this.languages.find(lang => lang.selectedLanguage === storedLanguage);
        if (language) {
          this.selectedLanguage = language.selectedLanguage;
          this.selectedLanguageIcon = language.icon;
        }
      }
    });
    this.eventSubscription.push(routeEvents);
    this.logo = environment.IMG_URL+this.clientDetailService.LOGO_DARK;
    
  }

  ngOnInit(): void {
  
    //time and date visualization
    const timerSubscription = interval(1000).subscribe((interval)=>{
      this.currentDate = moment().format('MM/DD/YYYY');
      this.currentTime = moment().format('h:mm A');
    });
    this.eventSubscription.push(timerSubscription);
    this.selectedLanguage = localStorage.getItem('Selected_Language') || 'en';
    //broadcastservice added
    this.broadCastService.on(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID).subscribe(() => {
      this.multilingualService.updateSelectedLanguageResponse(this.selectedLanguage);
    });
    setTimeout(() => {this.broadCastService.broadcast(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID)}, 1000);
    this.multilingualService.currentLanguageResponse.subscribe((data: any) => {
      if (data) {
        this.selected_range = data ?? '';
        this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
      }
    });
    
  }

  ngOnDestroy():void{
    this.eventSubscription.forEach((event)=>event.unsubscribe());
  }
  languages = [
    { languageValue: 'English', selectedLanguage: 'en', icon: '../assets/images/header/English.png' },
    // { languageValue: 'French', selectedLanguage: 'fr', icon: '../assets/images/header/french.png' },
    { languageValue: 'Spanish', selectedLanguage: 'es', icon: '../assets/images/header/spanish.png' }
  ];

  changeLanguage(language: any): void {
    this.selectedLanguage = language.selectedLanguage;
    this.selectedLanguageIcon = language.icon;
    localStorage.setItem('Selected_Language', this.selectedLanguage);
    this.translateService.use(this.selectedLanguage);
    this.dropdownOpen = false; // Close the dropdown after selection
    setTimeout(() => {
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_VIEW_LANGUAGE_ID)
    }, 1000);
  }
  getSelectedLanguageValue(): string {
    const selectedLang = this.languages.find(lang => lang.selectedLanguage === this.selectedLanguage);
    return selectedLang ? selectedLang.languageValue : 'Please Select';
  }
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const isInsideDropdown = target.closest('.language-dropdown');
    if (!isInsideDropdown) {
      this.dropdownOpen = false;
    }
  }
}
