import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConstantConfig } from '../config/constant.config';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { ClientDetailService } from './client-detail-service';
import * as moment from 'moment';
import 'moment-timezone';

@Injectable()
export class HttpService {
  errorMessage: string = 'Server Error';
  // Resolve HTTP using the constructor
  constructor(
    private localStorageService:LocalStorageService,
    private userService:UserService,
    private clientDetailService:ClientDetailService,
    private http: HttpClient) {

  }

  /**
  * @desc - get request
  * @param url - url of API
  */
  get(url: string,hide_header?:Boolean): Observable<any> {
    // using get request
    return this.http.get(url, {
      headers: this.setHeaders(false,hide_header)
    }).pipe(map((res: any) => res), catchError((err: any) => {
      return throwError({
        error: err['error'] || this.errorMessage
      });
    }));

  }

  /**
   * @desc - post request
   * @param url - url of API
   * @param obj - object to be send
   */
  post(url:string, obj: any,hide_header?:Boolean): Observable<any> {
    // using post request
    const body = obj;
    // using post request
    return this.http.post(url, body, {
      headers: this.setHeaders(false,hide_header)
    }) // using post request
      .pipe(
        map((res: any) => res), catchError((err: any) => {
          return throwError({
            error: err['error'] || this.errorMessage
          });
        })); // errors if any
  }

  /**
   * @desc - put request
   * @param url - url of API
   * @param obj - obj to be send
   */
  put(url:string, obj:any): Observable<any> {
    // using put request
    // Create a option
    const body = obj; // payload
    // using put request
    return this.http.put(url, body, {
      headers: this.setHeaders()
    }) // using put request
      .pipe(
        map((res: any) => res), catchError((err: any) => {
          return throwError({
            error: err['error'] || this.errorMessage
          });
        })); // errors if any
  }


  /**
  * @desc - patch request
  * @param url - url of API
  * @param obj - obj to be send
  */
  patch(url:string, obj:any): Observable<any> {
    // using patch request
    // Create a option
    const body = obj; // payload
    // using put request
    return this.http.patch(url, body, {
      headers: this.setHeaders()
    }) // using put request
      .pipe(
        map((res: any) => res), catchError((err: any) => {
          return throwError({
            error: err['error'] || this.errorMessage
          });
        })); // errors if any
  }

  /**
   * @desc - delete request
   * @param url - url of API
   * @param id - id to be send for delete
   */
  delete(url:string, id: string = ''): Observable<any> {
    if (id) {
      url = `${url}/${id}`;
    }
    return this.http.delete(url, {
      headers: this.setHeaders()
    }) // using delete request
      .pipe(map((res: any) => res), catchError((err: any) => throwError({
        error: err['error'] || this.errorMessage
      }))); // errors if any
  }

  /**
  * @desc - delete request
  * @param url - url of API
  * @param body - body to be send for delete
  */
  deleteWithBody(url:string, body: any): Observable<any> {
    return this.http.request('delete', url, {
      headers: this.setHeaders(),
      body: body
    }) // using delete request
      .pipe(map((res: any) => res), catchError((err: any) => throwError({
        error: err['error'] || this.errorMessage
      }))); // errors if any
  }


  download(url:string, filters: any): Promise<any> {

    const headerObj: Object = {
      responseType: 'arraybuffer',
      observe: 'response',
      headers: this.setHeaders()
    };
    return this.http.get(url, headerObj)
    .toPromise()
    .then((res: any) => {
      return res;
    }).catch((err: any) => {
      return Promise.reject({
        error: err['error'] || this.errorMessage
      });
    });
  }

  downloadWithPost(url:string, obj: Object): Promise<any> {
    const authKey = this.localStorageService.get(ConstantConfig.AUTH_TOKEN);
    const customHeaders = new HttpHeaders({
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + authKey
    });

    return this.http.post(url, obj, {
      responseType: 'arraybuffer',
      observe: 'response',
      headers: customHeaders
    })
      .toPromise()
      .then((res: any) => {
        return res;
      }).catch((err: any) => {
        return Promise.reject({
          error: err['error'] || this.errorMessage
        });
      });
  }
  /**
   * @desc - upload request
   * @param url
   * @param obj
   * @returns
   */
  upload(url:string, obj: any,hide_header?:Boolean): Observable<any> {
    // using post request
    const body = obj;
    // using post request
    return this.http.post(url, body, {
      headers: this.setHeaders(true,hide_header)
    }) // using post request
      .pipe(
        map((res: any) => res), catchError((err: any) => {
          return throwError({
            error: err['error'] || this.errorMessage
          });
        })); // errors if any
  }

  setHeaders(remove_content_type?:Boolean,hide_header?:Boolean) {
    const expiryDate = new Date();
    expiryDate.setFullYear(expiryDate.getFullYear() - 1);
    const authKey = this.userService.AUTH_TOKEN;
    const clientSlug = this.clientDetailService.CLIENT_ID ;
    const timeZone = moment.tz.guess();
    const selectedLanguage:any = localStorage.getItem("Selected_Language") ?? 'en';
    // Create a request option
    let header;
    if(hide_header) {
      header = new HttpHeaders({
        'Authorization': 'Bearer ' + authKey,
        'hideLoader': 'application/json',
        'CLIENT-SLUG':clientSlug,
        'SELECTED-LANGUAGE':selectedLanguage,
        'Content-Type': 'application/json',
        'Cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': `${expiryDate}`,
        'Timezone':timeZone
      });
    } else {
      header = new HttpHeaders({
        'Authorization': 'Bearer ' + authKey,
        'CLIENT-SLUG':clientSlug,
        'SELECTED-LANGUAGE':selectedLanguage,
        'Content-Type': 'application/json',
        'Cache-control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': `${expiryDate}`,
        'Timezone':timeZone
      });
    }
    if(remove_content_type){
      header = header.delete("Content-Type");
    }
      return header;
  }

}
