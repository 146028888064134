import { NgModule  } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules  } from '@angular/router';
import { AuthComponent } from 'src/app/modules/auth/auth.component';
import { ErrorNotAuthorizedComponent } from 'src/app/modules/error/error-not-authorized/error-not-authorized.component';
import { ErrorNotFoundComponent } from 'src/app/modules/error/error-not-found/error-not-found.component';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { AuthGuard } from '../gaurds/auth.gaurd';

import { VerifyAuthenticationGuard } from '../gaurds/verify-authentication';
import { LandingComponent } from 'src/app/modules/landing/landing.component';
import { ModelBuilderComponent } from 'src/app/modules/servicing/model-builder/model-builder.component';
import { DirtyCheckGuard } from '../gaurds/dirty-check.gaurd';
import { DataMinerComponent } from 'src/app/modules/servicing/data-miner/data-miner.component';
import { ValuationsComponent } from 'src/app/modules/servicing/valuations/valuations.component';

const routes: Routes = [
  // {
  //   path:'error',
  //   component:ErrorComponent,
  //   data: { title: 'Error' }
  // },
  // {
  //   path:':clientId/error-404',
  //   component:ErrorNotFoundComponent
  // },
  // {
  //   path:'error-404',
  //   component:ErrorNotFoundComponent
  // },
  // {
  //   path:':clientId/error-401',
  //   component:ErrorNotAuthorizedComponent
  // },
  {
    path: '',
    component: AuthComponent,
    canActivate:[VerifyAuthenticationGuard],
    loadChildren: () => import('./../../modules/auth').then(m => m.AuthModule),
  },
  {
    path: ':clientId/home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'landing',
        component: LandingComponent,
        data: { title: 'Welcome' }
      },
      {
        path: '',
        redirectTo: 'landing', pathMatch: 'full'
      },
      {
        path: 'servicing',
        loadChildren: () => import('./../../modules/servicing').then(m => m.ServicingModule),
        data: { title: 'Servicing' }
      },
      {
        path: 'model-builder',
        component: ModelBuilderComponent,
        data: { id: 'MODEL_BUILDER' },
        children: [
          {
            path: 'edit/:id',
            component: ModelBuilderComponent,
            canDeactivate: [DirtyCheckGuard],
            data: { id: 'LS_EDIT_MODEL_BUILDER' },
          },
          {
            path: 'add',
            component: ModelBuilderComponent,
            canDeactivate: [DirtyCheckGuard],
            data: { id: 'LS_ADD_MODEL_BUILDER' },
          }
        ],
      },
      {
        path: 'data-miner',
        component: DataMinerComponent,
        data: { id: 'DATA_MINER' },
        children: [
          {
            path: 'edit/:id',
            component: DataMinerComponent,
            canDeactivate: [DirtyCheckGuard],
            data: { id: 'LS_EDIT_DATA_MINER' },
          },
          {
            path: 'add',
            component: DataMinerComponent,
            canDeactivate: [DirtyCheckGuard],
            data: { id: 'LS_ADD_DATA_MINER' },
          }
        ],
      },
      {
        path: 'valuations',
        component: ValuationsComponent,
        data: { id: 'MODEL_BUILDER' },
        children: [
          {
            path: 'edit/:id',
            component: ValuationsComponent,
            canDeactivate: [DirtyCheckGuard],
            data: { id: 'LS_EDIT_VALUATIONS' },
          },
          {
            path: 'add',
            component: ValuationsComponent,
            canDeactivate: [DirtyCheckGuard],
            data: { id: 'LS_ADD_VALUATIONS' },
          }
        ],
      },
      {
        path: 'configuration',
        loadChildren: () => import('./../../modules/configurations').then(m => m.ConfigurationsModule),
        data: { title: 'Configurations' }
      },
      {
        path: 'reports',
        loadChildren: () => import('./../../modules/reports').then(m => m.ReportsModule),
        data: { title: 'Reports' }
      },
      {
        path: 'lendisoft-configuration',
        loadChildren: () => import('./../../modules/lendisoft-configurations').then(m => m.LendiSoftConfigurationsModule),
        data: { title: 'Lendisoft Configurations' }
      },
      {
        path:'error-403',
        component:ErrorNotAuthorizedComponent,
        data: { title: 'Forbidden' , pageType:'ERROR' }
      },
      {
        path:'error-404',
        component:ErrorNotFoundComponent,
        data: { title: 'Page Not Found', pageType:'ERROR' }
      },
      {
        path:'**',
        redirectTo: 'error-404',
        pathMatch: 'full'
      },

    ]
  },
  {
    path: '**',
    redirectTo: 'error-404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{paramsInheritanceStrategy:'always',preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
