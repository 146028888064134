import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BroadcastService, HttpService, LoaderService, LocalStorageService, MessageService, UserService } from './core';
import { ClientDetailService } from './core/services/client-detail-service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { PathConfig } from './core/config/path.config';
import { ConstantConfig } from './core/config/constant.config';
import { ClientUtils } from './core/utils/clientUtil';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReLoginComponent } from './modules/auth/re-login/re-login.component';
import { MsgConstantConfig } from './core/config/message.constant.config';
import { AuthService } from './core/services/auth-service';
import { UserInfo } from './core/models/user/user-info.model';
import { UpdatePasswordComponent } from './core/modules/header/update-password/update-password.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isReLoginModalOpen:boolean = false;
  isChangeUserPasswordModelOpen:boolean = false;
  modalRef:any;
  constructor(
    public loaderService: LoaderService,
    private clientDetailService:ClientDetailService,
    private titleService: Title,
    private httpService:HttpService,
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService:LocalStorageService,
    private userService:UserService,
    private msgService:MessageService,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private modalService:NgbModal,
    private broadCastService:BroadcastService,
    private auth:AuthService,
    private translateService:TranslateService
  ) {
      
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    
   this.titleService.setTitle(this.clientDetailService.TITLE);
   const favicon = environment.IMG_URL+this.clientDetailService.FAVICON;
   (document as any).querySelector("link[rel*='icon']").href = favicon;

      // set idle parameters
      idle.setIdle(environment?.LOGIN_TIMEOUT_IN_SEC); // how long can they be inactive before considered idle, in seconds
      idle.setTimeout(60*60*2); // how long can they be idle before considered timed out, in seconds
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

      // do something when the user becomes idle
      idle.onIdleStart.subscribe(() => {
      });
      // do something when the user is no longer idle
      idle.onIdleEnd.subscribe(() => {
        cd.detectChanges(); // how do i avoid this kludge?
      });
      // do something when the user has timed out
      idle.onTimeout.subscribe(() => {
        this.openLoginModal();
      });
      // do something as the timeout countdown does its thing
      idle.onTimeoutWarning.subscribe(seconds => {
      });

      //check for user logged in notification
      this.broadCastService.on(MsgConstantConfig.USER_LOGGED_IN).subscribe(()=>{
        idle.watch();
      });

       //check for user logged out notification
      this.broadCastService.on(MsgConstantConfig.USER_LOGGED_OUT).subscribe(()=>{
        idle.stop();
        this.isChangeUserPasswordModelOpen = false;
      });

      //remove all notification and dismiss all popups
      this.broadCastService.on(MsgConstantConfig.USER_REMOVE_NOTIFICATION).subscribe(()=>{
        idle.stop();
        this.modalService.dismissAll();
      });


      //check for user auth token expiry
      this.broadCastService.on(MsgConstantConfig.AUTH_TOKEN_EXPIRED).subscribe(()=>{
        idle.stop();
        if(!this.isReLoginModalOpen){
          this.modalService.dismissAll();
          this.openLoginModal();
        }

      });

      // if authenticated start idle watcher
      if(this.auth.isAuthenticated() && !this.auth.isTokenExpired()){
        idle.watch();
      }

      //remove all notification and dismiss all popups
      this.broadCastService.on(MsgConstantConfig.CHANGE_USER_PASSWORD).subscribe(()=>{
        idle.stop();
        if(!this.isChangeUserPasswordModelOpen){
          this.modalService.dismissAll();
          this.openChangePassword();
        }
      });
  }

  ngOnInit(): void {
   // verify user session
   this.httpService.get(PathConfig.USER_ME).subscribe((response)=>{
    this.userService.setUserInfo(new UserInfo(response.user));
  },(err)=>{});
  }


  // open relogin modal
  openLoginModal(){
    this.isReLoginModalOpen = true;
    this.userService.setAuthToken('idle');
    const modalRef = this.modalService.open(ReLoginComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'sm' ,keyboard:false,backdrop:'static' });
    let body:any = document.querySelector('body');
    body.classList.add("login-modal-show");
    modalRef.result.then((userResponse:any) => {
      this.isReLoginModalOpen = false;
      if (userResponse) {
        // after login
        body.classList.remove("login-modal-show");
      }
    });
  }
  /**
   * logoutClient
   */
public logoutClient() {
  this.httpService.delete(PathConfig.CLIENT_AUTH).subscribe((client_response) => {
    this.removeClientInfo();
  }, (err) => {
    this.msgService.showError("Something went wrong");
  });
}

   //reset user information
   removeClientInfo():void{
    const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    this.localStorageService.remove(clientSlug + "_" + ConstantConfig.AUTH_TOKEN_EXPIRY);
    this.localStorageService.remove(clientSlug + "_" + ConstantConfig.AUTH_TOKEN);
    this.localStorageService.remove(clientSlug + "_" + ConstantConfig.USER_INFO);
    this.localStorageService.remove(clientSlug + "_" + ConstantConfig.SELECTED_THEME);
    this.localStorageService.remove(clientSlug + "_"  + ConstantConfig.LOGIN_AS_CLIENT);
    close();
   }

  //change password popup
  openChangePassword() {
    this.isChangeUserPasswordModelOpen = true;
    // this.userService.setAuthToken('idle');
    this.modalRef = this.modalService.open(UpdatePasswordComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'md' ,keyboard:false,backdrop:'static' });
    this.modalRef.componentInstance.showTitle = true;
    // let body:any = document.querySelector('body');
    // body.classList.add("login-modal-show");
    this.modalRef.result.then((userResponse:any) => {
      this.isChangeUserPasswordModelOpen = false;
      if (userResponse) {
        // after change password popup
        // body.classList.remove("login-modal-show");
      }
    });

  }
}
