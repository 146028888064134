import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { HttpService, MessageService, BroadcastService, PermissionService } from 'src/app/core';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { Utility } from 'src/app/core/utils/utility';
import { LoanDetail } from 'src/app/shared/models/loan/loan.detail.model';
import { isFutureDate } from 'src/app/core/validators/isfuturedate-validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.scss']
})
export class NewNoteComponent implements OnInit {
  @Input() loanID!: string;
  @Input() loanDetail!:LoanDetail;
  @ViewChild('f') formElement!: NgForm;
  addActivityForm!: UntypedFormGroup;
  form!: UntypedFormGroup;
  submitted: boolean = false;
  isDirty:boolean = false;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  afterInfo!:any;
  isExtPermission:boolean = false;
  addActivityFormSubmit: boolean = false;
  activityTypes:any;
  isAcivityPTPActive: boolean = false;
  isAcivityTypeSelected: boolean = false;
  isPastDate:boolean=false;
  isAmountZero:boolean=false;
  selectedAmount:number | undefined;
  selectedDate:string='';
  activityNotePermistion:boolean=false;
  selectedItem:string='';
  errormsg:string='';
  dateerrormsg:string='';
  isButtonDisable:boolean=false;
  associated_phone_numbers:any;
  US_PHONE_MASKING = ConstantConfig.US_PHONE_MASKING;
  showFullNote: boolean= false;



  constructor(private httpService:HttpService ,
    private msgService:MessageService,
    private modalService:NgbModal,
    private broadCastService:BroadcastService,
    private permissionService:PermissionService,
    public modal:NgbActiveModal,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
      this.afterInfo = '';
     // this.isExtPermission = this.permissionService.isSpecialPermission('process_extensions');
      this.activityNotePermistion=this.permissionService.isSpecialPermission('activity_note');

    }
 // Truncated note display logic
 get truncatedNote(): string {
  const note = this.addActivityForm.controls.note.value;
  return note.length > 200 ? note.substring(0, 200) + '...' : note;
}

// On clicking "see more", toggle the full note display
toggleNoteDisplay(): void {
  this.showFullNote = !this.showFullNote;
}

  ngOnInit(): void {
    this.addActivityForm = new UntypedFormGroup({
      activity_type_id: new UntypedFormControl('', [Validators.required]),
      phone_number:new UntypedFormControl(null,[Validators.required]),
      other_phone:new UntypedFormControl(null,[]),
      note: new UntypedFormControl('', [Validators.required, Validators.maxLength(10000) ]),
      ptp_date: new UntypedFormControl('', [isFutureDate]),
      ptp_amount: new UntypedFormControl('0.00', []),
    });


    //form controls
    this.form = new UntypedFormGroup({
      // id: new FormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
      pin: new UntypedFormControl(null, [Validators.required]),
    });


    this.activityTypes=this.activityTypes.filter((item:any) => item.managed_by==='USER');


    this.addActivityForm.controls.activity_type_id.valueChanges.subscribe(
      (val) => {
        const selectedActivity = this.activityTypes.find(
          (item:any) => item.id == val
        );
        this.isAcivityPTPActive = false;
        this.isAcivityTypeSelected = true;
        this.addActivityForm.controls.phone_number.patchValue(null);
        if (selectedActivity) {
          this.isAcivityPTPActive = selectedActivity?.is_input_ptp == 'YES';
          this.addActivityForm.controls.ptp_date.patchValue('');
          this.addActivityForm.controls.ptp_amount.patchValue('0.00');
          this.addActivityForm.updateValueAndValidity();
        }
        if(!['',null,undefined].includes(this.addActivityForm.controls.note.value) && this.isAcivityPTPActive==false){
          this.isButtonDisable=false;
          this.addActivityForm.controls.ptp_date.patchValue('');
          this.addActivityForm.controls.ptp_amount.patchValue('0.00');
          this.addActivityForm.updateValueAndValidity();
        }
      }
    );

    this.isButtonDisable=true;

    this.addActivityForm.controls.note.valueChanges.subscribe(
      (val) => {
        val=val.trim();
        // this.addActivityForm.controls.note.patchValue(val.trim());
        // this.addActivityForm.controls.note.markAsTouched();
        if(val==''&&!this.form.valid)
          this.isButtonDisable=true;
        else{
          this.isButtonDisable=false;
          let amt=this.addActivityForm.controls.ptp_amount.value;
            this.getAmount(amt,2);
          }
        // this.addActivityForm.controls.note.updateValueAndValidity();
        
      }
    );


// this.addActivityForm.controls.ptp_amount.valueChanges.subscribe(
//   (val) => {
//     let amt=val.slice(1);
//     this.curamt=amt;
//     if(((this.addActivityForm.controls.ptp_date.value==undefined ||this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==null) && (amt=='' && amt!=0 ) && (this.addActivityForm.controls.note.value!=''&&this.addActivityForm.controls.note.value!=undefined&& this.addActivityForm.controls.note.value!=null ))        ||     ((this.addActivityForm.controls.ptp_date.value!=undefined && this.addActivityForm.controls.ptp_date.value!='' && this.addActivityForm.controls.ptp_date.value!=null) && (amt!='' && amt!=0) && (this.addActivityForm.controls.note.value!=''&&this.addActivityForm.controls.note.value!=undefined&& this.addActivityForm.controls.note.value!=null )))
//     {
//       this.isButtonDisable=false;
//       this.errormsg='';
//     // this.isPastDate=false;
//     }
//     else if((this.addActivityForm.controls.ptp_date.value!=undefined && this.addActivityForm.controls.ptp_date.value!='' && this.addActivityForm.controls.ptp_date.value!=null) && (amt=='' || amt==0) &&((this.addActivityForm.controls.note.value!=''&&this.addActivityForm.controls.note.value!=undefined&& this.addActivityForm.controls.note.value!=null )))
//     {
//       this.errormsg="PTP amount cannot be zero or blank if there is a PTP Date.";
//       this.isButtonDisable=true;
//     }
//     else if((this.addActivityForm.controls.note.value==''||this.addActivityForm.controls.note.value==undefined || this.addActivityForm.controls.note.value==null ))
//     {
//       this.errormsg='';
//       this.isButtonDisable=true;
//     }else if((this.addActivityForm.controls.ptp_date.value==undefined || this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==null) && (amt!='' && amt!=0))
//     {
//       this.errormsg="1PTP Date cannot be blank if there is a PTP Amount.";
//       this.isButtonDisable=true;
//     }
//     // else if((this.addActivityForm.controls.ptp_date.value==undefined || this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==null) && (amt==0))
//     // {
//     //   this.errormsg="PTP amount cannot be zero.";
//     //   this.isButtonDisable=true;
//     // }


//     if(this.isPastDate)
//     {
//       this.isButtonDisable=true;
//     }



  // });

    if(this.loanDetail?.associated_phone_numbers){
      this.associated_phone_numbers=this.loanDetail?.associated_phone_numbers;
      this.otherPhoneNumber()
    }
  }

  onNoteValueChange(e:any){
    var val=e.target.value;
    this.addActivityForm.controls.note.patchValue(val.trim());
    this.addActivityForm.controls.note.markAsTouched();
    this.addActivityForm.controls.note.updateValueAndValidity();
    if(val==''&&!this.form.valid)
      this.isButtonDisable=true;
    else{
      this.isButtonDisable=false;
    let amt=this.addActivityForm.controls.ptp_amount.value;
      this.getAmount(amt,2);
    }
  }


  getDateRemove(e:any){
    let camt=this.addActivityForm.controls.ptp_amount.value;
    let amt=camt.slice(1);
    if((e==undefined || e=='' ||e==null) && (amt=='' || amt==0 || amt==undefined) &&
      ((this.addActivityForm.controls.note.value!=''&&this.addActivityForm.controls.note.value!=undefined&& 
      this.addActivityForm.controls.note.value!=null ))){
      this.errormsg="";
      this.isButtonDisable=false;
      this.isPastDate=false;
    }
    if((e==undefined || e=='' ||e==null) && (amt!='' && amt!=0 && amt!=undefined) &&
      ((this.addActivityForm.controls.note.value!=''&&this.addActivityForm.controls.note.value!=undefined&&
      this.addActivityForm.controls.note.value!=null ))){
      this.errormsg="PTP Date cannot be blank if there is a PTP Amount.";
      this.isButtonDisable=true;
      this.isPastDate=false;
    }
  }

  curamt:any;

  getAmount(e:any,type:number){
    let amt;
    if(type==2){
      amt=e;
      this.curamt=e;
    }
    
    else if(type==1){
      amt=e.target.value.slice(1);
      this.curamt=amt;
    }
    if(((this.selectedDate==undefined || this.selectedDate=='' || this.selectedDate==null) && (amt=='')) || 
      ((this.addActivityForm.controls.ptp_date.value!=undefined && this.addActivityForm.controls.ptp_date.value!='' && 
      this.addActivityForm.controls.ptp_date.value!=null) && (amt!='' && amt!=0) )){

      this.isButtonDisable=false;
      this.errormsg='';
    // this.isPastDate=false;
    }
    else if((this.addActivityForm.controls.ptp_date.value!=undefined && this.addActivityForm.controls.ptp_date.value!='' && 
      this.addActivityForm.controls.ptp_date.value!=null) && (amt=='' || amt==0)){

      this.errormsg="PTP amount cannot be zero or blank if there is a PTP Date.";
      this.isButtonDisable=true;
    }
  //     else if((this.addActivityForm.controls.note.value==''||this.addActivityForm.controls.note.value==undefined || 
  //       this.addActivityForm.controls.note.value==null ))
  //     {
  //       this.errormsg='';
  //       this.isButtonDisable=true;
  //     }
    //   else if((this.addActivityForm.controls.ptp_date.value==undefined || this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==null) && (amt==0))
    //   {
    //     this.errormsg="PTP amount cannot be zero.";
    //     this.isButtonDisable=true;
    //   }
    else if((this.addActivityForm.controls.ptp_date.value==undefined || this.addActivityForm.controls.ptp_date.value=='' || 
      this.addActivityForm.controls.ptp_date.value==null) && (amt!='' && amt!=0 && amt!=undefined) ){

      this.errormsg="PTP Date cannot be blank if there is a PTP Amount.";
      this.isButtonDisable=true;
    }
    else{
      this.errormsg=""
      this.isButtonDisable=false;
    }
    if(this.isPastDate){

      this.isButtonDisable=true;
    }


          /***********convert to decimal***************** */

    let data:any= this.addActivityForm.controls.ptp_amount.value;

    this.addActivityForm.controls.ptp_amount.patchValue( data==''?'':parseFloat(data).toFixed(2));
    this.addActivityForm.controls.ptp_amount.updateValueAndValidity();

    /***************************************** */
  
  }



  onActivitySubmit(content:any) {

    this.selectedAmount=this.addActivityForm.controls.ptp_amount.value==''?0:parseFloat(this.addActivityForm.controls.ptp_amount.value);
    this.selectedDate= this.addActivityForm.controls.ptp_date.value;

    if(this.selectedDate==undefined)
      this.addActivityForm.controls.ptp_date.reset();

    this.addActivityForm.markAllAsTouched();
    if (this.addActivityForm.valid && !this.addActivityFormSubmit) {

      if( ((this.selectedDate==''||this.selectedDate==undefined) && 
        ['',null,'0','0.00'].includes(this.addActivityForm.controls.ptp_amount.value)) ||   (this.selectedDate!='' && 
        this.selectedDate!=null  && this.selectedAmount>0) || this.isAcivityPTPActive==false ){

        if(!this.activityNotePermistion){

          this.form.reset();
          this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            size: 'sm',
          });
        }
        else{

          this.addActivityFormSubmit = true;
          const params = this.addActivityForm.value;
          params.loan_id = this.loanID;
          if(this.addActivityForm?.controls?.phone_number?.value!='other'){
            delete params?.other_phone;
            params.phone_slug=params?.phone_number?.slug;
            params.phone_title=params?.phone_number?.title;
            params.phone_number=params?.phone_number?.number;
          }
          if(this.addActivityForm?.controls?.phone_number?.value=='other'){
            params.phone_number= params?.other_phone;
            params.phone_title='Other';
            params.phone_slug='other';
            delete params?.other_phone;
          }

        this.errormsg='';

        this.httpService.post(PathConfig.LOAN_ACTIVITY_NOTES, params).subscribe(
          (response: any) => {
            this.msgService.showSuccess(response?.message);
            this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
            this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA, 'NEW_NOTE');
            this.modalService.dismissAll();
            this.addActivityFormSubmit = false;
            this.errormsg='';
            this.isPastDate=false;
            this.isButtonDisable=false;
          },
          (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
            this.modalService.dismissAll();
            this.addActivityFormSubmit = false;
          }
        );

      }

    }
    else{
        this.isAmountZero=true;
        //  this.isPastDate=true;
        this.isButtonDisable=true;
        if((this.selectedDate=='' && this.addActivityForm.controls.ptp_amount.value!='')||(this.selectedDate==null && this.addActivityForm.controls.ptp_amount.value!=''))

        this.errormsg="PTP Date cannot be blank if there is a PTP Amount.";
        if(this.selectedDate!='' && this.addActivityForm.controls.ptp_amount.value=='')
        this.errormsg="PTP amount cannot be zero or blank if there is a PTP Date.";

      }
    }

  }

  getSelectedItem(e:any){
    this.selectedItem=e.target.value;
    if(this.isAcivityTypeSelected&&(this.addActivityForm?.controls?.activity_type_id?.value==1||
    this.addActivityForm?.controls?.activity_type_id?.value==2||
    this.addActivityForm?.controls?.activity_type_id?.value==3||
    this.addActivityForm?.controls?.activity_type_id?.value==4||
    this.addActivityForm?.controls?.activity_type_id?.value==5||
    this.addActivityForm?.controls?.activity_type_id?.value==6||
    this.addActivityForm?.controls?.activity_type_id?.value==9)){
      this.addActivityForm?.controls?.phone_number?.setValidators([Validators.required]);
    }
      // if(this.addActivityForm?.controls?.phone_number?.value!=''||this.addActivityForm?.controls?.phone_number?.value!=null)
    else{
      this.addActivityForm?.controls?.phone_number?.clearValidators();
      this.addActivityForm?.controls?.other_phone?.clearValidators();
    }
    this.addActivityForm?.controls?.phone_number?.updateValueAndValidity();
    this.errormsg='';
  }

  openOverrideModal(content:any){
    this.form.reset();
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'sm',
      windowClass:'draggable-container',
      backdrop:false
    });
  }

  verifyPin(){

    if(this.form.valid){
      const params = this.form.getRawValue();
      const url = `${PathConfig.VERIFY_PIN}`;
      this.httpService.post(url, params).subscribe({
        next : (res) => {

          if(res?.message!='User not found' && res?.message!='User validation failed'){
            this.msgService.showSuccess(res?.message);
            this.activityNotePermistion=true;
            /********************** */
            this.addActivityFormSubmit = true;
            const params = this.addActivityForm.value;
            params.loan_id = this.loanID;

            if(this.addActivityForm?.controls?.phone_number?.value!='other'){
              delete params?.other_phone;
              params.phone_slug=params?.phone_number?.slug;
              params.phone_title=params?.phone_number?.title;
              params.phone_number=params?.phone_number?.number;
            }
            if(this.addActivityForm?.controls?.phone_number?.value=='other'){
              params.phone_number= params?.other_phone;
              params.phone_title='Other';
              params.phone_slug='other';
              delete params?.other_phone;
            }
            this.errormsg='';

            this.httpService.post(PathConfig.LOAN_ACTIVITY_NOTES, params).subscribe(
              (response: any) => {
                this.msgService.showSuccess(response?.message);
                this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACTIVITY);
                this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);

                this.modalService.dismissAll();
                this.addActivityFormSubmit = false;
                this.errormsg='';
                this.isPastDate=false;
                this.isButtonDisable=false;
              },
              (err) => {
                const msgList = Utility.getErrorMessage(err.error);
                msgList.forEach((msg) => this.msgService.showError(msg));
                this.modalService.dismissAll();
                this.addActivityFormSubmit = false;
              }
            );

          /******************** */
            }
            else{
              this.msgService.showError(res?.message);
            }
            this.submitted = false;
            this.isDirty = false;
            this.form.reset();
          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }

  //   else
  //   {
  // this.isAmountZero=true;
  // //  this.isPastDate=true;
  //  this.isButtonDisable=true;
  // if((this.selectedDate=='' && this.addActivityForm.controls.ptp_amount.value!='')||(this.selectedDate==null && this.addActivityForm.controls.ptp_amount.value!=''))

  // this.errormsg="PTP Date cannot be blank if there is a PTP Amount.";
  // if(this.selectedDate!='' && this.addActivityForm.controls.ptp_amount.value=='')
  // this.errormsg="PTP amount cannot be zero or blank if there is a PTP Date.";

  //   }
  // }
  }

  futureDateCheck(e?:Date){
    if(e){
      this.selectedDate=this.addActivityForm.controls.ptp_date.value;
      let amt=this.curamt;

      if((this.addActivityForm.controls.ptp_date.value!='' && this.addActivityForm.controls.ptp_date.value!=undefined && 
        this.addActivityForm.controls.ptp_date.value!=null)){

        let cDate = moment(new Date()).format("YYYY-MM-DD");
        let sDate = moment(e).format("YYYY-MM-DD")

        if(sDate>cDate){
          this.isPastDate=false;
          this.errormsg=this.errormsg=='PTP Date cannot be blank if there is a PTP Amount.'?'':this.errormsg;
          this.errormsg=(amt==0 ||amt=='' ||amt==undefined)?'PTP amount cannot be zero or blank if there is a PTP Date.':'';

          this.isButtonDisable=this.errormsg!=''?true:false;
        }
        else{
        this.isPastDate=true;
        this.errormsg=(amt==''||amt==undefined || amt==0)?'PTP amount cannot be zero or blank if there is a PTP Date.':'';
        this.dateerrormsg="";
        this.isButtonDisable=true;
        }

      }

      if((this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==undefined || 
        this.addActivityForm.controls.ptp_date.value==null)&&(amt=='' || amt==undefined || amt==null)){

        this.errormsg='';
        this.isPastDate=false;
        this.isButtonDisable=false;
      }
      if((this.addActivityForm.controls.ptp_date.value=='' || this.addActivityForm.controls.ptp_date.value==undefined || 
        this.addActivityForm.controls.ptp_date.value==null)&&(amt!='' && amt!=undefined && amt!=null)){
        this.isPastDate=false;
        this.errormsg='PTP Date cannot be blank if there is a PTP Amount.';
        this.isButtonDisable=this.errormsg!=''?true:false;
      }
    }
    else{
      this.errormsg='';
      this.isButtonDisable=false;
    }
    let amt=this.addActivityForm.controls.ptp_amount.value;
      this.getAmount(amt,2);
  }

  otherPhoneNumber(){
    if(this.addActivityForm?.controls?.phone_number?.value=='other'){
      this.addActivityForm?.controls?.other_phone?.setValidators([Validators.required]);
    }
    else{
      this.addActivityForm?.controls?.other_phone?.clearValidators();
      // this.addActivityForm.controls.other_phone.value='';
    }
    this.addActivityForm?.controls?.other_phone?.updateValueAndValidity();
  }

}



