import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { PathConfig } from 'src/app/core/config/path.config';
import { UserDetail } from 'src/app/shared/models/user/user-detail.model';
import * as _ from 'lodash';
import { Utility } from 'src/app/core/utils/utility';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { AbstractControl, NgForm, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-manual-charge-off',
  templateUrl: './manual-charge-off.component.html',
  styleUrls: ['./manual-charge-off.component.scss']
})
export class ManualChargeOffComponent implements OnInit {

  @Input() loanDetail!: any;
  @Input() loanID!: any;
  @Input() isRouteShowHistory : boolean = false;
  @ViewChild('f') formElement!: NgForm;
  /**
   * charge-Off Account
   */
  userList: Array<UserDetail> = [];
  manualChargeOffLoans: boolean = false;
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;

  submitted: boolean = false;
  isDirty:boolean = false;
  DATE_FORMAT = ConstantConfig.DATE_FORMAT;
  isLoanActive:boolean=false;
  appform!: UntypedFormGroup;
  manualChargeOffForm!: UntypedFormGroup;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  manualChargeOffPreview: any = null;
  afterManualChargeOffDate : any =  new Date();
  salesLocationArray:Array<String> = []
  mileageStatusArray: Array<{ label: string, slug: string }> = [
    { label: 'Actual Miles', slug: 'ACTUAL_MILES' },
    { label: 'True Mileage Unknown', slug: 'TRUE_MILEAGE_UNKNOWN' },
    { label: 'Beyond Mechanical Limits', slug: 'BEYOND_MECHANICAL_LIMITS' },
    { label: 'Exempt', slug: 'EXEMPT' }
  ];
  refresh:boolean = false;
  constructor(
    private httpService: HttpService,
    private msgService: MessageService,
    private permissionService: PermissionService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
    this.manualChargeOffLoans = this.permissionService.isSpecialPermission('manual_charge_off');
  }

  ngOnInit(): void {
    this.appform = new UntypedFormGroup({
      loanId: new UntypedFormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
       pin: new UntypedFormControl(null, [Validators.required]),
     });
    this.isLoanActive=this.loanDetail?.status=='ACTIVE'? true:false;

    this.manualChargeOffForm = new UntypedFormGroup({
       add_inventory: new UntypedFormControl('NO', [Validators.required]),
       stock_number: new UntypedFormControl(this.loanDetail?.collateral?.stock_number, []),
       sales_location: new UntypedFormControl(null, []),
       mileage: new UntypedFormControl('', [this.currencyRangeValidator]),
       mileage_status: new UntypedFormControl('ACTUAL_MILES', []),
       collateral_actual_cash_value: new UntypedFormControl('', [this.currencyRangeValidator]),
       vin: new UntypedFormControl(this.loanDetail?.collateral?.vehicle_vin, [Validators.maxLength(100),Validators.pattern('^(?!\\s*$).+')]),
     });
     this.getManualChargeOffPreview();
  }



  verifyPin()
  {
    if(this.appform.valid)
      {
        const params = this.appform.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.manualChargeOffLoans=true;
          // this.routeAccount();
          this.submit();
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.appform.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }


  }





  /**
   * Get Approval Modal
   * @param content
   */
  openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }

  /**
   * charge off loan Account
   */

  submit(){
    this.manualChargeOffForm.markAllAsTouched();
    ///loans/manual_charge_off/{loan_id}
    if (this.manualChargeOffForm.valid && !this.submitted)
      {
        let params                      = this.manualChargeOffForm.value;
        params.loan_id                    = this.loanDetail.id ?? null;
        params.before_status              = this.manualChargeOffPreview?.before?.status_value ?? null;
        params.after_status               = this.manualChargeOffPreview?.after?.status_value ?? null;
        params.before_sub_status          = this.manualChargeOffPreview?.before?.sub_status ?? null;
        params.after_sub_status           = this.manualChargeOffPreview?.after?.sub_status ?? null;
        params.before_charge_off_date     = null;
        params.after_charge_off_date      = this.afterManualChargeOffDate ?  moment(this.afterManualChargeOffDate).format('YYYY-MM-DD') : null;
        params.before_charge_off_amount   = this.manualChargeOffPreview?.before?.chargeOfAmmount ?? 0;
        params.after_charge_off_amount    = this.manualChargeOffPreview?.after?.chargeOfAmmount ?? 0;


      // if(params.add_inventory === 'YES')
      //   {
      const url =`${PathConfig.LOANS_MANUAL_CHARGE_OFF}/${this.loanID}` ;
        this.httpService.post(url, params)
        .subscribe((response:any) => {
          // this.msgService.showSuccess(response.message);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
          this.msgService.showSuccess(response?.message);
          this.submitted = false;
          this.isDirty = false;
          this.modalService.dismissAll();

        },
        (err:any) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.submitted = false;
        });
      // }
      // else
      // {
      //   this.submitMannualChargeOff();
      // }
      }
  }

  submitMannualChargeOff(): Promise<any>
  {
    return new Promise((resolve, reject) => {

    const url =`${PathConfig.LOANS_MANUAL_CHARGE_OFF}/${this.loanID}` ;
    this.httpService.get(url).subscribe((response)=>{
        this.submitManualChargeOffLog().then(response => {
          this.msgService.showSuccess(response?.message);
        }).catch(error => {
          // Handle error if needed

        });

      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
      this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
      this.msgService.showSuccess(response?.message);
      this.submitted = false;
        this.isDirty = false;
       },(err)=>{
         this.msgService.showError(err.error.message);
         this.submitted = false;
       });

       setTimeout(() => {
        resolve({ message: 'Operation completed successfully!' });
      }, 2000);
    });
  }

  submitManualChargeOffLog(): Promise<any> {
    // Your logic here, e.g., an HTTP request
    return new Promise((resolve, reject) => {

      let params                      = this.manualChargeOffForm.value;
      params.loan_id                    = this.loanDetail.id ?? null;
      params.before_status              = this.manualChargeOffPreview?.before?.status_value ?? null;
      params.after_status               = this.manualChargeOffPreview?.after?.status_value ?? null;
      params.before_sub_status          = this.manualChargeOffPreview?.before?.sub_status ?? null;
      params.after_sub_status           = this.manualChargeOffPreview?.after?.sub_status ?? null;
      params.before_charge_off_date     = null;
      params.after_charge_off_date      = this.afterManualChargeOffDate ?  moment(this.afterManualChargeOffDate).format('YYYY-MM-DD') : null;
      params.before_charge_off_amount   = this.manualChargeOffPreview?.before?.chargeOfAmmount ?? null;
      params.after_charge_off_amount    = this.manualChargeOffPreview?.after?.chargeOfAmmount ?? null;

      const url = `${PathConfig.LOANS_MANUAL_CHARGE_OFF_LOG}`;
        this.httpService.post(url, params)
        .subscribe((response:any) => {
          //this.msgService.showSuccess(response.message);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
          this.submitted = false;
          this.isDirty = false;
          this.modalService.dismissAll();
        },
        (err:any) => {
          const msgList = Utility.getErrorMessage(err.error);
          msgList.forEach((msg) => this.msgService.showError(msg));
          this.submitted = false;
        });

      setTimeout(() => {
        resolve({ message: 'Operation completed successfully!' });
      }, 2000);
    });
  }

  getManualChargeOffPreview()
  {
     ///loans/manual_charge_off/{loan_id}
     const url =`${PathConfig.LOANS_MANUAL_CHARGE_OFF_PREVIEW}/${this.loanID}` ;
     this.httpService.get(url).subscribe((response)=>{
              this.manualChargeOffPreview = response;
              this.salesLocationArray = this.manualChargeOffPreview.salesLocations.map((location:any) => location.sales_location);
              if(this.manualChargeOffPreview?.addToInventory == true){
                this.manualChargeOffForm.patchValue({add_inventory:'YES'});
                setTimeout(() => {
                  this.dynamicRequiredSetValidators();
                }, 200);
                
              }
              else{
                this.dynamicRequiredSetValidators();
              }
        },(err)=>{
          this.msgService.showError(err.error.message);
        });

  }

  currencyRangeValidator(control: AbstractControl) {
    const value = control.value;
    const isValid = value == '' || ( value >= 0 && value <= 99999999 );
    return isValid ? null : { currencyRange: true };
  }

  dynamicRequiredSetValidators() {
    this.refresh = false;

    if(this.manualChargeOffForm.controls.add_inventory.value == 'YES'){

      this.manualChargeOffForm.controls.stock_number.setValidators([Validators.required]);
      this.manualChargeOffForm.controls.sales_location.setValidators([Validators.required]);
      this.manualChargeOffForm.controls.mileage.setValidators([Validators.required,this.currencyRangeValidator]);
      this.manualChargeOffForm.controls.mileage_status.setValidators([Validators.required]);
      this.manualChargeOffForm.controls.collateral_actual_cash_value.setValidators([Validators.required,this.currencyRangeValidator]);
      this.manualChargeOffForm.controls.vin.setValidators([Validators.required,Validators.maxLength(100),Validators.pattern('^(?!\\s*$).+')]);
    
    }
    else{

      this.manualChargeOffForm.controls.stock_number.setValidators([]);
      this.manualChargeOffForm.controls.sales_location.setValidators([]);
      this.manualChargeOffForm.controls.mileage.setValidators([this.currencyRangeValidator]);
      this.manualChargeOffForm.controls.mileage_status.setValidators([Validators.required]);
      this.manualChargeOffForm.controls.collateral_actual_cash_value.setValidators([this.currencyRangeValidator]);
      this.manualChargeOffForm.controls.vin.setValidators([Validators.maxLength(100),Validators.pattern('^(?!\\s*$).+')]);
    
    }
    this.manualChargeOffForm.updateValueAndValidity();
    setTimeout(() => {
      this.refresh = true;
    }, 200);
  }

   //for collateral_actual_cash_value conversion 
   onAmountChange(){

    this.manualChargeOffForm.get('collateral_actual_cash_value')?.setValue(this.checkForNan(this.manualChargeOffForm.controls.collateral_actual_cash_value.value));

  }

  checkForNan(num:any){
    if(!isNaN(num) && num!=''){
      return this.roundToDecimal(parseFloat(num),2);
    }
    else {
      return parseFloat('0.00')?.toFixed(2);
    }
  }

  roundToDecimal(num:any, length:any) {
    return (new Decimal(Number(num))).toFixed(parseFloat(length) || 0);
  }

  // Function to replace spaces with &nbsp;
  replaceSpacesWithNbsp(input: any): string {
    if (!input) return input; // Return as is if input is empty or null
    return input.replace(/ /g, '&nbsp;');
  }

}
