<div class="login-form-content">
  <form class="login-form m-auto">
    <h4 class="text-center mb-5 form-heading">Please Wait...</h4>
    <div class="description">
      <p>
        There is some processing going on with this loan, kindly wait for a few min(s) till the processing completes.
        Thanks for your patience!
      </p>
    </div>
    <div class="row text-justify">
      <div class="col-md-6 text-left">
        <a [href]="queuesUrl">Queue</a>
      </div>
      <div class="col-md-6 text-right">
        <a [href]="refreshUrl">Refresh</a>
      </div>
      <!-- <div class="col-md-4 text-right">
        <a [href]="nextUrl" [class.inactiveLink]="!showNext">Next</a>
      </div> -->
    </div>
  </form>
</div>
