import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ScrollingModule,
    DragDropModule
  ],
  exports: [
    CommonModule,
    ScrollingModule,
    DragDropModule
  ]
})
export class MaterialWidgetModule { }
