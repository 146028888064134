import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { BroadcastService, HttpService, MessageService } from 'src/app/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';

@Component({
  selector: 'app-data-warehouse',
  templateUrl: './data-warehouse.component.html',
  styleUrls: ['./data-warehouse.component.scss']
})
export class DataWarehouseComponent implements OnInit {
  @ViewChild('dataWareHouseAccordion', { static: true }) dataWareHouseAccordion!: NgbAccordion;
  defaultPanel: Array<string> = [];
  dataWarehouseList: Array<any> = [];
  isEditPermission: boolean = true;
  field_search: string = '';
  category_search: string = '';
  isDataWareHouseLoading: boolean = false;
  @Input() isModuleServicing: boolean = false;
  openPanels: any;
  constructor(
     private httpService: HttpService,
     private msgService: MessageService, 
     private broadCastService: BroadcastService,
     private translateService:TranslateService
   ) {
     this.translateService.setDefaultLang('en');
     this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); }

  ngOnInit(): void {
    this.getDatawareHouseList();
  }
  searchPending: boolean = false;

  onListSearch(val: string, type: string) {
    if (this.isDataWareHouseLoading) {
      this.searchPending = true;
      switch (type) {
        case "field":
          this.field_search = val;
          break;
        case "category":
          this.category_search = val;
          break;
      }
    }
    else {
      this.searchPending = false;
      switch (type) {
        case "field":
          this.field_search = val;
          break;
        case "category":
          this.category_search = val;
          break;
      }
      this.getDatawareHouseList();
    }
  }

  getDatawareHouseList() {
    this.isDataWareHouseLoading = true;
    const isSearchEmpty = this.field_search.trim() === '';
    const url = `${PathConfig.DATA_WAREHOUSE}?search=${this.field_search}&category=${this.category_search}`;
    this.httpService.get(url).subscribe(
      (response) => {
        this.dataWarehouseList = response || [];
        this.defaultPanel = this.dataWarehouseList?.map((warehouse: any) => `warehouse-${warehouse.id}`);
        this.isDataWareHouseLoading = false;
        this.openPanels = isSearchEmpty ? [] : this.defaultPanel;
        setTimeout(() => this.dataWareHouseAccordion?.expandAll(), 1000);
        if (this.searchPending) {
          this.searchPending = false;
          this.onListSearch(this.field_search, 'field');
        }
      },
      (err) => {
        this.msgService.showError(err.error.message);
        this.isDataWareHouseLoading = false;
      }
    );
  }

  fieldSelected(field: any){
    this.broadCastService.broadcast(
      MsgConstantConfig.NOTIFICATION_FIELD, field
    );
  }

  // optimizing rendering of list of mapping tool
  TrackById(index: any, item: any) {
    return item?.id || index;
  }

  searchReset() {
    console.log('searchReset')
    this.field_search = '';
    this.category_search = '';
    this.getDatawareHouseList();
  }

}
