import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedUserServiceService {

  constructor() { }
  private userResponse = new Subject();
  currentUserResponse = this.userResponse.asObservable();


  updateSelectedUserResponse(userResponse:any) {
    this.userResponse.next(userResponse)
  }

}
