import { Injectable } from '@angular/core';

import { LocalStorageService } from './local-storage.service';
import { ConstantConfig } from '../config/constant.config';
import { UserInfo } from '../models/user/user-info.model';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientUtils } from '../utils/clientUtil';
import { ClientDetailService } from './client-detail-service';

@Injectable()
export class UserService {

  constructor(private localStorageService: LocalStorageService,
    private edService:EncryptDecryptService, private activatedRoute: ActivatedRoute, private router :Router, private clientDetailService:ClientDetailService) { }


  /**
   * set user Object
   * @param- userObj
   */
  public setUserInfo(userObj: UserInfo) {
    const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    const info = this.edService.encrypt(JSON.stringify(userObj));
    this.localStorageService.set(clientSlug + "_" + ConstantConfig.USER_INFO, info);
  }

  /**
   * set user Object
   * @param- clientSlug
   * @param- userObj
   */
  public setClientUserInfo(clientSlug: string, userObj: UserInfo) {
    const info = this.edService.encrypt(JSON.stringify(userObj));
    this.localStorageService.set(clientSlug + "_" + ConstantConfig.USER_INFO, info);
  }

  /**
   * get user info
   * @param- field
   * @returns- {string}
   */
  getUserInfo(field: string = ''):string | UserInfo | any {
    //  user detail from local storage
    const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    const userInfoObj = this.localStorageService.get(clientSlug + "_" + ConstantConfig.USER_INFO) || '';
    const info = this.edService.decrypt(userInfoObj);
    let data = '';
    if (info) {
      data =  field ? JSON.parse(info)[field] : JSON.parse(info);
    }
    return data;
  }

/**
   * set user Permissions
   */
  setPermissions(clientSlug:string ,permissions:Array<string>){
    const info = permissions ? this.edService.encrypt(JSON.stringify(permissions)) : JSON.stringify([]);
    this.localStorageService.set(clientSlug + "_" + ConstantConfig.USER_PERMISSION, info);
  }

  /**
   * get user Permissions
   */
   get PERMISSIONS():Array<any> {
    //  user permission from local storage
    const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    const userPermissions = this.localStorageService.get(clientSlug + "_" + ConstantConfig.USER_PERMISSION);
    const info :any = userPermissions ? JSON.parse(this.edService.decrypt(userPermissions)) : [];
    return info;
  }

  /**
   * get Auth Token
   * @returns- {string}
   * constructor
   */
  public get AUTH_TOKEN(): string {
    let clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    if(!clientSlug){
      clientSlug = this.clientDetailService.CLIENT_ID;
    }
    const token = this.localStorageService.get(clientSlug + "_"  + ConstantConfig.AUTH_TOKEN);
    return token ? token : '';
  }



  /**
   * Set Auth Token Expiry
   * @param- time
   */
   public setAuthTokenExpiry(time: string) {
    const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    const timeInSecond = new Date(time).getTime();
    this.localStorageService.set(clientSlug + "_"  + ConstantConfig.AUTH_TOKEN_EXPIRY,timeInSecond);
  }

    /**
   * Set Client Auth Token Expiry
   * @param- time
   */
     public setClientAuthTokenExpiry(clientSlug:string,time: string) {
      const timeInSecond = new Date(time).getTime();
      this.localStorageService.set(clientSlug + "_"  + ConstantConfig.AUTH_TOKEN_EXPIRY,timeInSecond);
    }

  /**
   * Get Auth Token Expiry
   * @return - number
   */
   public getAuthTokenExpiry():number {
    const clientSlug = this.clientDetailService.CLIENT_ID;
    const tokenExpiry = this.localStorageService.get(clientSlug + "_"  + ConstantConfig.AUTH_TOKEN_EXPIRY);
    return tokenExpiry ? tokenExpiry : 0;
  }

  /**
   * Set Auth Token
   * @param- authToken
   */
  public setAuthToken(authToken: string) {
    const clientSlug = ClientUtils.getClientSlug(this.activatedRoute, this.router);
    this.localStorageService.set(clientSlug + "_"  + ConstantConfig.AUTH_TOKEN,authToken);
  }

  /**
   * Set Auth Token
   * @param- clientSlug
   * @param- authToken
   */
  public setClientAuthToken(clientSlug:String, authToken: string) {
    this.localStorageService.set(clientSlug + "_"  + ConstantConfig.AUTH_TOKEN,authToken);
  }

  /**
   * Set Login status
   * @param- clientSlug
   */
  public setDirectLogin(clientSlug:String) {
    this.localStorageService.set(clientSlug + "_"  + ConstantConfig.LOGIN_AS_CLIENT, true);
  }

  /**
   * get login status
   * @param- clientSlug
   */
  public getLoginType(clientSlug:String) {
    const loginType = this.localStorageService.get(clientSlug + "_"  + ConstantConfig.LOGIN_AS_CLIENT);
    return loginType == 'true';
  }

  /**
   * getClientId
   */
  public getClientId() {
    let clientId;
    this.activatedRoute.paramMap.subscribe(params => {
      clientId = params.get('clientId') || '';
    });
    if(!clientId){
      let url = this.router.url.split("/");
      if(url.length > 1){
        clientId = url[1];
      }
    }
    return clientId;
  }
}
