import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
@Component({
  selector: 'app-ls-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class LSFileUploadComponent implements OnInit , OnChanges {
  @ViewChild('fileInput') fileInput!:ElementRef;
  @Input() heading:string = '';
  @Input() imgPath:string = '';
  @Input() imgType:string = '';
  @Input() isEditPermission:boolean = true;
  @Output() fileSelected:EventEmitter<any> = new EventEmitter();
  @Output() fileReset:EventEmitter<any> = new EventEmitter();
  uniqueID:string  = _.uniqueId('file_');
  constructor(private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); } 
  ngOnChanges(changes:SimpleChanges):void{
    const img = changes['imgPath'];
    if(img &&  (img.currentValue!= img.previousValue)){
      if(this.imgType == 'Profile'){
        this.imgPath = img.currentValue ? img.currentValue : '../assets/images/icons/default-user.png' ;
      }else{
        this.imgPath = img.currentValue ? img.currentValue : '../assets/images/icons/default.png' ;
      }
    }

    const isPermission = changes['isEditPermission'];
    if(isPermission &&  (isPermission.currentValue!= isPermission.previousValue)){
      this.isEditPermission = isPermission.currentValue;
    }
  }

  ngOnInit(): void {

}

  onUploadBtnClick(){
    this.fileInput.nativeElement.click();
  }
  // on file upload
  onFileChange(event:any) {
    if(event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      this.fileSelected.emit(file);
    }
    this.fileInput.nativeElement.value = '';
  }
  // on file reset
  onFileReset() {
    this.fileReset.emit(true);
    this.fileInput.nativeElement.value = '';
  }

}
