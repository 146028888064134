import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor( private translateService:TranslateService
  ) {
      
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
     }

  ngOnInit(): void {
  }

}
