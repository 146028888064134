import { AbstractControl } from '@angular/forms';

/**
Email Validation
--------------------
Valid formats:
test@test.com
test@test.com;test1@test.com
test@test.com;test1@test.com;test2@test.com;test3@test.com
 */
export function EmailConditionalValidator(control: AbstractControl) {
 // const regExp = /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/;
 
 //supports multi email validation
  const regExp = /^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/;
  if(control.value && control.value.startsWith('{')) return null;
  if (control.value && !regExp.test(control.value)) {
    return { invalidEmail: "Invalid Email" };
  }
  return null;
}