import { FormGroup } from '@angular/forms';

export function DueDateValidator(date_date: string, first_due_date: string){
    return (formGroup: FormGroup) => {
        const fdd_form_control = formGroup.controls[first_due_date];
        const dd_form_control = formGroup.controls[date_date];

        let DD = dd_form_control.value;
        let FDD = fdd_form_control.value;

        if(DD) {
        
            if (FDD && FDD > DD) {

                dd_form_control.setErrors({invalid_FFD_Before_DD: true });

                dd_form_control.markAsTouched();

            }

            else
                dd_form_control.setErrors(null);
        }
        else {
            dd_form_control.setErrors({required:true});
        }
    }
}