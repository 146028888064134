<!-- Process Extension Modal -->
<div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
  <div class="draggable-modal-content cursor-pointer" cdkDragHandle>
<form #f="ngForm" [formGroup]="form" novalidate>
    <div class="modal-header align-items-center " >
      <h6 class="modal-title modal-title-success">{{'Process Extension'|translate}}</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <!-- Eligible  => class = > text-success-light  -->
      <!-- Ineligible  => class = > lms-text-light-pink  -->
      <div class="bg-note-info p-2 text-center" [ngClass]="isEligible ? 'text-success-light' : 'text-error'">{{ isEligible ? ('Eligible'|translate) : ('Ineligible'|translate) }}</div>
      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form-content">
            <div>
              <div class="row">
                <div class="form-group col-lg-3">
                  <label class="form-group-label">{{'# of Extensions'|translate}}</label>
                </div>
                <div class="form-group col-lg-3">
                  <select name="" id=""   [(ngModel)]="selectedExtension"  class="form-control" formControlName="extension_needed" (change)="onExtensionNeededChange()">
                    <option [ngValue]="null">{{'--Please Select--'|translate}}</option>
                    <!-- <option [ngValue]="extNeeded" *ngFor="let extNeeded of extensionNeededList; let i = index">{{ extNeeded ?? '' }}</option> -->
                    <ng-container *ngFor="let extNeeded of extensionNeededList; let i = index">
                      <option *ngIf="isEligible" [ngValue]="extNeeded">
                        {{ extNeeded ?? '' }}
                      </option>
                    </ng-container>

                  </select>
                  <val-errors controlName="extension_needed">
                    <ng-template valError="required">{{'This field is required'|translate}}</ng-template>
                  </val-errors>
                </div>
                <div class="form-group col-lg-3">
                  <label class="form-group-label">{{'Remaining'|translate}}</label>
                </div>
                <div class="form-group col-lg-2">
                  <input type="text" class="form-control" [readonly]="true" [value]="(extensionRemaining && extensionRemaining >0 )? extensionRemaining : 0">
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-sm table-no-bg table-align-middle mb-0">
                <thead>
                  <tr>
                    <td class="border-0">{{'Field Name'|translate}}</td>
                    <td class="border-0">{{'Before'|translate}}</td>
                    <td class="border-0"></td>
                    <td class="border-0">{{'After'|translate}}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{'Due Date'|translate}}:</td>
                    <td>{{ (beforeDetails?.current_due_date | date: DATE_FORMAT_MMDDYYYY)  || "-" }}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td class="lms-text-light-purple">
                      {{ (isEligible && afterExtensionDueDate && selectedExtension ? (afterExtensionDueDate | date: DATE_FORMAT_MMDDYYYY) : '-') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{'Past Due Amount'|translate}}:</td>
                    <td>{{(beforeDetails?.current_amount_due  || 0) | currency}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td class="lms-text-light-purple"> {{  (isEligible && pastDueAfter && selectedExtension ? (pastDueAfter | currency) : '-')}}</td>
                  </tr>
                  <tr>
                    <td>{{'Extensions Granted'|translate}}:</td>
                    <td>{{total_extension_taken || '-'}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <!-- <td class="lms-text-light-purple">{{ isEligible ? (form.value?.extension_needed + total_extension_taken  || '-') : '-'}}</td> -->
                   <td class="lms-text-light-purple"> {{ (isEligible && selectedExtension && (form.value?.extension_needed + total_extension_taken))  || '-' }}</td>

                  </tr>
                  <tr>
                    <td>{{'Last Extension Date'|translate}}:</td>
                    <td>{{ (beforeDetails?.latest_process_extension_date ?(beforeDetails?.latest_process_extension_date | date:DATE_FORMAT_MMDDYYYY) : '-')}}</td>
                    <td>
                      <img class="img-fluid icon-dark" src="assets/images/icons/arrow2.png" alt="" />
                      <img class="img-fluid icon-light" src="assets/images/icons/arrow2-light.png" />
                    </td>
                    <td class="lms-text-light-purple">{{ (isEligible && afterLastExtensionDate && selectedExtension ? (afterLastExtensionDate  | date:DATE_FORMAT_MMDDYYYY) : '-' )}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mt-4">
            {{ acknowledgement }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <div>
        <ng-container *ngFor="let errorMsg of errorMessage">
          <ng-container *ngIf="errorMsg">
            <span class="d-block font-size-14 lms-text-light-pink">{{ errorMsg }}</span>
          </ng-container>
        </ng-container>
      </div>

      <div>
        <button type="button" [disabled]="!isEligible"  class="btn btn-success-light btn-150"  (click)="onFormSubmit()" *ngIf="isExtPermission">
          {{'Process'|translate}}
        </button>
        <button type="button" class="btn btn-pink btn-150" (click)="openGetApprovalModal(GetApprovalModal)" *ngIf="!isExtPermission">
          {{'Get Approval'|translate}}
        </button>
      </div>
    </div>
</form>
</div>
</div>
<!-- Override Modal -->
<ng-template #GetApprovalModal let-modal>
  <div class="draggable-container" cdkDrag cdkDragBoundary=".modal" cdkDragRootElement="draggable-container">
    <div class="draggable-modal-content cursor-pointer" cdkDragHandle>

    <div class="modal-header " >
      <h6 class="modal-title text-center mb-3">{{'Get Approval'|translate}}</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form #f="ngForm" [formGroup]="appform" (ngSubmit)="verifyPin()" novalidate>
    <div class="modal-body pb-0 px-0">


      <div class="lms-module-card">
        <div class="card-body">
          <div class="card-form form-vertical">
            <div class="form-group">
              <label class="form-group-label">{{'Initials'|translate}}</label>
              <input type="text" name="employee_initials" formControlName="employee_initials" class="form-control form-control-sm" value="EB1" />
              <val-errors controlName="employee_initials">
                <ng-template valError="required">{{'Initial is required'|translate}}</ng-template>
              </val-errors>
            </div>
            <div class="form-group">
              <label class="form-group-label">{{'PIN'|translate}}</label>
              <input type="password" name="pin" formControlName="pin" class="form-control form-control-sm" value="212121" />
              <val-errors controlName="pin">
                <ng-template valError="required">{{'PIN is required'|translate}}</ng-template>
              </val-errors>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" (click)="this.appform.valid ?  modal.dismiss('Cross click') : ''" class="btn btn-primary btn-block">
        {{'Get Approval'|translate}}
      </button>
    </div>
  </form>
  </div>
  </div>
  </ng-template>
