import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ceil } from 'lodash';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { Utility } from 'src/app/core/utils/utility';
import { LoanDetail } from 'src/app/shared/models/loan/loan.detail.model';

@Component({
  selector: 'app-process-extension',
  templateUrl: './process-extension.component.html',
  styleUrls: ['./process-extension.component.scss']
})
export class ProcessExtensionComponent implements OnInit {
  @Input() loanID!: string;
  @Input() loanDetail!:LoanDetail;
  @Input() previousTransaction!: any;

  @ViewChild('f') formElement!: NgForm;
  form!: UntypedFormGroup;
  appform!: UntypedFormGroup;
  submitted: boolean = false;
  isDirty:boolean = false;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  afterInfo!:any;
  isExtPermission:boolean = false;
  errorMessage : any = [];
  processExtensionDetails : any = [];
  isEligible : boolean = false;
  extensionNeededList : any = [];
  afterExtensionDueDate! : any;
  afterLastExtensionDate : any =  new Date();
  beforeDetails! : any;
  extensionRemaining : any = 0 ;
  acknowledgement : string = '';
  possibleDueDate : any = [];
  total_extension_taken : any = 0;
  pastDueAfter : any = null;
  remainingExtForYear : any = null;
  selectedExtension: any = null; // Define a property to store the selected value
  constructor(
    private httpService:HttpService ,
    private msgService:MessageService,
    private modalService:NgbModal,
    private broadCastService:BroadcastService,
    private permissionService:PermissionService,
    public modal:NgbActiveModal,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en');
      this.afterInfo = '';
      this.isExtPermission = this.permissionService.isSpecialPermission('process_extensions');
    }

  ngOnInit(): void {
    this.processExtensionDetails = this.loanDetail?.process_extension_details;
    this.beforeDetails = {
      current_due_date : this.loanDetail?.current_due_date,
      current_amount_due : this.loanDetail?.current_amount_due,
      extension_granted : this.processExtensionDetails?.latest_process_extension?.extension_granted,
      latest_process_extension_date : this.processExtensionDetails?.latest_process_extension?.date
    }
    this.total_extension_taken = this.processExtensionDetails?.total_extension_taken || 0;
    this.total_extension_taken = this.total_extension_taken//parseInt( this.total_extension_taken)
    this.extensionRemaining = this.processExtensionDetails?.total_extension_remaining || 0;
    this.possibleDueDate = this.processExtensionDetails?.possible_due_dates;

    this.form = new UntypedFormGroup({
      loan_id: new UntypedFormControl(this.loanID, []),
      extension_needed: new UntypedFormControl(null, [Validators.required]),
    });
    this.form.valueChanges.subscribe((e) => (this.isDirty = true));
    this.checkEligibility(this.processExtensionDetails);


    this.appform = new UntypedFormGroup({
      // id: new FormControl(null, [Validators.required]),
      employee_initials: new UntypedFormControl(null, [Validators.required]),
      pin: new UntypedFormControl(null, [Validators.required]),
    });


  }



  checkEligibility(processExtensionDetails : any)
  {
    this.isEligible = processExtensionDetails?.eligible ? true : false;
    this.errorMessage = this.isEligible === false ? (processExtensionDetails?.eligibility_message || 'Unable to grabe portfolio details' ) : null;

    if(processExtensionDetails){
      const maxExtensionAllowedAtOnce = (((processExtensionDetails?.remaing_ext_for_year < processExtensionDetails?.loan_min_extension_allowed) && processExtensionDetails?.remaing_ext_for_year >= 0) ? ceil(processExtensionDetails?.remaing_ext_for_year) :  processExtensionDetails?.loan_min_extension_allowed )|| 0;
     // const maxExtensionAllowedAtOnce = processExtensionDetails?.loan_min_extension_allowed || 0;
      for(let i = 1; i <= maxExtensionAllowedAtOnce ; i++){
        if(i <= this.extensionRemaining){
          this.extensionNeededList.push(i);
        }
      }
    }
    this.acknowledgement = processExtensionDetails?.portfolio?.required_acknowledgement === 'TRUE' ?
    'Note : ' + processExtensionDetails?.portfolio?.extension_acceptable_acknowledgement + ' Required' : '';
  }

  verifyPin()
  {
    if(this.appform.valid)
      {
        const params = this.appform.getRawValue();
        const url = `${PathConfig.VERIFY_PIN}`;
        this.httpService.post(url, params).subscribe({
          next : (res) => {


            if(res?.message!='User not found' && res?.message!='User validation failed')
            {
          this.msgService.showSuccess(res?.message);
          this.isExtPermission=true;
          this.onSubmit();
            }
          else
          {
          this.msgService.showError(res?.message);
          }
            this.submitted = false;
            this.isDirty = false;
            this.appform.reset();


          },
          error : (err) => {
            const msgList = Utility.getErrorMessage(err.error);
            msgList.forEach((msg) => this.msgService.showError(msg));
          }
        });

      }
  }

  onExtensionNeededChange()
  {
    const extensionNeeded = Number(this.form.value?.extension_needed);
    if(extensionNeeded){
      let params = {loan_id:this.loanID,
        extension_required:extensionNeeded}
      // const url = `${PathConfig.PROCESS_EXTENTION_PREVIEW}?loan_id=${this.loanID}&extension_required=${extensionNeeded}`;
      const url = `${PathConfig.PROCESS_EXTENTION_PREVIEW}`;
      this.pastDueAfter = null;
      this.httpService.post(url,params).subscribe(
        (response) => {
          this.pastDueAfter = response?.preview_data?.remainingAmountDue ?? '-';
        },
        (err) => {
          this.msgService.showError(err.error.message);
        }
      );
      if(this.possibleDueDate.hasOwnProperty(extensionNeeded - 1)){
        this.afterExtensionDueDate = this.possibleDueDate[extensionNeeded - 1];
      }
    }
    else{
      this.afterExtensionDueDate=null;
      this.pastDueAfter = null;
    }
  }

  // on submit form
  onSubmit() {

    if (this.form.valid && !this.submitted) {
      this.submitted = true;
      const params = this.form.value;

      const url = `${PathConfig.LOAN_PROCESS_EXTENSION}`;
      this.httpService.post(url, params)
      .subscribe((response:any) => {
        if(response?.status === 0){
          this.errorMessage = response?.error;
          this.isEligible = false;
        }
        else{
          this.msgService.showSuccess(response.message);
          // this.afterInfo = response?.loan_process_extension?.after || '';
          this.beforeDetails = {
            current_due_date : response?.loan_details?.current_due_date,
            current_amount_due : response?.loan_details?.current_amount_due,
            extension_granted : response?.last_loan_process_extension?.extension_granted,
            latest_process_extension_date : response?.last_loan_process_extension?.date
          }
          this.extensionRemaining = response?.process_extension_details?.total_extension_remaining || 0;
          if(response?.process_extension_details?.eligible !== true){
            this.isEligible = false;
            this.errorMessage = response?.process_extension_details?.eligibility_message
          }
          this.submitted = false;
          this.isDirty = false;
          // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
          this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
          this.modal.dismiss('Cross click');
        }

      },
      (err:any) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.submitted = false;
      });
    }
  }

  // on Form submit
  onFormSubmit(){
    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

  // get approval
  openGetApprovalModal(content: any) {
    this.modalService.open(content, { centered: true, size: 'sm', windowClass:'draggable-container',
    backdrop:false });
  }



  // calculateRemainingAmountDuePlusSus(e:any){

  //   const periodPushed = Number(this.form.value?.extension_needed) ?? 0;
  //   if(periodPushed){
  //     this.pastDueAfter = 0;
  //     // let periodPushed = e?.target?.value;
  //     // periodPushed = periodPushed.charAt(0);
  //     let calcRemaining_amount_due_plus_sus = 0;

  //     calcRemaining_amount_due_plus_sus = this.previousTransaction?.remaining_amount_due_plus_sus - (periodPushed*this.previousTransaction?.current_scheduled_payment);
  //      calcRemaining_amount_due_plus_sus = Math.round((calcRemaining_amount_due_plus_sus + Number.EPSILON) * 100) / 100;

  //     let RemainingSuspenceBalanceForNonCash = this.calculateRemainingSuspenceBalanceForNonCash(this.previousTransaction?.current_scheduled_payment,
  //       this.previousTransaction?.percentage_to_roll,calcRemaining_amount_due_plus_sus)
  
  //     this.pastDueAfter = this.calculateRemainingAmountDueForNonCash(calcRemaining_amount_due_plus_sus,RemainingSuspenceBalanceForNonCash);
   //   }
  //   else{
  //     this.pastDueAfter = null;
  //   }
  // }

  // calculateRemainingSuspenceBalanceForNonCash(currentSchedulePayment:any, percentageToRoll:any, remainingAmountDuePlusSus:any){
  //   let H10 = currentSchedulePayment;
  //   let H30 = percentageToRoll;
  //   let H60 = remainingAmountDuePlusSus;
  //   let roundoffValue:any=0;
  //   roundoffValue = Math.round(((H60/H10) + Number.EPSILON) * 100) / 100;
  //   roundoffValue = roundoffValue.toFixed(0);
  //   let cal1 = H60 - ( H10 * (roundoffValue));
  //   // cal1 = Math.round(((cal1) + Number.EPSILON) * 100) / 100;
 //   let remainingSuspenceBalance = H60 < 0 ? 0: (cal1 > H10*(1-H30) ? 0 : cal1);
   //   return remainingSuspenceBalance;
  // }

  // calculateRemainingAmountDueForNonCash(remainingAmountDuePlusSus:any, remainingSuspenseBalance:any){
  //   return remainingAmountDuePlusSus < 0 ? 0 : (remainingAmountDuePlusSus - remainingSuspenseBalance);
  // }

}
