<!-- Main Error Wrapper Start -->
<div class="error-main-wrapper">
  <div class="error-inner-content">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-8">
          <h2 class="error-title font-weight-bold">Uh oh!</h2>
          <h5 class="error-sub-title font-weight-bold">
            You don’t have access to that page.
          </h5>
          <div class="error-description"><p>Error: 403</p></div>
          <a href="javascript:;" class="btn btn-primary" (click)="goBack()"
            >Go Back</a
          >
        </div>
        <div class="col-lg-4 py-5">
          <img class="img-fluid" src="assets/images/error/403.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Main Error Wrapper End -->
