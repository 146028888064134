import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictDirective } from './restrict.directive';


@NgModule({
  imports: [CommonModule],
  declarations: [
   RestrictDirective
  ],
  exports: [RestrictDirective]
})
export class DirectivesModule {
  static forRoot(): ModuleWithProviders<DirectivesModule> {
    return {
      ngModule: DirectivesModule
    };
  }
}
