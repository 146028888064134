import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { BroadcastService, HttpService, MessageService, PermissionService } from 'src/app/core';
import { ConstantConfig } from 'src/app/core/config/constant.config';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { PathConfig } from 'src/app/core/config/path.config';
import { Utility } from 'src/app/core/utils/utility';
import { LoanDetail } from 'src/app/shared/models/loan/loan.detail.model';



@Component({
  selector: 'app-due-date',
  templateUrl: './due-date.component.html',
  styleUrls: ['./due-date.component.scss']
})
export class DueDateComponent implements OnInit {
  @Input() loanID!: string;
  @Input() loanDetail!:LoanDetail;
  @ViewChild('f') formElement!: NgForm;
  form!: UntypedFormGroup;
  submitted: boolean = false;
  isDirty:boolean = false;
  appform!: UntypedFormGroup;
  DATE_FORMAT_MMDDYYYY = ConstantConfig.DATE_FORMAT_MMDDYYYY;
  isDueDatePermission:boolean = false;
  current_due_date:any;
  new_due_date:any;
  is_new_due_date_invalid:boolean=false;
  is_new_due_date_past_date:boolean=false;
  is_new_due_date_grater_29days:boolean=false;
  is_new_due_date_not_selected:boolean=true;
  nextmonthdate:any;
  constructor( private httpService:HttpService ,
    private msgService:MessageService,
    private broadCastService:BroadcastService,
    private modalService:NgbModal,
    private permissionService:PermissionService,
    public modal:NgbActiveModal) { }

  ngOnInit(): void {

    this.form = new UntypedFormGroup({
    // loan_id:new FormControl(this.loanID, []),
      current_due_date: new UntypedFormControl('', [Validators.required]),
    });
    this.current_due_date=  this.loanDetail?.current_due_date;
    if(this.loanDetail?.any_active_side_note_exist)
      this.form.disable();

  }



  getDif(curdate:string)
  {

    this.is_new_due_date_not_selected=false;
    let today=new Date();
    let tod=Date.parse(today.toString());
    let d1 = Date.parse(curdate);
    let d2 = Date.parse(this.current_due_date);
    // this.is_new_due_date_past_date=false;
    // this.is_new_due_date_invalid=false;
//     if(d1<tod)
//     {
// this.is_new_due_date_past_date=true;
//     }
//     else
//     {
//       this.is_new_due_date_past_date=false;
//     }

      this.nextmonthdate =moment(curdate, 'YYYY-MM-DD').add(1, 'M').format('YYYY-MM-DD hh:mm:ss');
      this.new_due_date=curdate;
      if(d1<=d2)
      this.is_new_due_date_invalid=true;
      else
      this.is_new_due_date_invalid=false;


      let date1: Date = new Date(curdate);
      let date2: Date = new Date(this.current_due_date);

      let timeInMilisec: number = date1.getTime() - date2.getTime();
      let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));

      if(daysBetweenDates>29)
      this.is_new_due_date_grater_29days=true;
      else
      this.is_new_due_date_grater_29days=false;




   }
  onSubmit() {

    if (this.form.valid && !this.submitted && !this.loanDetail?.any_active_side_note_exist) {
      this.submitted = true;
      const params = this.form.value;


      const url = `${PathConfig.DUE_DATE_UPDATE}/`+this.loanID;
      this.httpService.post(url, params)
      .subscribe((response:any) => {
        this.msgService.showSuccess(response.message);
       // this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN);
       this.broadCastService.broadcast(MsgConstantConfig.REFRESH_QUEUE_DETAIL_DATA);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_LOAN_TRANSACTION_LIST);
        this.broadCastService.broadcast(MsgConstantConfig.REFRESH_ACCOUNT_HISTORY_LIST);
        this.submitted = false;
        this.isDirty = false;
        this.modalService.dismissAll();
      },
      (err:any) => {
        const msgList = Utility.getErrorMessage(err.error);
        msgList.forEach((msg) => this.msgService.showError(msg));
        this.submitted = false;
      });
    }
  }

  // on Form submit
  onFormSubmit(){
    this.formElement.ngSubmit.emit();
    (this.formElement as any).submitted = true;
    this.onSubmit();
  }

}
