import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MsgConstantConfig } from 'src/app/core/config/message.constant.config';
import { BroadcastService } from 'src/app/core/services/broadcaster.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loading-bar-modal',
  templateUrl: './loading-bar-modal.component.html',
  styleUrls: ['./loading-bar-modal.component.scss']
})
export class LoadingBarModalComponent implements OnInit {

  //messages that are displayed on the pop up
  @Input() message ='';
  @Input() message2 = '';
  //if time_bar gets true bar progress moves slowly
  @Input() time_bar? : any = false;
  //for stuck at 90 until process not complete
  @Input() slowSpeed? : number = 5000;
  percentage : number = 0;
  //if this pop up need to be draggable set this to true
  @Input() draggable : boolean = false;

  @Input() message3 = '';
  isProgressZero:boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public loaderService: LoaderService,
    private broadCastService:BroadcastService,
    private translateService:TranslateService
  ) {
    this.translateService.setDefaultLang('en');
    this.translateService.use(localStorage.getItem('Selected_Language') || 'en'); } 

  ngOnInit(): void {

    //this makes progress bar 100% full
    this.broadCastService.on(MsgConstantConfig.SHOW_HIDE_CLIENT_PROGRESS_BAR).subscribe((res:any) => {
      if(res){
        this.percentage=100;
      }
    });
    this.broadCastService.on(MsgConstantConfig.RESET_LOADING_BAR_PROGRESS).subscribe((res:any) => {
      if(res && !this.isProgressZero){
        this.percentage=0;
        this.isProgressZero=true;
      }
    });
    this.timer(this.time_bar);

  }

  //this determines if the bar will increase rapidly or slowly
  timer(time_b:any){
    //slower bar progress
    if(time_b){
      this.loaderService.hideLoader();
      setTimeout(()=> this.increaseLoaderPercentage(this.percentage),this.slowSpeed);
    }

    //rapid bar progress
    if(!time_b){
      this.loaderService.hideLoader();
    setTimeout(()=> this.increaseLoaderPercentage(this.percentage),1000);
    }
  }

  //increases the bar progress
  increaseLoaderPercentage(item:number){
    if( this.percentage<99){
    this.percentage+=1;
      return this.timer(this.time_bar);
    }

  }

}
