
import { AbstractControl, ValidatorFn } from '@angular/forms';
    
export function EmailOccurenceValidator(maxOccurence: number,delimiter:string =';'): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const occurence = control?.value?.split(delimiter) || [];
      if (control?.value && (occurence?.length > maxOccurence)) {
        return {maxOccurenceExceed: true};
      }
      return null;
    }; 
  }