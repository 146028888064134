import { AbstractControl } from '@angular/forms';

/**
Number Validation
--------------------
Valid formats:
12354
52
 */
export function EmailValidator(control: AbstractControl) {
  const regExp = /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/;
  if (control.value && !regExp.test(control.value)) {
    return { invalidEmail: "Invalid Email" };
  }
  return null;
}