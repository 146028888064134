import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth-service';
import { ClientDetailService } from '../services/client-detail-service';

@Injectable({
  providedIn: 'root'
})
class PermissionsService {
  constructor(private auth: AuthService, private router: Router,private clientDetailService:ClientDetailService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.auth.isAuthenticated()) {
      const url = !this.clientDetailService.CLIENT_ID ? '/lendisoft' : `/${this.clientDetailService.CLIENT_ID}`;
      this.router.navigate([url]);
      return false;
    }
    return true;
  }
}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}
