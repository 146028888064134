import { environment } from 'src/environments/environment';

export class PathConfig {
  public static get API_ENDPOINT(): string { return environment.API_URL; }
  public static AUTH_LOGIN_LOGOUT = `${PathConfig.API_ENDPOINT}/auth`;
  public static AUTH_2FA_VERIFICATION = `${PathConfig.API_ENDPOINT}/confirm_code`;
  public static AUTH_2FA_RESEND_CODE = `${PathConfig.API_ENDPOINT}/resend_code`;
  public static AUTH_FORGOT_PASSWORD = `${PathConfig.API_ENDPOINT}/forgot_password`;
  public static AUTH_RESET_PASSWORD = `${PathConfig.API_ENDPOINT}/reset_password`;
  public static USER_ME = `${PathConfig.API_ENDPOINT}/me`;
  public static VERIFY_PIN = `${PathConfig.API_ENDPOINT}/verify_pin`;

  public static UPDATE_PASSWORD = `${PathConfig.API_ENDPOINT}/update_password`;
  public static LS_CLIENTS = `${PathConfig.API_ENDPOINT}/clients`;
  public static LS_CLIENTS_DETAIL = `${PathConfig.API_ENDPOINT}/clients/detail`;
  public static UPLOAD_MEDIA = `${PathConfig.API_ENDPOINT}/medias`;
  public static UPLOAD_MEDIA_IMPORT = `${PathConfig.API_ENDPOINT}/medias/import_store`;
  public static UPLOAD_MEDIA_MAPPING = `${PathConfig.API_ENDPOINT}/medias/mapping_store`;
  public static CLIENT_MAINTENANCES = `${PathConfig.API_ENDPOINT}/client_maintenances`;
  public static USER_MAINTENANCES = `${PathConfig.API_ENDPOINT}/user_maintenances`;
  public static USERS = `${PathConfig.API_ENDPOINT}/users`;
  public static ROLES = `${PathConfig.API_ENDPOINT}/roles`;
  public static ROLE_CATEGORIES = `${PathConfig.API_ENDPOINT}/role_categories`;
  public static CLIENT_AUTH = `${PathConfig.API_ENDPOINT}/auth/client`;
  public static VERSIONS = `${PathConfig.API_ENDPOINT}/versions`;
  public static AUTH_RESET_PASSWORD_TOKEN_VALIDATE = `${PathConfig.API_ENDPOINT}/check_reset_password_token`;
  public static ROLES_PERMISSION_MODULES = `/assets/json/permission-modules.json`;
  public static AUDIT_TRAILS = `${PathConfig.API_ENDPOINT}/audit_trails`;
  public static NOTIFICATIONS = `${PathConfig.API_ENDPOINT}/notifications`;
  public static NOTIFICATION_CATEGORIES = `${PathConfig.API_ENDPOINT}/notification_categories`;
  public static ROLE_VERSIONS = `${PathConfig.API_ENDPOINT}/role_versions`;
  public static NOTIFICATION_VERSIONS = `${PathConfig.API_ENDPOINT}/notification_versions`;
  public static IMPORT_TOOL= `${PathConfig.API_ENDPOINT}/import_tools`;
  public static IMPORT_TOOL_VERIFY= `${PathConfig.IMPORT_TOOL}/verify`;
  public static IMPORT_TOOL_NEW_VERIFY= `${PathConfig.IMPORT_TOOL}/new-verify`;
  public static IMPORT_TOOL_VALIDATE= `${PathConfig.IMPORT_TOOL}/validate`;
  public static IMPORT_TOOL_VALIDATE_IMPORT= `${PathConfig.IMPORT_TOOL}/validateimport`;
  public static IMPORT_TOOL_STORE_IMPORT_DATA= `${PathConfig.IMPORT_TOOL}/data/store`;
  public static IMPORT_TOOL_EXPORT= `${PathConfig.IMPORT_TOOL}/data/export`;
  public static IMPORT_ERROR_EXPORT= `${PathConfig.IMPORT_TOOL}/errors/export`;
  public static MAPPING_TOOL_CATEGORY = `${PathConfig.API_ENDPOINT}/mapping_tool_categories`;
  public static MAPPING_TOOL_TEMPLATE = `${PathConfig.API_ENDPOINT}/mapping_tool_templates`;
  public static MAPPING_TOOL_TEMPLATE_DES_FILE = `${PathConfig.API_ENDPOINT}/mapping_tool_templates/destination_files`;
  public static MAPPING_TOOL_CONTROL_TTL_LIST = `/assets/json/control-total.json`;
  public static DATA_WAREHOUSE = `${PathConfig.API_ENDPOINT}/data_warehouses`;
  public static LOANS = `${PathConfig.API_ENDPOINT}/loans`;
  public static LOANSLIST = `${PathConfig.API_ENDPOINT}/loans/list`;
  public static LOANSLISTWITHTOTALS = `${PathConfig.API_ENDPOINT}/loans/list_with_totals`;
  public static GLOBAL_SEARCH = `${PathConfig.API_ENDPOINT}/loans/global_search`;
  public static LOAN_APPLICANTS = `${PathConfig.API_ENDPOINT}/loan_applicants`;
  public static LOAN_REFRENCES = `${PathConfig.API_ENDPOINT}/loan_references`;
  public static LOAN_COLLATERALS = `${PathConfig.API_ENDPOINT}/loan_collaterals`;
  public static LOANS_MANUAL_CHARGE_OFF = `${PathConfig.API_ENDPOINT}/loans/manual_charge_off`;
  public static IMPORT_TOOL_PROCESS = `${PathConfig.API_ENDPOINT}/import_tools/process`;
  public static IMPORT_TOOL_PROCESS_MAPPING = `${PathConfig.API_ENDPOINT}/import_tools/process_mapping`;
  public static NOTIFICATION_LOGS = `${PathConfig.API_ENDPOINT}/notification_logs`;
  public static LOAN_TRANSACTIONS = `${PathConfig.API_ENDPOINT}/loan_transactions`;
  public static LOAN_TRANSACTIONS_START_STOP_ACCURAL = `${PathConfig.API_ENDPOINT}/loan_transactions/store_system_transactions`;
  public static LOAN_TRANSACTIONS_REVERSAL = `${PathConfig.API_ENDPOINT}/loan_transactions/reversal`;
  public static LOAN_ACTIVITY_NOTES = `${PathConfig.API_ENDPOINT}/loan_activity_note`;
  public static LOAN_BANKRUPTSY = `${PathConfig.API_ENDPOINT}/loan_bankruptsies`;
  public static LOAN_ADDITIONAL_PRODUCTS = `${PathConfig.API_ENDPOINT}/loan_additional_products`;
  public static LOAN_TITLES = `${PathConfig.API_ENDPOINT}/loan_titles`;
  public static LOAN_ACTIVITY_NOTE_EXPORT = `${PathConfig.API_ENDPOINT}/loan_activity_note/export`;
  public static SEND_NOTIFICATION_PREVIEW = `${PathConfig.API_ENDPOINT}/notifications/send_preview`;
  public static LOAN_ACC_HISTORY = `${PathConfig.API_ENDPOINT}/account_histories`;
  public static LOAN_ROUTE = `${PathConfig.API_ENDPOINT}/loans/route`;
  public static PBAR_BUILDERS = `${PathConfig.API_ENDPOINT}/pbar_builders`;
  public static ODDS_ROLL_BUILDERS = `${PathConfig.API_ENDPOINT}/odds_roll_builders`;
  public static PAYMENT_INFO_CODES = `${PathConfig.API_ENDPOINT}/standard_configurations/payment_info_codes`;
  public static PAYMENT_METHODS = `${PathConfig.API_ENDPOINT}/standard_configurations/payment_methods`;
  public static INPUT_SCOREBOARD_CATEGORY = `${PathConfig.API_ENDPOINT}/input_scorecard_categories`;
  public static INPUT_SCOREBOARD = `${PathConfig.API_ENDPOINT}/input_scorecards`;
  public static INPUT_SCOREBOARD_TEST = `${PathConfig.API_ENDPOINT}/input_scorecards/test`;
  public static NBAR_BUILDERS = `${PathConfig.API_ENDPOINT}/nbar_builders`;
  public static ACTIVITY_TYPES = `${PathConfig.API_ENDPOINT}/activity_types`;
  public static LOAN_REAMORTIZATIONS = `${PathConfig.API_ENDPOINT}/loan_reamortizations`;

  public static LOAN_PROCESS_EXTENSION = `${PathConfig.API_ENDPOINT}/loan_porcess_extensions`;
  public static LOAN_PREVIOUS_TRANSACTION = `${PathConfig.API_ENDPOINT}/loan_transactions/previous-transaction`;
  public static DUE_DATE_UPDATE = `${PathConfig.API_ENDPOINT}/loans/due_date_update`;

  public static PAY_OFF = `${PathConfig.API_ENDPOINT}/loans/payoff`;

  public static WAIVE_OFF_FEE = `${PathConfig.API_ENDPOINT}/loans/waive_off`;
  public static SUBMIT_REPO_REVIEW = `${PathConfig.API_ENDPOINT}/loan_repo_reviews`;
  public static ASSIGNMENT = `${PathConfig.API_ENDPOINT}/assignments`;
  // public static ASSIGNMENT_PREVIEW_DATA = `${PathConfig.API_ENDPOINT}/assignments/preview-data`;
  public static ASSIGNMENT_PREVIEW_DATA = `${PathConfig.API_ENDPOINT}/assignments/pre-data`;

  public static ASSIGNMENT_PROCESSING_STATUS= `${PathConfig.API_ENDPOINT}/system/processing_status`;

  public static ASSIGNED_DEPARTMENT = `${PathConfig.API_ENDPOINT}/assignments/department/users`;
  public static ASSIGNMENT_CATEGORIES = `${PathConfig.API_ENDPOINT}/assignment_categories`;
  public static ASSIGNMENT_VERSIONS = `${PathConfig.API_ENDPOINT}/assignment_versions`;
  public static STANDARD_CONFIGURATIONS = `${PathConfig.API_ENDPOINT}/standard_configurations`;
  public static LOAN_FORECASTED_TRANSACTIONS = `${PathConfig.API_ENDPOINT}/loan_forecasted_transactions`;
  public static IMPORT_TOOL_ATTR_INFO = `${PathConfig.API_ENDPOINT}/import_tools/process/attribute/info`;
  public static IMPORT_TOOL_ATTR_INFO_MAPPING = `${PathConfig.API_ENDPOINT}/import_tools/process_field_mapping`;
  public static LOAN_REPO_REVIEWS_KICKBACK = `${PathConfig.API_ENDPOINT}/loan_repo_reviews/kickback`;
  public static CAMPAIGN_BUILDER = `${PathConfig.API_ENDPOINT}/campaigns`;
  public static GOAL_REPORT = `${PathConfig.API_ENDPOINT}/reports/goal_report`;
  public static REPORT_SETTING = `${PathConfig.API_ENDPOINT}/report_settings`;
  public static LTV_CHART = `${PathConfig.API_ENDPOINT}/ltv_chart/data`;
  public static MAKES_LIST = `${PathConfig.API_ENDPOINT}/makes`;
  public static INPUT_SCORECARD_VERSION = `${PathConfig.API_ENDPOINT}/input_scorecard_versions`;
  public static CHAMPION_CHALLENGER_REPORT = `${PathConfig.API_ENDPOINT}/reports/champion_challenger_report`;
  public static CHAMPION_CHALLENGER_REPORT_DATA = `${PathConfig.API_ENDPOINT}/champion_challenger_report_data`;
  public static VENDOR_CONFIGURATIONS = `${PathConfig.API_ENDPOINT}/vendor_configurations`;
  public static TEXT_TEMPLATES = `${PathConfig.API_ENDPOINT}/text_templates`;
  public static LOAN_ASSIGNMENT_LOGS = `${PathConfig.API_ENDPOINT}/department/assignment/details`;
  public static STD_SETTING_SUMMARY_CHART = `${PathConfig.API_ENDPOINT}/standard_configurations/summary_chart`;
  public static STATE_LIST = `${PathConfig.API_ENDPOINT}/standard_configurations/states`;
  public static FORGOT_PIN = `${PathConfig.API_ENDPOINT}/forgot_pin`;
  public static AUTH_RESET_PIN_TOKEN_VALIDATE = `${PathConfig.API_ENDPOINT}/check_reset_pin_token`;
  public static AUTH_RESET_PIN = `${PathConfig.API_ENDPOINT}/reset_pin`;
  public static NOTIFICATION_SHOW_PREVIEW = `${PathConfig.API_ENDPOINT}/notifications/show_preview`;
  public static DASHBOARD_DATA = `${PathConfig.API_ENDPOINT}/dashboard`;

  public static WATERFALL_DELETE = `${PathConfig.API_ENDPOINT}/waterfall/delete/:id`;
  public static REQUEST_VBT = `${PathConfig.API_ENDPOINT}/request_vbt`;
  public static CONFIRM_VBT = `${PathConfig.API_ENDPOINT}/confirm_vbt`;
  public static TEXT_MESSAGES = `${PathConfig.API_ENDPOINT}/text_messages`;
  public static SEND_TEMPLATES_MESSAGES = `${PathConfig.API_ENDPOINT}/send_template_message`;
  public static SEND_FREE_MESSAGES = `${PathConfig.API_ENDPOINT}/send_message`;

  public static LOAN_DETAILS = `${PathConfig.API_ENDPOINT}/text_messages/loan_details`;
  public static SBT_TEXT_TEMPLATES = `${PathConfig.API_ENDPOINT}/templates_from_sbt`;
  public static SYNC_TEXT_TEMPLATES = `${PathConfig.API_ENDPOINT}/sync_templates_from_sbt`;
  public static SUB_STATUS_LIST = `${PathConfig.API_ENDPOINT}/standard_configurations/sub_statuses`;
  public static SBT_TEXT_READ = `${PathConfig.API_ENDPOINT}/text_messages/read`;
  public static SBT_PARSE_TEMPLATE = `${PathConfig.API_ENDPOINT}/text_messages/parse_template`;

  public static PROCESSING_STATUS = `${PathConfig.API_ENDPOINT}/system/processing_status`
  public static UNREAD_COUNT = `${PathConfig.API_ENDPOINT}/text_messages/unread_count`
  public static API_KEYS = `${PathConfig.API_ENDPOINT}/system/api-keys`
  public static FILES_CATEGORIES = `${PathConfig.API_ENDPOINT}/categories`
  public static FILES_SUB_CATEGORIES = `${PathConfig.API_ENDPOINT}/sub_categories`
  public static FILES_LIST = `${PathConfig.API_ENDPOINT}/report_files`
  public static TEMP_FILE = `${PathConfig.API_ENDPOINT}/temporary_url`
  public static PROCESS_EXTENTION_PREVIEW = `${PathConfig.API_ENDPOINT}/process_extension/preview`
  public static PMTS_CHART = `${PathConfig.API_ENDPOINT}/pmt_chart/data`;
  public static BUCKET_CHART = `${PathConfig.API_ENDPOINT}/bucket-view/data`;
  public static DASHBOARD_GOAL_DATA_POST = `${PathConfig.API_ENDPOINT}/dashboard/chartUpdate`;
  public static BUILDER_CATEGORIES = `${PathConfig.API_ENDPOINT}/builder_categories`;
  public static CONSULTING_SERVICE_BUILDERS = `${PathConfig.API_ENDPOINT}/consulting_service_builders`;
  public static FILE_DOWNLOAD = `${PathConfig.API_ENDPOINT}/consulting_service_builders/download`;
  public static LS_CLIENTS_TOKEN = `${PathConfig.API_ENDPOINT}/clients/generate_token`;
  public static REPORT_DATA_WAREHOUSE = `${PathConfig.API_ENDPOINT}/report_data_attributes`;
  public static DATA_WAREHOUSE_CUSTOM_FIELD = `${PathConfig.API_ENDPOINT}/custom_data_attributes`;
  public static DATA_WAREHOUSE_CUSTOM_FIELD_CATEGORY = `${PathConfig.API_ENDPOINT}/data_attribute_categories`;
  public static LOAN_COMPANY = `${PathConfig.API_ENDPOINT}/loan_companies`;
  public static LOAN_COMPANY_SS = `${PathConfig.API_ENDPOINT}/companies`;
  public static REPORT_DATA_EXIST = `${PathConfig.API_ENDPOINT}/report_files/yearMonthReportDataExist`;
  public static CUSTOM_VENDOR_CONFIG = `${PathConfig.API_ENDPOINT}/custom_vendor_configurations/configurations_data`;

  public static LOANS_TYPE = `${PathConfig.API_ENDPOINT}/loans/type`;
  public static TEXT_MESSAGES_LOANS = `${PathConfig.API_ENDPOINT}/text_messages/applicant_messages`;
  public static SUBMIT_KICKBACK_PREVIEW = `${PathConfig.API_ENDPOINT}/repo_reviews/preview`;
  

  public static LOAN_CPI_SIDE_NOTE = `${PathConfig.API_ENDPOINT}/loan_side_note`;
  public static LOAN_DOWN_PAYMENT = `${PathConfig.API_ENDPOINT}/loan_down_payments`;
  public static LOAN_DOWN_PAYMENT_POST = `${PathConfig.API_ENDPOINT}/loan_down_payments/bulk_update`;
  

  public static LOANS_MANUAL_CHARGE_OFF_PREVIEW = `${PathConfig.API_ENDPOINT}/loans/manual_charge_off_preview`;
  public static LOANS_MANUAL_CHARGE_OFF_LOG = `${PathConfig.API_ENDPOINT}/loans/manual_charge_off_log`;
  public static LOANS_MANUAL_CHARGE_OFF_ADD_INVENTORY = `${PathConfig.API_ENDPOINT}/loans/manual_charge_off_add_inventory`;

  public static PAYMENT_MERCHANTS= `${PathConfig.API_ENDPOINT}/payment_merchants`;
  public static PAYMENT_PORTALS= `${PathConfig.API_ENDPOINT}/payment_portals`;
  public static CLR_CONFIGURATION = `${PathConfig.API_ENDPOINT}/vendor_configurations/clear_configuration`;

  public static MAKE_CALL = `${PathConfig.API_ENDPOINT}/make_call`;
  public static AGENT_CALL_STATUS = `${PathConfig.API_ENDPOINT}/agent_status`;
  public static ACTIVITY_CODES = `${PathConfig.API_ENDPOINT}/activity_codes`;
  public static CALL_HANG_UP_AND_POST_DISPOSITION = `${PathConfig.API_ENDPOINT}/hang_up_and_post_disposition`;
  

  public static RETRY_IMPORT_BG_PROCESS = `${PathConfig.API_ENDPOINT}/import_tools/retry`;
  public static LOAN_DEFERRED_SALES_TAX_TRANSACTIONS = `${PathConfig.API_ENDPOINT}/loan_deferred_sale_tax`;
}

